import React, { useEffect, useState } from "react";

interface Props {
  setFilter: (mode: any) => void;
}

const MenuFooter: React.FC<Props> = ({ setFilter }) => {
  const [showEvents, setShowEvents] = useState(true);
  const [showMeetings, setShowMeetings] = useState(true);
  const [showTasks, setShowTasks] = useState(true);

  const [hideCompletedTasks, setHideCompletedTasks] = useState(false);
  const [hideDoneTasks, setHideDoneTasks] = useState(false);
  const [hideEveryDayTasks, setHideEveryDayTasks] = useState(false);

  useEffect(() => {
    setFilter(() => {
      return {
        tasks: showTasks,
        events: showEvents,
        meetings: showMeetings,
        hideDone: hideDoneTasks,
        hideCompleted: hideCompletedTasks,
        hideEveryDay: hideEveryDayTasks,
      };
    });
  }, [showEvents, showMeetings, showTasks, hideCompletedTasks, hideDoneTasks, hideEveryDayTasks]);

  return (
    <div className="menu_footer">
      <div className="footer-head">
        <div className="show">
          <div className="title">Показать</div>
          <div className="show_settings">
            <div className="show_events">
              <div className="show_events-checkbox">
                <label className="checkbox-container">
                  <input
                    type="checkbox"
                    checked={showTasks}
                    onChange={() => setShowTasks(!showTasks)}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="show_events-title">Задачи</div>
            </div>
            <div className="show_meetings">
              <div className="show_events-checkbox">
                <label className="checkbox-container">
                  <input
                    type="checkbox"
                    checked={showMeetings}
                    onChange={() => setShowMeetings(!showMeetings)}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="show_meetings-title">Совещания</div>
            </div>
            <div className="show_tasks">
              <div className="show_events-checkbox">
                <label className="checkbox-container">
                  <input
                    type="checkbox"
                    checked={showEvents}
                    onChange={() => setShowEvents(!showEvents)}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="show_tasks-title">События</div>
            </div>
          </div>
        </div>
        {showTasks && (
          <div className="hide">
            <div className="title">Скрыть</div>
            <div className="show_settings">
              <div className="show_events">
                <div className="show_events-checkbox">
                  <label className="checkbox-container">
                    <input
                      type="checkbox"
                      checked={hideCompletedTasks}
                      onChange={() =>
                        setHideCompletedTasks(!hideCompletedTasks)
                      }
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="show_events-title">Завершенные задачи</div>
              </div>
              <div className="show_meetings">
                <div className="show_events-checkbox">
                  <label className="checkbox-container">
                    <input
                      type="checkbox"
                      checked={hideDoneTasks}
                      onChange={() => setHideDoneTasks(!hideDoneTasks)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="show_meetings-title">Выполненные задачи</div>
              </div>
              <div className="show_tasks">
                <div className="show_events-checkbox">
                  <label className="checkbox-container">
                    <input
                      type="checkbox"
                      checked={hideEveryDayTasks}
                      onChange={() => setHideEveryDayTasks(!hideEveryDayTasks)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="show_tasks-title">Ежедневные задачи</div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="prioritets">
        <div className="title">Приоритеты задач</div>
        <div className="prioritet_container">
          <div className="left">
            <div className="prioritet">
              <div className="red_oval"></div>
              <div className="prioritet_title">Срочный</div>
            </div>
            <div className="prioritet">
              <div className="prioritet">
                <div className="gray_oval"></div>
                <div className="prioritet_title">Низкий</div>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="prioritet">
              <div className="orange_oval"></div>
              <div className="prioritet_title">Высокий</div>
            </div>
            <div className="prioritet">
              <div className="prioritet">
                <div className="green_oval"></div>
                <div className="prioritet_title">Средний</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuFooter;
