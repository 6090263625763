import React from "react";
import { connect, useSelector } from "react-redux";
import { State } from "../../../rootReducer";
import { Role } from "../../types";
import styled, { keyframes } from "styled-components";
import { columnsOfTables } from "../../shared_data";
import LocationMeet from '../Icon/imgs/LocationMeet.svg'

type Props = {
  name: string;
  desc: string | undefined; // undefined потому что по задаче может быть еще не назначено исполнителя. Из ProjectTaskTr, TaskTr может быть undefined
  roles: Role[];
  className?: string;
  notificationBall?: boolean;
  mark?: boolean;
  id?: number;
  width?: boolean;
};

const WrapperNameCell = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  line-height: 16px;
  /* max-width: ${columnsOfTables[1].style.minWidth}; */
  min-width: ${columnsOfTables[2].style.maxWidth};
  width: ${({ widthLocal }) => widthLocal && "300px"};
  min-width: 65%;
  max-width: 75%;
`;
const TitleStyle = styled.div`
  // padding-top: 8px;
  letter-spacing: 0.15px;
  font-weight: 400;
  font-size: 16px;
  color: ${({ active }) => (active ? "#02405E" : "#212121")};
  white-space: nowrap; /* Запрещаем перенос строк */
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  padding-right: 4px;
  text-overflow: ellipsis; /* Добавляем многоточие */
  width: 400px;
`;

const blinking = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const MarkerNewTask = styled.div`
  animation: ${blinking} 2s linear infinite;
  opacity: 1;
  border-radius: 100%;
  max-width: 8px;
  width: 100%;
  height: 8px;
  background-color: #1baaf0;
  margin-top: 15px;
  position: absolute;
  left: 115px;
  top: 9px;
  // margin-right: 8px;
`;

const DescriptionStyle = styled.div`
  font-weight: normal;
  font-size: 14px;
  color: rgba(41, 42, 52, 0.5);
  white-space: nowrap; /* Запрещаем перенос строк */
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  padding-right: 4px;
  text-overflow: ellipsis; /* Добавляем многоточие */
  width: ${({ widthLocal }) => widthLocal && "300px"};
`;

const NameCell: React.FC<Props> = ({
  name,
  mark = false,
  desc,
  id,
  width = false,
}) => {
  const selectTaskId = useSelector(
    (state: State) => state.taskInfoPlate.selectedTask
  );

  return (
    <WrapperNameCell widthLocal={width ? width : undefined}>
      <div
        style={
             desc ?
             {display: "flex", lineHeight: "22px", position: "relative" }
            : { display: "flex", paddingTop:"10px", position: "relative" }
        }
      >
        {/* {<MarkerNewTask />} */}
        <TitleStyle title={name} active={id === selectTaskId?.id}>
          {name}
        </TitleStyle>
      </div>
      <DescriptionStyle
        title={desc}
        widthLocal={width ? width : undefined}
        active={id === selectTaskId?.id}
      >
  {/*    <img src={LocationMeet} alt="" />  {desc}  */}
      </DescriptionStyle>
    </WrapperNameCell>
  );
};

const mapStateToProps = (state: State) => {
  return {
    roles: state.commonInfo.roles,
  };
};

export default connect(mapStateToProps)(NameCell);
