export function throttle(func: Function, ms: number) {
    let isThrottled = false,
        savedArgs: any[] | null,
        savedThis: any;

    const wrapper = (...args: any[]) => {

        if (isThrottled) {
            savedArgs = args;
            return;
        }

        func.apply(null, args);

        isThrottled = true;

        setTimeout(function () {
            isThrottled = false
            if (savedArgs) {
                wrapper.apply(savedThis, savedArgs);
                savedArgs = savedThis = null;
            }
        }, ms);
    }

    return wrapper;
}