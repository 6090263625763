import { types, NewTaskAction } from "./action_types";
import { Reducer } from "redux";
import { Schedule, Section } from "../../common/types";
import { cookieMaster } from "../../utils/CookieMaster";

// const ms = Date.now()
// const beg = (new Date(ms + 3600000 - ms % 3600000)).toLocaleString().replace(/\./g, '-').split(',').join('')
// const end = (new Date(ms + 7200000 - ms % 3600000)).toLocaleString().replace(/\./g, '-').split(',').join('')

// PLEASE  don`t use toLocaleString() method, because his work different in different browsers.
// Instead this, use custom dateFormatUTC() method
const begin = dateFormatUTC(Date.now());
const end = dateFormatUTC(Date.now() + 3600000);

function dateFormatUTC(arg) {
  let date = new Date(arg);
  let dateNumber: any = date.getDate();
  let month: any = date.getMonth() + 1; // because numeration of month starts from 0
  let hours: any = date.getHours();
  let minutes: any = date.getMinutes();
  let seconds: any = date.getSeconds();
  if (month < 10) month = "0" + month;
  if (dateNumber < 10) dateNumber = "0" + dateNumber;
  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;
  if (seconds < 10) seconds = "0" + seconds;

  return (
    dateNumber +
    "-" +
    month +
    "-" +
    date.getFullYear() +
    " " +
    hours +
    ":" +
    minutes +
    ":" +
    seconds
  );
}

const inititalState = {
  id: 0,
  name: "",
  description: "",
  begin: begin,
  end: end,
  author_id: parseInt(cookieMaster.getCookie("user_id")) as number | null,
  task_load: 1,
  work_load: 100,
  provide_to: 0,
  project_id: null as number | null,
  executor_id: null as number | null,
  priority_id: 0,
  task_status_id: 1,
  workflow_id: 1,
  status_id: 1,
  lastChangedValue: "task_load",
  project_section_id: null as number | null,
  isSendingTask: false, //*
  executorSchedule: null as Schedule | null, //*
  projectSections: [] as Section[],
  next_id: null as number | null,
  parent_id: null as number | null,
  prev_id: null as number | null,
  child_ids: [] as number[],
  delegated: false,
  delegatedTo: null as number | null,
  successfulCreatedTask: [] as any[], // этот параметр используется только для делегирования задач пользователям. Компонент ExecutorSelectWithDelegate
  id_bp: null as number | null ,
  hasBP: false,
  private:false as boolean
};
export type NewTaskState = typeof inititalState;

const reducer: Reducer<NewTaskState, NewTaskAction> = (
  state = inititalState,
  action
) => {
  switch (action.type) {
    case types.SET_TASK_NAME:
      return {
        ...state,
        name: action.name,
      };
    case types.SET_TASK_DESCRIPTION:
      return {
        ...state,
        description: action.description,
      };
    case types.CLEAR_TASK:
      return inititalState;
    case types.SET_TASK_PRIORITY:
      return {
        ...state,
        priority_id: action.priority,
      };
    case types.SET_TASK_PROJECT:
      return {
        ...state,
        project_id: action.projectId,
      };
    case types.SET_TASK_EXECUTOR:
      return {
        ...state,
        executor_id: action.executor_id,
      };
    case types.SET_TASK_BEGIN:
      return {
        ...state,
        begin: action.begin,
      };
    case types.SET_TASK_END:
      return {
        ...state,
        end: action.end,
      };
    case types.SET_IS_FETCHING_TASK:
      return {
        ...state,
        isSendingTask: action.isSending,
      };
    case types.SET_TASK_SECTION:
      return {
        ...state,
        project_section_id: action.id,
      };
    case types.SET_TASK_WORKLOAD:
      return {
        ...state,
        work_load: action.workLoad,
      };
    case types.SET_TASKLOAD:
      return {
        ...state,
        task_load: action.taskLoad,
      };
    case types.SET_EXECUTOR_SCHEDULE:
      return {
        ...state,
        executorSchedule: action.schedule,
      };
    case types.OPEN_EXISTING_TASK:
      return {
        ...state,
        ...action.task,
      };
    case types.SET_PROJECT_SECTIONS:
      return {
        ...state,
        projectSections: action.sections,
      };
    case types.SET_PREV_TASK:
      return {
        ...state,
        prev_id: action.id,
      };
    case types.SET_NEXT_TASK:
      return {
        ...state,
        next_id: action.id,
      };
    case types.SET_PROVIDE_TO:
      return {
        ...state,
        provide_to: action.val,
      };
    case types.SET_PARENT_TASK:
      return {
        ...state,
        parent_id: action.id,
      };
    case types.SET_CHILD_TASKS:
      return {
        ...state,
        child_ids: action.ids,
      };
    case types.SET_DELEGATE_TO:
      return {
        ...state,
        delegatedTo: action.delegateTo,
      };
    case types.SET_LAST_CHANGED:
      return {
        ...state,
        lastChangedValue: action.arg,
      };
    case types.SUCCESSFUL_DELEGATED_NEW_TASK:
      return {
        ...state,
        successfulCreatedTask: [...state.successfulCreatedTask, action.val],
      };

    default:
      return state;
  }
};

export default reducer;
