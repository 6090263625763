export function droppedItemsAnalize(callback, ...items): void {
    items.forEach((item) => {
        if (item instanceof DataTransferItem) item = item.webkitGetAsEntry()
        if (item.isDirectory) {
            let directoryReader = item.createReader()
            directoryReader.readEntries((ent) => {
                ent.forEach(e => droppedItemsAnalize(callback, e))
            })
        } else if (item.isFile) {
            item.file(file => {
                callback(file)
            })
        }
    })
}