import {
  types,
  setCompanyAction,
  setPlateEditModeAction,
  setInitialCompanyAction,
  // updateComments,
} from './action_types';

export const setCompany = (data): setCompanyAction => ({
  type: types.SET_COMPANY,
  data,
});


export const setPlateEditMode = (value): setPlateEditModeAction => ({
  type: types.SET_PLATE_EDIT_MODE,
  value,
});

export const setInitial = (): setInitialCompanyAction => ({
  type: types.SET_INITIAL,
});