import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { StatusContext } from "../../../context/status";
import NameOfStep from "../templates/NameOfStep";
import TextAreaOrInputElement from "../templates/TextAreaOrInputElement";
import "../BpResultForm.scss";

const CheckOfFinanceDirector = () => {
  const {
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,

    company_id,
    setloadDataNow,
  } = useContext(StatusContext);

  const [data, setData] = useState(
    settingsOfFields?.checkOfFinanceDirector?.extra
      ? settingsOfFields.checkOfFinanceDirector.extra
      : {}
  );

  const url = taskId ? `${apiBp}/task/${taskId}` : null;
  // console.log(taskId);
  useEffect(() => {
    console.log("use in form worked");
    if (taskId) {
      axios
        .patch(
          url,
          { ...settingsOfFields.checkOfFinanceDirector, extra: data },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))
        // .then(() => setInputValue())

        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);

  // console.log("     ", );

  return (
    <form id="checkOfFinanceDirector">
      <div>
        <NameOfStep text={"Комментарии по заключению юристов"} />
        <div>
          <TextAreaOrInputElement
            name={"f6"}
            isInput={false}
            type={"text"}
            data={data}
            setData={setData}
            placeholder={
              "* Обязательно прочтение договора и всех комментариев бухгалтеров, протокола разногласий и комментарий по вопросам и дополнениям"
            }
          />
        </div>
        {/* <div className="warningText">
          * Обязательно прочтение договора и всех комментариев бухгалтеров,
          протокола разногласий и комментарий по вопросам и дополнениям
        </div> */}
      </div>
      <div>
        <NameOfStep text={"Комментарии по заключению главного бухгалтера"} />
        <div>
          <TextAreaOrInputElement
            name={"f6_1"}
            isInput={false}
            type={"text"}
            data={data}
            setData={setData}
            placeholder={
              "* Обязательно прочтение договора и всех комментариев бухгалтеров, протокола разногласий и комментарий по вопросам и дополнениям"
            }
          />
        </div>
      </div>
      <div>
        <NameOfStep
          text={"Утверждение альтернативных коммерческих предложений"}
        />
        <div>
          <TextAreaOrInputElement
            name={"f6_2"}
            isInput={false}
            type={"text"}
            data={data}
            setData={setData}
          />
        </div>
      </div>
      <div>
        <NameOfStep text={"Комментарии и уведомление отдела бух учета"} />
        <div>
          <TextAreaOrInputElement
            name={"f6_3"}
            isInput={false}
            type={"text"}
            data={data}
            setData={setData}
          />
        </div>
      </div>
    </form>
  );
};

export default CheckOfFinanceDirector;
