import React from 'react';

const DefaultIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.125 5.46875H21.875V18.75C21.875 18.9572 21.7927 19.1559 21.6461 19.3024C21.4996 19.4489 21.3009 19.5312 21.0937 19.5312H3.90625C3.69905 19.5312 3.50034 19.4489 3.35382 19.3024C3.20731 19.1559 3.125 18.9572 3.125 18.75V5.46875Z" stroke="#292A34" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.125 10.1562H21.875" stroke="#292A34" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.125 14.8438H21.875" stroke="#292A34" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.59375 10.1562V19.5312" stroke="#292A34" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        
    );
};

export default DefaultIcon;