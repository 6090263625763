//   Выбор и оплата тарифа

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect, useDispatch } from "react-redux";
import Button from "../../../common/components/newComponent/Buttons/Button";
import Icon from "../../../common/components/Icon";
import { State } from "../../../rootReducer";
import {
  changeStep,
  amount,
  setSelectedModules,
  fetchModulesPrice,
  actualRequest,
  checkActivePaymethod,
  // setSelectedModules,
} from "../actions";
import Checkbox from "../../../common/components/CheckboxItem/Checkbox";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";

type Props = {
  monthsState: number;
  membersState: number;
  currentCompanyTariffInfo: any;
  handlerCheckbox: any;
  membersCount_rentDate: any;
  modules_prices: any;
  isPaid: boolean;
  changeStep: (step: number) => void;
  // setSelectedModules: (module: string) => void;
  onChangeCheckbox: (v: any) => any;
  setMembersState: (v: number) => void;
  setMonthsState: (v: number) => void;
  amount: (
    company_id: number | string,
    months_count: number,
    users_count: number,
    modules: string[]
    // payment_type: string
  ) => any;
  commonInfoCompany: any;
  months_count: number;
  users_count: number;
  total_price: string;
  subscribe_status: boolean;
  loading: boolean;
  subscribe_end: string;
  bill_info: any;
  modules: string[];
};

const NewRow = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  // overflow: auto;
  &:last-child {
    margin-bottom: 0;
  }
  ${({ styles }) => styles && { ...styles }}
`;

const CheckBoxInputDisable = styled.div`
  padding: 8px 5px;
  font-size: 17px;
  display: flex;
  align-items: center;
  color: gray;
  cursor: default;
  > span {
    margin: 0px 8px;
    width: 110px;
  }
`;

const StepTitle = styled.div`
  font-size: 24px;
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 36px;
  text-align: left;
`;

const CheckBoxInput = styled.div`
  padding: 8px 5px;
  font-size: 17px;
  display: flex;
  align-items: center;
  > span {
    margin: 0px 8px;
    width: 110px;
  }
`;

const NewRowChexboxText = styled.div`
  display: flex;
  margin-top: 40px;
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 18px;
  margin-left: 5px;
  &:last-child {
    margin-bottom: 0;
  }
  ${({ styles }) => styles && { ...styles }}
`;

const BoldText = styled.b`
  font-weight: 500;
`;

const PaymentSetup: React.FC<Props> = ({
  amount,
  months_count,
  users_count,
  total_price,
  subscribe_status,
  modules,
  loading,
  modules_prices,
  subscribe_end,
  bill_info,

  // members
}) => {
  const [usersCount, setUsersCount] = useState<number>(users_count);
  const [monthCount, setMonthCount] = useState<number>(months_count);
  const [firstRender, setFirstRender] = useState<boolean>(true);

  const dispatch = useDispatch();

  let company_id = localStorage.getItem("company_id");
  useEffect(() => {
    dispatch(checkActivePaymethod(true));
    dispatch(fetchModulesPrice());
    if (company_id && firstRender) dispatch(actualRequest(+company_id));
    setFirstRender(false);
  }, []);

  useEffect(() => {
    if (company_id) amount(company_id, monthCount, usersCount, modules);
  }, [monthCount, usersCount, modules]);

  const history = useHistory();

  const btnPayment = () => {
    history.push("/payment/method");
  };

  const btnCompany = () => {
    history.push("/CompanyDetails");
  };

  return (
    <NewRow styles={{ justifyContent: "center", overflow: "auto" }}>
      <div style={{ padding: "24px", width: "920px" }}>
        <div
          style={{
            width: "540px",
            height: "783px",
            background: "#FFFFFF",
            boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.2)",
            borderRadius: "4px",
            padding: "29px",
          }}
        >
          <StepTitle>Подписка EASY</StepTitle>
          {subscribe_status ? (
            <>
              <NewRow>
                Количество сотрудников сейчас:
                <BoldText> {" " + bill_info.users_count}</BoldText>
              </NewRow>
              <NewRow>
                Модули в подписке:
                <BoldText>
                  {bill_info.modules
                    .map((item) => {
                      if (item === "easy") {
                        item = "task";
                      }
                      return item;
                    })
                    .join(", ")
                    .toUpperCase()}
                </BoldText>
              </NewRow>
              <NewRow style={{ marginBottom: "22px" }}>
                Подписка оплачена до:
                <BoldText> {" " + subscribe_end} г.</BoldText>
              </NewRow>
            </>
          ) : (
            <NewRow>Подписка отсутствует</NewRow>
          )}
          <NewRow style={{ fontSize: "18px", fontWeight: "400" }}>
            Укажите параметры подписки
          </NewRow>
          <NewRow
            styles={{
              height: "40px",
              lineHeight: "40px",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              style={{
                width: "34px",
                // height: "24px",
                // textAlign: "center",
                // marginRight: "19px",
              }}
            >
              <Icon name={"Peoples1"} />
            </div>
            <div
              style={{
                marginRight: "30px",
                width: "103px",
                textAlign: "left",
                marginLeft: "10px",
              }}
            >
              Сотрудники
            </div>
            <div style={{ width: "90px", position: "relative" }}>
              <span
                onClick={() => {
                  setUsersCount(usersCount > 0 ? usersCount - 1 : 0);
                }}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 6,
                  fontSize: "35px",
                  cursor: "pointer",
                  zIndex: 1,
                }}
              >
                -
              </span>
              <TextField
                value={usersCount}
                onChange={(e) => {
                  +e.target.value >= 0
                    ? setUsersCount(+e.target.value)
                    : setUsersCount(0);
                }}
                id="outlined-number-helper-text"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                size="small"
                InputProps={{
                  inputProps: {
                    style: {
                      textAlign: "center",
                      paddingLeft: "17px",
                      paddingRight: "17px",
                    },
                  },
                }}
              />
              <span
                onClick={() => {
                  setUsersCount(usersCount + 1);
                }}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 6,
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              >
                +
              </span>
            </div>
            <div style={{ marginLeft: "18px" }}>человек</div>
          </NewRow>
          <NewRow
            styles={{
              height: "40px",
              lineHeight: "40px",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div>
              <Icon name={"calendar-Blank "} />
            </div>
            <div
              style={{
                marginRight: "30px",
                width: "103px",
                lineHeight: "20px",
                textAlign: "left",
                marginLeft: "10px",
              }}
            >
              Срок действия подписки
            </div>
            <div style={{ width: "90px", position: "relative" }}>
              <span
                onClick={() => {
                  if (monthCount > 1) setMonthCount(monthCount - 1);
                }}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 6,
                  fontSize: "35px",
                  cursor: "pointer",
                  zIndex: 1,
                }}
              >
                -
              </span>
              <TextField
                value={monthCount}
                onChange={(e) =>
                  setMonthCount(+e.target.value > 0 ? +e.target.value : 0)
                }
                id="outlined-number-helper-text"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                size="small"
                InputProps={{
                  inputProps: {
                    style: {
                      textAlign: "center",
                      paddingLeft: "17px",
                      paddingRight: "17px",
                    },
                  },
                }}
              />
              <span
                onClick={() => {
                  if (monthCount >= 1) setMonthCount(monthCount + 1);
                }}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 6,
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              >
                +
              </span>
            </div>
            <div style={{ marginLeft: "18px" }}>месяцев</div>
          </NewRow>
          <NewRowChexboxText>Модули:</NewRowChexboxText>
          <div style={{ marginBottom: "10px", paddingLeft: "17px" }}>
            <CheckBoxInput>
              <span>TASK</span>

              <Checkbox
                // onChange={() => {
                //   dispatch(setSelectedModules("easy"));
                // }}
                name="input3"
                checked={modules.includes("easy")}
              />

              <span style={{ width: "auto" }}>
                {modules_prices && modules_prices.easy
                  ? modules_prices.easy.amount + " руб. за сотрудника"
                  : "информация загружается"}
              </span>
            </CheckBoxInput>

            <CheckBoxInput>
              <span>WAY</span>

              <Checkbox
                disabled
                // onChange={() => {
                //   dispatch(setSelectedModules("way"));
                // }}
                name="input5"
                checked={modules.includes("way")}
              />

              <span style={{ width: "auto" }}>
                {/* {modules_prices && modules_prices.way
                  ? modules_prices.way.amount + " руб. за сотрудника"
                  : "информация загружается"} */}
                (скоро запуск)
              </span>
            </CheckBoxInput>
            <CheckBoxInputDisable>
              <span>MEET</span>

              <Checkbox
                disabled
                // onChange={() => {
                //   dispatch(setSelectedModules("meet"));
                // }}
                name="input4"
                checked={modules.includes("meet")}
              />

              <span style={{ width: "auto" }}>
                {/* {modules_prices && modules_prices.meet
                  ? modules_prices.meet.amount + " руб. за сотрудника"
                  : "информация загружается"} */}
                (скоро запуск)
              </span>
            </CheckBoxInputDisable>
            <CheckBoxInputDisable>
              <span>DAY</span>

              <Checkbox
                disabled
                // onChange={(e) => {
                //   dispatch(setSelectedModules("day"));
                // }}
                // data-value="input6"
                checked={modules.includes("day")}
              />

              <span style={{ width: "auto" }}> (скоро запуск)</span>
            </CheckBoxInputDisable>
          </div>

          <div
            style={{
              width: "320px",
              height: "96px",
              backgroundColor: "#F2F2F2",
              borderRadius: "12px",
              border: "1px solid #E0E0E0",
              padding: "16px",
              marginBottom: "16px",
              marginTop: "40px",
            }}
          >
            <div style={{ marginBottom: "16px", width: "338.31px" }}>
              Сумма к оплате
            </div>
            {loading ? (
              <div
                style={{ marginTop: "16px", fontSize: "16px", fontWeight: 400 }}
              >
                загрузка...
              </div>
            ) : (
              <div style={{ fontSize: "36px", fontWeight: 300 }}>
                {total_price}
              </div>
            )}
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              marginTop: "40px",
            }}
          >
            <Button
              title={"Отмена"}
              design={"outline"}
              style={{
                width: "163px",
                height: "50px",
                alignItems: "center",
                textAlign: "center",
                fontSize: "18px",
              }}
              onClick={btnCompany}
            />
            <Button
              title={"Оплатить"}
              style={{
                width: "163px",
                height: "50px",
                alignItems: "center",
                textAlign: "center",
                fontSize: "18px",
              }}
              onClick={btnPayment}
              disabled={loading || !total_price}
            />
          </div>
        </div>
        {/* )} */}
      </div>
    </NewRow>
  );
};

const mapStateToProps = (state: State) => {
  return {
    currentTariff: state.payment.currentTariff,
    step: state.payment.step,
    months_count: state.payment.months_count,
    users_count: state.payment.users_count,
    paid_success: state.payment.paid_success,
    paymentDetailsData: state.payment.paymentDetails,
    commonInfoCompany: state.commonInfo.company,
    total_price: state.payment.total_price,
    subscribe_status: state.payment.subscribe_status,
    modules: state.payment.modules,
    modules_prices: state.payment.module_prices,
    loading: state.payment.loading,
    subscribe_end: state.payment.subscribe_end,
    bill_info: state.payment.bill_info,
    isPaid: state.payment.isPaid,
  };
};

const mapDispatchToProps = {
  changeStep,
  amount,
  // setSelectedModules,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSetup);
