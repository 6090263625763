import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import {
  AccordionIcon1,
  AccordionIcon2,
  AccordionIcon3,
  AccordionIcon4,
  AccordionIcon6,
  AccordionIcon5,
  AccordionIcon7,
} from "../../../../../../img/projectIcons"
import CommonCirclePreloader from "../../../../../../common/components/CommonInfoPreloader/CommonCirclePreloader";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ChatMeeting from "./ChatMeeting";

interface Props {
  meetingId :string
  item : any
}

const DivNumberTasks = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
`;
const DivRight = styled.div`
  display: flex;
  align-items: center;
`;
const DivLeft = styled.div`
  display: flex;
  align-items: center;
`;

const DivContainerDetails = styled.div`
  /* padding: 0px 19px 21px 21px; */
  display: flex;
  flex-direction: column;
`;

const DivButton = styled.div`
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin: 0 auto;
`;
const Button = styled.button`
  letter-spacing: 0.15px;
  color: #01b0e9;
  background: rgba(0, 0, 0, 0);
  font-size: 16px;
  /* transition:all 0.3s;
  :hover {
    color: #008ab6;
  } */
`;
const TextSection = styled.h2`
  display: inline;
  width: 400px;
  color: #292a34;
  line-height: 24px;
  text-decoration: none;
  font-size: 16px;
`;

const useStyles = makeStyles((theme) => ({
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
    "&.Mui-expanded": {
      margin: "0",
      border: "1px solid rgba(41, 42, 52, 0.5)",
    },
  },
  MuiAccordionSym: {
    "&>.MuiAccordionSummary-content": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    ".Mui-expanded": {
      minWidth: "48px",
      border: "1px solid #36EA0",
    },
  },
  MuiAccordionrootNone: {
    "&>.MuiAccordionSummary-root:hover": {
      cursor: "default",
    },
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
}));

const DivIcons2 = styled.p`
  transform: ${({ rotateLocal }) => rotateLocal && "rotate(90deg)"};
  width: 10px;
  height: 15px;
`;

const MeetingComments: React.FC<Props> = ({
  meetingId, item 
}) => {
  const [openAccord, setOpenAccord] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: any) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  return (
    <Accordion
      expanded={openAccord}
      onChange={(e, expanded) => setOpenAccord(expanded)}
      classes={{
        root: classes.MuiAccordionroot,
      }}
      style={{
        width: "98%",
        boxShadow: "none",
        marginBottom: "10px",
        // border: "1px solid #436EA0",
        borderRadius: "4px 4px 0px 0px",
      }}
    >
      <AccordionSummary
        //   disableTouchRipple={!tasksState.length}
        classes={{
          root: classes.MuiAccordionSym,
        }}
        style={{
          backgroundColor: "#F4F4F4",  
          height: "46px",
          overflow: "hidden",
          border: "1px solid #dbdbdb",
          borderRadius: "4px",
          //   marginBottom: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          padding: "0px 12px",
          minHeight: "46px",
        }}
      >
        <DivLeft>
          <div style={{ width: "70px", paddingLeft: "15px" }}>
            <DivIcons2 rotateLocal={openAccord ? openAccord : undefined}>
              <AccordionIcon2 />
            </DivIcons2>
          </div>
          <TextSection>
            Комментарии и файлы
          </TextSection>
        </DivLeft>
     {/*   <DivRight>
          <Button onClick={handleClick}>
            <AccordionIcon7 />
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={(e) => {
                handleClose(e);
                // editClickHandler();
              }}
            >
              Редактировать
            </MenuItem>
          </Menu>
            </DivRight> */}
      </AccordionSummary>
      <AccordionDetails
        style={{
          height: "430x",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Тут будет загружаться измененный компонент-комментарий из Easy Meet */}
           
       <ChatMeeting
                meetingId = {meetingId}
                meetingData = {item}
                openAccord = {openAccord}
       />

      </AccordionDetails>
    </Accordion>
  );
};

export default MeetingComments;
