import React, { useCallback, useState } from "react";
import styled from "styled-components";
import Button from "../../../../../common/components/newComponent/Buttons/Button";
import NameWithInput from "../../../../../common/components/newComponent/Inputs/NameWithInput";
import CustomInput from "../../../../../common/components/newComponent/Inputs/CustomInput";
import axios from "axios";
import { cookieMaster } from "../../../../../utils/CookieMaster";

let REACT_APP_PUBLIC_MEETING = `${process.env.REACT_APP_PUBLIC_MEETING}`;

interface Props {
  meetingId: number;
  userId: number;
  openRefuse: boolean;
  setOpenRefuse: (mode: boolean) => void;
  statusDataMember: boolean;
  setStatusDataMember: (mode: boolean) => void;
}

const ModalAddQuetion = styled.div`
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 0px 22px -8px rgba(34, 60, 80, 0.1);
  margin-top: 20px 0;
  transition: transform 0.3s ease;
  transform: ${({ openRefuse }) =>
    openRefuse ? "translateY(0vh)" : "translateY(-100vh)"};
  max-width: 530px;
  width: 100%;
  padding: 1.5rem 1rem;
  max-height: 100vh;
  overflow-y: auto;
  position: fixed;
  z-index: 2;
  left: calc((100% - 350px) / 2);
  top: 30%;
`;

const WrapperButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.25rem;
  align-items: center;
  transition: transform 0.3s ease;
  transform: ${({ openRefuse }) =>
    openRefuse ? "translateY(0vh)" : "translateY(-100vh)"};
  & > div {
    flex: 1;
  }
`;

const WrapperBlockSections = styled.div`
  max-width: 500px;
  width: 100%;

  li {
    display: flex;
    justify-content: space-between;
    min-height: 24px;
    line-height: 24px;
    margin: 0.5rem 0;
  }
`;

const WrapperBlockInput = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RefusedWindow: React.FC<Props> = ({
  meetingId,
  userId,
  openRefuse,
  setOpenRefuse,
  statusDataMember,
  setStatusDataMember,
}) => {
  const [value, setValue] = useState<string>();

  const refusalClick = async () => {
    try {
      const data = await axios.post(
        `${REACT_APP_PUBLIC_MEETING}/api/v1/meetings/${meetingId}/members/${userId}/deny`,
        {
          data: value,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `${cookieMaster.getCookie("access_token")}`,
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
    setStatusDataMember(!statusDataMember);
    closeHandler();
  };

  const closeHandler = useCallback(() => {
    setOpenRefuse(false);
  }, []);
  console.log(value);

  return (
    <ModalAddQuetion openRefuse={openRefuse}>
      <NameWithInput title="Причина отказа" withoutIcon={true}>
        <WrapperBlockSections className="project_sections">
          <WrapperBlockInput>
            <CustomInput value={value} inputChangeHandler={setValue} />
            <Button
              onClick={refusalClick}
              disabled={!value}
              title="Отправить"
              design="outline"
              style={{ marginLeft: ".5rem", alignItems: "center" }}
            />
          </WrapperBlockInput>
        </WrapperBlockSections>{" "}
      </NameWithInput>
      <WrapperButtons openRefuse={openRefuse}>
        <Button
          onClick={closeHandler}
          design="secondary"
          title="Отмена"
          style={{ marginRight: ".5rem" }}
        />
      </WrapperButtons>
    </ModalAddQuetion>
  );
};

export default RefusedWindow;
