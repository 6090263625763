import  styled  from 'styled-components';
import React, { ReactNode } from 'react';


interface Props {
    children: ReactNode
}

const MeetingHeaderBlock = styled.div`
    position: relative;
    width: 100%;
    height: 90px;
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
`;

const MeetingHeader: React.FC<Props> = ({children}) => {
    return (
        <MeetingHeaderBlock>
            {children}
        </MeetingHeaderBlock>
    );
};

export default MeetingHeader;