//  Тут выбирается поле "Загрузка"

import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { User, Task, Section } from '../../../common/types';
import { State } from '../../../rootReducer';
import {
  recalculateTaskLoad,
  setDelegeteTo,
  setShownTaskInfoPlate,
  setTaskWorkLoadToggler,
} from '../actions';
import { setTaskAddMode } from '../../Tasks/actions';
import Toggler from '../../../common/components/Togglers/Toggler';

type Props = {
  users: User[];
  executor_id: number | undefined;
  selectedTask: Task | null;
  delegeteTo: number | null;
  selectedTaskProjectSections: Section[];
  setDelegeteTo: (delegeteTo: number) => void;
  setTaskAddMode: (mode: boolean) => void;
  setShownTaskInfoPlate: (show: boolean) => void;
  recalculateTaskLoad: (val?: object) => void;
  taskWorkLoadSwitcher: string;
  setTaskWorkLoadToggler: () => void;
  disabled: boolean;
  task_load: number;
  work_load: number;
};

const ExecutorSelect: React.FC<Props> = ({
  users,
  executor_id,
  selectedTask,
  delegeteTo,
  selectedTaskProjectSections,
  setDelegeteTo,
  setTaskAddMode,
  setShownTaskInfoPlate,
  taskWorkLoadSwitcher,
  setTaskWorkLoadToggler,
  recalculateTaskLoad,
  disabled = false,
  task_load,
  work_load,
}) => {
  const [InputValue, setInputValue] = useState<any>(
    selectedTask
      ? taskWorkLoadSwitcher === 'task_load'
        ? selectedTask.task_load
        : selectedTask.work_load
      : taskWorkLoadSwitcher === 'task_load'
      ? task_load
      : work_load
  );

  useEffect(() => {
    // нужно, чтобы в редюсере было значение первого пользователя в чипсине, пока нет selectedTask

    setInputValue(
      selectedTask
        ? taskWorkLoadSwitcher === 'task_load'
          ? selectedTask.task_load
          : selectedTask.work_load
        : taskWorkLoadSwitcher === 'task_load'
        ? task_load
        : work_load
    );
  }, [selectedTask?.id]);

  // для обнуления хука
  useMemo(() => {
    setInputValue(
      selectedTask
        ? taskWorkLoadSwitcher === 'task_load'
          ? selectedTask.task_load
          : selectedTask.work_load
        : taskWorkLoadSwitcher === 'task_load'
        ? task_load
        : work_load
    );
  }, [taskWorkLoadSwitcher]);

  return (
    <div className="taskWorkLoad lightbox">
      <div className={'innerComponents'}>
        <input
          type={'text'}
          className={`input_div ${disabled ? 'disabled' : ''}`}
          value={InputValue?.toString()}
          onChange={(e) => {setInputValue(e.target.value)}}
          onBlur={(e) => {

            if (selectedTask) {
              let comparisonValue =
                taskWorkLoadSwitcher === 'task_load'
                  ? selectedTask.task_load
                  : selectedTask.work_load;
              if (comparisonValue !== parseFloat(e.target.value)) { // если значение загрузки изменилось
                let obj = [];
                obj[taskWorkLoadSwitcher] = parseFloat(e.target.value);  // Считываем введенное вещественное значение
                recalculateTaskLoad(obj);
              }
            } else {
              let comparisonValue =
                taskWorkLoadSwitcher === 'task_load' ? task_load : work_load; // если значение загрузки изменилось
              if (comparisonValue !== parseFloat(e.target.value)) {
                let obj = [];
                obj[taskWorkLoadSwitcher] = parseFloat(e.target.value);   // Считываем введенное вещественное значение
                recalculateTaskLoad(obj);
              }
            }
          }}
        />

        <Toggler
          elemsList={[
            { name: 'Часы', value: 'task_load' },
            { name: 'Проценты', value: 'work_load' },
          ]}
          selectHandler={(active) => {
            if (active !== taskWorkLoadSwitcher) setTaskWorkLoadToggler();
          }}
          activeValue={taskWorkLoadSwitcher}
          styles={{ marginLeft: '8px' }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    work_load: state.taskInfoPlate.work_load,
    task_load: state.taskInfoPlate.task_load,

    taskWorkLoadSwitcher: state.taskInfoPlate.taskWorkLoadSwitcher,
    executor_id: state.taskInfoPlate.selectedTask?.executor_id,
    users: state.taskInfoPlate.projectUsers,
    selectedTask: state.taskInfoPlate.selectedTask,
    delegeteTo: state.taskInfoPlate.delegateTo,
    selectedTaskProjectSections: state.taskInfoPlate.projectSections,
  };
};

const mapDispatchToProps = {
  // updateTask,
  setDelegeteTo,
  setTaskAddMode,
  setShownTaskInfoPlate,
  setTaskWorkLoadToggler,
  recalculateTaskLoad,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(ExecutorSelect);
