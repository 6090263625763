export class Filter {
  name = null;
  format = null;
  start_at = null;
  location = null;
  chairman = [];
  secretary = [];
  members = [];
  status = [];

  constructor(filter) {
    Object.entries(filter).map((item) => {
      if (this.hasOwnProperty(item[0])) {
        if (Array.isArray(item[1])) {
          if (item[1].length !== 0) {
            this[item[0]] = item[1];
          }
        } else if (item[1] !== "") {
          this[item[0]] = item[1];
        }
      }
    });
  }
  getUrl() {
    const arr = Object.keys(this).filter((item) => this[item] !== null);
    const url = arr.map((item) => {
      if (item == "start_at") {
        const date = new Date(this[item]);
        return `filter[${item}]=${date.toISOString()}`;
      }
      if (item !== "chairman" && item !== "secretary" && item !== "status" && item !== "members") {
        return `filter[${item}]=${this[item]}`;
      }
      if (this[item].length > 0) {
        return this[item]
          .map((chairman) => {
            return `filter[${item}][]=${chairman}`;
          }).join("&")
          
      }
    }).filter(item=>item);
    return url.join("&");
  }
}
