import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { nanoid } from "nanoid";
import { StatusContext } from "../../../context/status";
import AddFileButton from "../templates/AddFileButton";
import NameOfStep from "../templates/NameOfStep";
import RadioButtons from "../templates/RadioButtons";
import TextAreaOrInputElement from "../templates/TextAreaOrInputElement";
import "../BpResultForm.scss";
import SelectTemplate from "../templates/SelectTemplate";

let fileTranslate;

const BpResultFormTreaty = () => {
  const {
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,
    taskCurrentGlobal,
    setchangeTask,
    users,
    initiator_id,

    company_id,

    setloadDataNow,
  } = useContext(StatusContext);

  // Если settingsOfFields.init.extra отсутствует- передаем пустой объект
  const [data, setData] = useState(
    settingsOfFields?.init?.extra ? settingsOfFields.init.extra : {}
  );

  const [allFilesTreaty1, setAllFilesTreaty1] = useState([]);
  const [allFilesTreaty2, setAllFilesTreaty2] = useState([]);
  const [allFilesTreaty3, setAllFilesTreaty3] = useState([]);
  const [allFilesTreaty4, setAllFilesTreaty4] = useState([]);
  const [allFilesTreaty5, setAllFilesTreaty5] = useState([]);

  function translateFile(file) {
    let reader = new FileReader();

    reader.onload = function () {
      let result = [...new Uint8Array(reader.result)]
        .map((item) => item.toString(16).padStart(2, "0"))
        .join("");
      fileTranslate = result;
      return result;
    };
    reader.readAsArrayBuffer(file);
  }

  // console.log("taskCurrentGlobal", taskCurrentGlobal);

  //Добавляет файлы
  const onSendFileTreaty = (e, numberFilesTreaty) => {
    let file = e.target.files[0];
    translateFile(file);
    setTimeout(() => {
      if (numberFilesTreaty === "filesTreaty1") {
        if (file && file.name) {
          setAllFilesTreaty1([
            ...allFilesTreaty1,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesTreaty1",
            },
          ]);
        }
      } else if (numberFilesTreaty === "filesTreaty2") {
        if (file && file.name) {
          setAllFilesTreaty2([
            ...allFilesTreaty2,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesTreaty2",
            },
          ]);
        }
      } else if (numberFilesTreaty === "filesTreaty3") {
        if (file && file.name) {
          setAllFilesTreaty3([
            ...allFilesTreaty3,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesTreaty3",
            },
          ]);
        }
      } else if (numberFilesTreaty === "filesTreaty4") {
        if (file && file.name) {
          setAllFilesTreaty4([
            ...allFilesTreaty4,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesTreaty4",
            },
          ]);
        }
      } else if (numberFilesTreaty === "filesTreaty5") {
        if (file && file.name) {
          setAllFilesTreaty5([
            ...allFilesTreaty5,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesTreaty5",
            },
          ]);
        }
      }
    }, [500]);
  };

  //Удаляет файлы
  const onDeletedFileTreaty = (keyId, numberFilesTreaty) => {
    if (numberFilesTreaty === "filesTreaty1") {
      setAllFilesTreaty1(allFilesTreaty1.filter((file) => file.id !== keyId));
    } else if (numberFilesTreaty === "filesTreaty2") {
      setAllFilesTreaty2(allFilesTreaty2.filter((file) => file.id !== keyId));
    } else if (numberFilesTreaty === "filesTreaty3") {
      setAllFilesTreaty3(allFilesTreaty3.filter((file) => file.id !== keyId));
    } else if (numberFilesTreaty === "filesTreaty4") {
      setAllFilesTreaty4(allFilesTreaty4.filter((file) => file.id !== keyId));
    } else if (numberFilesTreaty === "filesTreaty5") {
      setAllFilesTreaty5(allFilesTreaty5.filter((file) => file.id !== keyId));
    }
  };

  //Смотрит на какую кнопку какого рездела с файлами было нажато
  const onClickFetchFilesTreaty = (e, numberFilesTreaty) => {
    e.preventDefault();

    let data;

    if (numberFilesTreaty === "filesTreaty1") {
      data = JSON.stringify({
        files: allFilesTreaty1,
      });
      console.log("Нажал на кнопку для отправки файлов filesTreaty1");
    }

    if (numberFilesTreaty === "filesTreaty2") {
      data = JSON.stringify({
        files: allFilesTreaty2,
      });
      console.log("Нажал на кнопку для отправки файлов filesTreaty2");
    }

    if (numberFilesTreaty === "filesTreaty3") {
      data = JSON.stringify({
        files: allFilesTreaty3,
      });
      console.log("Нажал на кнопку для отправки файлов filesTreaty3");
    }

    if (numberFilesTreaty === "filesTreaty4") {
      data = JSON.stringify({
        files: allFilesTreaty4,
      });
      console.log("Нажал на кнопку для отправки файлов filesTreaty4");
    }

    if (numberFilesTreaty === "filesTreaty5") {
      data = JSON.stringify({
        files: allFilesTreaty5,
      });
      console.log("Нажал на кнопку для отправки файлов filesTreaty5");
    }

    let config = {
      method: "patch",
      url: `${apiBp}/task/${taskId}`,
      headers: {
        Authorization: bearer,
        "company-id": company_id,
        "secret-token": document.cookie.replace(
          /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        ),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setchangeTask((prev) => prev + 1);
        console.log("Запрос отправлен");
      })
      .catch((e) => {
        console.log(e);
      });

    if (numberFilesTreaty === "filesTreaty1") {
      setAllFilesTreaty1([]);
    }

    if (numberFilesTreaty === "filesTreaty2") {
      setAllFilesTreaty2([]);
    }

    if (numberFilesTreaty === "filesTreaty3") {
      setAllFilesTreaty3([]);
    }

    if (numberFilesTreaty === "filesTreaty4") {
      setAllFilesTreaty4([]);
    }

    if (numberFilesTreaty === "filesTreaty5") {
      setAllFilesTreaty5([]);
    }
  };

  const formOfTreaty = [
    "Аренда",
    "Поставка",
    "Купля-продажа",
    "Подряд",
    "Оказание услуг",
    "Факторинг",
    "Лизинг",
    "Кредит",
    "Другое",
  ];
  const typeOfTreaty = ["С покупателем", "С поставщиком", "Договор комиссии"];
  const typeOfSettlements = ["Аванс", "Предоплата", "Отсрочка", "Рассрочка"];
  const typeOfMutualSettlements = [
    "Отсрочка",
    "Предоплата",
    "Отсрочка + Аванс",
    "Другое",
  ];
  const taxes = [
    "ОСНО",
    "УСН 6 %",
    "УСН 15 %",
    "Патент",
    "Налог на проф доход (самозанятый)",
    "НКО-некоммерческая организация",
    "НДФЛ",
  ];

  const url = taskId ? `${apiBp}/task/${taskId}` : null;
  // console.log(taskId);
  useEffect(() => {
    console.log("use in form worked");
    if (settingsOfFields && settingsOfFields.init && taskId) {
      axios
        .patch(
          url,
          { ...settingsOfFields.init, extra: data },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))
        // .then(() => setInputValue())

        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);

  // console.log("     ", );

  const user = users.find((user) => user.id === initiator_id);
  const subdivision = user?.departments_model;
  // const sub = user?.departments_model.find((head) => head.head_id === 1);
  // console.log(subdivision);

  return (
    <form
      id="init"
      // className={
      //   (statusOfTask !== 0 || statusOfTask !== 2) && "unavailableForm"  //не дает редактировать форму в зависимости от статуса
      // }
    >
      <div>
        <NameOfStep text={"Наименование договора"} />
        <TextAreaOrInputElement
          name={"a1"}
          isInput={true}
          type={"text"}
          data={data}
          setData={setData}
        />
      </div>
      <div>
        <NameOfStep text={"Подразделение Инициатора"} />
        <SelectTemplate
          listArr={[subdivision[0]?.name]}
          name={"a1_1"}
          data={data}
          setData={setData}
        />
      </div>
      <div>
        <NameOfStep text={"Вид договора"} />
        <SelectTemplate
          listArr={formOfTreaty}
          name={"a1_2"}
          data={data}
          setData={setData}
        />
        {data.a1_2 === "Другое" && (
          <>
            <div style={{ margin: "10px 0px 10px 51px" }}>
              Указать другой вид договора
            </div>
            <TextAreaOrInputElement
              name={"a1_2_1"}
              isInput={false}
              type={"text"}
              data={data}
              setData={setData}
            />
          </>
        )}
      </div>
      <div>
        <NameOfStep text={"Мы поставляем/нам поставляют"} />
        <RadioButtons
          data={data}
          setData={setData}
          name={"a1_3"}
          positiveText={"Мы поставляем"}
          negativeText={"Нам поставляют"}
        />
      </div>
      {data.a1_3 === "Мы поставляем" && (
        <div>
          <NameOfStep text={"Планируемый ежемесячный доход"} />
          <TextAreaOrInputElement
            name={"a1_4"} //we
            isInput={true}
            type={"number"}
            data={data}
            setData={setData}
          />
        </div>
      )}
      {data.a1_3 === "Нам поставляют" && (
        <div>
          <NameOfStep text={"Планируемый ежемесячный расход"} />
          <TextAreaOrInputElement
            name={"a1_5"} //to us
            isInput={true}
            type={"number"}
            data={data}
            setData={setData}
          />
        </div>
      )}
      <div>
        <NameOfStep text={"Сумма по договору"} />
        <TextAreaOrInputElement
          name={"a1_6"} //to us
          isInput={true}
          type={"number"}
          data={data}
          setData={setData}
        />
      </div>
      <div>
        <NameOfStep text={"Вид налогообложения"} />
        <SelectTemplate
          listArr={taxes}
          name={"a1_7"}
          data={data}
          setData={setData}
        />
      </div>
      <div>
        <NameOfStep text={"Организация, заключающая договор с нашей стороны"} />
        <SelectTemplate
          listArr={["ООО «Гауф Рус»", "Другая организация"]}
          name={"a1_8"}
          data={data}
          setData={setData}
        />
        {data.a1_8 === "Другая организация" && (
          <>
            <div style={{ margin: "10px 0px 10px 51px" }}>
              Указать другую организацию
            </div>
            <div>
              <TextAreaOrInputElement
                name={"a1_8_1"}
                isInput={true}
                type={"text"}
                data={data}
                setData={setData}
              />
            </div>
          </>
        )}
      </div>
      <div>
        <NameOfStep text={"Контрагент существующий"} />
        <RadioButtons
          data={data}
          setData={setData}
          name={"a1_9"}
          positiveText={"Да"}
          negativeText={"Нет"}
        />
      </div>
      {data.a1_9 === "Да" && (
        <div>
          <NameOfStep
            text={"Номер задач предыдущего договора с данным контрагентом"}
          />
          <TextAreaOrInputElement
            name={"a1_10"} //to us
            isInput={true}
            type={"number"}
            data={data}
            setData={setData}
          />
        </div>
      )}
      <div>
        <NameOfStep text={"Чья форма договора?"} />
        <RadioButtons
          data={data}
          setData={setData}
          name={"a1_11"}
          positiveText={"Наша"}
          negativeText={"Контрагента"}
        />
      </div>
      {data.a1_11 === "Наша" && (
        <>
          <div>
            <NameOfStep text={"Создать договор с нуля"} />
            <RadioButtons
              data={data} //Наша
              setData={setData}
              name={"a1_12"}
              positiveText={"Да"}
              negativeText={"Нет"}
            />
          </div>
          {data.a1_12 == "Нет" && (
            <div>
              <NameOfStep text={"Прикрепить типовой договор"} />
              <div className="file-uploaded__block">
                <ul>
                  {taskCurrentGlobal &&
                    taskCurrentGlobal["files"] &&
                    taskCurrentGlobal["files"]["list"]
                      .filter((item) => item.tag == "filesTreaty1")
                      .map((item2) => (
                        <li>
                          <a
                            href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                            target="_blank"
                          >
                            {item2.name}
                          </a>
                        </li>
                      ))}
                </ul>
              </div>
              <AddFileButton
                filesList={taskCurrentGlobal}
                data={data}
                setData={setData}
                id={"a1_12_1"}
                handlerOnSendFile={onSendFileTreaty}
                handlerOnDeletedFile={onDeletedFileTreaty}
                handlerOnClickFetchFiles={onClickFetchFilesTreaty}
                allFiles={allFilesTreaty1}
                numberFiles={"filesTreaty1"}
              />
            </div>
          )}
        </>
      )}
      {data.a1_12 === "Да" && data.a1_11 === "Наша" && (
        <>
          <div>
            <NameOfStep text={"Тип  договора "} />
            <SelectTemplate
              listArr={typeOfTreaty}
              name={"a1_13"}
              data={data}
              setData={setData}
            />
          </div>
          <div>
            <NameOfStep text={"О чем договорились с контрагентом"} />
            <TextAreaOrInputElement
              name={"a1_14"}
              isInput={false}
              type={"text"}
              data={data}
              setData={setData}
              placeholder={
                "Основные условия поставки/предоставления услуг, в т ч сроки, стоимость и сроки оплаты, индивидуальные условия: отгрузки, гарантии, иное."
              }
            />
          </div>
          <div>
            <NameOfStep text={"Вид взаиморасчетов"} />
            <SelectTemplate
              listArr={typeOfSettlements}
              name={"a1_15"}
              data={data}
              setData={setData}
            />
          </div>
          <div>
            <NameOfStep text={"Другие существенные условия"} />
            <TextAreaOrInputElement
              name={"a1_16"}
              isInput={false}
              type={"text"}
              data={data}
              setData={setData}
            />
          </div>
          <div>
            <NameOfStep text={"О чем договорились с контрагентом"} />
            <TextAreaOrInputElement
              name={"a1_17"}
              isInput={false}
              type={"text"}
              data={data}
              setData={setData}
              placeholder={
                "Суть договоренностей между инициатором и контактным лицом контрагента – что наша сторона получает в результате данного договора."
              }
            />
          </div>
          <div>
            <NameOfStep text={"Вид взаиморасчетов"} />
            <SelectTemplate
              listArr={typeOfMutualSettlements}
              name={"a1_18"}
              data={data}
              setData={setData}
            />
            {data.a1_18 === "Отсрочка" && (
              <TextAreaOrInputElement
                name={"a1_18_1"}
                isInput={true}
                type={"number"}
                data={data}
                setData={setData}
              />
            )}
            {data.a1_18 === "Отсрочка + Аванс" && (
              <TextAreaOrInputElement
                name={"a1_18_2"}
                isInput={true}
                type={"number"}
                data={data}
                setData={setData}
              />
            )}
            {data.a1_18 === "Другое" && (
              <TextAreaOrInputElement
                name={"a1_18_3"}
                isInput={true}
                type={"number"}
                data={data}
                setData={setData}
              />
            )}
          </div>
          <div>
            <NameOfStep text={"Кредитный лимит"} />
            <RadioButtons
              data={data}
              setData={setData}
              name={"a1_19"}
              positiveText={"Присутствует"}
              negativeText={"Отсутствует"}
              otherText={"Указать"}
            />
            {data.a1_19 === "Указать" && (
              <TextAreaOrInputElement
                name={"a1_19_1"}
                isInput={true}
                type={"number"}
                data={data}
                setData={setData}
              />
            )}
          </div>
          <div>
            <NameOfStep text={"Анкета"} />
            <div className="file-uploaded__block">
              <ul>
                {taskCurrentGlobal &&
                  taskCurrentGlobal["files"] &&
                  taskCurrentGlobal["files"]["list"]
                    .filter((item) => item.tag == "filesTreaty2")
                    .map((item2) => (
                      <li>
                        <a
                          href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                          target="_blank"
                        >
                          {item2.name}
                        </a>
                      </li>
                    ))}
              </ul>
            </div>
            <AddFileButton
              filesList={taskCurrentGlobal}
              data={data}
              setData={setData} //если да
              id={"a1_20"}
              handlerOnSendFile={onSendFileTreaty}
              handlerOnDeletedFile={onDeletedFileTreaty}
              handlerOnClickFetchFiles={onClickFetchFilesTreaty}
              allFiles={allFilesTreaty2}
              numberFiles={"filesTreaty2"}
            />
          </div>
          <div>
            <NameOfStep text={"Учредительные документы"} />
            <div className="file-uploaded__block">
              <ul>
                {taskCurrentGlobal &&
                  taskCurrentGlobal["files"] &&
                  taskCurrentGlobal["files"]["list"]
                    .filter((item) => item.tag == "filesTreaty3")
                    .map((item2) => (
                      <li>
                        <a
                          href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                          target="_blank"
                        >
                          {item2.name}
                        </a>
                      </li>
                    ))}
              </ul>
            </div>
            <AddFileButton
              filesList={taskCurrentGlobal}
              data={data}
              setData={setData} //если да
              id={"a1_21"}
              handlerOnSendFile={onSendFileTreaty}
              handlerOnDeletedFile={onDeletedFileTreaty}
              handlerOnClickFetchFiles={onClickFetchFilesTreaty}
              allFiles={allFilesTreaty3}
              numberFiles={"filesTreaty3"}
            />
          </div>
          <div>
            <NameOfStep
              text={"Проект договора с заполненными реквизитами обеих сторон"}
            />
            <div className="file-uploaded__block">
              <ul>
                {taskCurrentGlobal &&
                  taskCurrentGlobal["files"] &&
                  taskCurrentGlobal["files"]["list"]
                    .filter((item) => item.tag == "filesTreaty4")
                    .map((item2) => (
                      <li>
                        <a
                          href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                          target="_blank"
                        >
                          {item2.name}
                        </a>
                      </li>
                    ))}
              </ul>
            </div>
            <AddFileButton
              filesList={taskCurrentGlobal}
              data={data}
              setData={setData} //если да
              id={"a1_22"}
              handlerOnSendFile={onSendFileTreaty}
              handlerOnDeletedFile={onDeletedFileTreaty}
              handlerOnClickFetchFiles={onClickFetchFilesTreaty}
              allFiles={allFilesTreaty4}
              numberFiles={"filesTreaty4"}
            />
          </div>
          <div>
            <NameOfStep text={"Отчетность контрагента за предыдущий год"} />
            <div className="file-uploaded__block">
              <ul>
                {taskCurrentGlobal &&
                  taskCurrentGlobal["files"] &&
                  taskCurrentGlobal["files"]["list"]
                    .filter((item) => item.tag == "filesTreaty5")
                    .map((item2) => (
                      <li>
                        <a
                          href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                          target="_blank"
                        >
                          {item2.name}
                        </a>
                      </li>
                    ))}
              </ul>
            </div>
            <AddFileButton
              filesList={taskCurrentGlobal}
              data={data}
              setData={setData}
              id={"a1_23"}
              handlerOnSendFile={onSendFileTreaty}
              handlerOnDeletedFile={onDeletedFileTreaty}
              handlerOnClickFetchFiles={onClickFetchFilesTreaty}
              allFiles={allFilesTreaty5}
              numberFiles={"filesTreaty5"}
            />
          </div>
        </>
      )}
    </form>
  );
};

export default BpResultFormTreaty;
