import {Action} from 'redux';

export enum types {
    SET_COMPANY = '@@COMPANY_INFO_PLATE/SET_COMPANY',
    SET_PLATE_EDIT_MODE = '@@COMPANY_INFO_PLATE/SET_PLATE_EDIT_MODE',
    SET_INITIAL = '@@COMPANY_INFO_PLATE/SET_INITIAL',
}

export interface setCompanyAction extends Action<types.SET_COMPANY> {
    data: any;
}

export interface setPlateEditModeAction extends Action<types.SET_PLATE_EDIT_MODE> {
    value: boolean;
}

export interface setInitialCompanyAction extends Action<types.SET_INITIAL> {

}



export type CompanyInfoPlateAction = setCompanyAction | setPlateEditModeAction | setInitialCompanyAction;
