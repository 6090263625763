import React, { useMemo } from 'react';
import { State } from '../../../rootReducer';
import { connect } from 'react-redux';
import { Comment, User } from '../../../common/types';


type Props = {
    parentComment: Comment,
    users: User[]
}


const ParentComment: React.FC<Props> = ({ parentComment, users }) => {

    const author = useMemo(() => {
        let author = users.find(user => user.id === parentComment.author_id)
        if (!author) return null
        return `${author.surname} ${author.name}`
    }, [parentComment.author_id, users])


    return (
        <div className="parent_comment">
            <div className="parent_comment_author">{author || 'TEST'}</div>
            <div className="commented_text">{parentComment.text}</div>
        </div>
    )
}


const mapStateToProps = (state: State) => ({
    users: state.commonInfo.users
})


export default connect(mapStateToProps)(ParentComment)