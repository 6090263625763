/*
 Translation by Genexus Japan Inc.
 */
export default {
  date: {
    month_full: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
    month_short: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
    day_full: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
    day_short: ["日", "月", "火", "水", "木", "金", "土"]
  },
  labels: {
    dhx_cal_today_button: "今日",
    day_tab: "日",
    week_tab: "週",
    month_tab: "月",
    new_event: "新イベント",
    icon_save: "保存",
    icon_cancel: "キャンセル",
    icon_details: "詳細",
    icon_edit: "編集",
    icon_delete: "削除",
    confirm_closing: "",
    //変更が取り消されます、宜しいですか？
    confirm_deleting: "イベント完全に削除されます、宜しいですか？",
    section_description: "デスクリプション",
    section_time: "期間",
    confirm_recurring: "繰り返されているイベントを全て編集しますか？",
    section_recurring: "イベントを繰り返す",
    button_recurring: "無効",
    button_recurring_open: "有効",
    full_day: "終日",
    button_edit_series: "シリーズを編集します",
    button_edit_occurrence: "コピーを編集",
    /*agenda view extension*/
    agenda_tab: "議題は",
    date: "日付",
    description: "説明",
    /*year view extension*/
    year_tab: "今年",
    /*week agenda view extension*/
    week_agenda_tab: "議題は",
    /*grid view extension*/
    grid_tab: "グリッド",
    /* touch tooltip*/
    drag_to_create: "Drag to create",
    drag_to_move: "Drag to move",
    /* dhtmlx message default buttons */
    message_ok: "OK",
    message_cancel: "Cancel",
    /* wai aria labels for non-text controls */
    next: "Next",
    prev: "Previous",
    year: "Year",
    month: "Month",
    day: "Day",
    hour: "Hour",
    minute: "Minute"
  }
};