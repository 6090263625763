// Тут описанно действие кнопки "Закрытие дня"

import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {
    changeTaskList, closingDay, closingDayDispatchAll, closingDayHidingSignal,
    fetchTasks,
    fetchVisualizationList,
    launchProcedureFinishTask,
    loadSpecificTask, LocalTimeSheetRecord, sendComment,
    setSelectedUserId, setShowDaysOff,
    timeSheetActions, timesheetCacheRecord,
} from '../actions';
import {File as FileType, Task} from '../../../common/types';
import {State} from '../../../rootReducer';
import {selectTask, setShownTaskInfoPlate} from '../../TaskInfoPlate/actions';
import styled from 'styled-components';
import Input from "../../../common/components/Inputs/Input";
import Button from "../../../common/components/newComponent/Buttons/Button";
import {nanoid} from "nanoid";
import {updateTask} from "../../../common/actions";
import Icon from "../../../common/components/Icon";
import Priority from "../../../common/components/Priority/Priority";
import TaskStatus from "../../../common/components/TaskStatus/TaskStatus";
import {parseStringToDate} from "../../TaskInfoPlate/reducer";
import DateMaster from "../../../utils/DateMaster";

type Props = {
    shortVersion?: boolean;
    tasks: Task[];
    isLoading: boolean;
    currentId: number;
    selectedUserId: number;
    changeTaskList: (tasks: Task[]) => void;
    fetchTasks: (pageNum: number) => void;
    setSelectedUserId: (id: number) => void;
    activeColumns: string[];
    selectTask: (val: Task) => void;
    setShownTaskInfoPlate: (val: boolean) => void;
    loadSpecificTask: (val: number) => void;
    isShownTaskInfoPlate: boolean;
    selectedTask: Task | null;
    launchProcedureFinishTask: (val, taskId, shortVersion) => void,
    commentFiles: FileType[];
    sendComment: (task_Id?: number, commentText?: string) => void;
    updateTask: (id: number, params: any) => void;
    timeSheetActions: (actionType: string, taskId: number, value: number) => void,
    closingDay: (value: boolean) => void,
    visualData: any,
    todayDate: string,
    LocalTimeSheetRecord: (actionType: string, taskId: number, task_load_sum?: number | null) => void
    scheduleUser: {},
    fetchVisualizationList: (currentUser: number) => void,
    timesheetCacheRecord: (actionType: string, task_id?: number, task_load_sum?: number, status_id?: number) => void,
    closingDayDispatchAll: () => void,
    timeSheet: any,
    TimeSheetCache: any,
    closingDayWindowShow: boolean,
    closingDayHidingSignal: (val: boolean) => void,
    isShownDayClose: boolean,
    setShowDaysOff: (show: boolean) => void,
    closingDayWindowShowHidingSignal: boolean
};

const NewRow = styled.div`
    display: block;
`

const ListRow = styled.div`
    display: flex;
    height: 56px;
    &:last-child {        
        & > div:last-child {
           border-bottom: none !important
        }
    }
`

const TaskNameBox = styled.div`
    font-weight: 500;
    line-height: 56px;
    width: 215px;
    margin-right: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const TableHeader = styled.div`
  display: flex;
  padding: 8px 0px;
  color: #a8a8a8;
  & > div {
    padding-right: 4px;
    font-size: 11px;
    line-height: 16px;
    &:last-child {
      margin-right: 0px;
    }
  }
`;

const TimeParts = styled.div`
    padding: 8px;
`

const TasksBlock = styled.div`
    overflow-x: hidden;
    height: calc(100vh - 295px)
`

const InputBox = styled.div`
    width: 72px;    
    & input::placeholder {
        text-align: right;
        color: #A8A8A8
    }    
`

const Title = styled.div`
    font-size: 20px;
    line-height: 24px;
    padding: 8px 0;
`

// ******************************* width изменена
const MainBlock = styled.div`
  overflow: hidden;
  position: fixed;
  width: 720px;   
  height: calc(100vh - 76px);
  top: 76px;
  transition: right 0.8s ease;
  right: ${({closingDayWindowShowHidingSignal}) =>
    closingDayWindowShowHidingSignal ? '-600px' : '0'};
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  background: #ffffff;
  z-index: 100;
  padding: 16px;
`;

const ClosingDay: React.FC<Props> = ({
                                         launchProcedureFinishTask,
                                         sendComment,
                                         updateTask,
                                         timeSheetActions,
                                         closingDay,
                                         visualData,
                                         shortVersion = false,
                                         LocalTimeSheetRecord,
                                         todayDate,
                                         // commonInfo,
                                         scheduleUser,
                                         currentId,
                                         fetchVisualizationList,
                                         timesheetCacheRecord,
                                         closingDayDispatchAll,
                                         timeSheet,
                                         TimeSheetCache,
                                         closingDayWindowShow,
                                         isShownDayClose,
                                         setShowDaysOff,
                                         closingDayHidingSignal,
                                         closingDayWindowShowHidingSignal
                                     }) => {

    const [firstRender, setFirstRender] = useState<boolean>(true);

    useEffect(() => {
        if (firstRender) {
            // send signal for sliding window
            setTimeout(() => closingDayHidingSignal(false), 0)
            setFirstRender(false)
        }
    }, [firstRender]);

    const TaskRow = ({taskId, taskName, priority, value, task_load, status_id}) => {
        const TS_record = timeSheet.find(item => item.task_id === taskId)
        let visual_item = visualData.find(item => item.id === taskId)
        let TSCvalue = TimeSheetCache.find(it => it.task_id === taskId)?.task_load_sum

        if (value > 0) {
            if (timeSheet.length) {
                let found_item = TimeSheetCache.find(item => item.task_id === taskId)
                if (found_item) {
                    if (found_item.task_load_sum === undefined) timesheetCacheRecord('write', taskId, parseInt(value))
                } else {
                    timesheetCacheRecord('write', taskId, parseInt(value))
                }
            } else {
                timesheetCacheRecord('write', taskId, parseInt(value))
            }
        }

        const dateMaster = useMemo(() => {
            return new DateMaster(visual_item.end);
        }, [visual_item]);

        let filled_icon = false
        if (TS_record) {
            if (TS_record.comment_text && TS_record.comment_text !== '') filled_icon = true
            if (TS_record.comment_files && TS_record.comment_files.length) filled_icon = true
        }


        return (
            <ListRow>
                <div style={{display: 'flex'}}>
                    <Icon name={filled_icon ? 'comment_filled' : 'comment_border'}
                          onClick={() => {
                              launchProcedureFinishTask(true, taskId as number, true)
                          }}/>
                    <Priority id={priority} noname={true}/>
                </div>

                <div style={{display: 'flex', borderBottom: '1px solid #E0E0E0', width: '470px'}}>
                    <TaskNameBox>
                        {taskName}
                    </TaskNameBox>

                {/* Изменено: marginLeft */}        
                <div style={{marginLeft: '10px',height: '56px',display: 'flex',}}>
                        <TaskStatus
                            id={
                                (TS_record && TS_record.status_id)
                                    ? TS_record.status_id
                                    : status_id === 10 &&
                                    new Date(
                                        dateMaster.year,
                                        dateMaster.month,
                                        dateMaster.day,
                                        dateMaster.hours,
                                        dateMaster.minutes
                                    ).getTime() <= new Date(Date.now()).getTime() ? 14 : status_id
                            }
                            taskId={taskId}
                            closeDayFlag={true}
                        />
                    </div>

                    {/********************** Добавлено 
                    <div 
                    style={{marginLeft: '20px'}} >

                    <InputBox>
                        <Input
                            inputType={'number'}
                            min={1}
                            placeholder={'0'}
                            defaultValue={TSCvalue ? TSCvalue : (value !== 0 ? value : undefined)}
                            value={undefined}
                            title={'Введите'}
                            styleInput={{height: '29px', padding: '8px',textAlign: 'right'}}
                            styleInputDiv={{marginTop: '12px'}}
                            changeHandler={(value) => {
                                // let result = value.match(/(?<![-.])\b[0-9]+\b(?!\.[0-9])/g);
                                timesheetCacheRecord('write', taskId, isNaN(parseInt(value)) ? 0 : parseInt(value))
                            }}
                        />
                    </InputBox>

                    </div>*/}

                    <div
                        style={{lineHeight: '56px', width: '72px', textAlign: 'right'}}
                    >
                        {task_load}ч
                    </div>
                </div>
            </ListRow>
        );
    };

    let current = parseStringToDate(todayDate);
    let UTC_current = new Date(
        current[0],
        current[1],
        current[2],
        current[3],
        current[4],
        current[5]
    );

    let todayTasks = visualData.filter((task) => {
        let beginDate = parseStringToDate(task.begin);
        let UTC_begin = new Date(
            beginDate[0],
            beginDate[1],
            beginDate[2],
            beginDate[3],
            beginDate[4],
            beginDate[5]
        );
        if (
            UTC_current.getFullYear() === UTC_begin.getFullYear() &&
            UTC_current.getMonth() === UTC_begin.getMonth() &&
            UTC_current.getDate() === UTC_begin.getDate()
        ) {
            return true; // задача началась сегодня
        }
        if (UTC_current > UTC_begin) return true; // задача уже началась
    });

    let tomorrowTasks = visualData.filter((task) => {
        let beginDate = parseStringToDate(task.begin);
        let UTC_begin = new Date(
            beginDate[0],
            beginDate[1],
            beginDate[2],
            beginDate[3],
            beginDate[4],
            beginDate[5]
        );
        if (UTC_current < UTC_begin) return true; // начало с завтрашнего дня
    });

    let workHours =
        scheduleUser?.['weekDays']?.find(
            (item) => item.day === UTC_current.getDay()
        )?.time[0] || 0;
    let remainTime;
    if (workHours)
        remainTime =
            parseInt(workHours.end.substr(0, 2)) -
            parseInt(workHours.begin.substr(0, 2));

    return (
        <MainBlock closingDayWindowShowHidingSignal={closingDayWindowShowHidingSignal}>
            <NewRow>
                <Icon
                    style={{marginBottom: '8px'}}
                    name="cross"
                    onClick={async () => {
                        closingDayHidingSignal(true)

                        LocalTimeSheetRecord('clear_whole', 0);
                        timesheetCacheRecord('clear');
                        // setTimeout(() => setShowDaysOff(false), 1000)
                        setTimeout(() => closingDay(false), 800)
                    }}
                />
            </NewRow>

            <NewRow>
                <Title> Закрытие дня </Title>
            </NewRow>

            {/*  Изменено */}            
            <NewRow>
                <TableHeader>
                    <div style={{width: '350px', textAlign: 'center'}}>Задача</div>
                    <div style={{width: '130px', textAlign: 'center',marginLeft: '30px'}}>Статус</div>
                {/*   <div style={{width: '78px', marginLeft: '10px', textAlign: 'center'}}>Затрачено</div>  */}
                    <div style={{width: '72px', marginLeft: '35px', textAlign: 'left'}}>Загрузка</div>
                </TableHeader>
            </NewRow>

            <NewRow style={{overflowY: 'scroll', height: 'calc(100vh - 265px)'}}>
                {Boolean(todayTasks.length) && (
                    <NewRow>
                        <NewRow>
                            <TimeParts>Сегодня</TimeParts>
                        </NewRow>
                        <NewRow>
                            <TasksBlock>
                                {todayTasks.map((task,index) => {

                                    let value; 

                                   // let value=0;
                                   // console.log("task",task)
                                   // console.log("index",index)

                                    let visual_item = visualData.find(
                                        (item) => item.id === task.id
                                    );


                                 //   console.log("visual_item.task_load_sum",visual_item.task_load_sum) 
                                 //   console.log("visual_item.task_load",visual_item.task_load)        

                                    ///**************************************** */
                                //    if (visual_item.task_load_sum > 0) {
                                //    if (index === 0) {  value = visual_item.task_load_sum-1;}      
                                //    else {value = visual_item.task_load_sum;}  
                                //    }
                                //   if (value<0) {value = 0;}


                                   let requiredValue = Math.round(
                                       visual_item.task_load - visual_item.task_load_sum
                                   );
                                   if (requiredValue > 0) {
                                       if (remainTime - requiredValue >= 0) {
                                           value = requiredValue;
                                            remainTime -= requiredValue;
                                        } else {
                                            value = remainTime;
                                            if (remainTime > 0) remainTime = 0;
                                        }
                                    } else {
                                        value = 0;
                                    }
                                    return (
                                        <TaskRow
                                            taskId={task.id}
                                            taskName={task.name}
                                            priority={task.priority_id}
                                            key={nanoid()}
                                            status_id={task.status_id}
                                            value={value}
                                            task_load={task.task_load}
                                        />
                                    );
                                })}
                            </TasksBlock>
                        </NewRow>
                    </NewRow>
                )}

                {Boolean(tomorrowTasks.length) && (
                    <NewRow>
                        <NewRow>
                            <TimeParts>Планируемые</TimeParts>
                        </NewRow>
                        <NewRow>
                            <TasksBlock>
                                {tomorrowTasks.map((task) => {
                                    return (
                                        <TaskRow
                                            taskId={task.id}
                                            taskName={task.name}
                                            priority={task.priority_id}
                                            key={nanoid()}
                                            status_id={task.status_id}
                                            value={0}
                                            task_load={task.task_load}
                                        />
                                    );
                                })}
                            </TasksBlock>
                        </NewRow>
                    </NewRow>
                )}
            </NewRow>

            <NewRow style={{marginRight: '25px', height: '52px'}}>
                <NewRow
                    style={{
                        paddingTop: '16px',
                        bottom: 0,
                        display: 'flex',
                        width: '100%',
                        borderTop: '1px solid #E0E0E0',
                    }}
                >
                    <Button
                        title={'Готово'}
                        onClick={() => {
                            closingDayDispatchAll();
                         //   setTimeout(()=>{window.location.reload()},1000);  // ************************************* Перезагрузка
                        }}
                    />
                    <Button
                        style={{marginLeft: '8px'}}
                        title={'Отмена'}
                        design={'outline'}
                        onClick={() => {
                            closingDayHidingSignal(true)

                            setTimeout(() => closingDay(false), 800)
                            LocalTimeSheetRecord('clear_whole', 0);
                            timesheetCacheRecord('');
                        }}
                    />
                </NewRow>
            </NewRow>
        </MainBlock>
    );
};

const mapStateToProps = (state: State) => {
    return {
        visualData: state.tasksPage.visualizationData,
        closingDayWindowShow: state.tasksPage.closingDayWindowShow,
        selectedTask: state.taskInfoPlate.selectedTask,
        isShownTaskInfoPlate: state.taskInfoPlate.isShownTaskInfoPlate,
        activeColumns: state.tasksPage.activeColumns,
        tasks: state.tasksPage.tasks,
        isLoading: state.tasksPage.isLoading,
        currentId: state.commonInfo.current_user,
        selectedUserId: state.tasksPage.selectedUserId,
        commentFiles: state.taskInfoPlate.commentFiles,
        todayDate: state.taskInfoPlate.begin,
        scheduleUser: state.commonInfo.scheduleUser,
        timeSheet: state.tasksPage.timeSheet,
        TimeSheetCache: state.tasksPage.TimeSheetCache,
        isShownDayClose: state.tasksPage.isShownDayClose,
        closingDayWindowShowHidingSignal: state.tasksPage.closingDayWindowShowHidingSignal
    };
};

const mapDispatchToProps = {
    setShownTaskInfoPlate,
    selectTask,
    changeTaskList,
    fetchTasks,
    setSelectedUserId,
    loadSpecificTask,
    launchProcedureFinishTask,
    sendComment,
    updateTask,
    timeSheetActions,
    closingDay,
    LocalTimeSheetRecord,
    fetchVisualizationList,
    timesheetCacheRecord,
    closingDayDispatchAll,
    setShowDaysOff,
    closingDayHidingSignal
};

export default connect(mapStateToProps, mapDispatchToProps)(ClosingDay);
