import React from 'react';

const AccordionIcon5 = () => {
    return (
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 1L4.3333 7L1 4" stroke="#43A047" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        
    );
};

export default AccordionIcon5;