export default {
  date: {
    month_full: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
    month_short: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
    day_full: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
    day_short: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"]
  },
  labels: {
    dhx_cal_today_button: "Oggi",
    day_tab: "Giorno",
    week_tab: "Settimana",
    month_tab: "Mese",
    new_event: "Nuovo evento",
    icon_save: "Salva",
    icon_cancel: "Chiudi",
    icon_details: "Dettagli",
    icon_edit: "Modifica",
    icon_delete: "Elimina",
    confirm_closing: "",
    //Le modifiche apportate saranno perse, siete sicuri?
    confirm_deleting: "L'evento sarà eliminato, siete sicuri?",
    section_description: "Descrizione",
    section_time: "Periodo di tempo",
    full_day: "Intera giornata",
    confirm_recurring: "Vuoi modificare l'intera serie di eventi?",
    section_recurring: "Ripetere l'evento",
    button_recurring: "Disattivato",
    button_recurring_open: "Attivato",
    button_edit_series: "Modificare la serie",
    button_edit_occurrence: "Modificare una copia",
    /*agenda view extension*/
    agenda_tab: "Agenda",
    date: "Data",
    description: "Descrizione",
    /*year view extension*/
    year_tab: "Anno",
    /*week agenda view extension*/
    week_agenda_tab: "Agenda",
    /*grid view extension*/
    grid_tab: "Griglia",
    /* touch tooltip*/
    drag_to_create: "Drag to create",
    drag_to_move: "Drag to move",
    /* dhtmlx message default buttons */
    message_ok: "OK",
    message_cancel: "Cancel",
    /* wai aria labels for non-text controls */
    next: "Next",
    prev: "Previous",
    year: "Year",
    month: "Month",
    day: "Day",
    hour: "Hour",
    minute: "Minute",
    /* recurring event components */
    repeat_radio_day: "Quotidiano",
    repeat_radio_week: "Settimanale",
    repeat_radio_month: "Mensile",
    repeat_radio_year: "Annuale",
    repeat_radio_day_type: "Ogni",
    repeat_text_day_count: "giorno",
    repeat_radio_day_type2: "Ogni giornata lavorativa",
    repeat_week: " Ripetere ogni",
    repeat_text_week_count: "settimana:",
    repeat_radio_month_type: "Ripetere",
    repeat_radio_month_start: "Il",
    repeat_text_month_day: "giorno ogni",
    repeat_text_month_count: "mese",
    repeat_text_month_count2_before: "ogni",
    repeat_text_month_count2_after: "mese",
    repeat_year_label: "Il",
    select_year_day2: "del",
    repeat_text_year_day: "giorno",
    select_year_month: "mese",
    repeat_radio_end: "Senza data finale",
    repeat_text_occurences_count: "occorenze",
    repeat_radio_end3: "Fine",
    repeat_radio_end2: "Dopo",
    month_for_recurring: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Jiugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
    day_for_recurring: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Jovedì", "Venerdì", "Sabato"]
  }
};