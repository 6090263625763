export default function (scheduler) {
  //Initial idea and implementation by Steve MC
  scheduler._temp_key_scope = function () {
    scheduler.config.key_nav = true;
    scheduler.$keyboardNavigation._pasteDate = null; // used for copy and paste operations
    scheduler.$keyboardNavigation._pasteSection = null; // used for copy and paste operations
    var isCopy = null;
    var pos = {};
    if (!document.body) {
      scheduler.event(window, "load", function () {
        scheduler.event(document.body, "mousemove", trackMousePosition);
      });
    } else {
      scheduler.event(document.body, "mousemove", trackMousePosition);
    }
    function trackMousePosition(event) {
      event = event || window.event;
      pos.x = event.clientX;
      pos.y = event.clientY;
    }
    function currentTarget() {
      var mousePointer = false;
      var keyNavPointer = false;
      var target = document.elementFromPoint(pos.x, pos.y);
      while (target && target != scheduler._obj) {
        target = target.parentNode;
      }
      mousePointer = !!(target == scheduler._obj);
      keyNavPointer = scheduler.$keyboardNavigation.dispatcher.isEnabled();
      return mousePointer || keyNavPointer;
    }
    scheduler.attachEvent("onMouseMove", function (id, e) {
      var state = scheduler.getState();

      // make sure scheduler is fully initialized before calling scheduler.getActionData
      if (!(state.mode && state.min_date)) {
        return;
      }
      var position = scheduler.getActionData(e);
      scheduler.$keyboardNavigation._pasteDate = position.date;
      scheduler.$keyboardNavigation._pasteSection = position.section;
    });
    function clear_event_after(ev) {
      delete ev.rec_type;
      delete ev.rec_pattern;
      delete ev.event_pid;
      delete ev.event_length;
    }
    function copyEvent(ev) {
      return scheduler._lame_copy({}, ev);
    }
    scheduler._make_pasted_event = function (ev) {
      var date = scheduler.$keyboardNavigation._pasteDate;
      var section = scheduler.$keyboardNavigation._pasteSection;
      var event_duration = ev.end_date - ev.start_date;
      var copy = copyEvent(ev);
      clear_event_after(copy);
      copy.start_date = new Date(date);
      copy.end_date = new Date(copy.start_date.valueOf() + event_duration);
      if (section) {
        var property = scheduler._get_section_property();
        if (scheduler.config.multisection) copy[property] = ev[property]; // save initial set of resources for multisection view
        else copy[property] = section;
      }
      return copy;
    };
    scheduler._do_paste = function (is_copy, modified_ev, original_ev) {
      if (scheduler.callEvent("onBeforeEventPasted", [is_copy, modified_ev, original_ev]) === false) {
        return;
      }
      scheduler.addEvent(modified_ev);
      scheduler.callEvent("onEventPasted", [is_copy, modified_ev, original_ev]);
    };
    scheduler._is_key_nav_active = function () {
      if (this._is_initialized() && !this._is_lightbox_open() && this.config.key_nav) {
        return true;
      }
      return false;
    };
    function getSelectedEvent() {
      var node = scheduler.$keyboardNavigation.dispatcher.getActiveNode();
      if (node && node.eventId) return node.eventId;
      return scheduler._select_id;
    }
    scheduler.event(document, "keydown", function (e) {
      // compatibility fix - scheduler focus on ctrl+v on mouse hover
      if ((e.ctrlKey || e.metaKey) && e.keyCode == 86 && scheduler._buffer_event && !scheduler.$keyboardNavigation.dispatcher.isEnabled()) {
        scheduler.$keyboardNavigation.dispatcher.isActive = currentTarget();
      }
    });
    scheduler._key_nav_copy_paste = function (e) {
      if (!scheduler._is_key_nav_active()) return true;
      if (e.keyCode == 37 || e.keyCode == 39) {
        // Left, Right arrows
        e.cancelBubble = true;
        var next = scheduler.date.add(scheduler._date, e.keyCode == 37 ? -1 : 1, scheduler._mode);
        scheduler.setCurrentView(next);
        return true;
      }
      var select_id = getSelectedEvent();
      if ((e.ctrlKey || e.metaKey) && e.keyCode == 67) {
        // CTRL+C
        if (select_id) {
          scheduler._buffer_event = copyEvent(scheduler.getEvent(select_id));
          isCopy = true;
          scheduler.callEvent("onEventCopied", [scheduler.getEvent(select_id)]);
        }
        return true;
      }
      if ((e.ctrlKey || e.metaKey) && e.keyCode == 88) {
        // CTRL+X
        if (select_id) {
          isCopy = false;
          var ev = scheduler._buffer_event = copyEvent(scheduler.getEvent(select_id));
          scheduler.updateEvent(ev.id);
          scheduler.callEvent("onEventCut", [ev]);
        }
      }
      if ((e.ctrlKey || e.metaKey) && e.keyCode == 86 && currentTarget(e)) {
        // CTRL+V
        var ev = scheduler._buffer_event ? scheduler.getEvent(scheduler._buffer_event.id) : scheduler._buffer_event;
        ev = ev || scheduler._buffer_event;
        if (ev) {
          var new_ev = scheduler._make_pasted_event(ev);
          if (isCopy) {
            new_ev.id = scheduler.uid();
            scheduler._do_paste(isCopy, new_ev, ev);
          } else {
            // cut operation
            var res = scheduler.callEvent("onBeforeEventChanged", [new_ev, e, false, ev]);
            if (res) {
              scheduler._do_paste(isCopy, new_ev, ev);
              isCopy = true; // switch to copy after first paste operation
            }
          }
        }

        return true;
      }
    };
  };
  scheduler._temp_key_scope();
}