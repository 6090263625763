import React, { useEffect, useState } from "react";
import UserTr from "./UserTr";
import { IMeta, User } from "../../../common/types";
import { State } from "../../../rootReducer";
import { connect, useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import { fetchUsers, setCurrentPage } from "../actions";

type Props = {
  userList: User[];
  usersMeta: IMeta;
  currentPage: number;
  setCurrentPage: (mode: number) => void;
};

const WrapperUsers = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

const WrapperRef = styled.div`
  height: 10px;
`;

const UsersTableContent: React.FC<Props> = ({
  userList,
  usersMeta,
  currentPage,
  setCurrentPage
}) => {
  const dispatch = useDispatch();

  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView && currentPage < usersMeta.last_page) {
      setCurrentPage(currentPage + 1);
      dispatch(fetchUsers());
    }
  }, [inView]);

  return (
    <>
      <WrapperUsers>
        {userList.map((user) => (
          <UserTr key={user.id} user={user} />
        ))}
        {currentPage < usersMeta.last_page && (
          <WrapperRef ref={ref} style={{ height: "5px" }} />
        )}
      </WrapperUsers>
    </>
  );
};

const mapStateToProps = (state: State) => ({
  userList: state.usersPage.depGroupUsers,
  usersMeta: state.usersPage.usersMeta,
  currentPage: state.usersPage.currentPage,
});

const mapDispatchToProps = {
  setCurrentPage
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersTableContent);
