import React, { useState } from "react";
import MeetingAddPanel from "../../../../Meetings/components/Sidebar/MeetingAddPanel";

interface Props {
  addMeeting: (prop: any) => void;
  isShowAddMeeting: boolean;
  setIsShowAddObject: (mode: boolean) => void;
  activeMeeting?: any;
  editMeeting: (id: string, props: any) => void;
  isEdit: boolean;
  setIsEdit: (mode: boolean) => void;
  setActiveMeeting: (mode: any) => void;
  users: any;
  cellData: any;
  setCellData: (mode: any) => void;
  addEventHandler: (event: any, ans: any) => void;
}

const AddMeetForm: React.FC<Props> = ({
  addMeeting,
  isShowAddMeeting,
  setIsShowAddObject,
  activeMeeting,
  setActiveMeeting,
  editMeeting,
  isEdit,
  setIsEdit,
  users,
  cellData,
  setCellData,
  addEventHandler,
}) => {
  const [meetWithCalendar, setMeetWithCalendar] = useState<boolean>(false);
  return (
    <>
      <MeetingAddPanel
        addMeeting={addMeeting}
        isShowAddMeeting={isShowAddMeeting}
        setIsShowAddMeeting={setIsShowAddObject}
        activeMeeting={activeMeeting}
        setActiveMeeting={setActiveMeeting}
        editMeeting={editMeeting}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        users={users}
        meetWithCalendar={meetWithCalendar}
        cellData={cellData}
        setCellData={setCellData}
        addEventHandler={addEventHandler}
      />
    </>
  );
};

export default AddMeetForm;
