import React from 'react'
import {store} from '../App'
import {enqueueSnackbar, closeSnackbar} from '../common/actions'
// import {BrowserRouter, Route, Redirect, Switch} from 'react-router-dom'
// import {render} from "react-dom"
// import {useHistory} from "react-router-dom";
// import {render} from "react-dom";

export function CreateNotif(text: string, variant: string = 'error'): any {
    store.dispatch(enqueueSnackbar({
        message: text,
        options: {
            key: performance.now(),
            variant,
            action: key => (
                <img className="snackbar_cross" onClick={() => {
                    store.dispatch(closeSnackbar(key))
                }} src={require('../img/white_close.svg').default} alt="close"/>
            )
        }
    }))
}