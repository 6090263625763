// Выбор должности для добавления пользователя в компанию

import React, {useEffect} from 'react';  //***************************************** */
import withTitle from '../../../utils/hoc/withTitle';
import { connect,useSelector } from 'react-redux'; //**************************************** */
import SelectWithSecondaryBtn from '../../../common/components/Selects/SelectWithSecondaryBtn';
import { setUserPosition } from '../actions';
import { State } from '../../../rootReducer';
import { getPosSelectData } from '../selectors';
import { SelectData, User } from '../../../common/types';
import NameWithInput from '../../../common/components/newComponent/Inputs/NameWithInput';

type Props = {
  posSelectData: SelectData;
  userId: number;
  currentUserInfo: User | null;
  setUserPosition: (position: number[]) => void;
  setChangeDataUser: any;
  rolesUser: number[];
  title?: string;
  isAddUserInCompany?: boolean;
};

//Должность можно менять для новых пользователей, и для других пользователей, если ты админ или директор

const PositionSelect: React.FC<Props> = ({
  posSelectData,
  userId,
  currentUserInfo,
  rolesUser,
  setUserPosition,
  setChangeDataUser,
  title,
  isAddUserInCompany,
}) => {



  // ***********************  Получение информации из state
 // const { positions} = useSelector(
 //   (state: State) => state.commonInfo
 // );
//  console.log("positions***",positions)
//****************************************************** */
const val = useSelector((state: State)=> state.newUser.positions)
useEffect(()=>{
  (userId !== 0 || isAddUserInCompany) &&
  setChangeDataUser((prev) => ({
    ...prev,
    data: { ...prev.data, positions: val },
    schedule: { ...prev.schedule },
  }));
},[val])

// console.log("isAddUserInCompany",isAddUserInCompany) 
//useEffect(()=>{console.log(setUserPosition);
//if (isAddUserInCompany===true) { setUserPosition([positions[0].id]); }
//},[isAddUserInCompany])  //******************************************** */


  return (
    <NameWithInput title={title} iconName="bag">
      <SelectWithSecondaryBtn
        disabled={
          !(
            userId === 0 ||
            (userId === currentUserInfo?.id &&
              currentUserInfo!.roles.sort()[0] < rolesUser.sort()[0]) ||
            currentUserInfo?.roles.includes(1) ||
            currentUserInfo!.roles.sort()[0] < rolesUser.sort()[0]
          )
        }
        addPosition = {true}
        list={posSelectData.list}
        selected={posSelectData.selected}
        selectHandler={(value) => {
          setUserPosition([value as number]);    

        //  console.log("value position",value); //********************************* */
        //  console.log("posSelectData.list",posSelectData.list); //********************************* */
        //  console.log("posSelectData.selected",posSelectData.selected); //********************************* */

          (userId !== 0 || isAddUserInCompany) &&
            setChangeDataUser((prev) => ({
              ...prev,
              data: { ...prev.data, positions: [value] },
              schedule: { ...prev.schedule },
            }));
        }}
      />
    </NameWithInput>
  );
};

const mapStateToProps = (state: State) => ({
  posSelectData: getPosSelectData(state),
  userId: state.newUser.id,
  rolesUser: state.newUser.roles,
  currentUserInfo: state.commonInfo.currentUserInfo,
});

const mapDispatchToProps = {
  setUserPosition: setUserPosition,
};

export default connect(mapStateToProps, mapDispatchToProps)(PositionSelect);
