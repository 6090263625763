import {formatToStringUTC} from './format'

//!!!!!!!!!не изменять структуру и порядок, только содержимое

export const columnsOfTables = [
  {
    columnId: "id",
    showingText: "№",
    style: { minWidth: "121px", paddingLeft: "20px" },
  },

  {
    columnId: "name",
    showingText: "Задача",
    style: { minWidth: "256px", maxWidth: "750px" },
  },
  {
    columnId: "author_id",
    showingText: "Автор",
    style: { minWidth: "136px" },
  },
  {
    columnId: "executor_id",
    showingText: "Исп.",
    style: { minWidth: "58px" },
  },
  {
    columnId: "status_id",
    showingText: "Статус",
    style: { minWidth: "159px" },
  },
  {
    columnId: "end",
    showingText: "Дедлайн",
    style: { minWidth: "93px" },
  },

  {
    columnId: "task_load",
    showingText: "Загрузка",
    style: { minWidth: "83px" },
  },

  {
    columnId: "project_name",
    showingText: "Проект/раздел",
    style: { minWidth: "158px" },
  },
  {
    columnId: "priority_id",
    showingText: "Приоритет",
    style: { minWidth: "123px" },
  },
  {
    columnId: "id_bp",
    showingText: "БП",
    style: { minWidth: "40px" },
  },
];

////////// shared functions for Saga in tasks and Projects
export function sorting(tasksOrder) {
  if (tasksOrder.col_name === "") {
    return "";
  } else {
    let result = "&order=" + tasksOrder.col_name;
    if (!tasksOrder.order_direct) result += "&orderType=desc";
    return result;
  }
}

export function table_filter(tableFilter) {
  return tableFilter ? "&search=" + tableFilter : "";
}

export function CollectAllFilters(filters) {
  let collect_all_values = "";
  if (filters.statuses) {
    let statuses_bank = "";
    filters.statuses.forEach((item) => {
      // и значения в конечном стринге разделяю запятыми
      if (statuses_bank.length) statuses_bank += ",";
      statuses_bank += item;
    });
    if (statuses_bank.length)
      collect_all_values += "&status_id=" + statuses_bank;

    let authors_bank = "";
    filters.authors.forEach((item) => {
      // и значения в конечном стринге разделяю запятыми
      if (authors_bank.length) authors_bank += ",";
      authors_bank += item;
    });
    if (authors_bank.length) collect_all_values += "&author_id=" + authors_bank;

    let executors_bank = "";
    filters.executors.forEach((item) => {
      // и значения в конечном стринге разделяю запятыми
      if (executors_bank.length) executors_bank += ",";
      executors_bank += item;
    });
    if (executors_bank.length)
      collect_all_values += "&executor_id=" + executors_bank;

    if (filters.date_from.length) {
      collect_all_values += "&begin_from=" + formatToStringUTC(filters.date_from);
    }

    if (filters.date_to.length) {
      collect_all_values += "&end_to=" + formatToStringUTC(filters.date_to);
    }
  }
  return collect_all_values;
}
