import React from "react";

const BsFilter57_active = ({ props }) => {
  return (
    <svg
      {...props}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="35"
        height="35"
        rx="3.5"
        fill="#7B7B7B"
        fill-opacity="0.1"
        stroke="#7B7B7B"
      />
    </svg>
  );
};

export default BsFilter57_active;
