import {useEffect} from 'react';
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack'
import { removeSnackbar } from '../../actions'
import { State } from '../../../rootReducer';


type Notif = {
    key: number,
    message: string,
    options: any
    dismissed: boolean
}

let displayed: number[] = []


const storeDisplayed = (id) => {
    displayed = [...displayed, id]
}
const removeDisplayed = (id) => {
    displayed = [...displayed.filter(key => id !== key)]
}




const Notifier = ({ notifications, removeSnackbar }) => {


    const {enqueueSnackbar, closeSnackbar} = useSnackbar()
    
    useEffect(() => {
        notifications.forEach(({key, message, options = {}, dismissed = false}: Notif) => {
            if (dismissed) {
                closeSnackbar(key)
                return
            }
            if (displayed.includes(key)) return
            enqueueSnackbar(message, {
                key,
                ...options,
                onExited: (event, myKey) => {
                    removeSnackbar(myKey)
                    removeDisplayed(myKey)
                }
            })

            storeDisplayed(key)

        }, [notifications, closeSnackbar, enqueueSnackbar])
    })


    return null
}




const mapStateToProps = (state: State) => {
    return {
        notifications: state.commonInfo.notifications
    }
}

const mapDispatchToProsp = dispatch => {
    return {
        removeSnackbar: (key) => dispatch(removeSnackbar(key))
    }
}

export default connect(mapStateToProps, mapDispatchToProsp)(Notifier)