import React, { useState } from "react";
import { MeetingListItem } from "../../types";
import MeetingItem from "./components/MeetingItem/MeetingItem";
import styled from "styled-components";
import CreateQuestion from "./components/Questions/CreateQuestion";
import { Meeting } from "../../../../common/types";

interface Props {
  changeMeeting: boolean;
  setChangeMeeting(v: boolean): void;
  statusDataMember: boolean;
  setStatusDataMember: (v: boolean) => void;
  listMeeting: any;
  deleteMeeting: (id: number) => void;
  setActiveMeeting: (meeting: any) => void
  setIsEdit: (mode: boolean) => void;
}

const DivMain = styled.div`
  overflow-y: auto;
  height: calc(100vh - 270px);
`;

const MeetingList: React.FC<Props> = ({
  listMeeting,
  deleteMeeting,
  changeMeeting,
  setChangeMeeting,
  statusDataMember,
  setStatusDataMember,
  setActiveMeeting,
   setIsEdit
}) => {

  return (
    <DivMain>
      <>
        {listMeeting?.data?.length === 0 ? (
          <div style={{ paddingLeft: 24, fontSize: 24 }}>
            Совещания отсутствуют
          </div>
        ) : (
          <>
            {listMeeting?.data?.map((meeting: any) => {
              return (
                <MeetingItem
                  changeMeeting={changeMeeting}
                  setChangeMeeting={setChangeMeeting}
                  key={meeting.id}
                  meeting={meeting}
                  deleteMeeting={deleteMeeting}
                  statusDataMember={statusDataMember}
                  setStatusDataMember={setStatusDataMember}
                  setActiveMeeting={setActiveMeeting}
                  setIsEdit = {setIsEdit}
                />
              );
            })}
          </>
        )}
      </>
    </DivMain>
  );
};

export default MeetingList;
