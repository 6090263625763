import {
  types,
  changeTaskListAction,
  fetchTaskDependenceAction,
  changeStepAction,
  pricePartsAction,
  paidSuccessAction,
  paymentDetailsAction,
  setPaidAction,
  setFreeAction,
  billPDFCreateAction,
  amountAction,
  actualRequestAction,
  setModulesPriceAction,
  totalPriceAction,
  createBillAction,
  setSubscribeStatusAction,
  setSelectedModulesAction,
  setPaymentRequestIDAction,
  setSubscribeStartAction,
  setBillUrlAction,
  setSubscribeEndAction,
  setLoadingAction,
  setMonthsCountAction,
  fetchModulesPriceAction,
  setUsersPaidAction,
  setBillInfoAction,
  fetchOnlineBillStatusAction,
  fetchOfflineBillStatusAction,
  checkActivePaymethodAction,
} from "./action_types";
import { Task } from "../../common/types";

export const createBill = (
  company_id: number | string,
  months_count: number,
  users_count: number,
  modules: string[],
  payment_type: string
): createBillAction => ({
  type: types.CREATE_BILL,
  company_id,
  months_count,
  users_count,
  modules,
  payment_type,
});

export const billPDFCreate = (
  payment_request_id: number
): billPDFCreateAction => ({
  type: types.BILL_PDF_CREATE,
  payment_request_id,
});

export const amount = (
  company_id: number | string,
  months_count: number,
  users_count: number,
  modules: string[]
  // payment_type: string
): amountAction => ({
  type: types.AMOUNT,
  company_id,
  months_count,
  users_count,
  modules,
  // payment_type,
});

export const fetchOnlineBillStatus = (
  company_id: number
): fetchOnlineBillStatusAction => ({
  type: types.FETCH_ONLINE_BILL_STATUS,
  company_id,
});

export const fetchOfflineBillStatus = (
  company_id: number
): fetchOfflineBillStatusAction => ({
  type: types.FETCH_OFFLINE_BILL_STATUS,
  company_id,
});

export const actualRequest = (company_id: number): actualRequestAction => ({
  type: types.ACTUAL_REQUEST,
  company_id,
});
export const setBillInfo = (bill_info: any): setBillInfoAction => ({
  type: types.SET_BILL_INFO,
  bill_info,
});

export const fetchModulesPrice = (): fetchModulesPriceAction => ({
  type: types.FETCH_MODULES_PRICE,
});
export const setModulesPrice = (
  module_prices: object
): setModulesPriceAction => ({
  type: types.SET_MODULES_PRICE,
  module_prices,
});

export const setTotalPrice = (total_price: string): totalPriceAction => ({
  type: types.SET_TOTAL_PRICE,
  total_price,
});

export const setPaid = (isPaid: boolean): setPaidAction => ({
  type: types.SET_PAID,
  isPaid,
});
export const checkActivePaymethod = (
  is_active_paymethod: boolean
): checkActivePaymethodAction => ({
  type: types.CHECK_ACTIVE_PAYMETHOD,
  is_active_paymethod,
});

export const setBillUrl = (url: string): setBillUrlAction => ({
  type: types.SET_BILL_URL,
  url,
});

export const setPaymentRequestId = (
  payment_request_id: number
): setPaymentRequestIDAction => ({
  type: types.SET_PAYMENT_REQUEST_ID,
  payment_request_id,
});

export const setSubscribeStart = (
  subscribe_start: string
): setSubscribeStartAction => ({
  type: types.SET_SUBSCRIBE_START,
  subscribe_start,
});

export const setSubscribeEnd = (
  subscribe_end: string
): setSubscribeEndAction => ({
  type: types.SET_SUBSCRIBE_END,
  subscribe_end,
});

export const setLoading = (loading: boolean): setLoadingAction => ({
  type: types.SET_LOADING,
  loading,
});
export const setUsersPaid = (users_paid: number): setUsersPaidAction => ({
  type: types.SET_USERS_PAID,
  users_paid,
});

export const setMonthCount = (months_count: number): setMonthsCountAction => ({
  type: types.SET_MONTHS_COUNT,
  months_count,
});

export const setSubscribeStatus = (
  subscribe_status: boolean
): setSubscribeStatusAction => ({
  type: types.SET_SUBSCRIBE_STATUS,
  subscribe_status,
});

export const setSelectedModules = (
  module: string
): setSelectedModulesAction => ({
  type: types.SET_SELECTED_MODULES,
  module,
});

export const changeStep = (stepNumber: number): changeStepAction => ({
  type: types.CHANGE_STEP,
  stepNumber,
});

export const changeTaskList = (tasks: Task[]): changeTaskListAction => ({
  type: types.CHANGE_TASK_LIST,
  tasks,
});

export const fetchTaskDependence = (id: number): fetchTaskDependenceAction => ({
  type: types.FETCH_TASK_DEPENDENCE,
  id,
});

export const paidSuccess = (value): paidSuccessAction => ({
  type: types.PAID_SUCCESS,
  value,
});

export const paymentDetails = (value): paymentDetailsAction => ({
  type: types.PAYMENT_DETAILS,
  value,
});

export const changePriceParts = (
  members?: number,
  months?: number
): pricePartsAction => ({
  type: types.CHANGE_PRICE_PARTS,
  members,
  months,
});

export const setFree = (companyId): setFreeAction => ({
  type: types.SET_FREE,
  companyId,
});
