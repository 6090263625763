// Редактирование профиля компании
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { State } from "../../../rootReducer";
import { useForm } from "react-hook-form";
import { setCompany, setPlateEditMode } from ".././actions";
import CustomInput from "../../../common/components/newComponent/Inputs/CustomInput";
import Button from "../../../common/components/newComponent/Buttons/Button";
import { showCompanyWindow } from "../../../common/actions";
import { fetchCreateCompany } from "../../Login/actions";
import { TextareaAutosize } from "@material-ui/core";
import Picture from "./picture";

const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0;
  height: 40px;
  span {
    font-size: 18px;
    line-height: 24px;
    color: #212121;
  }
  input {
    font-size: 18px;
    line-height: 24px;
    color: #212121;
  }
`;
const WrapperButton = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  button:first-child {
    margin-right: 8px;
  }
`;

const TopInfoTextItem = styled.div`
  margin-left: 8px;
  line-height: 24px;
`;

type Input = {
  name: string;
  description?: string;
  address?: string;
  inn?: number;
  kpp?: number;
  ogrn?: number;
  info?: string;
};

const EditModule = ({
  plateEditMode,
  setPlateEditMode,
  companyInfoPlate,
  setCompany,
}) => {
  const changeHandler1 = () => {};

  const setInputsValue = () => {};

  const [companyImage, setCompanyImage] = useState();

  const active = useSelector(
    (state: State) => state.commonInfo.show_company_window
  );
  const dispatch = useDispatch();
  const { register, handleSubmit, getValues } = useForm();
  const { id, name } = useSelector((state: State) => state.companyInfoPlate);

  return (
    <div>
      {plateEditMode && (
        <div>
          <WrapperTitle>
            <CustomInput
              register={register}
              name="name"
              placeholder="Введите название"
              value={companyInfoPlate.name ? companyInfoPlate.name : undefined}
              inputChangeHandler={(a) => setCompany({ name: a })}
            />
            {/* )} */}
          </WrapperTitle>

          <div
            style={{ display: "flex", marginTop: "16px", marginBottom: "16px" }}
          >
            {/* Показ картинки для компании. Если не выбрана - стандартная */}

            <Picture
              image={companyInfoPlate.logo}
              edit={plateEditMode}
              changeHandler1={changeHandler1}
              setInputsValue={setInputsValue}
              companyImage={companyImage}
              setCompanyImage={setCompanyImage}
            />

            <div style={{ marginLeft: "64px", width: "100%" }}>
              <div style={{ height: "40px", marginBottom: "8px" }}>
                <CustomInput
                  placeholder={"Телефон"}
                  height={"40px"}
                  width={"100%"}
                  value={
                    companyInfoPlate.phone ? companyInfoPlate.phone : undefined
                  }
                  inputChangeHandler={(a) => setCompany({ phone: a })}
                />
              </div>
              <div style={{ height: "40px", marginBottom: "8px" }}>
                <CustomInput
                  placeholder={"Email"}
                  height={"40px"}
                  width={"100%"}
                  value={
                    companyInfoPlate.email ? companyInfoPlate.email : undefined
                  }
                  inputChangeHandler={(a) => setCompany({ email: a })}
                />
              </div>
              <div style={{ height: "40px", marginBottom: "8px" }}>
                <CustomInput
                  placeholder={"Веб сайт"}
                  height={"40px"}
                  width={"100%"}
                  value={
                    companyInfoPlate.site ? companyInfoPlate.site : undefined
                  }
                  inputChangeHandler={(a) => setCompany({ site: a })}
                />
              </div>
            </div>
          </div>

          <div style={{ display: "flex", marginBottom: "8px" }}>
            <div
              style={{
                width: "160px",
                display: "inline-table",
                lineHeight: "40px",
              }}
            >
              Описание
            </div>
            <TextareaAutosize
              onChange={(e) => {
                setCompany({ description: e.target.value });
              }}
              value={
                companyInfoPlate.description
                  ? companyInfoPlate.description
                  : undefined
              }
              minRows={2}
              style={{
                border: "1px solid #e0e0e0",
                borderRadius: "6px",
                height: "72px",
                width: "100%",
                resize: "none",
                overflow: "auto",
              }}
            />
          </div>

          <div style={{ display: "flex", marginBottom: "8px" }}>
            <div
              style={{
                width: "160px",
                display: "inline-table",
                lineHeight: "40px",
              }}
            >
              Юр. адрес
            </div>
            <CustomInput
              height={"40px"}
              width={"100%"}
              style={{}}
              value={
                companyInfoPlate.address ? companyInfoPlate.address : undefined
              }
              inputChangeHandler={(a) => setCompany({ address: a })}
            />
          </div>

          <div style={{ display: "flex", marginBottom: "8px" }}>
            <div
              style={{
                width: "160px",
                display: "inline-table",
                lineHeight: "40px",
              }}
            >
              ИНН
            </div>
            <CustomInput
              height={"40px"}
              width={"100%"}
              style={{}}
              value={companyInfoPlate.inn ? companyInfoPlate.inn : undefined}
              inputChangeHandler={(a) => setCompany({ inn: a })}
            />
          </div>

          <div style={{ display: "flex", marginBottom: "8px" }}>
            <div
              style={{
                width: "160px",
                display: "inline-table",
                lineHeight: "40px",
              }}
            >
              КПП
            </div>
            <CustomInput
              height={"40px"}
              width={"100%"}
              style={{}}
              value={companyInfoPlate.kpp ? companyInfoPlate.kpp : undefined}
              inputChangeHandler={(a) => setCompany({ kpp: a })}
            />
          </div>

          <div style={{ display: "flex", marginBottom: "8px" }}>
            <div
              style={{
                width: "160px",
                display: "inline-table",
                lineHeight: "40px",
              }}
            >
              ОГРН
            </div>
            <CustomInput
              height={"40px"}
              width={"100%"}
              style={{}}
              value={companyInfoPlate.ogrn ? companyInfoPlate.ogrn : undefined}
              inputChangeHandler={(a) => setCompany({ ogrn: a })}
            />
          </div>

          <div style={{ display: "flex", marginBottom: "8px" }}>
            <div
              style={{
                width: "160px",
                display: "inline-table",
                lineHeight: "40px",
              }}
            >
              ОКВЭД
            </div>
            <CustomInput
              height={"40px"}
              width={"100%"}
              style={{}}
              value={
                companyInfoPlate.okved ? companyInfoPlate.okved : undefined
              }
              inputChangeHandler={(a) => setCompany({ okved: a })}
            />
          </div>

          <div style={{ display: "flex", marginBottom: "8px" }}>
            <div
              style={{
                width: "160px",
                display: "inline-table",
                lineHeight: "40px",
              }}
            >
              БИК
            </div>
            <CustomInput
              height={"40px"}
              width={"100%"}
              style={{}}
              value={companyInfoPlate.bik ? companyInfoPlate.bik : undefined}
              inputChangeHandler={(a) => setCompany({ bik: a })}
            />
          </div>

          <div style={{ display: "flex", marginBottom: "8px" }}>
            <div
              style={{
                width: "160px",
                display: "inline-table",
                lineHeight: "40px",
              }}
            >
              Расчетный счет
            </div>
            <CustomInput
              height={"40px"}
              width={"100%"}
              style={{}}
              value={
                companyInfoPlate.checking_account
                  ? companyInfoPlate.checking_account
                  : undefined
              }
              inputChangeHandler={(a) => setCompany({ checking_account: a })}
            />
          </div>

          <div style={{ display: "flex", marginBottom: "8px" }}>
            <div
              style={{
                width: "160px",
                display: "inline-table",
                lineHeight: "40px",
              }}
            >
              Название банка
            </div>
            <CustomInput
              height={"40px"}
              width={"100%"}
              style={{}}
              value={
                companyInfoPlate.bank_name
                  ? companyInfoPlate.bank_name
                  : undefined
              }
              inputChangeHandler={(a) => setCompany({ bank_name: a })}
            />
          </div>

          <div style={{ display: "flex", marginBottom: "8px" }}>
            <div
              style={{
                width: "160px",
                display: "inline-table",
                lineHeight: "40px",
              }}
            >
              Корр. счет
            </div>
            <CustomInput
              height={"40px"}
              width={"100%"}
              style={{}}
              value={
                companyInfoPlate.correspondent_account
                  ? companyInfoPlate.correspondent_account
                  : undefined
              }
              inputChangeHandler={(a) =>
                setCompany({ correspondent_account: a })
              }
            />
          </div>
        </div>
      )}

      {plateEditMode && (
        <WrapperButton>
          <Button
            title="Отмена"
            design="outline"
            onClick={() => {
              dispatch(showCompanyWindow(false));
              dispatch(setPlateEditMode(false));
            }}
          />
          <Button
            title="Готово"
            onClick={() => {
              dispatch(fetchCreateCompany(companyInfoPlate, []));
            }}
          />
        </WrapperButton>
      )}
    </div>
  );
};
const mapStateToProps = (state: State) => {
  return {
    plateEditMode: state.companyInfoPlate.plateEditMode,
    companyInfoPlate: state.companyInfoPlate,
  };
};

const mapDispatchToProps = {
  setPlateEditMode,
  setCompany,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditModule);
