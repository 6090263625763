import React from 'react';

const PhoneIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.03089 12.189C9.84127 13.8458 11.1842 15.1826 12.8447 15.9855C12.9662 16.043 13.1006 16.068 13.2346 16.0578C13.3687 16.0477 13.4978 16.0028 13.6092 15.9276L16.0541 14.2973C16.1622 14.2252 16.2866 14.1812 16.4161 14.1693C16.5455 14.1574 16.6758 14.178 16.7953 14.2292L21.3692 16.1895C21.5246 16.2555 21.6543 16.3702 21.7388 16.5163C21.8233 16.6625 21.8581 16.8321 21.8378 16.9997C21.6932 18.1309 21.1413 19.1707 20.2853 19.9243C19.4293 20.6779 18.328 21.0937 17.1875 21.0937C13.6651 21.0937 10.287 19.6945 7.79624 17.2038C5.30552 14.713 3.90625 11.3349 3.90625 7.81249C3.90631 6.67203 4.32206 5.5707 5.07567 4.71471C5.82927 3.85872 6.86904 3.30677 8.00029 3.1622C8.16788 3.14191 8.33753 3.17665 8.48366 3.26116C8.6298 3.34568 8.74451 3.4754 8.81052 3.63077L10.7725 8.20869C10.8232 8.3271 10.8439 8.45623 10.8327 8.58456C10.8214 8.7129 10.7786 8.83647 10.7081 8.94426L9.08345 11.4267C9.0095 11.5383 8.96579 11.6673 8.95658 11.8009C8.94736 11.9345 8.97297 12.0682 9.03089 12.189V12.189Z" stroke="#292A34" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        
    );
};

export default PhoneIcon;