import React from 'react';
import CustomBlock from './CustomBlock';
import styled from 'styled-components';
import Button from '../../../common/components/newComponent/Buttons/Button';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { fetchData } from '../../../utils/fetchData';
import { useSelector } from 'react-redux';
import { State } from '../../../rootReducer';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useState } from 'react';

const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;
const LinkStyle = styled.div`
  max-width: 272px;
  height: 100%;
  font-size: 18px;
  line-height: 24px;
  color: #212121;
  word-wrap: break-word;
  margin: 24px 0;

  textarea {
    width: 100%;
    resize: none;
    overflow: auto;
  }
`;
const CompanyRequest = () => {
  const { current_user } = useSelector((state: State) => state.commonInfo);
  const [uniqueLink, setUniqueLink] = useState();
  const history = useHistory();

  useEffect(() => {
    fetchData
      .patch(`/api/v1/users/${current_user}/unique_link/reset`, null)
      .then((data) => {
        // console.log(data.unique_link.split('/'));

        setUniqueLink(data.unique_link);
      })
      .catch((error) => console.warn(error));
  }, []);
  return (
    <CustomBlock title="Запросить доступ">
      <span>Отправьте эту ссылку администратору огранизации</span>
      <LinkStyle>
        <TextareaAutosize id="linkRequest" value={uniqueLink} />
      </LinkStyle>
      <WrapperButtons>
        <Button
          style={{ width: '132px' }}
          big
          title="Скопировать"
          onClick={() => {
            (document.getElementById(
              'linkRequest'
            ) as HTMLInputElement).select();
            document.execCommand('copy');
          }}
        />
        <Button
          style={{ width: '132px' }}
          big
          design="secondary"
          title="На главную"
          onClick={() => history.push('/login')}
        />
      </WrapperButtons>
    </CustomBlock>
  );
};

export default CompanyRequest;
