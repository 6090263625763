//   Выбор и оплата тарифа

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect, useDispatch } from "react-redux";
import Button from "../../common/components/newComponent/Buttons/Button";
import Icon from "../../common/components/Icon";
import { State } from "../../rootReducer";
import {
  changePriceParts,
  changeStep,
  paidSuccess,
  paymentDetails,
} from "./actions";
import BreadCrumbsText from "./Components/breadCrumbText";
import BreadCrumbsArrow from "./Components/breadCrumbArrow";
import { fetchData } from "../../utils/fetchData";
import { nanoid } from "nanoid";
import { useHistory } from "react-router-dom";
// import { PayemntInfo } from "../Payment/action_types";
import { cookieMaster } from "../../utils/CookieMaster";
import PaymentSetup from "./Components/PaymentSetup";

type Props = {
  currentTariff: string;
  changeStep: (step: number) => void;
  step: number;
  months: number;
  members: number;
  changePriceParts: (members?: number, months?: number) => void;
  paid_success: boolean;
  commonInfoCompany: any;
  paymentDetails: (val: object) => void;
  paymentDetailsData: object;
  users_count: number;
};

const NewRow = styled.div`
  display: flex;
  // margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  ${({ styles }) => styles && { ...styles }}
`;

const StepTitle = styled.div`
  font-size: 24px;
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 36px;
  text-align: center;
`;

const NameForInputField = styled.div`
  width: 152px;
  padding: 12px 8px;
  margin-right: 8px;
`;

const NameRow3rdScreen = styled.div`
  width: 464px;
  height: 40px;
  display: flex;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Payment: React.FC<Props> = ({
  currentTariff,
  paid_success,
  changeStep,
  step,
  changePriceParts,
  members,
  months,
  paymentDetails,
  commonInfoCompany,
  paymentDetailsData,
  users_count,
}) => {
  const [monthsState, setMonthsState] = useState<number>(months);
  const [membersState, setMembersState] = useState<number>(users_count);
  const [companyId, setCompanyId] = useState<number>(0);
  const history = useHistory();
  const dispatch = useDispatch();
  // const [currentCompanyID, setCurrentCompanyID] = useState<number>(1);
  const [clickedTariff, setClickedTariff] = useState<number>(1);
  const [currentCompanyTariffInfo, setCurrentCompanyTariffInfo] =
    useState<object | null>(null);

  const [handlerCheckbox, setHandlerCheckbox] = useState<any>({
    inputs: [
      { name: "input1", completed: false },
      { name: "input2", completed: false },
      { name: "input3", completed: false, price: 99 },
      { name: "input4", completed: false, price: 49 },
      { name: "input5", completed: false, price: 99 },
      { name: "input6", completed: false, price: 99 },
    ],
  });

  const onChangeCheckbox = (e: any) => {
    const { name, checked } = e.target;
    setHandlerCheckbox((handlerCheckbox) => {
      return {
        ...handlerCheckbox,
        inputs: handlerCheckbox.inputs.map((input) => {
          if (input.name === name) {
            return { ...input, completed: checked };
          }
          return input;
        }),
      };
    });
  };

  const breadCrumbsItems = ["Выбор подписки", "Оплата"];

  const membersCount_rentDate = [{ text: "1", value: 1 }];
  for (let i = 2; i < 13; i++)
    membersCount_rentDate.push({ text: i.toString(), value: i });

  useEffect(() => {
    (async () => {
      if (step === 2) {
        // console.log("step2");
        setClickedTariff(step);
      }

      if (commonInfoCompany && Object.keys(commonInfoCompany).length) {
        //   const tariffInfo = await fetchData.get(
        //      `/api/v1/pricing_plans/${clickedTariff}`
        //    );
        // console.log("tariffInfo");
        const tariffInfo = { price_per_month: 99 };
        setCurrentCompanyTariffInfo(tariffInfo);
      }
    })();
  }, [setClickedTariff, clickedTariff, step, history.location.pathname]);

  let disabled = true;
  if (Object.keys(paymentDetailsData).length === 8) {
    let empty_field = false;

    Object.values(paymentDetailsData).forEach((item) => {
      if (item === "") empty_field = true;
    });

    if (!empty_field) disabled = false;
  }

  useEffect(() => {
    setMonthsState(months);
  }, [months]);

  useEffect(() => {
    setMembersState(members);
  }, [members]);
  // console.log(step);

  useEffect(() => {
    if (companyId === 0) {
      // console.log("company_Id_0");
      let LS_company_id = localStorage.getItem("company_id");

      if (LS_company_id) {
        setCompanyId(parseInt(LS_company_id));
      }
    } else {
      let path = history.location.pathname.split("/");
      if (path[path.length - 2] === "payment") {
        // console.log("payment");
        setCompanyId(+path[path.length - 1]);
      }
    }
  }, [
    localStorage.getItem("company_id"),
    commonInfoCompany,
    companyId,
    history.location.pathname,
  ]);

  useEffect(() => {
    let path = history.location.pathname.split("/");

    if (path.includes("transaction")) {
      // console.log("transaction");
      fetchData
        .get(
          `/api/v1/account/transactions?transaction_id=${
            path[path.length - 1]
          }`,
          {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "application/json",
            Authorization: `${cookieMaster.getCookie("access_token")}`,
            "company-id": `${path[path.length - 3]}`,
          }
        )
        .then((transactions) => {
          if (transactions[0].status === "PENDING") {
            // console.log("В процессе");
          } else if (transactions[0].status === "SUCCEDED") {
            // console.log("Оплачено");
            dispatch(paidSuccess(true));
            dispatch(changeStep(4));
          } else if (transactions[0].status === "CANCELLED") {
            // console.log("Ошибка");
            dispatch(paidSuccess(false));
            dispatch(changeStep(4));
          }
        });
    } else if (path[path.length - 1] !== "payment") {
      // console.log("else");
      setCompanyId(+path[path.length - 1]);
    }
  }, []);

  const navigation = useHistory();

  const btnPayment = () => {
    navigation.push("/payment/method");
  };

  return (
    <NewRow
      styles={{ justifyContent: "center", overflowY: "scroll", height: "93vh" }}
    >
      <div
      // style={{
      //   padding: "24px",
      //   width: "920px",

      // }}
      >
        <NewRow styles={{ marginTop: "10px" }}>
          {breadCrumbsItems.map((name, number) => {
            return (
              <div style={{ display: "flex" }} key={nanoid()}>
                <BreadCrumbsText
                  contextStep={number + 1}
                  currentStep={step}
                  name={name}
                  changeStep={changeStep}
                />
                {breadCrumbsItems.length !== number + 1 && <BreadCrumbsArrow />}{" "}
                {/*если это не последний пункт*/}
              </div>
            );
          })}
        </NewRow>

        {/* {step === 1 && ( */}
        {/* // <div> */}
        {/* <StepTitle>Выбор тарифа</StepTitle>
            <NewRow styles={{ justifyContent: "center" }}>
              {tilesSourceData.map((item) => {
                return (
                  <Tile
                    key={nanoid()}
                    companyId={companyId}
                    iconName={item.iconName}
                    color={item.color}
                    active={item.active}
                    tariffName={item.tariffName}
                    describe1={item.describe1}
                    price={item.price}
                    describe2={item.describe2}
                    // buttonTitle={item.buttonTitle}
                    buttonDesign={item.buttonDesign}
                    changeStep={changeStep}
                    id={item.id}
                    selectedTariffId={(arg) => {
                      setClickedTariff(arg);

                      // let newVal = {...paymentDetails}
                      // newVal['pricing_plan_id'] = arg
                      // setPaymentDetails(newVal)

                      let newPram = {};
                      newPram["pricing_plan_id"] = arg;
                      paymentDetails(newPram);
                    }}
                  />
                );
              })}
            </NewRow> */}
        {/* <TarifDescription></TarifDescription> */}
        {/* </div> */}
        {/* // )} */}
        {/* {step === 1 && ( */}
        <div>
          <PaymentSetup
            // months={months}
            monthsState={monthsState}
            setMonthsState={setMonthsState}
            membersState={membersState}
            // step={step}
            currentCompanyTariffInfo={currentCompanyTariffInfo}
            handlerCheckbox={handlerCheckbox}
            // setHandlerCheckbox={setHandlerCheckbox}
            // commonInfoCompany={commonInfoCompany}
            // setClickedTariff={setClickedTariff}
            // setCurrentCompanyTariffInfo={setCurrentCompanyTariffInfo}
            // clickedTariff={clickedTariff}
            // membersState={membersState}
            setMembersState={setMembersState}
            membersCount_rentDate={membersCount_rentDate}
            onChangeCheckbox={onChangeCheckbox}
          />
        </div>
        {/* )} */}

        {/* {step === 2 && (
          <div>
            <NewRow>
              <PaymentMethod />
            </NewRow>
          </div>
        )} */}

        {/* {step === 3 && (
          <NewRow
            styles={{
              justifyContent: "center",
              marginTop: "115px",
            }}
          >
            <div style={{ width: "320px" }}>
              <NewRow styles={{ width: "100%", justifyContent: "center" }}>
                {paid_success ? (
                  <Icon name={"success_payment"} />
                ) : (
                  <Icon name={"failed_payment"} />
                )}
              </NewRow>

              {paid_success ? (
                <NewRow styles={{ fontSize: "24px", justifyContent: "center" }}>
                  Оплата прошла успешно
                </NewRow>
              ) : (
                <NewRow styles={{ fontSize: "24px", justifyContent: "center" }}>
                  Ошибка оплаты
                </NewRow>
              )}
              <NewRow styles={{ padding: "0 32px", color: "#A8A8A8" }}></NewRow>
              <div>
                {!paid_success && (
                  <Button
                    title={"Повторить попытку"}
                    style={{ width: "100%", marginBottom: "8px" }}
                    big={true}
                    onClick={() => {
                      changeStep(2);
                    }}
                  />
                )}

                <Button
                  design={"outline"}
                  title={"Вернуться на главную"}
                  style={{ width: "100%" }}
                  big={true}
                  onClick={() => {
                    history.replace("/");
                  }}
                />
              </div>
            </div>
          </NewRow>
        )} */}
      </div>
    </NewRow>
  );
};

const mapStateToProps = (state: State) => {
  // console.log(state.commonInfo.company)
  return {
    currentTariff: state.payment.currentTariff,
    step: state.payment.step,
    months: state.payment.months,
    members: state.payment.members,
    paid_success: state.payment.paid_success,
    paymentDetailsData: state.payment.paymentDetails,
    commonInfoCompany: state.commonInfo.company,
    users_count: state.payment.users_count,
  };
};

const mapDispatchToProps = {
  changeStep,
  changePriceParts,
  paymentDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
