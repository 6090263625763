import {
  createStyles,
  FormControl,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";
import Pagination from "rc-pagination";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { cookieMaster } from "../../../../utils/CookieMaster";

interface Props {
  paginationMeta: any;
  setPaginationMeta: (mode: {}) => void;
  setMeetingList: (mode: any) => void;
  page: number;
  setPage: (mode: number) => void
}


const WrapperPagination = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  // margin: 5px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-top: 1px solid #e7e3e3;
  box-shadow: 2px 2px 20px -9px;
`;

const PaginationBox = styled.div`
  margin: auto;
  display: flex;
  padding: 10px 10px 10px 0;
`;

const useStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      minWidth: 60,
      marginRight: 20,
    },
    selectEmpty: {},
  })
);

const PaginationMeeting: React.FC<Props> = ({
  paginationMeta,
  setPaginationMeta,
  setMeetingList,
  page,
  setPage
}) => {
  const classes = useStyles();


 
  const onPageClickNumber = (e) => {
    setPage(e);

  };

  return (
    <>
      {paginationMeta.total > 1 && (
        <WrapperPagination>
          <PaginationBox>
            <Pagination
              showTitle={false}
              onChange={(e)=>onPageClickNumber(e)}
              className="ant-pagination"
              defaultCurrent={page}
              total={paginationMeta?.last_page * 10}
              current={page}
              // prev_page={paginationMeta?.links[0]}
              // next_page={paginationMeta?.links[-1]}
            />
          </PaginationBox>
        </WrapperPagination>
      )}
    </>
  );
};

export default PaginationMeeting;
