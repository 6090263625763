export default {
  date: {
    month_full: ["Januar", "Februar", "Mars", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Desember"],
    month_short: ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Des"],
    day_full: ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"],
    day_short: ["Søn", "Mon", "Tir", "Ons", "Tor", "Fre", "Lør"]
  },
  labels: {
    dhx_cal_today_button: "I dag",
    day_tab: "Dag",
    week_tab: "Uke",
    month_tab: "Måned",
    new_event: "Ny hendelse",
    icon_save: "Lagre",
    icon_cancel: "Avbryt",
    icon_details: "Detaljer",
    icon_edit: "Rediger",
    icon_delete: "Slett",
    confirm_closing: "",
    //Your changes will be lost, are your sure ?
    confirm_deleting: "Hendelsen vil bli slettet permanent. Er du sikker?",
    section_description: "Beskrivelse",
    section_time: "Tidsperiode",
    /*recurring events*/
    confirm_recurring: "Vil du forandre hele dette settet av repeterende hendelser?",
    section_recurring: "Repeter hendelsen",
    button_recurring: "Av",
    button_recurring_open: "På",
    button_edit_series: "Rediger serien",
    button_edit_occurrence: "Redigere en kopi",
    /*agenda view extension*/
    agenda_tab: "Agenda",
    date: "Dato",
    description: "Beskrivelse",
    /*year view extension*/
    year_tab: "År",
    /*week agenda view extension*/
    week_agenda_tab: "Agenda",
    /*grid view extension*/
    grid_tab: "Grid",
    /* touch tooltip*/
    drag_to_create: "Drag to create",
    drag_to_move: "Drag to move",
    /* dhtmlx message default buttons */
    message_ok: "OK",
    message_cancel: "Cancel",
    /* wai aria labels for non-text controls */
    next: "Next",
    prev: "Previous",
    year: "Year",
    month: "Month",
    day: "Day",
    hour: "Hour",
    minute: "Minute",
    /* recurring event components */
    repeat_radio_day: "Daglig",
    repeat_radio_week: "Ukentlig",
    repeat_radio_month: "Månedlig",
    repeat_radio_year: "Årlig",
    repeat_radio_day_type: "Hver",
    repeat_text_day_count: "dag",
    repeat_radio_day_type2: "Alle hverdager",
    repeat_week: " Gjentas hver",
    repeat_text_week_count: "uke på:",
    repeat_radio_month_type: "På hver",
    repeat_radio_month_start: "På",
    repeat_text_month_day: "dag hver",
    repeat_text_month_count: "måned",
    repeat_text_month_count2_before: "hver",
    repeat_text_month_count2_after: "måned",
    repeat_year_label: "på",
    select_year_day2: "i",
    repeat_text_year_day: "dag i",
    select_year_month: "",
    repeat_radio_end: "Ingen sluttdato",
    repeat_text_occurences_count: "forekomst",
    repeat_radio_end3: "Stop den",
    repeat_radio_end2: "Etter",
    month_for_recurring: ["Januar", "Februar", "Mars", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Desember"],
    day_for_recurring: ["Sondag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"]
  }
};