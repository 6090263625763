import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setProjectObservers } from '../actions';
import { getObserversData, getObservers } from '../selectors';
import { State } from '../../../rootReducer';
import MultySelect from '../../../common/components/Selects/MultySelect';

type Props = {
  observersSelectData: any;
  setProjectObservers: (cpe: number[]) => void;
  observersIds: number[];
  disabled?: boolean;
};

const ObserversSelect: React.FC<Props> = ({
  observersSelectData,
  observersIds,
  setProjectObservers,
  disabled
}) => 
{
  return (
    <MultySelect
      disabled={disabled}
      itemsList={observersSelectData.list}
      selectedItems={observersSelectData.selected}
      isValid={observersSelectData.selected.length > 0}
      addHandler={(value: number) => {
        setProjectObservers([...observersIds, value as number]);
      }}
      removeHandler={(value: number) => {
        setProjectObservers(observersIds.filter((id) => id !== value));
      }}
    />
  );
};

const mapStateToProps = (state: State) => {
  return {
    observersIds: getObservers(state), // ид выбранных наблюдателей
    observersSelectData: getObserversData(state), // Получить юзеров по роли - кто может быть наблюдателем
  };
};

const mapDispatchToProps = {
  setProjectObservers
};

export default connect(mapStateToProps, mapDispatchToProps)(ObserversSelect);
