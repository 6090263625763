export default {
    name: {
      name: "name",
      placeholder: "Введите Ваше Название",
      label: "Название",
      error: "Invalid Name",
      errorHint: "Невалидное значение",
    },
    description: {
      name: "description",
      placeholder: "Введите Вашe Описание",
      label: "Описание",
      error: "Invalid Description ",
      errorHint: "Невалидное значение",
    },
  
    phone: {
      name: "phone",
      placeholder: "Введите Ваш Номер ",
      label: "Номер",
      error: "Invalid Tel",
      errorHint: "Номер телефона должен содержать '+'",
    },
  
    email: {
      name: "email",
      placeholder: "Введите Вашу Почту ",
      label: "email",
      error: "Invalid Email",
      errorHint: "Невалидное значение",
    },
  
    address: {
      name: "address",
      placeholder: "Введите Ваш Адрес ",
      label: "Адрес",
      error: "Invalid Address",
      errorHint: "Невалидное значение",
    },
  
    phys_address: {
      name: "phys_address",
      placeholder: "Введите Ваш Юридический адрес ",
      label: "Юридический адрес",
      error: "Invalid Adress",
      errorHint: "Невалидное значение",
    },
  
    inn: {
      name: "address",
      placeholder: "Введите Ваш ИНН ",
      label: "ИНН",
      error: "Invalid INN",
      errorHint: "Невалидное значение",
    },
  
    kpp: {
      name: "kpp",
      placeholder: "Введите Ваш КПП ",
      label: "КПП",
      error: "Invalid Adress",
      errorHint: "Невалидное значение",
    },
  
    ogrn: {
      name: "ogrn",
      placeholder: "Введите Ваш ОГРН ",
      label: "ОГРН",
      error: "Invalid OGRN",
      errorHint: "Невалидное значение",
    },
  
    paid: {
      name: "paid",
      placeholder: "Введите Ваш Paid ",
      label: "Paid",
      error: "Invalid Paid",
      errorHint: "Невалидное значение",
    },
  
    site: {
      name: "site",
      placeholder: "Вставьте ссылку на ващ Сайт",
      label: "Сайт",
      error: "Invalid OGRN",
      errorHint: "Невалидное значение",
    },
  
    okved: {
      name: "okved",
      placeholder: "Введите Ваш ОКВЭД ",
      label: "ОКВЭД",
      error: "Invalid OKVED",
      errorHint: "Невалидное значение",
    },
  
    bik: {
      name: "bik",
      placeholder: "Введите Ваш БИК ",
      label: "БИК",
      error: "Invalid BIK",
      errorHint: "Невалидное значение",
    },
    checking_account: {
      name: "checking_account",
      placeholder: "Введите Ваш Расчетный счет ",
      label: "Расчетный счет",
      error: "Invalid Checking Account",
      errorHint: "Невалидное значение",
    },
  
    bank_name: {
      name: "bank_name",
      placeholder: "Введите Вашe  Наименование банка ",
      label: "Наименование банка",
      error: "Invalid Bank Name",
      errorHint: "Невалидное значение",
    },
  
    correspondent_account: {
      name: "correspondent_account",
      placeholder: "Введите Ваш Корреспонондентский счет ",
      label: "Корреспонондентский счет",
      error: "Invalid Correspondent Account",
      errorHint: "Невалидное значение",
    },
  };
  