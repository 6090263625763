import React, { useEffect, useState } from "react";
import InputWithUnit from "../../../common/components/Inputs/InputWithUnit";
import { State } from "../../../rootReducer";
import { connect } from "react-redux";
import {
  setTaskWorkLoad,
  setTaskLoad,
  recalculateTaskLoad,
  lastChanged,
} from "../actions";
import { Item, Schedule } from "../../../common/types";
import styled, { css } from "styled-components";
import palette from "../../../common/components/palette";
// import {calculateTaskLoad} from '../../../utils/calculateTaskLoad';

type Props = {
  taskLoad: number;
  workLoad: number;
  taskBegin: string;
  taskEnd: string;
  schedule: Schedule | null;
  delegated: boolean;
  setTaskWorkLoad: (load: number) => void;
  setTaskLoad: (load: number) => void;
  recalculateTaskLoad: (val?: object) => void;
  executor_id: number | null;
  selectedItems: Item[];
};

const WrapperTabBlock = styled.div`
  max-width: 128px;
  width: 100%;
  display: flex;
  border: 1px solid ${palette.ultraBlueLighter};
  border-radius: 6px;
`;
const TabStyle = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  padding: 0.5rem;
  border: 1px solid ${palette.blueLight};

  ${({ activeTab }) => {
    if (activeTab === "hour") {
      return `background-color: ${palette.blueLight};
     
      border-radius: 6px 0 0 6px;
      `;
    } else {
      return `background-color: ${palette.white};`;
    }
    if (activeTab === "percent") {
      return `background-color: ${palette.blueLight};
        border-radius: 0 6px 6px 0;
      `;
    }
  }};
`;

const TaskWorkLoad: React.FC<Props> = ({
  executor_id,
  taskLoad,
  setTaskWorkLoad,
  setTaskLoad,
  workLoad,
  taskBegin,
  taskEnd,
  schedule,
  delegated,
  recalculateTaskLoad,
  selectedItems,
}) => {
  const [activeTab, setActiveTab] = useState("hour");
  return (
    <>
      <div className="new_task_work_load_wrapper">
        <InputWithUnit
          unit="ч"
          defaultValue={taskLoad}
          disabled={
            (executor_id
              ? executor_id
              : selectedItems.length && selectedItems?.[0].value) === null ||
            delegated
          }
          changeHandler={(value: string) => {
            recalculateTaskLoad({ task_load: parseFloat(value) }); //********************** */
            setTaskLoad(parseFloat(value)); //********************************* */
          }}
        />

        <InputWithUnit
          unit="%"
          defaultValue={workLoad}
          disabled={
            (executor_id
              ? executor_id
              : selectedItems.length && selectedItems?.[0].value) === null ||
            delegated
          }
          changeHandler={(value: string) => {
            recalculateTaskLoad({ work_load: parseFloat(value) }); //****************************** */
            setTaskWorkLoad(parseFloat(value)); //************************************ */
          }}
        />
      </div>
      <WrapperTabBlock>
        <TabStyle
          onClick={() => {
            setActiveTab("hour");
          }}
          activeTab={activeTab}
        >
          Часы
        </TabStyle>
        <TabStyle
          onClick={() => {
            setActiveTab("percent");
          }}
          activeTab={activeTab}
        >
          Проценты
        </TabStyle>
      </WrapperTabBlock>
    </>
  );
};

const mapStateToProps = (state: State) => ({
  executor_id: state.newTask.executor_id,
  taskLoad: state.newTask.task_load,
  workLoad: state.newTask.work_load,
  taskBegin: state.newTask.begin,
  taskEnd: state.newTask.end,
  schedule: state.newTask.executorSchedule,
  delegated: state.newTask.delegated,
});

const mapDispatchToProps = {
  setTaskWorkLoad,
  setTaskLoad,
  lastChanged,
  recalculateTaskLoad,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskWorkLoad);
