export default {
  date: {
    month_full: ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],
    month_short: ["Sty", "Lut", "Mar", "Kwi", "Maj", "Cze", "Lip", "Sie", "Wrz", "Paź", "Lis", "Gru"],
    day_full: ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"],
    day_short: ["Nie", "Pon", "Wto", "Śro", "Czw", "Pią", "Sob"]
  },
  labels: {
    dhx_cal_today_button: "Dziś",
    day_tab: "Dzień",
    week_tab: "Tydzień",
    month_tab: "Miesiąc",
    new_event: "Nowe zdarzenie",
    icon_save: "Zapisz",
    icon_cancel: "Anuluj",
    icon_details: "Szczegóły",
    icon_edit: "Edytuj",
    icon_delete: "Usuń",
    confirm_closing: "",
    //Zmiany zostaną usunięte, jesteś pewien?
    confirm_deleting: "Zdarzenie zostanie usunięte na zawsze, kontynuować?",
    section_description: "Opis",
    section_time: "Okres czasu",
    full_day: "Cały dzień",
    /*recurring events*/
    confirm_recurring: "Czy chcesz edytować cały zbiór powtarzających się zdarzeń?",
    section_recurring: "Powtórz zdarzenie",
    button_recurring: "Nieaktywne",
    button_recurring_open: "Aktywne",
    button_edit_series: "Edytuj serię",
    button_edit_occurrence: "Edytuj kopię",
    /*agenda view extension*/
    agenda_tab: "Agenda",
    date: "Data",
    description: "Opis",
    /*year view extension*/
    year_tab: "Rok",
    /*week agenda view extension*/
    week_agenda_tab: "Agenda",
    /*grid view extension*/
    grid_tab: "Tabela",
    /* touch tooltip*/
    drag_to_create: "Drag to create",
    drag_to_move: "Drag to move",
    /* dhtmlx message default buttons */
    message_ok: "OK",
    message_cancel: "Cancel",
    /* wai aria labels for non-text controls */
    next: "Next",
    prev: "Previous",
    year: "Year",
    month: "Month",
    day: "Day",
    hour: "Hour",
    minute: "Minute",
    /* recurring event components */
    repeat_radio_day: "Codziennie",
    repeat_radio_week: "Co tydzie",
    repeat_radio_month: "Co miesic",
    repeat_radio_year: "Co rok",
    repeat_radio_day_type: "Kadego",
    repeat_text_day_count: "dnia",
    repeat_radio_day_type2: "Kadego dnia roboczego",
    repeat_week: " Powtarzaj kadego",
    repeat_text_week_count: "tygodnia w dni:",
    repeat_radio_month_type: "Powtrz",
    repeat_radio_month_start: "W",
    repeat_text_month_day: "dnia kadego",
    repeat_text_month_count: "miesica",
    repeat_text_month_count2_before: "kadego",
    repeat_text_month_count2_after: "miesica",
    repeat_year_label: "W",
    select_year_day2: "miesica",
    repeat_text_year_day: "dnia miesica",
    select_year_month: "",
    repeat_radio_end: "Bez daty kocowej",
    repeat_text_occurences_count: "wystpieniu/ach",
    repeat_radio_end3: "Zakocz w",
    repeat_radio_end2: "Po",
    month_for_recurring: ["Stycznia", "Lutego", "Marca", "Kwietnia", "Maja", "Czerwca", "Lipca", "Sierpnia", "Wrzenia", "Padziernka", "Listopada", "Grudnia"],
    day_for_recurring: ["Niedziela", "Poniedziaek", "Wtorek", "roda", "Czwartek", "Pitek", "Sobota"]
  }
};