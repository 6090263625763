import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { StatusContext } from "../../../context/status";
import TextAreaOrInputElement from "../templates/TextAreaOrInputElement";
import CheckboxElement from "../templates/CheckboxElement";
import SelectTemplate from "../templates/SelectTemplate";
import NameOfStep from "../templates/NameOfStep";
import "../BpResultForm.scss";

const formOfAction = [
  "Удалить учетную запись",
  "Сохранить учетную запись, но обнулить данные для авторизации пользователя",
  "Без изменений",
  "Текстовое поле",
];

const EmployeeAccountActionsDismissal = () => {
  const {
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,
    company_id,
    setloadDataNow,
  } = useContext(StatusContext);

  const [data, setData] = useState(
    settingsOfFields?.employeeAccountActions?.extra
      ? settingsOfFields.employeeAccountActions.extra
      : {}
  );

  const url = taskId ? `${apiBp}/task/${taskId}` : null;
  useEffect(() => {
    console.log("use in form worked");
    if (taskId) {
      axios
        .patch(
          url,
          {
            ...settingsOfFields.employeeAccountActions,
            extra: data,
          },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))
        // .then(() => setInputValue())

        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);

  return (
    <form id="checkFinalOfJurist">
      <div>
        <NameOfStep text={"Действие"} />
        <SelectTemplate
          listArr={formOfAction}
          name={"e5_1"}
          data={data}
          setData={setData}
        />
      </div>

      {data.e5_1 === "Текстовое поле" && (
        <div>
          <NameOfStep text={"Комментарий"} />
          <TextAreaOrInputElement //если да
            name={"e5_1_1"}
            isInput={true}
            type={"text"}
            data={data}
            setData={setData}
          />
        </div>
      )}

      <div>
        <NameOfStep text={"Действие"} />
        <CheckboxElement
          name={"e5_2"}
          text={"Выполненно"}
          data={data}
          setData={setData}
        />
      </div>
    </form>
  );
};

export default EmployeeAccountActionsDismissal;
