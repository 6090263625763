import React, { useContext, useEffect } from "react";
import { CreateNotif } from "../../../../../../utils/createNotification";
import { StatusContext } from "../../../context/status";

import "../BpResultForm.scss";
const AddFileButton = ({
  id,
  handlerOnSendFile,
  handlerOnDeletedFile,
  handlerOnClickFetchFiles,
  numberFiles,
  allFiles,
  data,
  setData,
  filesList,
  otherTerm,
}) => {
  const {
    isAdmin,
    setloadDataNow,
    statusOfTask,
    taskId,
    executorIdofTask,
    userId,
  } = useContext(StatusContext);

  useEffect(() => {
    if (filesList.files?.list.length) {
      if (filesList.files?.list.length) {
        setloadDataNow((loadDataNow) => !loadDataNow);
        setData((prevState) => {
          return { ...prevState, [id]: filesList.files?.list.length };
        });
      } else {
        setData((prevState) => {
          return { ...prevState, [id]: null };
        });
      }
    }
  }, [data[id]]);
  // console.log(executorIdofTask, userId);

  return (
    <>
      <div
        className={
          statusOfTask === 0 || statusOfTask === 2 ? "" : "unavailableForm" //не дает редактировать форму в зависимости от статуса //не дает редактировать форму в зависимости от статуса
        }
      >
        <>
          <input
            type="file"
            id={id}
            onChange={(e) => {
              if (executorIdofTask === userId || isAdmin) {
                handlerOnSendFile(e, numberFiles);
              } else {
                CreateNotif("У вас нет полномочий для данного действия");
              }
            }}
          />
          <label className="p__drop-content download-file" htmlFor={id}>
            <img
              src={`${process.env.REACT_APP_PUBLIC_URL}/assets/FilePlus.svg`}
              alt="download"
            />
            Прикрепить файл
          </label>
          {allFiles[0] && (
            <h2
              className="error-message-in-forms"
              style={{ margin: "10px 15px 15px 42px", color: "#292a34" }}
            >
              Загруженные файлы:
            </h2>
          )}
          {allFiles?.map((file, index) => (
            <div key={file.id} className="file-download">
              <span
                onClick={() => handlerOnDeletedFile(file.id, numberFiles)}
                className="file-download_deletedBtn"
              >
                ×
              </span>
              <img
                style={{ height: "22px" }}
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/FilePlus.png`}
                alt="#"
              />

              <p>{file?.name}</p>
            </div>
          ))}
          {allFiles?.length ? (
            <button
              onClick={(e) => {
                handlerOnClickFetchFiles(e, numberFiles);
                setloadDataNow((loadDataNow) => !loadDataNow);
              }}
              className="file-download_btnSubmit"
            >
              Отправить
            </button>
          ) : (
            ""
          )}
        </>
      </div>
      {!filesList.files?.by_tag[numberFiles] &&
        taskId &&
        (statusOfTask === 0 || statusOfTask === 2) &&
        (executorIdofTask === userId || isAdmin) &&
        !otherTerm && (
          <h2
            className="error-message-in-forms-add-file"
            style={{ color: "red", marginLeft: "42px" }}
          >
            Прикрепите файл
          </h2>
        )}
    </>
  );
};

export default AddFileButton;
