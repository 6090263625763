import React, { useContext, useState, useEffect, useCallback } from "react";
import { StatusContext } from "../../../../context/status";
import "./multySelect.scss";

const MultySelect = ({ executors, selectUserHandler }) => {
  const { userId, idExecutor, setIdExecutor } = useContext(StatusContext);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({
    value: "",
    id: "",
  });
  const [searchData, setSearchData] = useState("");
  let executor;

  useEffect(() => {
    executor = executors.find((user) => user.id === userId);
    setSelectedItem({
      value: `${executor?.name} ${executor?.surname}`,
      id: executor.id,
    });
  }, []);

  useEffect(() => {
    setIdExecutor(selectedItem.id)
  }, [selectedItem])

  //   useEffect(() => {
  //     selectUserHandler(selectedItem.id);
  //   }, [selectedItem]);

  const outsideClickHandler = useCallback(
    (e) => {
      if (isOpen) {
        setIsOpen(false);
      }
    },
    [isOpen]
  );

  useEffect(() => {
    document.addEventListener("click", outsideClickHandler);
    return () => document.removeEventListener("click", outsideClickHandler);
  }, [outsideClickHandler]);

  // const execNameAndSurname = executors?.find(
  //   (item) => item.id === selectedExecutor
  // );

  return (
    <div style={{ position: "relative" }}>
      <input
        list={"executor_task"}
        style={{ marginLeft: "40px", width: 600 }}
        className="input-form executor_input"
        id="executor_tasks_bp"
        value={searchData ? searchData : selectedItem?.value}
        onChange={(e) => {
          setIsOpen(true);
          setSearchData(e.target.value);
        }}
        onClick={() => {
          setIsOpen(!isOpen);
          setSelectedItem({ value: "", id: "" });
          setIdExecutor(selectedItem.id);
        }}
      />
      {isOpen && (
        <div
          className="select_div"
          style={{
            position: "absolute",
            flexDirection: "column",
          }}
        >
          {executors
            .filter((item) => {
              if (searchData) {
                if (
                  item.name.toLowerCase().indexOf(searchData.toLowerCase()) >=
                    0 ||
                  item.surname
                    .toLowerCase()
                    .indexOf(searchData.toLowerCase()) >= 0
                ) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            })
            .map((item) => (
              <div
                key={item.id}
                className={"item_sel"}
                id={item.id}
                onClick={(e) => {
                  e.stopPropagation();
                  setSearchData("");
                  if (item.value !== selectedItem?.value) {
                    setSelectedItem({
                      value: `${item.name} ${item.surname}`,
                      id: item.id,
                    });
                  }
                  setIdExecutor(selectedItem.id);
                  setIsOpen(false);
                }}
              >
                {item.name} {item.surname}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default MultySelect;
