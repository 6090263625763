import React, { useContext, useState } from "react";
import { StatusContext } from "../../../context/status";
import NameOfStep from "../templates/NameOfStep";
import time from "../../../../../../img/time.svg";
import "./noSample.scss";

let fileTranslate;

const NoSample = () => {
  const { comment, setComment, timeСosts, setTimeСosts, statusOfTask } =
    useContext(StatusContext);

  // Если settingsOfFields.initial.extra отсутствует- передаем пустой объект
  const [data, setData] = useState("");

  const handleComment = (e) => {
    setComment(e.target.value);
  };

  const handleTime = (e) => {
    setTimeСosts(e.target.value);
  };

  return (
    <>
      {statusOfTask > 2 ? (
        <form className="comments_div">
          <div>
            <NameOfStep text={"Комментарий"} />
            {/* <h2 className="salary">Комментарий</h2> */}
            <input
              className="input-form"
              type="text"
              value={comment}
              onChange={(e) => handleComment(e)}
            />
          </div>
          {/* <div>
            <div className="step">
              <img src={time} alt="" />
              <span className="span">Затраченное время</span>
            </div>

            <div
              className="textArea"
              style={{ position: "absolute", width: "150px" }}
            >
              <input
                className="input-form"
                type="number"
                value={timeСosts}
                onChange={(e) => handleTime(e)}
                min="0"
              />
            </div>
            <span className="text">час</span>
          </div> */}
        </form>
      ) : (
        <form>
          <div className="">
            <NameOfStep text={"Комментарий"} />
            {/* <h2 className="salary">Комментарий</h2> */}
            <input
              className="input-form"
              type="text"
              value={comment}
              onChange={(e) => handleComment(e)}
            />
          </div>
          {/* <div>
            <div className="step">
              <img src={time} alt="" />
              <span className="span">Затраченное время</span>
            </div>

            <div
              className="textArea"
              style={{ position: "absolute", width: "150px" }}
            >
              <input
                className="input-form"
                type="number"
                value={timeСosts}
                onChange={(e) => handleTime(e)}
                min="0"
              />
            </div>
            <span className="text">час</span>
          </div> */}
        </form>
      )}
    </>
  );
};

export default NoSample;
