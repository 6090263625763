import React from 'react';
import styled from 'styled-components';

type Props = {
  email: string;
};

const EmailBlickStyle = styled.div`
  max-width: 200px;
  min-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UserEmailCell: React.FC<Props> = ({ email }) => {
  return <EmailBlickStyle>{email}</EmailBlickStyle>;
};

export default UserEmailCell;
