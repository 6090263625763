import React from "react";
import styled, { css } from "styled-components";
import Icon from "../../Icon";
import palette from "../../palette";

type Props = {
  hint?: string;
  title?: string;
  design?: string;
  onClick?: (e: any) => void;
  icon?: string;
  style?: Object;
  disabled?: boolean;
  isSending?: boolean;
  big?: boolean;
  iconStyle?: object;
  children?: any;
};

const primary = css`
  background: ${palette.btnPrimary};
  color: ${palette.white};
  font-weight: 500;
  &:hover {
    background: ${palette.btnPrimaryHover};
  }
  &:disabled {
    background: ${palette.ultraLightGray};
    color: ${palette.gray};
  }
`;

const secondary = css`
  background: ${palette.btnSecondary};
  border-color: ${palette.lightGray};
  color: ${palette.textBlack};
  &:hover {
    border-color: ${palette.btnSecondaryHover};
  }
  &:disabled {
    background: ${palette.lightGray};
    color: ${palette.white};
  }
`;

const outline = css`
  background: ${palette.white};
  color: ${palette.darkBlue};
  font-weight: 40;
  border-color: ${palette.lightGray};
  &:hover {
    border-color: ${palette.darkBlue};
  }
  &:disabled {
    border-color: transparent;
    color: ${palette.gray};
    background: #f2f2f2;
  }
`;
const blue_lightest = css`
  background: ${palette.blueLight};
  color: ${palette.darkBlue};
  font-weight: 400;
  border-color: ${palette.darkBlue};
  &:hover {
    border-color: ${palette.darkBlue};
  }
  &:disabled {
    border-color: transparent;
    color: ${palette.gray};
    background: #f2f2f2;
  }
`;

const green_main_filled = css`
  background: ${palette.greenMain};
  color: ${palette.white};
  font-weight: 400;
  // border-color: ${palette.darkBlue};
  &:hover {
    border-color: ${palette.white};
  }
  &:disabled {
    border-color: transparent;
    color: ${palette.gray};
    background: #f2f2f2;
  }
`;

const transparent = css`
  background: transparent;
  color: ${palette.darkBlue};
  font-weight: 40;
  border-color: transparent;
  &:hover {
    color: ${palette.blue};
  }
  &:disabled {
    color: ${palette.lightGray};
  }
`;

const sendingStyle = css`
  color: ${palette.blue};
  position: relative;
  pointer-events: none;

  &::before {
    z-index: 2;
    content: "";
    margin: auto;
    border-radius: 50%;
    border: 3px solid #fff;
    border-top-color: ${palette.blue};
    border-left-color: ${palette.blue};
    opacity: 0.6;
    animation: spin 0.6s linear infinite;
    pointer-events: none;
    width: 14px;
    height: 14px;
    position: absolute;
  }
`;

const ButtonStyle = styled.button`
  display: flex;
  justify-content: space-around;
  padding: ${({ big }) => (big ? "11px" : "0.5rem 1rem")};
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid transparent;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: center;

  ${({ design }) => {
    switch (design) {
      case "secondary":
        return secondary;
      case "outline":
        return outline;
      case "blue_lightest":
        return blue_lightest;
      case "green_main_filled":
        return green_main_filled;
      case "transparent":
        return transparent;
      default:
        return primary;
    }
  }}
  ${({ isSending }) => isSending && sendingStyle}
  ${({ styles }) => styles}
`;

const Button: React.FC<Props> = ({
  hint,
  title,
  design = "primary",
  icon,
  onClick,
  disabled = false,
  isSending = false,
  big = false,
  style,
  iconStyle,
  children,
}) => {
  return (
    <ButtonStyle
      title={hint}
      hint={hint}
      onClick={onClick}
      design={design}
      style={style}
      disabled={disabled}
      isSending={isSending}
      big={big}
    >
      {icon && <Icon name={icon} style={iconStyle} />}

      {title}
      {children}
    </ButtonStyle>
  );
};

export default Button;
