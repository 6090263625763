import { call, put, takeLatest } from "redux-saga/effects";
import { fetchData } from "../../utils/fetchData";
import {
  setBillInfo,
  setBillUrl,
  setLoading,
  setModulesPrice,
  setPaid,
  setPaymentRequestId,
  setSubscribeEnd,
  setSubscribeStart,
  setSubscribeStatus,
  setTotalPrice,
  setUsersPaid,
} from "./actions";
import {
  types,
  billPDFCreateAction,
  amountAction,
  actualRequestAction,
  createBillAction,
  setSelectedModulesAction,
  fetchModulesPriceAction,
  fetchOnlineBillStatusAction,
  fetchOfflineBillStatusAction,
} from "./action_types";

// function* billPDFCreate({ payment_request_id }: billPDFCreateAction) {
//   try {
//     yield put(setLoading(true));
//     let res = yield call(
//       fetchData.post,
//       `/api/v1/payment/bill-pdf-create/${payment_request_id}`,
//       {}
//     );

//     yield put(setLoading(false));
//   } catch (error) {
//     console.warn(error);
//   }
// }

function* amount({
  company_id,
  months_count,
  users_count,
  modules,
}: // payment_type,
amountAction) {
  try {
    yield put(setLoading(true));

    let res = yield call(
      fetchData.post,
      "/api/v1/payment/amount",
      JSON.stringify({
        company_id,
        months_count,
        users_count,
        modules,
      })
    );
    if (res.amount >= 0) {
      let stringAmount = (+res.amount).toLocaleString("ru-RU", {
        style: "currency",
        currency: "RUB",
      });
      yield put(setTotalPrice(stringAmount));
    }

    yield put(setLoading(false));
  } catch (error) {
    console.warn(error);
  }
}
function* createBill({
  company_id,
  months_count,
  users_count,
  modules,
  payment_type,
}: createBillAction) {
  try {
    yield put(setLoading(true));
    let res = yield call(
      fetchData.post,
      "/api/v1/payment/bill",
      JSON.stringify({
        company_id,
        months_count,
        users_count,
        modules,
        payment_type,
      })
    );

    if (res) {
      if (payment_type === "online") {
        yield put(setBillUrl(res.url));
        if (!res.isPaid) window.location.href = res.url;
      }
      if (payment_type === "offline") {
        yield put(setPaymentRequestId(res.id));
      }
      yield put(setPaid(res.isPaid));
    }
    yield put(setLoading(false));
  } catch (error) {
    console.warn(error);
  }
}

function* actualRequest({ company_id }: actualRequestAction) {
  try {
    yield put(setLoading(true));
    let res = yield call(
      fetchData.post,
      "/api/v1/payment/actual-request",
      JSON.stringify({
        company_id,
      })
    );
    if (res) {
      // let stringAmount = (+res.amount).toLocaleString("ru-RU", {
      //   style: "currency",
      //   currency: "RUB",
      // });

      yield put(setUsersPaid(res.users_count));
      let start = new Date(res.start).toLocaleDateString();
      let end = new Date(res.end).toLocaleDateString();
      yield put(setBillInfo(res));
      yield put(setSubscribeStatus(true));
      // yield put(setTotalPrice(stringAmount));
      yield put(setSubscribeStart(start));
      yield put(setSubscribeEnd(end));
      yield put(setPaymentRequestId(res.id));

      res.modules.forEach((item) => {
        setSelectedModules(item);
      });
    } else {
      yield put(setSubscribeStatus(false));
    }
    yield put(setLoading(false));
  } catch (error) {
    console.warn(error);
  }
}
function* fetchOnlineBillStatus({ company_id }: fetchOnlineBillStatusAction) {
  try {
    yield put(setLoading(true));
    let res = yield call(
      fetchData.post,
      "/api/v1/payment/online-bill-status-check",
      JSON.stringify({
        company_id,
      })
    );

    if (res) {
      yield put(setBillUrl(res.url));
    } else {
      yield put(setBillUrl(""));
    }
    yield put(setLoading(false));
  } catch (error) {
    console.warn(error);
  }
}

function* fetchOfflineBillStatus({ company_id }: fetchOfflineBillStatusAction) {
  try {
    yield put(setLoading(true));
    let res = yield call(
      fetchData.post,
      "/api/v1/payment/offline-bill-status-check",
      JSON.stringify({
        company_id,
      })
    );

    if (res) {
      yield put(setPaymentRequestId(res.id));
    } else {
      yield put(setPaymentRequestId(0));
    }
    yield put(setLoading(false));
  } catch (error) {
    console.warn(error);
  }
}

function* fetchModulesPrice({}: fetchModulesPriceAction) {
  try {
    yield put(setLoading(true));
    let res = yield call(fetchData.get, "/api/v1/payment/modules-price");
    if (res) yield put(setModulesPrice(res.module_prices));

    yield put(setLoading(false));
  } catch (error) {
    console.warn(error);
  }
}
function* setSelectedModules(module: setSelectedModulesAction) {
  setSelectedModules(module);
}

// export function* watchBillPDFCreate() {
//   yield takeLatest(types.BILL_PDF_CREATE, billPDFCreate);
// }
export function* watchAmount() {
  yield takeLatest(types.AMOUNT, amount);
}

export function* watchCreateBill() {
  yield takeLatest(types.CREATE_BILL, createBill);
}
export function* watchActualRequest() {
  yield takeLatest(types.ACTUAL_REQUEST, actualRequest);
}
export function* watchFetchModulesPrice() {
  yield takeLatest(types.FETCH_MODULES_PRICE, fetchModulesPrice);
}
export function* watchSetSelectedModules() {
  yield takeLatest(types.SET_SELECTED_MODULES, setSelectedModules);
}
export function* watchFetchOnlineBillStatus() {
  yield takeLatest(types.FETCH_ONLINE_BILL_STATUS, fetchOnlineBillStatus);
}
export function* watchFetchOfflineBillStatus() {
  yield takeLatest(types.FETCH_OFFLINE_BILL_STATUS, fetchOfflineBillStatus);
}
