import React, { useEffect, useState } from "react"; //***************************** */
import withTitle from "../../../utils/hoc/withTitle";
import { connect, useSelector } from "react-redux";
import SelectWithSecondaryBtn from "../../../common/components/Selects/SelectWithSecondaryBtn";
import { setUserDepartment, setUserInActiveInfo } from "../actions";
import { State } from "../../../rootReducer";
import { User } from "../../../common/types";
import NameWithInput from "../../../common/components/newComponent/Inputs/NameWithInput";
import axios from "axios";
import { cookieMaster } from "../../../utils/CookieMaster";
import { fetchData } from "../../../utils/fetchData";

type Props = {
  userId: number;
  currentUserInfo: User | null;
  rolesUser: number[];
  title?: string;
  setUserInActiveInfo: (userInfo: any)=> void;
  setChangeDataUser: any;
  isAddUserInCompany: boolean;
  isActivee: boolean
};

//активность можно менять для новых пользователей и если ты админ или директор

const ActivitySelect: React.FC<Props> = ({
  userId,
  currentUserInfo,
  rolesUser,
  title,
  setUserInActiveInfo,
  setChangeDataUser,
  isAddUserInCompany,
  isActivee
}) => {
  const activeList = [
    { value: "active", text: "Активен" },
    { value: "inactive", text: "Неактивен" },
  ];
  const [isActive, setIsActive] = useState<any>(activeList[0]);
  
  useEffect(()=>{
    setIsActive(isActivee?activeList[0]:activeList[1])
  },[isActivee])
  const handleSelect = (value) => {
  
      fetchData
        .get(`/api/v1/users/${userId}/deactivation/violations`)
      .then((res) => setUserInActiveInfo(res));
  };

  return (
    <NameWithInput title={title} iconName="bag">
      <SelectWithSecondaryBtn
        disabled={
          !(
            userId === 0 ||
            (userId === currentUserInfo?.id &&
              currentUserInfo!.roles.sort()[0] < rolesUser.sort()[0]) ||
            currentUserInfo?.roles.includes(1) ||
            currentUserInfo!.roles.sort()[0] < rolesUser.sort()[0]
          )
        }
        list={activeList}
        selected={isActive}
        selectHandler={(value) => {
          // setIsActive(activeList.find((item) => item.value == value));
          handleSelect(value);
        }}
      />
    </NameWithInput>
  );
};

const mapStateToProps = (state: State) => {
  return {
    userId: state.newUser.id,
    rolesUser: state.newUser.roles,
    currentUserInfo: state.commonInfo.currentUserInfo,
    isActivee: state.newUser.is_active,
  };
};
const mapDispatchToProps = {
  setUserDepartment: setUserDepartment,
  setUserInActiveInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivitySelect);
