import React, { useEffect, useState } from "react"; //***************************** */
import { connect, useSelector } from "react-redux";
import { setUserDepartment, setUserInActiveInfo } from "../actions";
import { State } from "../../../rootReducer";
import { User } from "../../../common/types";
import axios from "axios";
import { cookieMaster } from "../../../utils/CookieMaster";
import { fetchData } from "../../../utils/fetchData";
import styled from "styled-components";
import trash from "../../../img//trash-false.svg";

type Props = {
  userId: number;
  setUserDelete: (mode: any) => void;
};

const DeleteDiv = styled.button`
  background: inherit;
  color: grey;
  margin: 10px 0 10px 145px;
  position: relative;
  &:hover {
    text-decoration: underline;
  }
`;

const Preloader = styled.div`
      &:after {
        content: '';
        border-radius: 50%;
        border: 3px solid #1BAAF0;
        z-index: 3;
        border-bottom-color: transparent;
        border-left-color: transparent;
        animation: spin .5s linear infinite;
        transform: translateX(-50%);
        position:absolute;
        top:0;
        right:-34px;
        height:13px;
        width:13px;
  `;

const DeleteUser: React.FC<Props> = ({ userId, setUserDelete }) => {
  const company_id: any = Number(localStorage.getItem("company_id"));

  const [load, setLoad] = useState(false);

  const handleDelete = () => {
    fetchData
      .get(
        `/api/v1/companies/${company_id}/users/${userId}/check-delete`
      )
      .then((res) => {
        setUserDelete(res);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
        console.error(err);
      });
  };

  return (
    <DeleteDiv
      onClick={() => {
        handleDelete();
        setLoad(true);
      }}
    >
      <img src={trash} /> Удалить сотрудника
      {load ? <Preloader /> : null}
    </DeleteDiv>
  );
};

const mapStateToProps = (state: State) => {
  return {
    userId: state.newUser.id,
    rolesUser: state.newUser.roles,
    currentUserInfo: state.commonInfo.currentUserInfo,
    isActivee: state.newUser.is_active,
  };
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUser);
