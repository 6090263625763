import React from "react";

const BsFilter51Vector_two_active = ({ props }) => {
  return (
    <svg
      {...props}
      width="13"
      height="10"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.375 1L3.12495 8.875L1 7"
        stroke="#43A047"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default BsFilter51Vector_two_active;
