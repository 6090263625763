import React from "react";

const Vector3 = () => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1213 0H2.52129C1.58629 0 0.821289 0.765 0.821289 1.7V17L4.22129 13.6H16.1213C17.0563 13.6 17.8213 12.835 17.8213 11.9V1.7C17.8213 0.765 17.0563 0 16.1213 0ZM16.1213 11.9H4.22129L2.52129 13.6V1.7H16.1213V11.9Z"
        fill="#94959A"
      />
      <rect x="4.82129" y="4" width="9" height="2" fill="#94959A" />
      <rect x="4.82129" y="8" width="9" height="2" fill="#94959A" />
    </svg>
  );
};

export default Vector3;
