import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { nanoid } from "nanoid";
import { StatusContext } from "../../../context/status";
import TextAreaOrInputElement from "../templates/TextAreaOrInputElement";
import AddFileButton from "../templates/AddFileButton";
import NameOfStep from "../templates/NameOfStep";
import "../BpResultForm.scss";
import CheckboxElement from "../templates/CheckboxElement";

let fileTranslate;

const InitialProcessingOfDocumentsDismissal = () => {
  const {
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,
    taskCurrentGlobal,
    setchangeTask,
    company_id,
    setloadDataNow,
  } = useContext(StatusContext);
  const [nextLink, setNextLink] = useState(
    `${process.env.REACT_APP_PUBLIC_API}/users`
  );

  const [data, setData] = useState(
    settingsOfFields?.initialProcessingOfDocuments?.extra
      ? settingsOfFields.initialProcessingOfDocuments.extra
      : {}
  );

  const dataOfInitDismissalExtra = settingsOfFields?.initDismissal?.extra;

  const url = taskId ? `${apiBp}/task/${taskId}` : null;
  const [allFilesAccountant1, setAllFilesAccountant1] = useState([]);
  const [allFilesAccountant2, setAllFilesAccountant2] = useState([]);
  const [allFilesAccountant3, setAllFilesAccountant3] = useState([]);

  function translateFile(file) {
    let reader = new FileReader();

    reader.onload = function () {
      let result = [...new Uint8Array(reader.result)]
        .map((item) => item.toString(16).padStart(2, "0"))
        .join("");
      fileTranslate = result;
      return result;
    };
    reader.readAsArrayBuffer(file);
  }

  // console.log("taskCurrentGlobal", taskCurrentGlobal);

  //Добавляет файлы
  const onSendFileTreaty = (e, numberFilesTreaty) => {
    let file = e.target.files[0];
    translateFile(file);
    setTimeout(() => {
      if (numberFilesTreaty === "filesAccountant1") {
        if (file && file.name) {
          setAllFilesAccountant1([
            ...allFilesAccountant1,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesAccountant1",
            },
          ]);
        }
      } else if (numberFilesTreaty === "filesAccountant2") {
        if (file && file.name) {
          setAllFilesAccountant2([
            ...allFilesAccountant2,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesAccountant2",
            },
          ]);
        }
      } else if (numberFilesTreaty === "filesAccountant3") {
        if (file && file.name) {
          setAllFilesAccountant3([
            ...allFilesAccountant3,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesAccountant3",
            },
          ]);
        }
      }
    }, [500]);
  };

  //Удаляет файлы
  const onDeletedFileAccountant = (keyId, numberFilesAccountant) => {
    if (numberFilesAccountant === "filesAccountant1") {
      setAllFilesAccountant1(
        allFilesAccountant1.filter((file) => file.id !== keyId)
      );
    } else if (numberFilesAccountant === "filesAccountant2") {
      setAllFilesAccountant2(
        allFilesAccountant2.filter((file) => file.id !== keyId)
      );
    } else if (numberFilesAccountant === "filesAccountant3") {
      setAllFilesAccountant3(
        allFilesAccountant3.filter((file) => file.id !== keyId)
      );
    }
  };

  //Смотрит на какую кнопку какого рездела с файлами было нажато
  const onClickFetchFilesAccountant = (e, numberFilesAccountant) => {
    e.preventDefault();

    let data;

    if (numberFilesAccountant === "filesAccountant1") {
      data = JSON.stringify({
        files: allFilesAccountant1,
      });
      console.log("Нажал на кнопку для отправки файлов filesAccountant1");
    }

    if (numberFilesAccountant === "filesAccountant2") {
      data = JSON.stringify({
        files: allFilesAccountant2,
      });
    }

    if (numberFilesAccountant === "filesAccountant3") {
      data = JSON.stringify({
        files: allFilesAccountant3,
      });
      console.log("Нажал на кнопку для отправки файлов filesAccountant3");
    }

    let config = {
      method: "patch",
      url: `${apiBp}/task/${taskId}`,
      headers: {
        Authorization: bearer,
        "company-id": company_id,
        "secret-token": document.cookie.replace(
          /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        ),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setchangeTask((prev) => prev + 1);
        console.log("Запрос отправлен");
      })
      .catch((e) => {
        console.log(e);
      });

    if (numberFilesAccountant === "filesAccountant1") {
      setAllFilesAccountant1([]);
    }

    if (numberFilesAccountant === "filesAccountant2") {
      setAllFilesAccountant2([]);
    }

    if (numberFilesAccountant === "filesAccountant3") {
      setAllFilesAccountant3([]);
    }
  };

  useEffect(() => {
    console.log("use in form worked");
    if (taskId) {
      axios
        .patch(
          url,
          {
            ...settingsOfFields.initialProcessingOfDocuments,
            extra: data,
          },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))
        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);

  return (
    <form id="checkOfChiefAccountant">
      <div>
        <NameOfStep //сделать  Автозаполненное текстовое
          text={"Основание для увольнения"}
        />
        <TextAreaOrInputElement
          name={"b2"}
          isInput={true}
          type={"text"}
          data={data}
          setData={setData}
          value={
            dataOfInitDismissalExtra &&
            dataOfInitDismissalExtra.a1_2 === "ТД" &&
            dataOfInitDismissalExtra.a1_3
              ? settingsOfFields.initDismissal.extra.a1_3
              : null
          }
        />
      </div>

      {dataOfInitDismissalExtra &&
        dataOfInitDismissalExtra.a1_2 === "ТД" &&
        dataOfInitDismissalExtra.a1_3 === "заявление на увольнение" && ( //проверить
          <div>
            <NameOfStep text={"Подтверждение ознакомления"} />
            <CheckboxElement
              name={"b2_1"}
              text={"Ознакомлен"}
              data={data}
              setData={setData}
            />
          </div>
        )}

      {dataOfInitDismissalExtra &&
        dataOfInitDismissalExtra.a1_2 === "ТД" &&
        dataOfInitDismissalExtra.a1_3 !== "заявление на увольнение" && ( //проверить
          <div>
            <NameOfStep text={"Подписание"} />
            <>
              <div className="file-uploaded__block">
                <ul>
                  {taskCurrentGlobal &&
                    taskCurrentGlobal["files"] &&
                    taskCurrentGlobal["files"]["list"]
                      .filter((item) => item.tag == "filesAccountant1")
                      .map((item2) => (
                        <li>
                          <a
                            href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                            target="_blank"
                          >
                            {item2.name}
                          </a>
                        </li>
                      ))}
                </ul>
              </div>
              <AddFileButton
                filesList={taskCurrentGlobal}
                data={data}
                setData={setData}
                id={"b2_2"}
                handlerOnSendFile={onSendFileTreaty}
                handlerOnDeletedFile={onDeletedFileAccountant}
                handlerOnClickFetchFiles={onClickFetchFilesAccountant}
                allFiles={allFilesAccountant1}
                numberFiles={"filesAccountant1"}
              />
            </>
          </div>
        )}

      {dataOfInitDismissalExtra && dataOfInitDismissalExtra.a1_2 === "ГПХ" && (
        <div>
          <NameOfStep text={"Прикрепить документы"} />
          <>
            <div className="file-uploaded__block">
              <ul>
                {taskCurrentGlobal &&
                  taskCurrentGlobal["files"] &&
                  taskCurrentGlobal["files"]["list"]
                    .filter((item) => item.tag == "filesAccountant2")
                    .map((item2) => (
                      <li>
                        <a
                          href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                          target="_blank"
                        >
                          {item2.name}
                        </a>
                      </li>
                    ))}
              </ul>
            </div>
            <AddFileButton
              filesList={taskCurrentGlobal}
              data={data}
              setData={setData}
              id={"b2_3"}
              handlerOnSendFile={onSendFileTreaty}
              handlerOnDeletedFile={onDeletedFileAccountant}
              handlerOnClickFetchFiles={onClickFetchFilesAccountant}
              allFiles={allFilesAccountant2}
              numberFiles={"filesAccountant2"}
            />
            {/* Сделать чтобы только в формате pdf 
          <span className="file-uploaded_error">только в формате pdf</span>*/}
          </>
        </div>
      )}
      {dataOfInitDismissalExtra &&
        (dataOfInitDismissalExtra.a1_2 === "СМ3" ||
          dataOfInitDismissalExtra.a1_2 === "ИП") && (
          <div>
            <NameOfStep text={"Прикрепить проект соглашения о расторжении"} />
            <>
              <div className="file-uploaded__block">
                <ul>
                  {taskCurrentGlobal &&
                    taskCurrentGlobal["files"] &&
                    taskCurrentGlobal["files"]["list"]
                      .filter((item) => item.tag == "filesAccountant3")
                      .map((item2) => (
                        <li>
                          <a
                            href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                            target="_blank"
                          >
                            {item2.name}
                          </a>
                        </li>
                      ))}
                </ul>
              </div>

              <AddFileButton
                filesList={taskCurrentGlobal}
                data={data}
                setData={setData}
                id={"b2_4"}
                handlerOnSendFile={onSendFileTreaty}
                handlerOnDeletedFile={onDeletedFileAccountant}
                handlerOnClickFetchFiles={onClickFetchFilesAccountant}
                allFiles={allFilesAccountant3}
                numberFiles={"filesAccountant3"}
              />
              {/* Сделать чтобы только в формате pdf 
          <span className="file-uploaded_error">только в формате pdf</span>*/}
            </>
          </div>
        )}
      {dataOfInitDismissalExtra &&
        dataOfInitDismissalExtra.a1_2 === "вне штата" && (
          <div>
            <NameOfStep text={"Комментарий"} />
            <TextAreaOrInputElement
              name={"b2_5"}
              isInput={false}
              type={"text"}
              data={data}
              setData={setData}
            />
          </div>
        )}
      <div>
        <NameOfStep text={"Установка таймера"} />
        {/* Компонент с временем 2.6 */}
        {/* Компонент с временем 2.6 */}
        {/* name={"b2_6"} */}
      </div>
    </form>
  );
};

export default InitialProcessingOfDocumentsDismissal;
