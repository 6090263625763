import React, { useState } from "react";
import MiniCalendar from "../MiniCalendar/MiniCalendar";
import MenuFooter from "./MenuFooter";
import MenuHead from "./MenuHead";
import "./style.sass";
interface Props {
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
  setIsTaskView: (mode: boolean) => void;
  currentView: "day" | "week";
  setCurrentView: (mode: "day" | "week") => void;
  setIsShowAddObject: (mode: boolean) => void;
  events: any;
  setFilter: (mode: any) => void;
  filter: any
}
const Menu: React.FC<Props> = ({
  selectedDate,
  setSelectedDate,
  setIsTaskView,
  currentView,
  setCurrentView,
  setIsShowAddObject,
  events,
  setFilter,
  filter
}) => {
  const [switchContent, setSwitchContent] = useState(true);
  return (
    <div className="menu__wrapper">
      <div
        className="menu"
        style={{ width: `${!switchContent ? "200px" : "345px"}` }}
      >
        <MiniCalendar
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          currentView={currentView}
          setCurrentView={setCurrentView}
          switchContent={switchContent}
          setSwitchContent={setSwitchContent}
          events={events}
          filter={filter}
        />
        <MenuFooter setFilter={setFilter} />
      </div>
      <div
        className="content-switcher"
        onClick={() => setSwitchContent(!switchContent)}
      >
        <span
          className={`${
            switchContent ? "switcher-arrow-close" : "switcher-arrow-open"
          }`}
        >
          ▲
        </span>
      </div>
    </div>
  );
};

export default Menu;
