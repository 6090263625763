import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Icon from '../../../common/components/Icon';
import Priority from '../../../common/components/Priority/Priority';
import { State } from '../../../rootReducer';
import DateMaster from '../../../utils/DateMaster';
import { selectTask } from '../actions';

const WrapperDependence = styled.div`
  position: relative;
  height: 56px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  border: 1px solid
    ${({ status_id }) => {
      switch (status_id) {
        case 10:
          return '#1BAAF0';
        case 12:
          return '#27CBA6';
        case 2:
        case 3:
          return '#B201F1';
        case 14:
          return '#FF6666';
        default:
          return '#e0e0e0';
      }
    }};
`;
const TopBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  img {
    padding-right: 4px;
  }
  div {
    display: flex;
    padding-right: 4px;
    img {
      padding-right: 4px;
    }
  }
  span {
    padding-right: 48px;
  }
`;
const DedlineStyle = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #a8a8a8;
`;
const DependenceStyle = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #02405e;
`;
const InformationBlock = styled.div`
  max-width: 350px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const BottomBlock = styled.div`
  display: flex;
  justify-content: space-between;
  max-height: 16px;
  img {
    width: 16px;
  }
  span {
    font-size: 14px;
    line-height: 16px;
    color: #212121;
    font-weight: 500;
    margin-left: 4px;
  }
`;
const PriorityStyle = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
type Props = {
  id?: number;
  name?: string;
  dependence?: string;
  load?: any;
  onDelete?: any;
  priority_id?: any;
  dedline?: any;
  icon?: string;
  status_id?: number;
  task?: any;
};

const dependenciesName = {
  parent: 'Родительская',
  child: 'Дочерняя',
  prev: 'Предыдущая',
  next: 'Следующая',
};
const Dependence: React.FC<Props> = ({
  dependence = '',
  onDelete,
  icon,
  task,
}) => {
  let begin = new DateMaster(task?.begin).deadlineMini;
  let end = new DateMaster(task?.end).deadlineMini;
  const dispatch = useDispatch();
  const { dependencesTask, isDependencies, selectedTask } = useSelector(
    (state: State) => state.taskInfoPlate
  );
  const dateMaster = useMemo(() => {
    return new DateMaster(task.end);
  }, [task.id]);
  const openDependenceTask = () => {
    if (!isDependencies && selectedTask) {
      if (dependence === 'child') {
        let taskItem = dependencesTask[dependence].find(
          (item) => item.id === task.id
        );
        dispatch(selectTask(taskItem));
      } else {
        dispatch(selectTask(dependencesTask[dependence]));
      }
    }
  };
  return (
    <WrapperDependence
      status_id={
        task.status_id === 10 &&
        new Date(
          dateMaster.year,
          dateMaster.month,
          dateMaster.day,
          dateMaster.hours,
          dateMaster.minutes
        ).getTime() <= new Date(Date.now()).getTime()
          ? 14
          : task.status_id
      }
      onClick={openDependenceTask}
    >
      <TopBlock>
        <div>
          <Icon name="calendar-gray" />
          <DedlineStyle>{`${begin} - ${end}`}</DedlineStyle>
          <Icon name={icon} />
          <DependenceStyle>{dependenciesName[dependence]}</DependenceStyle>
        </div>
        <span>{task.task_load} ч</span>
      </TopBlock>
      <BottomBlock>
        <InformationBlock>
          {task.id}
          <span>{task.name}</span>
        </InformationBlock>
        <Icon
          name="cross"
          onClick={(e) => {
            e.stopPropagation();
            onDelete(task.id);
          }}
        />
      </BottomBlock>
      <PriorityStyle>
        <Priority
          style={{ background: 'transparent' }}
          id={task.priority_id}
          noname
        />
      </PriorityStyle>
    </WrapperDependence>
  );
};
export default Dependence;
