function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
var uidSeed = Date.now();
function uid() {
  return uidSeed++;
}

// iframe-safe array type check instead of using instanceof
function isArray(obj) {
  if (Array.isArray) {
    return Array.isArray(obj);
  } else {
    // close enough
    return obj && obj.length !== undefined && obj.pop && obj.push;
  }
}

// non-primitive string object, e.g. new String("abc")
function isStringObject(obj) {
  return obj && _typeof(obj) === "object" && Function.prototype.toString.call(obj.constructor) === "function String() { [native code] }";
}

// non-primitive number object, e.g. new Number(5)
function isNumberObject(obj) {
  return obj && _typeof(obj) === "object" && Function.prototype.toString.call(obj.constructor) === "function Number() { [native code] }";
}

// non-primitive number object, e.g. new Boolean(true)
function isBooleanObject(obj) {
  return obj && _typeof(obj) === "object" && Function.prototype.toString.call(obj.constructor) === "function Boolean() { [native code] }";
}
function isDate(obj) {
  if (obj && _typeof(obj) === "object") {
    return !!(obj.getFullYear && obj.getMonth && obj.getDate);
  } else {
    return false;
  }
}
function defined(obj) {
  return typeof obj != "undefined";
}
export default {
  uid: uid,
  mixin: function mixin(target, source, force) {
    for (var f in source) {
      if (target[f] === undefined || force) target[f] = source[f];
    }
    return target;
  },
  copy: function copy(object) {
    var i, result; // iterator, types array, result

    if (object && _typeof(object) == "object") {
      switch (true) {
        case isDate(object):
          result = new Date(object);
          break;
        case isArray(object):
          result = new Array(object.length);
          for (i = 0; i < object.length; i++) {
            result[i] = copy(object[i]);
          }
          break;
        case isStringObject(object):
          result = new String(object); // jshint ignore:line
          break;
        case isNumberObject(object):
          result = new Number(object); // jshint ignore:line
          break;
        case isBooleanObject(object):
          result = new Boolean(object); // jshint ignore:line
          break;
        default:
          result = {};
          for (i in object) {
            var varType = _typeof(object[i]);
            if (varType === "string" || varType === "number" || varType === "boolean") {
              result[i] = object[i];
            } else if (isDate(object[i])) {
              result[i] = new Date(object[i]);
            } else if (Object.prototype.hasOwnProperty.apply(object, [i])) result[i] = copy(object[i]);
          }
          break;
      }
    }
    return result || object;
  },
  defined: defined,
  isDate: isDate
};