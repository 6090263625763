import React from 'react';

const EmailIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.875 5.46875L12.5 14.0625L3.125 5.46875" stroke="#292A34" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.125 5.46875H21.875V18.75C21.875 18.9572 21.7927 19.1559 21.6462 19.3024C21.4997 19.4489 21.301 19.5312 21.0938 19.5312H3.90625C3.69905 19.5312 3.50034 19.4489 3.35382 19.3024C3.20731 19.1559 3.125 18.9572 3.125 18.75V5.46875Z" stroke="#292A34" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.7958 12.5L3.36621 19.3105" stroke="#292A34" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21.6338 19.3105L14.2041 12.5" stroke="#292A34" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        
    );
};

export default EmailIcon;