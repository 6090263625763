import React, { useEffect, useState } from "react";
// import Input from '../../../common/components/Inputs/Input';
// import SectionElem from "./SectionElem";
import { connect, useDispatch, useSelector } from "react-redux";
import {  addPositionInCompany, setPositions, setUserPosition } from "../actions";
import { State } from "../../../rootReducer";
import {  getPositionsUSer, getUserPositions } from "../selectors";
import styled from "styled-components";
import { Position, PositionInCompany, SectionInComponent } from "../../../common/types";

import Button from "../../../common/components/newComponent/Buttons/Button";
import CustomInput from "../../../common/components/newComponent/Inputs/CustomInput";
import {
  fetchProjectSectionsTaskPlate,
  setProjectSectionsTaskPlate,
} from "../../TaskInfoPlate/actions";
import {
  fetchProjectSectionsTask,
  setProjectSectionsTask,
} from "../../NewTask/actions";
import {
  setTaskSection
} from "../../../pages/TaskInfoPlate/actions"

type Props = {
  setUserPosition:(position: number[]) => void;
  positions: Position[];
  setPositions: (position: Position[]) => void;
  addPositionInCompany: (companyId: number, name: string) => void;
  closeHandler:() => void;
  saveHandler:() => void;
};





const WrapperBlockInput = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WrapperBlockSections = styled.div`
  max-width: 500px;
  width: 100%;

  li {
    display: flex;
    justify-content: space-between;
    min-height: 24px;
    line-height: 24px;
    margin: 0.5rem 0;
  }
`;
const Preloader = styled.div`
  &:before {
        content: '';
        background: #fff;
        z-index: 3;
        // width:100%;
        // height: 100%;
        position:absolute;
        // top:-5px;
        right:0;
  }
      &:after {
        content: '';
        border-radius: 50%;
        border: 3px solid #1BAAF0;
        z-index: 3;
        border-bottom-color: transparent;
        border-left-color: transparent;
        animation: spin .5s linear infinite;
        transform: translateX(-50%);
        position:absolute;
        top: 14px;
        right: 2px;
        width: 7px;
        height: 7px;
  `;

let positionsLength=0;

const PositionAdd: React.FC<Props> = ({
  setUserPosition,
  closeHandler,
  saveHandler,
  positions,
  setPositions,
  addPositionInCompany,
}) => {
  const [positionName, setPositionName] = useState("");
  const dispatch = useDispatch();
  const [isAddSection,setIsAddSection]=useState(false);
  const isLoad = useSelector((state: State)=> state.newUser.isLoad)

  useEffect(
    ()=>{
      setIsAddSection(false);
     if (positions)  positionsLength = positions.length;
    },[]
  )

  useEffect(
    ()=>{
      if (positions && (positions.length - positionsLength)===1) {
        setUserPosition([positions[positions.length-1].id as number]);
      }
    },[positions]
  )

  const addHandler = () => {
    if (!positionName) return;
    let companyIdString = localStorage.getItem("company_id");
    let companyId =  companyIdString ? +companyIdString : 1;
    if (companyId) {
      addPositionInCompany(companyId, positionName);
    } else {
      setPositions([...positions, { name: positionName, id: 0 }]);
    }
    setPositionName("");
    saveHandler();
  };
  return (
    <WrapperBlockSections className="project_sections">
      {isLoad&&<Preloader/>}
      <WrapperBlockInput>      
        <CustomInput
          // placeholder="Обязательное поле"
          value={positionName}
          inputChangeHandler={setPositionName}
        />
        <Button
          disabled={isLoad}
          onClick={addHandler}
          title="Добавить"
          design="outline"
          style={{ marginLeft: ".5rem", alignItems: "center" }}
        />
      </WrapperBlockInput>
    </WrapperBlockSections>
  );
};

const mapStateToProps = (state: State) => {
  return {
    positions: getPositionsUSer(state),
  };
};

const mapDispatchToProps = {
  setPositions,
  setUserPosition,
  addPositionInCompany,
};

export default connect(mapStateToProps, mapDispatchToProps)(PositionAdd);
