import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { StatusContext } from "../../../context/status";
import CheckboxElement from "../templates/CheckboxElement";
import NameOfStep from "../templates/NameOfStep";
import "../BpResultForm.scss";

const ConfirmationOfPaymentOfAccountingCalculationDismissal = () => {
  const {
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,
    company_id,
    setloadDataNow,
  } = useContext(StatusContext);

  // console.log("taskCurrentGlobal", taskCurrentGlobal);

  const [data, setData] = useState(
    settingsOfFields?.confirmationOfPaymentOfAccountingCalculation?.extra
      ? settingsOfFields.confirmationOfPaymentOfAccountingCalculation.extra
      : {}
  );

  const url = taskId ? `${apiBp}/task/${taskId}` : null;
  useEffect(() => {
    console.log("use in form worked");
    if (taskId) {
      axios
        .patch(
          url,
          {
            ...settingsOfFields.confirmationOfPaymentOfAccountingCalculation,
            extra: data,
          },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))

        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);

  return (
    <form id="checkOfJurist" className="form">
      <div>
        <NameOfStep text={"Подтверждение выплаты"} />
        <CheckboxElement
          name={"d4"}
          text={"Выплачено"}
          data={data}
          setData={setData}
        />
      </div>
    </form>
  );
};

export default ConfirmationOfPaymentOfAccountingCalculationDismissal;
