import React from "react";
import { useDispatch } from "react-redux";
import { setTaskPriority } from "../../../TaskInfoPlate/actions";
import { store } from "../../../../App";

interface Props {
  setIsTaskView: (mode: boolean) => void;
  setIsShowAddObject: (mode: boolean) => void;
}

const MenuHead: React.FC<Props> = ({ setIsTaskView, setIsShowAddObject }) => {
  const dispatch = useDispatch();
  return (
    <div className="menu__head-buttons">
      <button className="menu__head-button" onClick={() => setIsTaskView(true)}>
        Список задач
      </button>
      <button
        className="menu__head-button"
        onClick={() => {
          let priorities= store.getState().commonInfo.priorities;
          let priority = priorities.find((el) => el.slug === "medium") || priorities[1];
          dispatch(setTaskPriority(priority.id)); 
          setIsShowAddObject(true);
        }
        }
      >
        Добавить объект
      </button>
    </div>
  );
};

export default MenuHead;
