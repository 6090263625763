export default function (scheduler) {
  return function assert(check, message) {
    if (!check) {
      if (scheduler.config.show_errors && scheduler.callEvent("onError", [message]) !== false) {
        if (scheduler.message) {
          scheduler.message({
            type: "error",
            text: message,
            expire: -1
          });
        } else {
          // eslint-disable-next-line
          console.log(message);
        }

        // eslint-disable-next-line no-debugger
        debugger;
      }
    }
  };
}