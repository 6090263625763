import React, {useCallback, useState, useRef, useEffect} from 'react';
import AddButton from '../../../common/components/Buttons/AddButton';
// import DisplayTypeSelect from '../../../common/components/Selects/DisplayTypeSelect'
import UserProjectSelect from '../../../common/components/Selects/UserProjectSelect/UserProjectSelect';
import {Project, SelectData, Section, Task, User, Item} from '../../../common/types';
import {connect, useDispatch, useSelector} from 'react-redux';
import {getProjects} from '../../Projects/selectors';
import {State} from '../../../rootReducer';

import DotsButton from '../../../common/components/Buttons/DotsButton';
import {openExistingProject} from '../../NewProject/actions';
import {useHistory, useParams} from 'react-router-dom';
import {fetchData} from '../../../utils/fetchData';
import TableSearch from '../../../common/components/TableSearch/TableSearch';

import FilterButton from '../../../common/components/Buttons/filterButton';
import ViewWeekRoundedIcon from '@material-ui/icons/ViewWeekRounded';
import ProjectsFilters from '../../Projects/components/ProjectFilters';
import ColumnsWindow from '../../../common/ColumnsCustomWindow';
import Button from '../../../common/components/newComponent/Buttons/Button';
import Toggler from '../../../common/components/Togglers/Toggler';
import {
    DefaultFilterTemplates,
    FilterTemplates,
    setFilters,
    ShowFiltersWindow,
} from '../../../common/actions';
import FiltersWindow from '../../../common/FiltersWindow';
import Icon from '../../../common/components/Icon';
import {cookieMaster} from '../../../utils/CookieMaster';
import {urlApp} from '../../../App';
import {createDeleteResolutionItem, setNewParametersToReducer} from "../actions";
import styled from "styled-components";
import IdeaItem from "./IdeaItem";
import {nanoid} from "nanoid";
import NameWithInput from "../../../common/components/newComponent/Inputs/NameWithInput";
import {TextareaAutosize} from "@material-ui/core";
import CheckboxItem from "../../../common/components/CheckboxItem/CheckboxItem";
import MultySelect from "../../../common/components/Selects/MultySelect";
import TaskDateTime from "../../../common/components/DatePickers/DateTimePiker";
import {initialState} from "../reducer";
import SelectWithSecondaryBtn from "../../../common/components/Selects/SelectWithSecondaryBtn";


type Props = {
    projects: Project[];
    sections: Section[];
    selectedProject: Project | null;
    openExistingProject: (project: Project) => void;
    currentUserRoles: number[] | undefined;
    tableFilter: string;
    projectId: number | null;
    appliedFiltersExist: boolean;
    showColumnsWindow: boolean;
    showFiltersWindow: boolean;
    tableOrTreeToggler: string;
    ShowFiltersWindow: () => void;
    current_user: number;
    filters: any;
    FilterTemplates: (data: {}) => void;
    DefaultFilterTemplates: (data) => void;
    setFilters: (
        data_from?: string,
        date_to?: string,
        executors?: number[],
        authors?: number[],
        statuses?: number[],
        actual?: boolean
    ) => void;
    // businessProcessesModalShow: (show: boolean) => void;
    setNewParametersToReducer: (obj: object) => void,
    meetingIdeas: any[],
    newIdea: {
        agenda_item_id: number | null,
        name: string,
        description: string,
        author_id: number,
        review_data: string
    },
    commonUsers: User[],
    checkList: any[],
    createDeleteResolutionItem: (context: string, delete_index?: number) => void    // если послать без delete_index, то создаст. Если с ним - удалит
};


const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 36px;
  margin-right: 8px;
`;

const Ideas: React.FC<Props> = ({
                                    showFiltersWindow,
                                    showColumnsWindow,
                                    projectId,
                                    projects,
                                    selectedProject,
                                    openExistingProject,
                                    currentUserRoles,
                                    tableOrTreeToggler,
                                    ShowFiltersWindow,
                                    filters,
                                    setNewParametersToReducer,
                                    meetingIdeas,
                                    newIdea,
                                    createDeleteResolutionItem,
                                    commonUsers,
                                    checkList
                                }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [timer, setTimer] =
        useState<ReturnType<typeof setTimeout> | null>(null);
    // создал изолированную копию объекта, чтобы не перезаписать пропс
    const newRolesArr = currentUserRoles?.map((item) => {
        return item;
    });
    newRolesArr?.sort();

    const [showAddNewIdea, setShowAddNewIdea] = useState<boolean>(false);

    const [showMenu, setShow] = useState<boolean>(false);
    const {id} = useParams();
    const menu = useRef<HTMLDivElement>(null);
    const tasksOrder = useSelector(
        (state: State) => state.projectsPage.tasksOrder
    );
    const outsideClick = useCallback((e: Event) => {
        if (!menu.current?.contains(e.target as HTMLElement)) setShow(false);
    }, []);

    // useEffect(() => {
    //   document.addEventListener('click', outsideClick);
    //   return () => document.removeEventListener('click', outsideClick);
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    let company_id = localStorage.getItem('company_id');


    // console.log(showFiltersWindow)
    return <div>

        <div style={{marginTop: '8px'}}>
            {/*существующие*/}
            {!!meetingIdeas.length && meetingIdeas.map(idea => (<IdeaItem ideaItem={idea} key={nanoid()}/>))}
        </div>


        {!showAddNewIdea && <div style={{marginTop: '8px', justifyContent: 'start'}}>
            <Button title={'Добавить идею'} design={'outline'} onClick={() => setShowAddNewIdea(true)}/>
        </div>}

        {showAddNewIdea && <div className={'addNewMeetingItem'}>

            <div style={{fontSize: '16px'}}>
                Новая идея
            </div>

            <NameWithInput title="Название" iconName="people">
                <TextareaAutosize value={newIdea.name}
                                  onChange={arg => setNewParametersToReducer({
                                      newIdea: {
                                          ...newIdea,
                                          name: arg.target.value
                                      }
                                  })}/>
            </NameWithInput>

            <NameWithInput title="Описание" iconName="people">
                <TextareaAutosize value={newIdea.description} onChange={arg => setNewParametersToReducer({
                    newIdea: {
                        ...newIdea,
                        description: arg.target.value
                    }
                })}/>
            </NameWithInput>

            <NameWithInput title="Пункт повестки" iconName="people">
                <SelectWithSecondaryBtn
                    list={checkList.map(item => {
                        return {
                            text: item.name,
                            value: item.id
                        }
                    })}
                    selected={newIdea.agenda_item_id ? [checkList.find(item => item.id === newIdea.agenda_item_id)].map(item => ({
                                text: item.name,
                                value: item.id
                            }
                        ))[0]
                        : null}
                    selectHandler={(value, target) => {
                        setNewParametersToReducer({
                            newIdea: {
                                ...newIdea,
                                agenda_item_id: value
                            }
                        })
                    }}
                />
            </NameWithInput>

            <NameWithInput title="Автор" iconName="people">
                <SelectWithSecondaryBtn
                    list={commonUsers.map(user => {
                        return {
                            text: user.name + ' ' + user.surname,
                            value: user.id
                        }
                    })}
                    selected={commonUsers.filter(user => newIdea.author_id === user.id).map(user => {
                        return {
                            text: user.name + ' ' + user.surname,
                            value: user.id
                        }
                    })[0]}
                    selectHandler={(value, target) => {
                        setNewParametersToReducer({
                                     newIdea: {
                                         ...newIdea,
                                         author_id: value
                                     }
                                 })
                    }}
                />
            </NameWithInput>

            <NameWithInput title="Дата ревью" iconName="calendar-dark">
                <div style={{width: '232px'}}>
                    <TaskDateTime
                        acceptHandler={(val) => setNewParametersToReducer({
                            newIdea: {
                                ...newIdea,
                                review_data: val
                            }
                        })}
                        date={
                            newIdea.review_data ? newIdea.review_data : ''
                        }
                    />
                </div>
            </NameWithInput>

            <FlexDiv style={{justifyContent: 'flex-end', margin: '0px'}}>
                <Button title={"Отмена"} design={'secondary'} style={{marginRight: '8px'}} onClick={() => {
                    setNewParametersToReducer({
                        newTask: {
                            ...initialState.newTask
                        }
                    })
                    setShowAddNewIdea(false)
                }}/>
                <Button title={"Сохранить"}
                        disabled={newIdea.name === '' || newIdea.agenda_item_id === null || newIdea.author_id === null}
                        onClick={() => {
                            createDeleteResolutionItem('idea')
                            setShowAddNewIdea(false)
                        }}/>
            </FlexDiv>

        </div>}

    </div>
};

const mapStateToProps = (state: State) => {
    return {
        appliedFiltersExist: Boolean(
            Object.keys(state.projectsPage.filters.appliedFilters).length
        ),
        projects: getProjects(state),
        // selectData: getProjectSelectData(state),
        selectedProject: state.projectsPage.selectedProject,
        sections: state.commonInfo.sections,
        // showFiltersWindow: state.projectsPage.filtersWindowShow,
        showFiltersWindow: state.commonInfo.filters.showFiltersWindow,
        currentUserRoles: state.commonInfo.currentUserInfo?.roles,
        tableFilter: state.projectsPage.tableFilter,
        projectId: state.projectsPage.selectedProject?.id || null,
        showColumnsWindow: state.projectsPage.showColumnsWindow,
        tableOrTreeToggler: state.projectsPage.tableOrTreeToggler,
        current_user: state.commonInfo.current_user,
        filters: state.commonInfo.filters,
        meetingIdeas: state.meetingsPage.meetingIdeas,
        newIdea: state.meetingsPage.newIdea,
        commonUsers: state.commonInfo.users,
        checkList: state.meetingsPage.checkList,
    };
};

const mapDispatchToProps = {
    // fetchTasks,
    openExistingProject,
    ShowFiltersWindow,
    DefaultFilterTemplates,
    FilterTemplates,
    setFilters,
    setNewParametersToReducer,
    createDeleteResolutionItem
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Ideas);
