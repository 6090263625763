import React from "react";

const Approved:React.FC = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#DADADA" />
      <path
        d="M11.75 28.25V12.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.75 23.7522C17.75 19.2522 22.25 28.2522 28.25 23.7522V12.5022C22.25 17.0022 17.75 8.00216 11.75 12.5022"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Approved;
