import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { connect, useSelector } from "react-redux";
import { State } from "../../../../../rootReducer";
import { Status } from "../../../../../common/types";
import "../../style.sass";
import {
  AVERAGE_PRIORITY,
  COMPLETED_STATUS,
  HIGH_PRIORITY,
  MADE_STATUS,
  OVERDUE_STATUS,
  URGENT_PRIORITY,
} from "../../../helpers";
import moment from "moment/moment";

interface Props {
  task: any;
  projects: object;
  currentStatus: (stat: number, date: Date) => Status|null;
  setHighlightedItem: (mode: any) => void
}

const CardTask = styled.div`
  cursor: ${({ active }) => (active ? "grab" : "default")};
  border-radius: 5px;
  padding: 10px 10px 10px 20px;
  margin-bottom: 15px;
  background-color: #f5f5f5;
  box-sizing: border-box;
  position: relative;
`;

const ContentCard = styled.div`
  padding: 3px 0 3px 0;
  &::before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: ${({ active }) =>
      active === AVERAGE_PRIORITY
        ? "#FFD600"
        : active === HIGH_PRIORITY
        ? "#FF9900"
        : active === URGENT_PRIORITY
        ? "#F44336"
        : "#D3D3D3"};
    border-radius: 4px 0px 0px 4px;
  }
`;

const TaskName = styled.div`
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 15px;
  white-space: nowrap;
`;

const TaskDescription = styled.div`
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 3px 0 3px 0;
`;

const TaskDeadline = styled.div`
  font-size: 12px;
  flex-shrink: 0;
`;

const NameSection = styled.div`
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const StatusTask = styled.div`
  padding: 3px 0 3px 0;
`;

const TaskCard: React.FC<Props> = ({
  task,
  projects,
  currentStatus,
  setHighlightedItem
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const [card, setCard] = useState("");
  const [cardName, setCardName] = useState("");

  const status: Status|null = currentStatus(task.status_id, task.end);
  const formattedEndDate = moment(task.end).format('DD-MM-YYYY HH:mm');

  useEffect(() => {
    if (status?.id === OVERDUE_STATUS) {
      setCardName("task_card_overdue");
    } else if (status?.id === COMPLETED_STATUS) {
      setCard("task_comp");
      setCardName("comp_task_name");
    } else if (status?.id === MADE_STATUS) {
      setCard("task_completed");
    } else {
      setCard("");
    }
  }, []);

  const handleDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    name,
    task_id,
    priority_id,
    status_id
  ) => {
    const target = event.target as HTMLDivElement;
    setIsDragging(true);
    const eventData = {
      id: target.id,
      id_task: task_id,
      text: name,
      type: "EVENT_TASK_TYPE",
      priority_id: priority_id,
      status_id: status_id
    };
    event.dataTransfer.setData("text/plain", JSON.stringify(eventData));
  };

  const handleDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    setIsDragging(false);
  };

  let project_of_task;
  let section_of_task;
  Object.keys(projects).forEach((id) => {
    if (projects[id].id === task.project_id) {
      project_of_task = projects[id].name;
      projects[id].sections.forEach((section) => {
        if (section.id === task.project_section_id) {
          section_of_task = section.name;
        }
      });
    }
  });

  let priorities = useSelector((state: State) => state.commonInfo.priorities);
  let priorityStatus = priorities.find((el) => el.id === task.priority_id);


  const handleMouseEnter = (item) => {
    setHighlightedItem(item);    
  }

  const handleMouseLeave = () => {
    setHighlightedItem(null);
  }

  return (
    <CardTask
      onMouseEnter={() => handleMouseEnter(task.id)}
      onMouseLeave={handleMouseLeave}
      draggable
      onDragStart={(e) => handleDragStart(e, task.name, task.id, task.priority_id, task.status_id)}
      onDragEnd={handleDragEnd}
      active={isDragging}
      className={card}
    >
      <ContentCard active={priorityStatus?.slug} title={task.name}>
        <NameSection>
          <TaskName className={cardName}>
            {task.id}.{task.name}
          </TaskName>
          <TaskDeadline title="Дедлайн">
            { formattedEndDate }
          </TaskDeadline>
        </NameSection>
        <TaskDescription>Проект: {project_of_task}</TaskDescription>
        <TaskDescription>Раздел: {section_of_task}</TaskDescription>
        <StatusTask>
          <div>Статус: {status?.name}</div>
        </StatusTask>
      </ContentCard>
    </CardTask>
  );
};

const mapStateToProps = (state: State) => {
  return {
    projects: state.projectsPage.projectsAll,
  };
};

export default connect(mapStateToProps, {})(TaskCard);
