import React, {useEffect, useState, useCallback, useRef} from 'react';
import {connect} from 'react-redux';
// import {setUserAddMode} from '../Users/actions';
// import {clearUser, createUser} from './actions';

import {State} from '../../../rootReducer';
// import {getCanSend} from './selectors';
import {useHistory} from 'react-router-dom';
// import UserChangePassword from "./components/UserChangePasswordButton";
import Input from "../../../common/components/Inputs/Input";
import AddButton from "../../../common/components/Buttons/AddButton";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {ChangePassword, PasswordReset, setChangePasswordMode} from "../actions";
import {validatePassword} from "../../../utils/validPatterns";


type Props = {
    setChangePasswordMode: (mode: boolean) => void,
    PasswordReset: any,
    ChangePassword: any
}


const ChangePassPanel: React.FC<Props> = ({setChangePasswordMode, PasswordReset, ChangePassword}) => {

    const [oldPass, setOldPass] = useState('')
    const [newPass1, setNewPass1] = useState('')
    const [newPass2, setNewPass2] = useState('')

    const [newPass1valid, setnewPass1valid] = useState(true)
    const [newPass2valid, setnewPass2valid] = useState(true)
    const [checked, setChecked] = useState(false)
    const [NewOldEquals, setNewOldEquals] = useState(false)
    const plate = useRef<HTMLDivElement>(null)

    // self close window
    const outsideClickHandler = useCallback(function (e: Event): any {
        if (plate.current && !plate.current.contains(e.target as Node)) {
            setChangePasswordMode(false)
        }
    }, [])

    useEffect(() => {
        document.addEventListener('click', outsideClickHandler)
        return () => document.removeEventListener('click', outsideClickHandler)
    }, [outsideClickHandler])
    // self close window

    const confirm = () => {
        if (newPass1 !== newPass2) {
            setnewPass2valid(false)
            setnewPass1valid(false)
        } else {
            setnewPass2valid(true)
            setnewPass1valid(true)
        }

        if (oldPass !== '' && newPass1 !== '' && oldPass === newPass1) {
            if (!NewOldEquals) setNewOldEquals(true)
        } else {
            if (NewOldEquals) setNewOldEquals(false)
        }

        setChecked(true)

        if (oldPass !== '' && newPass1 !== '' && newPass2 !== '' && newPass1 === newPass2 && newPass1valid && newPass2valid && !NewOldEquals) {
            ChangePassword(oldPass, newPass2)
        }
    }

    return (
        <div className="add_pannel_wrapper">
            <div ref={plate} className={`change_pass_pannel`}>

                <div className="close_win" onClick={() => {
                    setChangePasswordMode(false)
                }}>
                    <CloseRoundedIcon/>
                </div>

                <div className="head">
                    <div className="main_title"> Сменить пароль</div>
                </div>

                <Input
                    disabled={false}
                    inputType="password"
                    placeholder="Текущий пароль"
                    withIcon={true}
                    title='Введите прежний пароль'
                    value={oldPass}
                    changeHandler={(val) => {
                        setOldPass(val)
                    }}
                    isValid={checked ? (oldPass !== '') : true}
                    validText={'Ввод текущего пароля является обязательным'}
                />


                <Input
                    disabled={false}
                    inputType="password"
                    placeholder="Новый пароль"
                    title='Введите новый пароль'
                    withIcon={true}
                    value={newPass1}
                    changeHandler={(val) => {
                        setNewPass1(val)
                    }}
                    isValid={checked ? (newPass1 === '' ? false : (NewOldEquals ? false : newPass1valid)) : true}
                    validText={newPass1 === '' ? 'Ввод нового пароля является обязательным' : NewOldEquals ? 'Новый пароль должен отличаться от старого' : 'Пароли должны совпадать'}
                />

                <Input
                    disabled={false}
                    inputType="password"
                    withIcon={true}
                    placeholder="Подтвердите пароль"
                    title='Подтвердите новый пароль'
                    value={newPass2}
                    changeHandler={(val) => {
                        setNewPass2(val)
                    }}
                    isValid={checked ? (newPass2 === '' ? false : newPass2valid) : true}
                    validText={newPass2 === '' ? 'Ввод нового пароля является обязательным' : 'Пароли должны совпадать'}
                />

                <div className={'change_pass_panel_buttons'}>
                    <div className={'text_link_action'} onClick={() => {
                        PasswordReset()
                    }}>Забыли пароль
                    </div>
                    <div onClick={() => {
                        confirm()
                    }} className="btn primary" title='Изменить'>
                        <div>Изменить</div>
                    </div>
                </div>

            </div>
        </div>
    )
}


const mapStateToProps = (state: State) => {
    return {
        isSending: state.newUser.isSending,
        roles: state.newUser.roles,
        userId: state.newUser.id,
        currentUser: state.commonInfo.current_user
    }
}

const mapDispatchToProps = {
    setChangePasswordMode,
    PasswordReset,
    ChangePassword
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassPanel)