// Создание БП
import React, { useContext, useEffect, useRef, useState } from "react";
import { StatusContext } from "../../context/status";
import axios from "axios";
import { CreateNotif } from "../../../../../utils/createNotification";
import { cookieMaster } from "../../../../../utils/CookieMaster";
import CloseIcon from "@material-ui/icons/Close";
import "./CreateBp.scss";
import { useSelector } from "react-redux";
import {
  getProjectsSelectList,
} from "../../../../TaskInfoPlate/selectors";

let templates;

const CreateBp = () => {
  const {
    company_id,
    bpListChange,
    setBpListChange,
    apiBp,
    bearer,
    setCreateBpStatus,
    createBpForm,
    setCreateBpForm,
    setCreateTaskStatus,
    createTaskForm,
    setCreateTaskForm,
    createBpStatus,
    createBpSampleStatus,
    setCreateBpSampleStatus,
    createBpSampleForm,
    setCreateBpSampleForm,
    createBpSampleFormDate,
    createBpSampleFormOptions,
    setCreateTaskSampleFormStatus,
    idSample,
    setIdSample,
    sampleArr,
    setSampleArr,
    setStatusCreateTask,
    tasksArr,
    setTasksArr,
    setNowBp,
    userId,
    users,
    idSample1,
    idSample2,
    idSample3,
    Mount,
    projects,
    projectSections,
    setProjectSections,
    loadTemplateBp,
    setLoadTemplateBp,
    addTasksMenu,
    statusCreateTask,
    createTaskSampleFormStatus,
    createTaskStatus,
  } = useContext(StatusContext);

  let begin2 = new Date(Date.now());
  let end2 = new Date(Date.now() + 10 * 24 * 60 * 60 * 1000);
  let monthBegin2 =
    begin2.getMonth() + 1 < 10
      ? "0" + (begin2.getMonth() + 1)
      : begin2.getMonth() + 1;
  let dayBegin2 =
    begin2.getDate() < 10 ? "0" + begin2.getDate() : begin2.getDate();
  let monthEnd2 =
    end2.getMonth() + 1 < 10
      ? "0" + (end2.getMonth() + 1)
      : end2.getMonth() + 1;
  let dayEnd2 = end2.getDate() < 10 ? "0" + end2.getDate() : end2.getDate();

  let dateBegin2 = begin2.getFullYear() + "-" + monthBegin2 + "-" + dayBegin2;

  let deadline2 = end2.getFullYear() + "-" + monthEnd2 + "-" + dayEnd2;

  //console.log("sampleArr",sampleArr)

  const [accessNext, setAccessNext] = useState("blue-btn blue-btn__disabled");

  const [file, setFile] = useState({});

  const [nameOfTemplate, setNameOfTemplate] = useState();

  const closeRef = useRef(null);
  const templateNameRef = useRef(null);

  const state = useSelector(state => state);

  useEffect(() => {
    
    let projectId = null; 
    let section = null;

    let projectId_quick_task = 0;
    let sectionId_quik_task = 0;

    let projectsSelect = getProjectsSelectList(state);
    let sections = state.commonInfo.sections;

    if (projectsSelect.list.length > 0 && projectsSelect.selected === null) {
      let index;
      for (index = 0; index < projectsSelect.list.length; ++index) {
        if (projectsSelect.list[index].text === "Быстрые задачи") {
 
          projectId = +projectsSelect.list[index].value;
 
          let value = projectId;
          let params = { project_id: value };
          let section0 = sections.find((sec) => sec.project_id === value);
          if (section0) {
            section = section0;
          }
          if (section) params["project_section_id"] = section.id;

          projectId_quick_task = +value;
  

          let value2 = null;

          if (projectId) {
            if (sections && sections.length > 0) {
              let index2;
              for (index2 = 0; index2 < sections.length; ++index2) {
                if (
                  sections[index2].project_id &&
                  sections[index2].project_id == projectId
                ) {
                  if (sections[index2].name === "Основной раздел") {
                    if (sections[index2].id && sections[index2].id !== 0)
                      value2 = sections[index2].id;
                  }
                }
              }
            }
          }

          sectionId_quik_task = +value2;

        }
      }
  }

    setCreateBpSampleForm({
      type: 0,
      businessProcess: {
        name: null,
        deadline: null,
        project_id: projectId_quick_task,
        project_section_id: sectionId_quik_task,
        tasks: 1,
        initiator_id: parseInt(
          document.cookie.replace(
            /(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/,
            "$1"
          )
        ),
      },
      options: [],
    });
    setCreateBpForm({
      name: null,
      initiator_id: parseInt(
        document.cookie.replace(
          /(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        )
      ),
      project_id: projectId_quick_task,
      project_section_id: sectionId_quik_task,
      deadlineDate: null,
      deadlineTime: "00:00:00",
      tasks: null,
      file_id: null,
      deadline: null,
    });
  }, [
    createBpSampleStatus,
    createBpStatus,
    createTaskStatus,
    createTaskSampleFormStatus,
    statusCreateTask,
    addTasksMenu,
  ]);


  useEffect(() => {
    if (
      createBpForm.name !== null &&
      createBpForm.project_id !== null &&
      createBpForm.project_section_id !== null
    ) {
      setAccessNext("blue-btn");
    } else {
      setAccessNext("blue-btn blue-btn__disabled");
    }
  }, [createBpForm]);
 
  /***  Создаем задачи для бизнес-процесса   */
  const saveBpSample2 = () => {
    let begin = new Date(Date.now());
    let deadline = new Date(Date.now() + 10 * 24 * 60 * 60 * 1000);
    //***************** */

    // console.log("createBpSampleForm", createBpSampleForm);

    if (
      createBpSampleForm.businessProcess.name &&
      createBpSampleForm.businessProcess.project_id &&
      createBpForm.project_section_id
    ) {     

      templates = JSON.stringify({
        company_id: company_id,
        name: createBpSampleForm.businessProcess.name
          ? createBpSampleForm.businessProcess.name
          : null,
        initiator_id: userId,
        project_id: createBpSampleForm.businessProcess.project_id
          ? createBpSampleForm.businessProcess.project_id
          : null,
        project_section_id: createBpSampleForm.businessProcess
          .project_section_id
          ? createBpSampleForm.businessProcess.project_section_id
          : null,
        deadline: createBpSampleForm.businessProcess.deadline
          ? createBpSampleForm.businessProcess.deadline
          : new Date(Date.now()),
      });

      let config = {
        method: "post",
        url: `${apiBp}/bp/from-template/${createBpSampleForm.type}`,
        headers: {
          Authorization: bearer,
          "company-id": company_id,
          "secret-token": localStorage.getItem("access_token_jwt"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: templates,
      };

      axios(config)
        .then((res) => {
          // Скрываем форму и очищаем данные
          setCreateTaskSampleFormStatus(false);
          setCreateBpStatus(false);
          setCreateBpSampleStatus(false);
          setNowBp({});
          setTasksArr([]);
          setCreateBpSampleForm({
            type: 0,
            businessProcess: {
              name: null,
              deadline: null,
              project_id: null,
              tasks: 1,
              initiator_id: document.cookie.replace(
                /(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/,
                "$1"
              ),
            },
            options: [],
          });

          setBpListChange((prev) => !prev);

          CreateNotif(
            `Бизнес-процесс "${createBpSampleForm.businessProcess.name}" создан по шаблону`,
            "success"
          );

          console.log("Бизнес-процесс: ", res.data);
        })
        .catch((err) => {
          CreateNotif(
            "Ошибка в посылке данных типовой задачи на сервер",
            "error"
          );
          console.log("Ошибка при создании типовой задачи: ", err);
        });

      /*
        console.log("createBpSampleForm.type", createBpSampleForm.type);
        CreateNotif(
          "Бизнес-процесс по данному шаблону еще в процессе разработки!",
          "warning"
        );*/
    } else {
      CreateNotif("Заполните, пожалуйста, все необходимые поля!", "error");
    }
  };
  //************************************************************************* */

  const saveBpSample = () => {
    let begin = new Date(Date.now());
    let end = new Date(Date.now() + 1 * 24 * 60 * 60 * 1000);
    let monthBegin =
      begin.getMonth() + 1 < 10
        ? "0" + (begin.getMonth() + 1)
        : begin.getMonth() + 1;
    let dayBegin =
      begin.getDate() < 10 ? "0" + begin.getDate() : begin.getDate();
    let monthEnd =
      end.getMonth() + 1 < 10 ? "0" + (end.getMonth() + 1) : end.getMonth() + 1;
    let dayEnd = end.getDate() < 10 ? "0" + end.getDate() : end.getDate();

    let dateBegin = begin.getFullYear() + "-" + monthBegin + "-" + dayBegin;

    let deadline = end.getFullYear() + "-" + monthEnd + "-" + dayEnd;

    console.log("deadline", deadline);
    console.log("createBpSampleForm", createBpSampleForm);

    //***************** */
    if (
      createBpSampleForm.businessProcess.name &&
      createBpSampleForm.businessProcess.project_id &&
      createBpForm.project_section_id
    ) {
      setCreateTaskSampleFormStatus(false);
      setCreateBpStatus(false);
      setCreateBpSampleStatus(false);
      setNowBp({});

      let tasksStr = "";
      for (let i in tasksArr) {
        tasksStr = tasksStr.concat(tasksArr[i]);
        if (i < tasksArr.length - 1) {
          tasksStr = tasksStr.concat("|");
        }
      }

      // Создание бизнес-процесса без шаблона
      if (
        createBpSampleForm.type === null ||
        createBpSampleForm.type === undefined ||
        createBpSampleForm.type === 0
      ) {
        //  Создание бизнес-процесса без шаблона если к нему прикреплены файлы
        if (createBpSampleForm.businessProcess.file_id) {
          fetch(
            `
          ${apiBp}/bp?name=${createBpSampleForm.businessProcess.name}
          &company_id= ${company_id}
          &initiator_id=${createBpSampleForm.businessProcess.initiator_id}
          &project_id=${createBpSampleForm.businessProcess.project_id}
          &project_section_id=${createBpForm.project_section_id}
          &file_id=${createBpSampleForm.businessProcess.file_id}
          &deadline=${
            createBpSampleForm.businessProcess.deadline
              ? createBpSampleForm.businessProcess.deadline
              : deadline
          }`,
            {
              method: "POST",
              headers: {
                Authorization: bearer,
                "company-id": company_id,
                "secret-token": document.cookie.replace(
                  /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                  "$1"
                ),
              },
            }
          )
            .then((res) => {
              res.json().then((data) => {
                if (data.message) {
                  CreateNotif(data.message, "warning");
                } else {
                  CreateNotif(
                    `Бизнес-процесс "${createBpSampleForm.businessProcess.name}" без шаблона создан`,
                    "success"
                  );
                }
              });
              // res.json();
            })
            .then((r) => {
              setBpListChange((prev) => !prev);
              setTasksArr([]);
              setCreateBpSampleForm({
                type: 0,
                businessProcess: {
                  name: null,
                  deadline: null,
                  project_id: null,
                  tasks: 1,
                  initiator_id: cookieMaster.getCookie("user_id"),
                },
                options: [],
              });
              console.log(r.businessProcess.tasks);
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          //  Создание бизнес-процесса без шаблона если у неё нет файлов
          fetch(
            `${apiBp}/bp?name=${createBpSampleForm.businessProcess.name}
          &company_id= ${company_id}  
          &initiator_id=${createBpSampleForm.businessProcess.initiator_id}
          &project_id=${createBpSampleForm.businessProcess.project_id}
          &project_section_id=${createBpForm.project_section_id}
          &deadline=${
            createBpSampleForm.businessProcess.deadline
              ? createBpSampleForm.businessProcess.deadline
              : deadline
          }`,
            {
              method: "POST",
              headers: {
                Authorization: bearer,
                "company-id": company_id,
                "secret-token": document.cookie.replace(
                  /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                  "$1"
                ),
              },
            }
          )
            .then((res) => {
              res.json().then((data) => {
                if (data.message) {
                  CreateNotif(data.message, "warning");
                } else {
                  CreateNotif(
                    `Бизнес-процесс "${createBpSampleForm.businessProcess.name}" без шаблона создан`,
                    "success"
                  );
                }
              });
              // res.json();
            })
            .then((r) => {
              setBpListChange((prev) => !prev);
              setTasksArr([]);
              setCreateBpSampleForm({
                type: 0,
                businessProcess: {
                  name: null,
                  deadline: null,
                  project_id: null,
                  tasks: 1,
                  initiator_id: cookieMaster.getCookie("user_id"),
                },
                options: [],
              });
            })
            .catch((e) => {
              console.log(e);
            });
        }
      } else {
        fetch(
          `${apiBp}/bp?name=${createBpSampleForm.businessProcess.name}
          &company_id= ${company_id}
          &initiator_id=${createBpSampleForm.businessProcess.initiator_id}
          &project_id=${createBpSampleForm.businessProcess.project_id}
          &project_section_id=${createBpForm.project_section_id}`,
          {
            method: "POST",
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": document.cookie.replace(
                /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                "$1"
              ),
            },
          }
        )
          .then((res) => {
            setBpListChange((prev) => !prev);
            setCreateBpSampleStatus(false);
            setTasksArr([]);
            setCreateBpSampleForm({
              type: 0,
              businessProcess: {
                name: null,
                deadline: null,
                project_id: null,
                tasks: 1,
                initiator_id: document.cookie.replace(
                  /(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/,
                  "$1"
                ),
              },
              options: [],
            });
            // console.log(res.data);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } else {
      CreateNotif("Заполните, пожалуйста, все необходимые поля!", "error");
    }
  };

  useEffect(() => {
    let arr = [];
    for (let i in createBpSampleFormOptions) {
      if (createBpSampleFormOptions[i]?.optionId) {
        arr.push(createBpSampleFormOptions[i]);
      }
    }
    setCreateBpSampleForm({ ...createBpSampleForm, options: arr });
  }, [createBpSampleFormOptions]);

  useEffect(() => {
    if (!!createBpSampleFormDate) {
      if (
        !!createBpSampleFormDate.deadlineDate &&
        !!createBpSampleFormDate.deadlineTime
      ) {
        setCreateBpSampleForm({
          ...createBpSampleForm,
          businessProcess: {
            ...createBpSampleForm.businessProcess,
            deadline:
              createBpSampleFormDate.deadlineDate +
              " " +
              createBpSampleFormDate.deadlineTime +
              ":00",
          },
        });
      } else if (
        !!createBpSampleFormDate.deadlineDate &&
        !createBpSampleFormDate.deadlineTime
      ) {
        setCreateBpSampleForm({
          ...createBpSampleForm,
          businessProcess: {
            ...createBpSampleForm.businessProcess,
            deadline: createBpSampleFormDate.deadlineDate + " 00:00:00",
          },
        });
      }
    }
  }, [createBpSampleFormDate]);

  useEffect(() => {
    if (
      createBpSampleForm.businessProcess.name !== null &&
      createBpSampleForm.businessProcess.project_id !== null &&
      createBpSampleForm.businessProcess.project_section_id !== null
    ) {
      setAccessNext("blue-btn");
    } else {
      setAccessNext("blue-btn blue-btn__disabled");
    }
  }, [createBpSampleForm]);

  // console.log("projects            ", projects);

  useEffect(() => {
    axios
      .get(`${apiBp}/bp-template`, {
        headers: {
          Authorization: bearer,
          "company-id": company_id,
          "secret-token": document.cookie.replace(
            /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
            "$1"
          ),
        },
      })
      .then((res) => {
        setSampleArr(res.data.data);
        // console.log("res", res.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [bpListChange, loadTemplateBp]);

  useEffect(() => {
    if (!!createBpForm.project_id) {
      axios
        .get(
          `${process.env.REACT_APP_PUBLIC_API}/projects/${createBpForm.project_id}`,
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              Authorization: bearer,
            },
          }
        )
        .then((res) => {
          // console.log(res.data.data);
          setProjectSections(res.data.data.sections);
        });
    }
  }, [createBpForm.project_id]);

  // console.log("projectSections       ", projectSections);

  useEffect(() => {
    if (createBpSampleForm.type === 0) {
      let bp = sampleArr.filter((el) => el.id === parseInt(idSample));
      bp = bp[0].businessProcessId;

      let tasksStr = "";
      if (bp && bp.tasks) {
        for (let i in bp.tasks) {
          tasksStr = tasksStr.concat(bp.tasks[i].id);
          if (i < bp.tasks.length - 1) {
            tasksStr = tasksStr.concat("|");
          }
        }

        setCreateBpSampleForm({
          ...createBpSampleForm,
          businessProcess: {
            name: bp.name,
            initiator_id: parseInt(
              document.cookie.replace(
                /(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/,
                "$1"
              )
            ),
            project_id: parseInt(bp.project_id),
            tasks: tasksStr,
            deadline: bp.deadline,
          },
        });
      }
    }
  }, [idSample]);

  useEffect(() => {
    if (Mount.current) {
      const handleClickOutside = (e) => {
        if (closeRef.current && !e.composedPath().includes(closeRef.current)) {
          // setCreateBpStatus(false);
          setCreateBpSampleStatus(false);
          Mount.current = false;
        }
      };
      document.body.addEventListener("click", handleClickOutside);

      return () =>
        document.body.removeEventListener("click", handleClickOutside);
    }
  }, [createBpStatus]);

  if (createBpStatus === true) {
    return (
      <div className="business__drop-content" ref={closeRef}>
        <div id="business" className="businessClass">
          <div>
            <div className="business__drop-content__title p__drop-content  p__drop-content_icon">
              * - обязательные для заполнения поля
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                // className="chatMenu-header__close"
                onClick={() => {
                  Mount.current = false;
                  setCreateBpStatus(false);
                  setCreateBpForm({
                    name: null,
                    initiator_id: parseInt(
                      document.cookie.replace(
                        /(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/,
                        "$1"
                      )
                    ),
                    project_id: null,
                    deadlineDate: null,
                    deadlineTime: null,
                    tasks: null,
                    sample: null,
                    file_id: null,
                  });
                  setCreateBpSampleStatus(false);
                  setCreateBpSampleForm({
                    type: 0,
                    businessProcess: {
                      name: null,
                      deadline: null,
                      project_id: null,
                      tasks: 1,
                      initiator_id: document.cookie.replace(
                        /(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/,
                        "$1"
                      ),
                    },
                    options: [],
                  });
                  setCreateTaskSampleFormStatus(false);
                }}
              >
                Закрыть
                <i
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    width: "28px",
                    border: "2px solid #a9a8a8",
                    borderRadius: "50%",
                    height: "28px",
                    marginLeft: "7px",
                  }}
                >
                  <CloseIcon
                    sx={{ width: "21px", hight: "21px", padding: "1px" }}
                    htmlColor="#737373"
                  />
                </i>
              </div>
            </div>
            <form id="new-bp__form">
              <div>
                <label className="p__drop-content" htmlFor="input-name">
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Article.svg`}
                    alt="Article"
                  />
                  Шаблон
                </label>

                <select
                  className="input-form"
                  ref={templateNameRef}
                  onChange={(e) => {
                    console.log("e.target.value", e.target.value);
                    if (e.target.value !== "Без шаблона") {
                      // console.log("e.target.value", e.target.value);
                      setCreateBpSampleStatus(true);
                      if (
                        +e.target.value == idSample1 ||
                        +e.target.value == idSample2 ||
                        +e.target.value == idSample3
                      ) {
                        setIdSample(e.target.value);
                        setCreateBpSampleForm({
                          ...createBpSampleForm,
                          type: +e.target.value,
                        });
                      } else {
                        // console.log("e.target.value", e.target.value);
                        setIdSample(e.target.value);
                        setCreateBpSampleForm({
                          ...createBpSampleForm,
                          type: +e.target.value,
                        });
                      }
                    } else {
                      setCreateBpSampleStatus(false);
                      setCreateBpSampleForm({
                        ...createBpSampleForm,
                        type: 0,
                      });
                    }
                  }}
                >
                  <option>Без шаблона</option>

                  {sampleArr.map((el) => (
                    <option value={el.id} key={el.id}>
                      {el.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                {/* {console.log("nameOfTemplate      ", nameOfTemplate)} */}
                <label className="p__drop-content" htmlFor="input-name">
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Article.svg`}
                    alt="Article"
                  />
                  Название*
                </label>
                <input
                  className="input-form"
                  type="text"
                  id="input-name"
                  value={
                    createBpSampleForm.businessProcess.name
                      ? createBpSampleForm.businessProcess.name
                      : nameOfTemplate
                  }
                  onChange={(e) => {
                    // console.log(e.target.value);
                    if (e.target.value.trim() === "") {
                      setCreateBpForm({ ...createBpForm, name: null });
                      setCreateBpSampleForm({
                        ...createBpSampleForm,
                        businessProcess: {
                          ...createBpSampleForm.businessProcess,
                          name: null,
                        },
                      });
                    } else {
                      setCreateBpForm({
                        ...createBpForm,
                        name: nameOfTemplate,
                      });
                      setCreateBpSampleForm({
                        ...createBpSampleForm,
                        businessProcess: {
                          ...createBpSampleForm.businessProcess,
                          name: e.target.value,
                        },
                      });
                    }
                  }}
                />
              </div>
              <div>
                <label className="p__drop-content" htmlFor="input-project">
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Article.svg`}
                    alt="Article"
                  />
                  Проект*
                </label>
                <select
                  className="input-form"
                  value={
                    createBpSampleForm.businessProcess.project_id
                      ? createBpSampleForm.businessProcess.project_id
                      : ""
                  }
                  onChange={(e) => {
                    setCreateBpSampleForm({
                      ...createBpSampleForm,
                      businessProcess: {
                        ...createBpSampleForm.businessProcess,
                        project_id: parseInt(e.target.value),
                      },
                    });
                    setCreateBpForm({
                      ...createBpForm,
                      project_id: parseInt(e.target.value),
                    });
                    setCreateTaskForm({
                      ...createTaskForm,
                      data: {
                        ...createTaskForm.data,
                        project_id: parseInt(e.target.value),
                      },
                    });
                  }}
                >
                  <option value={null}>Выбрать</option>
                  {projects.map((el) => {
                    return (
                      <option key={el.id} value={el.id}>
                        {el.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <label
                  className="p__drop-content"
                  htmlFor="input-project-section"
                >
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Article.svg`}
                    alt="Article"
                  />
                  Раздел проекта*
                </label>
                <select
                  id="input-project-section"
                  className="input-form"
                  disabled={!createBpForm.project_id ? true : false}
                  value={
                    createBpSampleForm.businessProcess.project_section_id
                      ? createBpSampleForm.businessProcess.project_section_id
                      : ""
                  }
                  onChange={(e) => {
                    console.log(e.target.value);
                    setCreateBpSampleForm({
                      ...createBpSampleForm,
                      businessProcess: {
                        ...createBpSampleForm.businessProcess,
                        project_section_id: parseInt(e.target.value),
                      },
                    });
                    setCreateBpForm({
                      ...createBpForm,
                      project_section_id: parseInt(e.target.value),
                    });
                    setCreateTaskForm({
                      ...createTaskForm,
                      data: {
                        ...createTaskForm.data,
                        project_section_id: parseInt(e.target.value),
                      },
                    });
                  }}
                >
                  <option value={null}>Выбрать</option>
                  {projectSections.map((el) => {
                    return (
                      <option key={el.id} value={el.id}>
                        {el.name}
                      </option>
                    );
                  })}
                </select>
              </div>   
            </form>
          </div>
          <div style={{zIndex: 1}}>
            {createBpSampleStatus === true ? (
              <button
                className={accessNext}
                id="bussines-next"
                onClick={() => {
                  setNameOfTemplate("");
                  saveBpSample2();
                }}
              >
                Сохранить
              </button>
            ) : (
              /* Если создаем бизнес-процесс без шаблона */
              <button
                className={accessNext}
                id="bussines-next"
                onClick={() => {
                  setNameOfTemplate("");
                  saveBpSample();
                }}
              >
                Сохранить
              </button>
            )}

            <button
              className="defualt__btn"
              id="close-btn"
              onClick={() => {
                Mount.current = false;
                setCreateBpStatus(false);
                setCreateBpForm({
                  name: null,
                  initiator_id: parseInt(
                    document.cookie.replace(
                      /(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/,
                      "$1"
                    )
                  ),
                  project_id: null,
                  deadlineDate: null,
                  deadlineTime: null,
                  tasks: null,
                  sample: null,
                  file_id: null,
                });
                setCreateBpSampleStatus(false);
                setCreateBpSampleForm({
                  type: 0,
                  businessProcess: {
                    name: null,
                    deadline: null,
                    project_id: null,
                    tasks: 1,
                    initiator_id: document.cookie.replace(
                      /(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/,
                      "$1"
                    ),
                  },
                  options: [],
                });
                setCreateTaskSampleFormStatus(false);
              }}
            >
              Отмена
            </button>
          </div>
        </div>
      </div>
    );
  } else return null;
  {
  }
};
export default CreateBp;
