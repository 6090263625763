import React from "react";
import "./style.sass";

interface Props {
  date: Date;
  view: "day" | "week" | "month";
  dayPrevClick: () => void;
  dayNextClick: () => void;
  weekPrevClick: () => void;
  weekNextClick: () => void;
}

const SchedulerDate: React.FC<Props> = ({
  date,
  dayPrevClick,
  dayNextClick,
  weekPrevClick,
  weekNextClick,
  view,
}) => {
  const formatDate = (date) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <>
      <div className="scheduler-date-box">
        {view === "day" && (
          <>
            <div className="scheduler-prev" onClick={dayPrevClick}>
            <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M7 0L-1.8677e-07 7L7 14L7 0Z" fill="#292A34"/>
            </svg>
            </div>
            <div className="scheduler-date">{formatDate(date)}</div>
            <div className="scheduler-next" onClick={dayNextClick}>
            <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path fillRule="evenodd" clipRule="evenodd" d="M0 14L7 7L1.66948e-07 0L0 14Z" fill="#292A34"/>
            </svg>
            </div>
          </>
        )}
        {view === "week" && (
          <>
          <div className="scheduler-prev" onClick={weekPrevClick}>
          <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7 0L-1.8677e-07 7L7 14L7 0Z" fill="#292A34"/>
           </svg>
          </div>
          <div className="scheduler-date">{formatDate(date)}</div>
          <div className="scheduler-next" onClick={weekNextClick}>
          <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 14L7 7L1.66948e-07 0L0 14Z" fill="#292A34"/>
          </svg>
          </div>
        </>
        )}
      </div>
    </>
  );
};

export default SchedulerDate;
