import moment from "moment";

//Статусы
export const FOR_PERFORMER_STATUS = 3; //исполнителю
export const IN_WORK_STATUS = 10; //в работе
export const OVERDUE_STATUS = 14; //14 - просрочена
export const COMPLETED_STATUS = 13; //13 - завершено
export const MADE_STATUS = 12; //12 - выполнено
export const DRAFT_STATUS = 18; //18 - черновик
export const CANCELED_STATUS = 11; //11 - отменено
export const SEND_AUTHOR_STATUS = 2; //2 - Отправлено автору

//Приоритеты
export const SHORT_PRIORITY = "low";
export const AVERAGE_PRIORITY = "medium";
export const HIGH_PRIORITY = "high";
export const URGENT_PRIORITY = "urgent";

export function convertDate(date) {
  return moment.utc(date).format("DD-MM-YYYY HH:mm:ss");
}

export function convertDateToUTC(date) {
  return moment.utc(date).format("YYYY-MM-DDTHH:mm:ss[Z]");
}

export function calculateDateIntervalsForView(date, view) {
  let result = {
    start_date: moment(date).startOf('day').toDate(),
    end_date: moment(date).endOf('day').toDate()
  };
  if (view === 'week') {
    result.start_date = moment(date).startOf('isoWeek').toDate();
    result.end_date = moment(date).endOf('isoWeek').toDate();
  }
  return result;
}

export function compareTime(date1, date2) {
  const timeFormat = 'HH:mm:ss';
  const firstDate = moment(date1).format(timeFormat);
  const secondDate = moment(date2).format(timeFormat);
  return moment(secondDate, timeFormat).diff(moment(firstDate, timeFormat));
}

export function roundUpMinutes(date) {
  const roundedMinutes = Math.round(date.getMinutes() / 15) * 15;
  const newDate = (new Date(date));
  newDate.setMinutes(roundedMinutes);
  newDate.setSeconds(0);
  return newDate;
}

export function adjustDates(startAt, endAt) {
  const roundedStart = roundUpMinutes(new Date(startAt));
  let roundedEnd = roundUpMinutes(new Date(endAt));

  // Проверка, чтобы убедиться, что разница между округленными значениями не менее 15 минут
  const diffInMinutes = Math.abs(Math.floor((roundedEnd.getTime() - roundedStart.getTime()) / (1000 * 60)));
  if (diffInMinutes < 15) {
    roundedEnd = new Date(roundedStart);
    roundedEnd.setMinutes(roundedEnd.getMinutes() + 15);
  }

  return {
    start_date: roundedStart,
    end_date: roundedEnd,
  }
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}