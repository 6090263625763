import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import palette from "../../palette";

type Props = {
  type?: string;
  width?: string;
  height?: string;
  value?: string;
  style?: object;
  placeholder?: string;
  disabled?: boolean;
  register?: any;
  name?: string;
  inputChangeHandler?: (value: string) => void;
};

// const styleBlue = css`
//   background: ${palette.blueLighter};
//   border: 1px solid ${palette.blueLighter};
//   input,
//   textarea {
//     background: ${palette.blueLighter};
//   }
//   &:hover {
//     border: 1px solid ${palette.blueMain};
//   }
//   &:focus-within {
//     background: ${palette.white};
//     border: 1px solid ${palette.blueMain};

//     input,
//     textarea {
//       background: ${palette.white};
//     }
//   }
// `;

const StyledInput = styled.input`
  position: relative;
  background: ${palette.white};
  border: 1px solid ${palette.lightGray};
  box-sizing: border-box;
  border-radius: 6px;
  //max-height: 2.5rem;
  height: ${({ height }) => (height ? height : "100%")};
  width: ${({ width }) => width};
  padding: 0.75rem;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${palette.textBlack};
  &[type="number"] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const StyledTextarea = styled.textarea`
  background: ${palette.white};
  border: 1px solid ${palette.lightGray};
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 6px;
  height: 2.5rem;
  min-height: ${({ placeholder }) => placeholder === "Описание" && "72px"};
  width: ${({ width }) => width};
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${palette.textBlack};
  max-height: 300px;
  width: 100%;
  overflow: auto;
  display: block;
  padding: 0.75rem;
  resize: none;
  &:not(:empty) {
    background-color: ${palette.blueLight};
    border-color: ${palette.ultraBlueLighter};
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const CustomInput: React.FC<Props> = ({
  type = "text",
  width = "100%",
  height = "100%",
  disabled,
  value,
  style,
  placeholder,
  register,
  name,
  inputChangeHandler = () => {},
}) => {
  // const [heightInput, setHeightInput] = useState();

  const inputRef = useRef<HTMLInputElement>();

  const onChange = useCallback(
    (e: React.ChangeEvent) => {
      const eTarget = e.currentTarget as HTMLInputElement;
      inputChangeHandler(eTarget.value);
      eTarget.style.height = "1px";
      eTarget.style.height = eTarget.scrollHeight + "px";
    },
    [inputChangeHandler]
  );

  const onInput = useCallback(
    (e: React.ChangeEvent) => {
      const eTarget = e.currentTarget as HTMLInputElement;
      inputChangeHandler(eTarget.value);
    },
    [inputChangeHandler]
  );

  // useEffect(() => {
  //   if (inputRef.current) {
  //     inputRef.current.style.height = inputRef.current.scrollHeight + 'px';
  //   }
  // }, []);

  return type === "textarea" ? (
    <StyledTextarea
      // ref={inputRef}
      ref={register}
      name={name}
      value={value}
      style={{ ...style }}
      placeholder={placeholder}
      onChange={onChange}
      disabled={disabled}
    />
  ) : (
    <StyledInput
      // ref={inputRef}
      ref={register}
      name={name}
      value={value}
      type={type}
      placeholder={placeholder}
      width={width}
      height={height}
      onChange={onInput}
      disabled={disabled}
      style={{ ...style }}
    />
  );
};

export default CustomInput;
