// Заголовки для списка совещаний
// В этом файле также осуществляется сортировка по колонкам совещаний.

import React, {  useState,   useCallback,  useEffect, useLayoutEffect,  useRef,} from "react";
import styled from "styled-components";
import SortIcon from "../../../common/components/SortIcon";


type Props = {
  Clicked: {
    col: string;
    count: number;
}
,setClicked:({col: string,
    count: number}) => void;    
}

const WrapperTasksTableHead = styled.div`
  /* max-width: 1280px; */
  overflow: hidden;
  width: 100%;
  padding: 0px 5px 0px 40px;
  margin-right: ${({ isScroll }) => isScroll && "8px"};
  display: flex;
  height: 69px;
  justify-content: space-between;
`;

const BlockStyled = styled.div`
  display: flex;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #a8a8a8;
  align-items: center;

  svg {
    width: 10px;
    height: 10px;
  }
`;

const HeaderItemName = styled.div`
  cursor: pointer;
  padding-left: 4px;
  & > span {
    position: relative;
  }
`;


// Завголовки колонок
const columnsOfTables = [
  {
    columnId: "id",
    showingText: "",
    style: { minWidth: "0px", textAlign: "right" },
  },
  {
    columnId: "name",
    showingText: "Наименование",
    style: { minWidth: "430px", maxWidth: "500px", paddingLeft: "105px" },
  },
  {
    columnId: "members",
    showingText: "Участники",
    style: { minWidth: "180px", textAlign: "left",  paddingLeft:"10px"  },
  },
  {
    columnId: "start_at",
    showingText: "Дата",
    style: { minWidth: "150px", textAlign: "center"},
  },
  {
    columnId: "long",
    showingText: "",
    style: { minWidth: "0px", textAlign: "center", paddingRight:"10px" },
  },
  {/*
    columnId: "project",
    showingText: "Проект",
    style: { minWidth: "200px", textAlign: "center", paddingRight:"10px" },
  */},
 {
    columnId: "format",
    showingText: "Формат совещания",
    style: { minWidth: "200px", textAlign: "center", paddingRight:"10px" },
  },
  {
    columnId: "location",
    showingText: "Место совещания",
    style: { minWidth: "350px", marginRight:"10px", paddingLeft: "51px" },
  },
  {
    columnId: "endBlock",
    showingText: "",
    style: { minWidth: "0px" },
  },
];


const MeetingTableHeader: React.FC<Props> = ({Clicked,setClicked}) => {



// Тут будет делаться запрос к backend для изменения сортировки совещаний
 const setOrderMeetings = (сlicked:string,boolean:boolean) => {

  console.log("clicked",Clicked)
//************************************************************* */
}

// Для передачи на back для поля name
const clickedName = (key) => {
  if  (Clicked.count == 2) {
    setClicked({
      col: "id",
      count: 1,
    });
  } else {
    setClicked({
      col: columnsOfTables[key].columnId,
      count:
        Clicked.col === columnsOfTables[key].columnId
          ? Clicked.count === 2
            ? 1
            : Clicked.count + 1
          : 1,
    });

  }
   
}

// Для проставление стрелочек по полю name
const activeSortName = (key) => {
    return    Clicked.col === columnsOfTables[key].columnId &&
                      Clicked.count === 1
                        ? "top"
                        : "bottom"
}

// Для передачи на back для остальных полей кроме name
const clickedAllFields = (key) => {
    if(columnsOfTables[key].columnId !== "members" ) {

      if  (Clicked.count == 2) {
        setClicked({
          col: "id",
          count: 1,
        });
      } else {

        setClicked({
          col: columnsOfTables[key].columnId,
          count:
            Clicked.col === columnsOfTables[key].columnId
              ? Clicked.count === 2
                ? 1
                : Clicked.count + 1
              : 1,
        }); 
             }    
      }
  }
 
  // Для проставление стрелочек по остальным полям кроме name
  const activeSortAllFields = (key) => {

    return   Clicked.col === columnsOfTables[key].columnId &&
    Clicked.count === 1
      ? "top"
      : "bottom"
  }

  return (
    <WrapperTasksTableHead >
      <BlockStyled>
        {Object.keys(columnsOfTables).map((key) => {
          return (
            (columnsOfTables[key].columnId === "name" 
            ) && (
              <HeaderItemName
                key={key}
                style={columnsOfTables[key].style}
                onClick={() => clickedName(key)}
              >
                <span>
                  {columnsOfTables[key].showingText}
                  <SortIcon
                    active={Clicked.col === columnsOfTables[key].columnId}
                    activeSort={activeSortName(key)}
                  />
                </span>
              </HeaderItemName>
            )
          );
        })}
      </BlockStyled>

      <BlockStyled  style={{ marginRight: "45px" }}>
        {Object.keys(columnsOfTables).map((key) => {
          return (
            (columnsOfTables[key].columnId === "members" ||
            columnsOfTables[key].columnId === "start_at" || 
            columnsOfTables[key].columnId === "long" || 
            columnsOfTables[key].columnId === "project" ||
            columnsOfTables[key].columnId === "format" ||
            columnsOfTables[key].columnId === "location" ||
            columnsOfTables[key].columnId === "endBlock"
            ) && (
              <HeaderItemName
                key={key}
                style={columnsOfTables[key].style}
                onClick={() => {clickedAllFields(key)}
              }
              >
                <span>
                  {columnsOfTables[key].showingText}
                  <SortIcon
                    active={Clicked.col === columnsOfTables[key].columnId}
                    activeSort={activeSortAllFields(key)}
                  />
                </span>
              </HeaderItemName>
            )
          );
        })}
      </BlockStyled>


    </WrapperTasksTableHead>
  );
};


export default MeetingTableHeader;
