import React from "react";

const bsFilter2 = ({ props }) => {
  return (
    <svg
      {...props}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 15.625C15.9518 15.625 18.75 12.8268 18.75 9.375C18.75 5.92322 15.9518 3.125 12.5 3.125C9.04822 3.125 6.25 5.92322 6.25 9.375C6.25 12.8268 9.04822 15.625 12.5 15.625Z"
        stroke="#292A34"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M3.02539 21.0928C3.9859 19.4303 5.367 18.0498 7.02996 17.09C8.69292 16.1302 10.5792 15.625 12.4992 15.625C14.4193 15.625 16.3055 16.1303 17.9685 17.0901C19.6314 18.0499 21.0125 19.4305 21.973 21.093"
        stroke="#292A34"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default bsFilter2;
