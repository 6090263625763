import React from "react";
import "../BpResultForm.scss";
const NameOfStep = ({ text }) => {
  return (
    <div>
      <label className="p__drop-content">
        <img
          src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Article.svg`}
          alt="Article"
        />
        {text + " *"}
      </label>
    </div>
  );
};

export default NameOfStep;
