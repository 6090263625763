import axios from "axios";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { nanoid } from "nanoid";
import { StatusContext } from "../../../context/status";
import AddFileButton from "../templates/AddFileButton";
import NameOfStep from "../templates/NameOfStep";
import SelectTemplate from "../templates/SelectTemplate";
import "../BpResultForm.scss";

let fileTranslate;

const EmployeeRegistration = () => {
  const {
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,
    taskCurrentGlobal,
    setchangeTask,
    company_id,
    setloadDataNow,
  } = useContext(StatusContext);

  console.log(settingsOfFields);
  const [data, setData] = useState(
    settingsOfFields?.employeeRegistration?.extra
      ? settingsOfFields.employeeRegistration.extra
      : {}
  );

  const [allFilesEmployeeRegistration1, setAllFilesEmployeeRegistration1] =
    useState([]);
  const [allFilesEmployeeRegistration2, setAllFilesEmployeeRegistration2] =
    useState([]);

  function translateFile(file) {
    let reader = new FileReader();

    reader.onload = function () {
      let result = [...new Uint8Array(reader.result)]
        .map((item) => item.toString(16).padStart(2, "0"))
        .join("");
      fileTranslate = result;
      return result;
    };
    reader.readAsArrayBuffer(file);
  }

  // console.log("taskCurrentGlobal", taskCurrentGlobal);

  console.log(allFilesEmployeeRegistration1);

  const url = taskId ? `${apiBp}/task/${taskId}` : null;

  //Добавляет файлы
  const onSendFileEmployeeRegistration = (
    e,
    numberFilesEmployeeRegistration
  ) => {
    let file = e.target.files[0];
    translateFile(file);
    setTimeout(() => {
      if (numberFilesEmployeeRegistration === "filesEmployeeRegistration1") {
        setAllFilesEmployeeRegistration1([
          ...allFilesEmployeeRegistration1,
          {
            data: fileTranslate,
            id: nanoid(),
            name: file.name.match(/(.*)\.[^.]+$/)[1],
            ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
            tag: "filesEmployeeRegistration1",
          },
        ]);
      } else if (
        numberFilesEmployeeRegistration === "filesEmployeeRegistration2"
      ) {
        setAllFilesEmployeeRegistration2([
          ...allFilesEmployeeRegistration2,
          {
            data: fileTranslate,
            id: nanoid(),
            name: file.name.match(/(.*)\.[^.]+$/)[1],
            ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
            tag: "filesEmployeeRegistration2",
          },
        ]);
      }
    }, [500]);
  };

  //Удаляет файлы
  const onDeletedFileEmployeeRegistration = (
    keyId,
    numberFilesEmployeeRegistration
  ) => {
    if (numberFilesEmployeeRegistration === "filesEmployeeRegistration1") {
      setAllFilesEmployeeRegistration1(
        allFilesEmployeeRegistration1.filter((file) => file.id !== keyId)
      );
    } else if (
      numberFilesEmployeeRegistration === "filesEmployeeRegistration2"
    ) {
      setAllFilesEmployeeRegistration2(
        allFilesEmployeeRegistration2.filter((file) => file.id !== keyId)
      );
    }
  };

  //Смотрит на какую кнопку какого рездела с файлами было нажато
  const onClickFetchFilesEmployeeRegistration = (
    e,
    numberFilesEmployeeRegistration
  ) => {
    e.preventDefault();

    console.log(numberFilesEmployeeRegistration);

    let data;

    if (numberFilesEmployeeRegistration === "filesEmployeeRegistration1") {
      data = JSON.stringify({
        files: allFilesEmployeeRegistration1,
      });
      console.log(
        "Нажал на кнопку для отправки файлов filesEmployeeRegistration1"
      );
    }

    if (numberFilesEmployeeRegistration === "filesEmployeeRegistration2") {
      data = JSON.stringify({
        files: allFilesEmployeeRegistration2,
      });
      console.log(
        "Нажал на кнопку для отправки файлов filesEmployeeRegistration2"
      );
    }

    let config = {
      method: "patch",
      url: `${apiBp}/task/${taskId}`,
      headers: {
        Authorization: bearer,
        "company-id": company_id,
        "secret-token": document.cookie.replace(
          /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        ),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setchangeTask((prev) => prev + 1);
        console.log("Запрос отправлен");
      })
      .catch((e) => {
        console.log(e);
      });

    if (numberFilesEmployeeRegistration === "filesEmployeeRegistration1") {
      setAllFilesEmployeeRegistration1([]);
    }

    if (numberFilesEmployeeRegistration === "filesEmployeeRegistration2") {
      setAllFilesEmployeeRegistration2([]);
    }
  };

  // console.log(taskId);
  useEffect(() => {
    console.log("use in form worked");
    if (taskId) {
      axios
        .patch(
          url,
          { ...settingsOfFields.employeeRegistration, extra: data },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))
        // .then(() => setInputValue())

        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);
  return (
    <form id="employeeRegistration">
      <div>
        <NameOfStep text={"Прикрепление документов"} />
        <div className="file-uploaded__block">
          <ul>
            {taskCurrentGlobal &&
              taskCurrentGlobal["files"] &&
              taskCurrentGlobal["files"]["list"]
                .filter((item) => item?.tag == "filesEmployeeRegistration1")
                .map((item2) => (
                  <li>
                    <a
                      href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                      target="_blank"
                    >
                      {item2.name}
                    </a>
                  </li>
                ))}
          </ul>
        </div>
        <AddFileButton
          filesList={taskCurrentGlobal}
          data={data}
          setData={setData} //если да
          id={"j10"}
          handlerOnSendFile={onSendFileEmployeeRegistration}
          handlerOnDeletedFile={onDeletedFileEmployeeRegistration}
          handlerOnClickFetchFiles={onClickFetchFilesEmployeeRegistration}
          allFiles={allFilesEmployeeRegistration1}
          numberFiles={"filesEmployeeRegistration1"}
        />
      </div>
      <div>
        <NameOfStep text={"Подтверждение официального оклада"} />
        <SelectTemplate
          listArr={["Подтверждает", "Не подтверждает"]}
          name={"j10_1"}
          data={data}
          setData={setData}
        />
      </div>
    </form>
  );
};

export default EmployeeRegistration;
