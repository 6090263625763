export default function (scheduler) {
  scheduler.$keyboardNavigation.MinicalCell = function (div, row, col) {
    this.container = div;
    this.row = row || 0;
    this.col = col || 0;
  };
  scheduler.$keyboardNavigation.MinicalCell.prototype = scheduler._compose(scheduler.$keyboardNavigation.KeyNavNode, {
    isValid: function isValid() {
      var grid = this._getGrid();
      return !!(grid[this.row] && grid[this.row][this.col]);
    },
    fallback: function fallback() {
      var row = this.row;
      var col = this.col;
      var grid = this._getGrid();
      if (!grid[row]) {
        row = 0;
      }
      var dir = true;
      if (row > grid.length / 2) {
        dir = false;
      }
      if (!grid[row]) {
        var defaultSlot = new scheduler.$keyboardNavigation.TimeSlot();
        if (defaultSlot.isValid()) {
          return defaultSlot;
        } else {
          return new scheduler.$keyboardNavigation.DataArea();
        }
      }
      if (dir) {
        for (var c = col; grid[row] && c < grid[row].length; c++) {
          if (!grid[row][c] && c == grid[row].length - 1) {
            row++;
            col = 0;
          }
          if (grid[row][c]) {
            return new scheduler.$keyboardNavigation.MinicalCell(this.container, row, c);
          }
        }
      } else {
        for (var c = col; grid[row] && c < grid[row].length; c--) {
          if (!grid[row][c] && !c) {
            row--;
            col = grid[row].length - 1;
          }
          if (grid[row][c]) {
            return new scheduler.$keyboardNavigation.MinicalCell(this.container, row, c);
          }
        }
      }
      return new scheduler.$keyboardNavigation.MinicalButton(this.container, 0);
    },
    focus: function focus() {
      scheduler.$keyboardNavigation.dispatcher.globalNode.disable();
      this.container.removeAttribute("tabindex");
      scheduler.$keyboardNavigation.KeyNavNode.prototype.focus.apply(this);
    },
    blur: function blur() {
      this.container.setAttribute("tabindex", "0");
      scheduler.$keyboardNavigation.KeyNavNode.prototype.blur.apply(this);
    },
    _getNode: function _getNode(row, col) {
      return this.container.querySelector(".dhx_year_body tr:nth-child(" + (row + 1) + ") td:nth-child(" + (col + 1) + ")");
    },
    getNode: function getNode() {
      return this._getNode(this.row, this.col);
    },
    _getGrid: function _getGrid() {
      var rows = this.container.querySelectorAll(".dhx_year_body tr");
      var grid = [];
      for (var i = 0; i < rows.length; i++) {
        grid[i] = [];
        var row = rows[i];
        var cells = row.querySelectorAll("td");
        for (var c = 0; c < cells.length; c++) {
          var cell = cells[c];
          var enabled = true;
          var css = scheduler._getClassName(cell);
          if (css.indexOf("dhx_after") > -1 || css.indexOf("dhx_before") > -1 || css.indexOf("dhx_scale_ignore") > -1) {
            enabled = false;
          }
          grid[i][c] = enabled;
        }
      }
      return grid;
    },
    keys: {
      "right": function right(e) {
        var grid = this._getGrid();
        var newRow = this.row;
        var newCol = this.col + 1;
        if (!grid[newRow] || !grid[newRow][newCol]) {
          if (grid[newRow + 1]) {
            newRow = newRow + 1;
            newCol = 0;
          } else {
            newCol = this.col;
          }
        }
        var next = new scheduler.$keyboardNavigation.MinicalCell(this.container, newRow, newCol);
        if (!next.isValid()) {
          next = next.fallback();
        }
        this.moveTo(next);
      },
      "left": function left(e) {
        var grid = this._getGrid();
        var newRow = this.row;
        var newCol = this.col - 1;
        if (!grid[newRow] || !grid[newRow][newCol]) {
          if (grid[newRow - 1]) {
            newRow = newRow - 1;
            newCol = grid[newRow].length - 1;
          } else {
            newCol = this.col;
          }
        }
        var next = new scheduler.$keyboardNavigation.MinicalCell(this.container, newRow, newCol);
        if (!next.isValid()) {
          next = next.fallback();
        }
        this.moveTo(next);
      },
      "down": function down() {
        var grid = this._getGrid();
        var newRow = this.row + 1;
        var newCol = this.col;
        if (!grid[newRow] || !grid[newRow][newCol]) {
          newRow = this.row;
        }
        var next = new scheduler.$keyboardNavigation.MinicalCell(this.container, newRow, newCol);
        if (!next.isValid()) {
          next = next.fallback();
        }
        this.moveTo(next);
      },
      "up": function up() {
        var grid = this._getGrid();
        var newRow = this.row - 1;
        var newCol = this.col;
        if (!grid[newRow] || !grid[newRow][newCol]) {
          var index = 0;
          if (this.col > grid[this.row].length / 2) {
            index = 1;
          }
          this.moveTo(new scheduler.$keyboardNavigation.MinicalButton(this.container, index));
        } else {
          var next = new scheduler.$keyboardNavigation.MinicalCell(this.container, newRow, newCol);
          if (!next.isValid()) {
            next = next.fallback();
          }
          this.moveTo(next);
        }
      },
      "enter": function enter(e) {
        this.getNode().querySelector(".dhx_month_head").click();
      }
    }
  });
  scheduler.$keyboardNavigation.MinicalCell.prototype.bindAll(scheduler.$keyboardNavigation.MinicalCell.prototype.keys);
}