import React, { useState } from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import ruLocale from "date-fns/locale/ru";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker } from "@material-ui/pickers";
import DateMaster from "../../../utils/DateMaster";

type Props = {
  date: Date | undefined;
  minDate?: Date;
  maxDate?: Date;
  acceptHandler: (isoDate: string) => void;
  placeholder?: string;
  styles?: Object;
};

const DateComponent = ({ date, setOpen, styles }) => {
  const dateMaster = date ? new DateMaster(date) : new Date();
  return (
    <div onClick={() => setOpen(true)} className="date_time" style={styles}>
      <div className="date">{date && dateMaster.getDate()}</div>
    </div>
  );
};

const CustomDatePicker: React.FC<Props> = ({
  date,
  placeholder,
  acceptHandler,
  minDate,
  maxDate,
  styles,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  let today = new Date();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
      <DatePicker
        invalidDateMessage="Введите дату пожалуйста!"
        invalidLabel="Введите дату пожалуйста!"
        onClick={(e) => {
        }}
        allowKeyboardControl
        placeholder={placeholder as string}
        value={date}
        open={isOpen}
        onClose={() => setOpen(false)}
        cancelLabel="Отменить"
        showTodayButton={
          // Если дата окончания задана и дата окончания < сегодняшнего числа- кнопку "Сейчас" для выбора даты начала не показываем
          maxDate !== undefined
            ? maxDate < today
              ? false
              : true
            : // Если дата начала задана и дата начала > сегодняшнего числа- кнопку "Сейчас" для выбора даты окончания не показываем
            minDate !== undefined
            ? minDate > today
              ? false
              : true
            : true
        }
        todayLabel="Сейчас"
        onChange={() => {}}
        minDate={minDate ? minDate : new Date("1900-01-01")}
        maxDate={maxDate ? maxDate : new Date("2100-01-01")}
        strictCompareDates={true}
        onAccept={(date) => {
          if (date) acceptHandler(date.toISOString());
        }}
        TextFieldComponent={() => (
          <DateComponent setOpen={setOpen} date={date} styles={styles} />
        )}
      />
    </MuiPickersUtilsProvider>
  );
};

export default CustomDatePicker;
