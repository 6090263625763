import {types, MeetingsAction} from './action_types';
import {Task, Project, Meeting} from '../../common/types';
import {Reducer} from 'redux';
import {columnsOfTables} from '../../common/shared_data';
import {begin} from "../TaskInfoPlate/reducer";
import {getCurrentUserInfo} from "../../common/selectors";
import {store} from "../../App";
// import {getCurrentUserInfo} from "../../common/";


export const initialState = {
    // selectedMeeting: 0,

    meetings: [] as Meeting[],
    selectedMeetingId: null as number | null,

    name: '',
    description: '',
    projectId: null as number | null,
    chairman_id: 0,
    secretary_id: 0,
    author_id: 0,
    participants: [],
    begin: begin,
    checkList: [],
    meetingRules: [],
    meetingTasks: [],
    meetingIdeas: [],
    newRule:
        {
            agenda_item_id: null as number | null,
            name: '',
            begin: '',
            end: '',
            description: '',
            executors: [] as number[]
        },
    newTask:
        {
            agenda_item_id: null as number | null,
            author_id: null as number | null,
            name: '',
            description: '',
            executors: [] as number[],
            responsible_id: null as number | null,
            done: 0,
        },
    newIdea:
        {
            agenda_item_id: null as number | null,
            author_id: null as number | null,
            name: '',
            description: '',
            review_data: ''
        },

    changedChecklistItems: [],
    toDeleteChecklistItems: [],
    toCreateChecklistItems: [],

    protocol: null,
    introduces: [],
    audio_file_id: null,
    projects: [],
    status: '',

    isSendingData: false,
    showMeetingRightPlateAnimation: false,
    showRightPlate: false,

    selectedProject: null as Project | null,
    projectAddMode: false,
    filtersWindowShow: false,
    isFetchingTasks: true,
    tableFilter: '',
    // filters: {
    //     filterParametersList: [
    //         {text: 'Статус', value: 1},
    //     ],
    //     // appliedFilters: {1: [2, 4]},
    //     appliedFilters: {},
    //     draft: 0,
    //     addNewRow: true,
    //     exec_auth: '',
    // },
    showColumnsWindow: false,
    businessProcessesModalShow: false,
    tasksOrder: {col_name: 'end', order_direct: true},

    tableOrTreeToggler: localStorage.getItem('project_show_toggle')
        ? (localStorage.getItem('project_show_toggle') as string)
        : 'table',
};

export type MeetingsState = typeof initialState;

const reducer: Reducer<MeetingsState, MeetingsAction> = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case types.WRITE_MEETINGS:
            // console.log(action)
            return {
                ...state,
                meetings: action.meetings,
            };

        case types.SET_NEW_PARAMETERS_TO_REDUCER:
            // console.log(action.data)
            return {
                ...state,
                ...action.data,
            };

        case types.SET_DEFAULT_TO_REDUCER:
            return {
                ...state,
                ...initialState,
                meetings: state.meetings
            };

        case types.MEETING_PLATE_HIDING_SIGNAL:
            return {
                ...state,
                showMeetingRightPlateAnimation: action.value
            };

        default:
            return state;
    }
};

export default reducer;
