import React from 'react';
import { setUserEmail } from '../actions';
import withTitle from '../../../utils/hoc/withTitle';
import { connect } from 'react-redux';
import Input from '../../../common/components/Inputs/Input';
import { State } from '../../../rootReducer';
import { patterns } from '../../../utils/validPatterns';
import { User } from '../../../common/types';
import NameWithInput from '../../../common/components/newComponent/Inputs/NameWithInput';

type Props = {
  email: string;
  userId: number;
  currentUserInfo: User | null;
  setUserEmail?: (name: string) => void;
  isAddUserInCompany?: boolean;
};
//Почту можно менять для новых пользователей, и если у пользователя есть роль

const UserEmail: React.FC<Props> = ({
  email,
  userId,
  currentUserInfo,
  setUserEmail = () => {},
  isAddUserInCompany,
}) => {
  return (
    <NameWithInput title="Email" iconName="email" necessarily>
      <Input
        disabled={
          isAddUserInCompany || userId !== 0
          // || currentUserInfo?.roles.includes(1) ? false : true
        }
        inputType="email"
        placeholder=""
        value={email}
        title=""
        changeHandler={(name) => setUserEmail(name)}
        // isValid={!!email && patterns.email.test(email)}
        // validText={
        //   !email
        //     ? 'Email является обязательным полем'
        //     : !patterns.email.test(email)
        //     ? 'Некорректный Email'
        //     : ''
        // }
      />
    </NameWithInput>
  );
};

const mapStateToProps = (state: State) => {
  return {
    userId: state.newUser.id,
    email: state.newUser.email,
    currentUserInfo: state.commonInfo.currentUserInfo,
  };
};

const mapDispatchToProps = {
  setUserEmail: setUserEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEmail);
