import React, { useCallback, useState } from "react";
import styled from "styled-components";
import Button from "../../../../../../common/components/newComponent/Buttons/Button";
import NameWithInput from "../../../../../../common/components/newComponent/Inputs/NameWithInput";
import CustomInput from "../../../../../../common/components/newComponent/Inputs/CustomInput";
import axios from "axios";
import { cookieMaster } from "../../../../../../utils/CookieMaster";

interface Props {
  // idForQuestion: number;
  openAddQuestion: boolean;
  setOpenAddQuestion: (mode: boolean) => void;
  addHandler: () => void;
  changeQuestion?: boolean;
  setChangeQuestion?(v: boolean): void;
  valueQuestions: string;
  setValueQuestions: (mode: string) => void;
}

const ModalAddQuetion = styled.div`
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 0px 22px -8px rgba(34, 60, 80, 0.1);
  margin-top: 20px 0;
  transition: transform 0.3s ease;
  transform: ${({ openAddQuestion }) =>
    openAddQuestion ? "translateY(0vh)" : "translateY(-100vh)"};
  max-width: 530px;
  width: 100%;
  padding: 1.5rem 1rem;
  max-height: 100vh;
  overflow-y: auto;
  position: fixed;
  z-index: 2;
  left: calc((100% - 350px) / 2);
  top: 30%;
`;

const WrapperButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.25rem;
  align-items: center;
  transition: transform 0.3s ease;
  transform: ${({ openAddQuestion }) =>
    openAddQuestion ? "translateY(0vh)" : "translateY(-100vh)"};
  & > div {
    flex: 1;
  }
`;

const WrapperBlockSections = styled.div`
  max-width: 500px;
  width: 100%;

  li {
    display: flex;
    justify-content: space-between;
    min-height: 24px;
    line-height: 24px;
    margin: 0.5rem 0;
  }
`;

const WrapperBlockInput = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CreateQuestion: React.FC<Props> = ({
  // idForQuestion,
  openAddQuestion,
  setOpenAddQuestion,
  addHandler,
  valueQuestions,
  setValueQuestions,
}) => {
  const closeHandler = useCallback(() => {
    setOpenAddQuestion(false);
  }, []);

  return (
    <ModalAddQuetion openAddQuestion={openAddQuestion}>
      <NameWithInput title="Название повестки" withoutIcon={true}>
        <WrapperBlockSections className="project_sections">
          <WrapperBlockInput>
            <CustomInput
              value={valueQuestions}
              inputChangeHandler={setValueQuestions}
            />
            <Button
              onClick={addHandler}
              title="Сохранить"
              design="outline"
              style={{ marginLeft: ".5rem", alignItems: "center" }}
            />
          </WrapperBlockInput>
        </WrapperBlockSections>{" "}
      </NameWithInput>
      <WrapperButtons openAddQuestion={openAddQuestion}>
        <Button
          onClick={closeHandler}
          design="secondary"
          title="Отмена"
          style={{ marginRight: ".5rem" }}
        />
      </WrapperButtons>
    </ModalAddQuetion>
  );
};

export default CreateQuestion;
