import React, {
    useState,
    useEffect,
    useMemo,
    useCallback,
    useRef,
} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
// import {Task, User, Comment, Schedule} from '../../common/types';
import Toggler, {ToggleElem} from '../../../common/components/Togglers/Toggler';
// import InfoTab from './components/InfoTab';
// import CommentTab from './components/CommentTab';
import {State} from '../../../rootReducer';
// import {fetchData} from '../../utils/fetchData';
import '../styles.sass';
// import {updateTask} from '../../common/actions';
// import TaskStatus from '../../common/components/TaskStatus/TaskStatus';
// import DateMaster from '../../utils/DateMaster';
import {TextareaAutosize} from '@material-ui/core';
import styled from 'styled-components';
// import Icon from '../../common/components/Icon';
import {useHistory} from 'react-router-dom';
import {Meeting, Status} from "../../../common/types";
import Icon from "../../../common/components/Icon";
import InfoTab from "./InfoTab";
import CommentTab from "./CommentTab";
import {fetchData} from "../../../utils/fetchData";
import {fetchUpdateData} from "../../../utils/fetchUpdateData";
import {meetingPlateHidingSignal, setMeetingDefaultValues, setNewParametersToReducer} from "../actions";
import {initialState} from "../reducer";
import TaskStatus from '../../../common/components/TaskStatus/TaskStatus';
import {CreateNotif} from "../../../utils/createNotification";
// import {fetchUpdateData} from '../../utils/fetchUpdateData';
// import Button from '../../common/components/newComponent/Buttons/Button';
// import ContextDropdownMenu from './components/ContextDropdownMenu';
// import {fetchTaskDependence, setFilterWindowShow} from '../Tasks/actions';

type Props = {
    isShownTaskInfoPlate: boolean;
    // selectedTask: Task | null;
    checkList: any[];
    taskInfoActiveTab: string;
    selectedUserId: number;
    taskName: string;
    // users: User[];
    commentList: Comment[];
    setShownTaskInfoPlate: (isShownTaskInfoPlate: boolean) => void;
    setTaskInfoActiveTab: (activeTab: string) => void;
    // setProjectUsers: (users: User[]) => void;
    setComentList: (commentList: Comment[]) => void;
    updateTask: (id: number, params: any) => void;
    // setExecutorSchedule: (schedule: Schedule) => void;
    fetchProjectSections: (projectId: number) => void;
    // changeCheckboxItem: (orderNumber: string | null, text: string | null, checkboxState: boolean) => void,
    rewriteWholeTaskList: (response: any) => void;
    currentUserId: number | undefined;
    currentUserTopRole: number | undefined;
    // selectTask: (val: Task | null) => void;
    setTaskName: (name: string) => void;
    setDefaultValues: () => void;
    flagForActionCyclycTask: string;
    rememberObjectCyclicTaskToChange: {};
    sendReqToChangeTemplRepTask: (obj: {}) => void;
    setCyclicTaskToChange: (val: {}) => void;
    setFlagRepeatTaskToChange: (val: string) => void;
    clearCyclicTaskToChange: () => void;
    labelsData: {
        comments_count: number,
        files_count: number
    }
    setLabelsData: (comments_count?: number, files_count?: number) => void,

    name: string,
    status: string,

    selectedMeetingId: null | number,

    showMeetingRightPlate: boolean,
    meetingPlateHidingSignal: (val: boolean) => void,
    setNewParametersToReducer: (obj: object) => void,
    setMeetingDefaultValues: () => void,
    meetings: Meeting[]
};

export const status_list: Status[] = [
    {id: 1, name: 'Предстоит', slug: 'pending'},
    {id: 2, name: 'Завершено', slug: 'finished'},
    {id: 3, name: 'Отменено', slug: 'cancelled'}]

const togglerElems: ToggleElem[] = [
    {
        name: 'Информация',
        value: 'info',
    },
    {
        name: 'Комментарии',
        value: 'comments',
    },
    // ,{
    //     name: 'История',
    //     value: 'history'
    // }
];

const WrapperTaskInfoPlate = styled.div`
  position: fixed;
  top: 76px;
  right: ${({showMeetingRightPlate}) =>
    showMeetingRightPlate ? '0' : '-600px'};
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  background: #ffffff;
  transition: right 0.8s ease;
  z-index: 100;
  padding: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  color: #212121 !important;
  display: flex;
  width: 584px;
  height: calc(100vh - 76px);
`;


const MeetingRightPlate: React.FC<Props> = (props) => {
    const {
        setComentList,
        commentList,
        checkList,
        taskInfoActiveTab,
        flagForActionCyclycTask,
        setFlagRepeatTaskToChange,
        labelsData,
        selectedMeetingId,
        showMeetingRightPlate,
        meetingPlateHidingSignal,
        setNewParametersToReducer,
        setMeetingDefaultValues,
        meetings,
        name,
        status
    } = props;
    const {timestamp} = useSelector((state: State) => state.commonInfo);
    const {dependencesTask} = useSelector(
        (state: State) => state.taskInfoPlate
    );
    const templateChecklist = [...checkList];
    const templateСommentList = [...commentList];
    const [titleStyle, setTitleStyle] = useState({});

    // const [showContextDropDown, setShowContextDropDown] = useState(false);

    // self-close for window
    let plate = useRef<HTMLDivElement>(null);
    // useEffect(() => {
    //     if (selectedTask?.id && isShownTaskInfoPlate)
    //         dispatch(fetchTaskDependence(selectedTask.id));
    // }, [selectedTask?.id, isShownTaskInfoPlate]);
    //
    // const outsideClickHandlerDropdownMenu = useCallback(
    //     function (e: Event): any {
    //         let Element = e.target as Element;
    //         if (!Element.closest('.ThreeDotsDropdownMenu'))
    //             setShowContextDropDown(false);
    //     },
    //     [setFilterWindowShow]
    // );
    // useEffect(() => {
    //     if (showContextDropDown) {
    //         document.addEventListener('click', outsideClickHandlerDropdownMenu);
    //         return () =>
    //             document.removeEventListener('click', outsideClickHandlerDropdownMenu);
    //     }
    // }, [showContextDropDown]);
    // self-close for window

    const dispatch = useDispatch();
    let history = useHistory();
    useEffect(() => {
        // if (selectedTask) {
        if (selectedMeetingId) {
            setTitleStyle({
                background: 'white',
                borderColor: 'white',
                borderRadius: '6px',
                fontSize: '20px',
                color: '#212121',
                padding: '0',
                height: '50px',
            });
        } else {
            setTitleStyle({
                background: 'FFF',
                borderRadius: '6px',
                padding: '12px',
                height: '50px',
                color: '#212121',
            });
        }
    }, [selectedMeetingId]);

    // при открытии должен ходить запрос на получение чек-листа. Изначально задача чек-лист в себе не хранит.
    useEffect(() => {
        (async () => {
            if (flagForActionCyclycTask !== '') setFlagRepeatTaskToChange(''); // обнуляю определенное значение для подтверджения изменения повторящейся задачи.

            // if (selectedTask) setShownTaskInfoPlate(true);
            // if (selectedMeetingId) setShownTaskInfoPlate(true);
            const taskId = selectedMeetingId
            if (!taskId) return;
            // let checklistForTask = await fetchData.get(
            //     `/api/v1/tasks/${taskId}/checklists`
            // );
            // if (checklistForTask) {
            // когда слишком много кликаешь с задачи на задачу, то выпадает ошибка в снекбаре too many attempts - и сервер вместо нормального ответа присылает undefined. Чтобы все не крашнулось, есть это условие.
            //     checklistForTask = Object.values(checklistForTask);
            //     rewriteWholeTaskList(checklistForTask);
            // }
            // информация по лейблам о комментах и атачментах
            // const executor_id = selectedTask?.executor_id;
            // if (!executor_id) return;
            // const data = await fetchData.get(`/api/v1/tasks/${selectedTask?.id}/info`);
            // if (data) {
            //     setLabelsData(data.comments_count, data.files_count)
            // }

        })();
        // Do something
        // setTaskName(selectedTask?.name || '');
    }, [selectedMeetingId]);


    useEffect(() => {
        (async () => {
            const taskId = selectedMeetingId;
            if (!taskId) return;
            // const commentList = await fetchData.get(
            //     `/api/v1/tasks/${taskId}/comments?order=created_at&page=1&orderType=desc`
            // );
            // if (commentList) {
            //     setComentList(commentList);
            // }
        })();
    }, [setComentList]);

    useEffect(() => {
        // получение чек-листа
        (async () => {
            if (selectedMeetingId) {
                const checkList = await fetchData.get(
                    `/api/v1/meetings/${selectedMeetingId}/agenda-items`
                );
                if (checkList) {
                    // console.log(checkList)
                    setNewParametersToReducer({checkList: checkList})
                } else {
                    CreateNotif('Не удалось получить чек-лист', 'error')
                }


                const meetingRules = await fetchData.get(
                    `/api/v1/meetings/${selectedMeetingId}/meeting-rules`
                );
                if (meetingRules) {
                    // console.log(meetingRules)
                    setNewParametersToReducer({meetingRules: meetingRules})
                } else {
                    CreateNotif('Не удалось получить правила', 'error')
                }


                const meetingTasks = await fetchData.get(
                    `/api/v1/meetings/${selectedMeetingId}/meeting-tasks`
                );
                if (meetingTasks) {
                    // console.log(meetingTasks)
                    setNewParametersToReducer({meetingTasks: meetingTasks})
                } else {
                    CreateNotif('Не удалось получить задачи', 'error')
                }


                const meetingIdeas = await fetchData.get(
                    `/api/v1/meetings/${selectedMeetingId}/meeting-ideas`
                );
                if (meetingIdeas) {
                    // console.log(meetingIdeas)
                    setNewParametersToReducer({meetingIdeas: meetingIdeas})
                } else {
                    CreateNotif('Не удалось получить идеи', 'error')
                }
            }
        })();
    }, []);


    const selectedMeeting = meetings.find(item => item.id === selectedMeetingId)

    let status_id = useMemo(() => {
        if (selectedMeeting && status !== initialState.status) {
            return status_list.find(item => item.name === status)?.id as number
        } else {
            return status_list.find(item => item.name === selectedMeeting?.status)?.id as number
        }
    }, [status])

    return (
        <WrapperTaskInfoPlate
            showMeetingRightPlate={showMeetingRightPlate}
            className={`meeting_info_plate`}
        >
            <div className="meeting_name">
                <Icon
                    style={{marginBottom: '8px'}}
                    name="cross"
                    onClick={() => {
                        meetingPlateHidingSignal(false)
                        setTimeout(() => {
                            setNewParametersToReducer({showRightPlate: false})
                            setMeetingDefaultValues()
                        }, 800)

                        // setDefaultValues();
                        // setShownTaskInfoPlate(false);
                        // history.location.pathname.includes('tasks') &&
                        // history.replace(`${selectedUserId}`);
                    }}
                />

                {/*disabled={currentUserId === selectedTask.author_id ? '' : (currentUserTopRole === 1 ? '' : 'disabled')}*/}

                <TextareaAutosize
                    // id={'rightPlate_name_textarea'}
                    aria-label=""
                    placeholder="Введите название"
                    onChange={(e) => {
                        // setTaskName(e.currentTarget.value);
                        // console.log(e.target.value)
                        setNewParametersToReducer({name: e.target.value})
                    }}
                    className={
                        ''
                        // currentUserId === selectedTask?.author_id
                        //     ? ''
                        //     : currentUserTopRole === 1
                        //     ? ''
                        //     : 'disabled'
                    }
                    // style={titleStyle}
                    style={{minHeight: '40px'}}
                    // disabled={currentUserId === selectedTask.author_id ? false : (currentUserTopRole !== 1)}
                    onBlur={(e) => {
                        if (selectedMeetingId) {
                            // setEditDescMode(false)
                            // if (e.target.value !== selectedMeeting?.name) {
                            // если новое значение отличается от начального
                            // мгновенно обновляю значение для заколлапсированного состояния
                            // setRenewValueDesc(e.target.value)
                            // send value to Saga

                            // if ([0, null].indexOf(selectedTask.cyclic_task_id) === -1) {
                            //     // сравнение одного с несколькими
                            //     // эта задача вообще циклическая или сама по себе ?
                            //     // значит циклическая
                            //     // if (flagForActionCyclycTask === '') {
                            //     setCyclicTaskToChange({name: e.target.value}); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                            //     // } else if (flagForActionCyclycTask === 'task') {
                            //     //   updateTask(selectedTask!.id, { name: e.target.value });
                            //     // } else {
                            //     //   updateTask(selectedTask!.id, { name: e.target.value });
                            //     //   sendReqToChangeTemplRepTask({ name: e.target.value });
                            //     // }
                            // } else updateTask(selectedTask!.id, {name: e.target.value});
                            // }
                        } else {
                            // console.log('II');
                            // setTaskName(e.target.value);
                        }
                    }}
                    value={selectedMeetingId ? (name === initialState.name ? selectedMeeting?.name : name) : name}
                />
            </div>

            {selectedMeetingId && (
                <div className="tabs_desc">
                    <div className={'first_row'} style={{position: 'relative', justifyContent: 'flex-end'}}>

                        {selectedMeeting && (
                            <TaskStatus
                                id={status_id}
                                taskId={selectedMeeting.id}
                                passHigher={(arg) => {
                                    setNewParametersToReducer({status: status_list.find(item => item.id === arg)?.name})
                                }}
                                customStatusList={status_list}
                            />
                        )}


                        {/*<Button*/}
                        {/*    onClick={() => {*/}
                        {/*        setShowContextDropDown(!showContextDropDown);*/}
                        {/*    }}*/}
                        {/*    icon={'3dots'}*/}
                        {/*    design={'outline'}*/}
                        {/*/>*/}

                        {/*{showContextDropDown && <ContextDropdownMenu/>}*/}
                    </div>
                </div>
            )}

            {taskInfoActiveTab === 'info' && <InfoTab/>}
            {/*{taskInfoActiveTab === 'comments' && <CommentTab/>}*/}
        </WrapperTaskInfoPlate>
        // </WrapperTaskInfoPlate>
    );
};

const mapStateToProps = (state: State) => {
    return {
        currentUserId: state.commonInfo.currentUserInfo?.id,
        checkList: state.taskInfoPlate.checkList,
        selectedUserId: state.tasksPage.selectedUserId,
        currentUserTopRole: state.commonInfo.currentUserInfo?.roles[0],

        meetings: state.meetingsPage.meetings,
        selectedMeetingId: state.meetingsPage.selectedMeetingId,
        name: state.meetingsPage.name,
        status: state.meetingsPage.status,


        selectedTask: state.taskInfoPlate.selectedTask,
        taskName: state.taskInfoPlate.name,
        users: state.commonInfo.users,
        taskInfoActiveTab: state.taskInfoPlate.taskInfoActiveTab,
        showMeetingRightPlate: state.meetingsPage.showMeetingRightPlateAnimation,
        flagForActionCyclycTask: state.taskInfoPlate.flagForActionCyclycTask,
        rememberObjectCyclicTaskToChange:
        state.taskInfoPlate.rememberObjectCyclicTaskToChange,
        commentList: state.taskInfoPlate.commentList,
        labelsData: state.taskInfoPlate.labelsData
    };
};

const mapDispatchToProps = {
    // setShownTaskInfoPlate,
    // setTaskInfoActiveTab,
    // setProjectUsers,
    // setComentList,
    // updateTask,
    // setExecutorSchedule,
    // fetchProjectSections,
    // rewriteWholeTaskList,
    // selectTask,
    // setTaskName,
    // setDefaultValues,
    // sendReqToChangeTemplRepTask,
    // setCyclicTaskToChange,
    // setFlagRepeatTaskToChange,
    // clearCyclicTaskToChange,
    // setLabelsData
    meetingPlateHidingSignal,
    setNewParametersToReducer,
    setMeetingDefaultValues
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(MeetingRightPlate);
