import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ruLocale from "date-fns/locale/ru";

const TimeComponent = ({ label, setOpen, time }) => {
  return (
    <div className="time_picker">
      <div className="label">{label}</div>
      <div onClick={() => setOpen(true)} className="time">
        {time}
      </div>
    </div>
  );
};

type Props = {
  label: string;
  time: string;
  acceptHandler: (time: string) => void;
};

const TimePikerWithLable: React.FC<Props> = ({
  label,
  time,
  acceptHandler,
}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
      <TimePicker
        invalidDateMessage="Введите время пожалуйста!"
        invalidLabel="Введите время пожалуйста!"
        value={new Date("1970-1-1-" + time)}
        open={isOpen}
        onClose={() => setOpen(false)}
        cancelLabel="Отменить"
        showTodayButton={true}
        todayLabel="Сейчас"
        ampm={false}
        onChange={() => {}}
        onAccept={(date) => {
          if (date) {
            let hours = date.getHours(),
              minutes = date.getMinutes();
            acceptHandler(
              `${hours < 10 ? "0" + hours : hours}:${
                minutes < 10 ? "0" + minutes : minutes
              }:00`
            );
          }
        }}
        TextFieldComponent={() => (
          <TimeComponent label={label} setOpen={setOpen} time={time} />
        )}
      />
    </MuiPickersUtilsProvider>
  );
};

export default TimePikerWithLable;
