import React from "react";

const BsFilter52_active = ({ props }) => {
  return (
<svg
{...props}
width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="35" height="35" rx="3.5" fill="#436EA0" fill-opacity="0.1" stroke="#436EA0"/>
<path d="M25.0417 16.8952L14.9183 10.7085C14.8331 10.6564 14.7354 10.6279 14.6355 10.6261C14.5356 10.6242 14.437 10.649 14.3499 10.6979C14.2627 10.7467 14.1901 10.818 14.1396 10.9042C14.0891 10.9904 14.0625 11.0885 14.0625 11.1885V23.5619C14.0625 23.6618 14.0891 23.7599 14.1396 23.8462C14.1901 23.9324 14.2627 24.0036 14.3499 24.0525C14.437 24.1014 14.5356 24.1262 14.6355 24.1243C14.7354 24.1224 14.8331 24.094 14.9183 24.0418L25.0417 17.8551C25.1239 17.8049 25.1918 17.7344 25.239 17.6503C25.2861 17.5663 25.3109 17.4715 25.3109 17.3752C25.3109 17.2788 25.2861 17.1841 25.239 17.1C25.1918 17.016 25.1239 16.9455 25.0417 16.8952Z" stroke="#436EA0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  );
};

export default BsFilter52_active;
