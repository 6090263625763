import { Action } from 'redux';

export enum types {
  FETCH_TOKEN = 'FETCH_TOKEN',
  FETCH_CREATE_COMPANY = 'FETCH_CREATE_COMPANY',
}
export type DataCompany = {
  name: string;
  description?: string;
  address?: string;
  inn?: number;
  kpp?: number;
  ogrn?: number;
  info?: string;
  logo?:any;
};

export interface fetchTokenAction extends Action<types.FETCH_TOKEN> {
  email: string;
  password: string;
}
export interface fetchCreateCompanyAction
  extends Action<types.FETCH_CREATE_COMPANY> {
  dataCompany: DataCompany;
  employees: object[];
}
