import React, {
  useState,
  useRef,
  useLayoutEffect,
} from 'react';
import { connect } from 'react-redux';
import { State } from '../../../rootReducer';
import { fetchUsers, setCurrentPage, setTableFilter, setUsers } from '../actions';
import { setOrder } from '../actions';
import styled from 'styled-components';
import SortIcon from '../../../common/components/SortIcon';
type Props = {
  setOrder: any;
  isScroll: boolean;
  setCurrentPage: (pageNum: number) => void;
};
const HeaderStyle = styled.div`
  display: flex;
  height: 32px;

  ${({ isScroll }) => isScroll && `margin-right:8px;`}
  svg {
  }
`;
const UsersTableHead: React.FC<Props> = ({
  setOrder,
  isScroll,
  setCurrentPage,
}) => {
  const [Clicked, setClicked] = useState<any>({ col: 'surname', count: 1 });

  const firstUpdate = useRef(false);

  useLayoutEffect(() => {
    // при инициализации это не должно отрабатывать. для этого сделано условие с useRef
    if (firstUpdate.current) {
      setOrder(Clicked.col, Clicked.count === 1);
      return;
    }
    firstUpdate.current = true;
  }, [Clicked]);

  return (
    <HeaderStyle
      isScroll={isScroll}
    >
      <TableHeaderItem
        columnName="Сотрудник"
        columnId="surname"
        Clicked={Clicked}
        setClicked={(obj) => {
          setClicked(obj)
          setCurrentPage(1)
        }}
        style={{ width: '100%' }}
      />

      <TableHeaderItem
        columnName="Email"
        columnId="email"
        Clicked={Clicked}
        setClicked={(obj) => {
          setClicked(obj)
          setCurrentPage(1)
        }}
        style={{ maxWidth: '200px', minWidth: '200px' }}
      />

      <TableHeaderItem
        columnName="Должность"
        columnId="positions"
        Clicked={Clicked}
        setClicked={(obj) => {
          setClicked(obj)
          setCurrentPage(1)
        }}
        style={{ maxWidth: '200px', minWidth: '200px' }}
      />
       <TableHeaderItem
        columnName="Активность"
        columnId="is_active"
        Clicked={Clicked}
        setClicked={(obj) => {
          setClicked(obj)
          setCurrentPage(1)
        }}
        style={{ maxWidth: '200px', minWidth: '200px' }}
      />
    </HeaderStyle>
  );
};

const HeaderItemName = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;
const TableHeaderItem = ({
  columnName,
  columnId,
  Clicked,
  setClicked,
  style,
}) => {
  return (
    <HeaderItemName style={style}>
      <div
        onClick={() => {
          setClicked({
            col: columnId,
            count:
              Clicked.col === columnId
                ? Clicked.count === 2
                  ? 1
                  : Clicked.count + 1
                : 1,
          });
        }}
        style={{ display: 'flex', position: 'relative', cursor: 'pointer' }}
      >
        {columnName}
        <SortIcon
          active={Clicked.col === columnId}
          activeSort={
            Clicked.col === columnId && Clicked.count === 1 ? 'top' : 'bottom'
          }
        />
      </div>
    </HeaderItemName>
  );
};

const mapStateToProps = (state: State) => ({
  tableFilter: state.usersPage.tableFilter,
});

const mapDispatchToProps = {
  setTableFilter,
  setUsers,
  setOrder,
  setCurrentPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersTableHead);
