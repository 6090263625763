import React, { useEffect, useState } from 'react';
import { State } from '../../../rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import DependenceChild from './DependenciesList/DependenceChild';
import DependenceParent from './DependenciesList/DependenceParent';
import DependencePrev from './DependenciesList/DependencePrev';
import DependenceNext from './DependenciesList/DependenceNext';
import Button from '../../../common/components/newComponent/Buttons/Button';
import { settingDependencies } from '../actions';
import { fetchData } from '../../../utils/fetchData';

const Dependencies = () => {
  const [dependencesState, setDependencesState] = useState({
    parent: null,
    child: [],
    prev: null,
    next: null,
  });
  const dispatch = useDispatch();
  const { dependencesTask, child_tasks, parent_id, next_id, prev_id } =
    useSelector((state: State) => state.taskInfoPlate);
  useEffect(() => {
    child_tasks?.length
      ? fetchData
          .get(`/api/v1/tasks?task_id=${child_tasks}`)
          .then((tasks) =>
            setDependencesState({ ...dependencesState, child: tasks })
          )
      : setDependencesState({ ...dependencesState, child: [] });
  }, [child_tasks]);
  useEffect(() => {
    parent_id
      ? fetchData
          .get(`/api/v1/tasks/${parent_id}`)
          .then((task) =>
            setDependencesState({ ...dependencesState, parent: task })
          )
      : setDependencesState((prevState) => {
          return {
            ...prevState,
            parent: null,
          };
        });
  }, [parent_id]);
  useEffect(() => {
    next_id
      ? fetchData
          .get(`/api/v1/tasks/${next_id}`)
          .then((task) =>
            setDependencesState({ ...dependencesState, next: task })
          )
      : setDependencesState({ ...dependencesState, next: null });
  }, [next_id]);
  useEffect(() => {
    prev_id
      ? fetchData
          .get(`/api/v1/tasks/${prev_id}`)
          .then((task) =>
            setDependencesState({ ...dependencesState, prev: task })
          )
      : setDependencesState({ ...dependencesState, prev: null });
  }, [prev_id]);

  return (
    <div>
      <Button
        design="outline"
        title="Добавить"
        style={{ marginBottom: '8px' }}
        onClick={() => dispatch(settingDependencies(true))}
      />
      {dependencesTask.parent !== null ? (
        <DependenceParent parentTask={dependencesTask.parent} />
      ) : dependencesState.parent !== null ? (
        <DependenceParent parentTask={dependencesState.parent} />
      ) : null}
      {dependencesTask.child.length > 0 ? (
        <DependenceChild childTasks={dependencesTask.child} />
      ) : dependencesState.child.length > 0 ? (
        <DependenceChild childTasks={dependencesState.child} />
      ) : null}
      {dependencesTask.prev !== null ? (
        <DependencePrev prevTask={dependencesTask.prev} />
      ) : dependencesState.prev !== null ? (
        <DependencePrev prevTask={dependencesState.prev} />
      ) : null}
      {dependencesTask.next !== null ? (
        <DependenceNext nextTask={dependencesTask.next} />
      ) : dependencesState.next !== null ? (
        <DependenceNext nextTask={dependencesState.next} />
      ) : null}
    </div>
  );
};

export default Dependencies;
