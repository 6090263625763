import { types, PaymentsAction } from "./action_types";
import { Reducer } from "redux";
import { columnsOfTables } from "../../common/shared_data";

let default_init_cols: string[] = [];

let localSt: any = localStorage.getItem("tasksTableCols");
if (localSt) {
  let parsed = JSON.parse(localSt);
  Object.keys(parsed).forEach((item) => {
    default_init_cols.push(parsed[item]);
  });
} else {
  columnsOfTables.forEach((item) => {
    if (item.columnId !== "executor_id") {
      default_init_cols.push(item.columnId);
    }
  });
}

const initialState = {
  currentTariff: "EASY FREE",
  step: 1,
  members: 5,
  months: 1,
  paid_success: false,
  paymentDetails: {
    payer_name: "",
    payer_surname: "",
    payer_email: "",
    country: "Россия",
    city: "",
    payer_address: "",
    postal_code: "",
    pricing_plan_id: 1,
  },
  // company_id: 0,
  months_count: 1,
  users_count: 5,
  modules: ["easy"],
  payment_type: "online",
  payment_request_id: 0,
  module_prices: {},
  total_price: "0",
  subscribe_status: false,
  isPaid: false,
  url: "",
  subscribe_start: "",
  subscribe_end: "",
  loading: false,
  bill_info: {},
  is_active_paymethod: false,
};

export type PaymentState = typeof initialState;

const reducer: Reducer<PaymentState, PaymentsAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case types.CHANGE_STEP:
      return {
        ...state,
        step: action.stepNumber,
      };

    case types.CHANGE_PRICE_PARTS:
      let members = action.members;
      let months = action.months;

      let new_obj = {
        ...state,
      };
      if (members) new_obj["members"] = members;
      if (months) new_obj["months"] = months;

      return new_obj;

    case types.PAYMENT_DETAILS:
      let paymentDetails = { ...state.paymentDetails };
      paymentDetails[Object.keys(action.value)[0]] = Object.values(
        action.value
      )[0];

      return {
        ...state,
        paymentDetails: paymentDetails,
      };

    case types.PAID_SUCCESS:
      return {
        ...state,
        paid_success: action.value,
      };

    case types.AMOUNT:
      let users_count = action.users_count;
      let months_count = action.months_count;
      let modules = action.modules;
      // let payment_type = action.payment_type;

      let new_obj1 = {
        ...state,
      };

      if (users_count) new_obj1["users_count"] = users_count;
      if (months_count) new_obj1["months_count"] = months_count;
      if (modules) new_obj1["modules"] = modules;
      // if (payment_type) new_obj1["payment_type"] = payment_type;

      return new_obj1;

    case types.CREATE_BILL:
      return state;

    case types.ACTUAL_REQUEST:
      return {
        ...state,
        company_id: action.company_id,
      };

    case types.SET_MODULES_PRICE:
      return {
        ...state,
        module_prices: action.module_prices,
      };

    case types.SET_TOTAL_PRICE:
      return {
        ...state,
        total_price: action.total_price,
      };
    case types.SET_SUBSCRIBE_STATUS:
      return {
        ...state,
        subscribe_status: action.subscribe_status,
      };
    case types.SET_SELECTED_MODULES:
      if (state.modules.includes(action.module)) {
        return {
          ...state,
          modules: state.modules.filter((module) => module !== action.module),
        };
      } else {
        return {
          ...state,
          modules: [...state.modules, action.module],
        };
      }

    case types.SET_PAID:
      return {
        ...state,
        isPaid: action.isPaid,
      };

    case types.SET_BILL_URL:
      return {
        ...state,
        url: action.url,
      };

    case types.SET_PAYMENT_REQUEST_ID:
      return {
        ...state,
        payment_request_id: action.payment_request_id,
      };

    case types.SET_SUBSCRIBE_START:
      return {
        ...state,
        subscribe_start: action.subscribe_start,
      };

    case types.SET_SUBSCRIBE_END:
      return {
        ...state,
        subscribe_end: action.subscribe_end,
      };

    case types.SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    case types.SET_USERS_PAID:
      return {
        ...state,
        users_count: action.users_paid,
      };
    case types.SET_MONTHS_COUNT:
      return {
        ...state,
        months_count: action.months_count,
      };
    case types.SET_BILL_INFO:
      return {
        ...state,
        bill_info: action.bill_info,
      };
    case types.CHECK_ACTIVE_PAYMETHOD:
      return {
        ...state,
        is_active_paymethod: action.is_active_paymethod,
      };

    default:
      return state;
  }
};

export default reducer;
