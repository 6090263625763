import { types, UsersAction } from "./action_types";
import { Reducer } from "redux";
import { IMeta, User } from "../../common/types";

export const initialState = {
  showWindowCreateDepGroup: false,
  depCreate: false,
  depCreateFromUser: false,
  showAddUserToGroupDep: false,
  userAddMode: false,
  dep_filter: "",
  selectedDepGroupId: null as number | null,
  depGroupUsers: [] as User[],
  isFetchUsers: false,
  isCreatingDep: false,
  depGroupPlateType: null as null | string,
  tableFilter: "",
  usersFilter: "all",
  usersOrder: { col_name: "surname", order_direct: true },
  total_users: 0 as number | null, // используется для калькуляции числа количества участников в группе\отделе который сейчас редактируется\активен
  load_users_by_pages: [] as User[],
  page_to_load: 1 as number,
  isUserEdit: false,
  isUserDelete: false,
  usersMeta: {} as IMeta,
  isLoading: false,
  currentPage: 1,
};

export type UsersState = typeof initialState;

const reducer: Reducer<UsersState, UsersAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case types.ADD_USER_TO_GROUP_DEP:
      return {
        ...state,
        showAddUserToGroupDep: action.show,
      };
    case types.SET_USER_ADD_MODE:
      return {
        ...state,
        userAddMode: action.mode,
      };
    case types.SET_DEP_FILTER:
      return {
        ...state,
        dep_filter: action.filter,
      };
    case types.SELECT_DEP_GROUP:
      return {
        ...state,
        selectedDepGroupId: action.id,
        total_users: action.totalUsers,
      };
    case types.SET_IS_FETCHING_USERS:
      return {
        ...state,
        isFetchUsers: action.isFetchingUsers,
      };
    case types.IS_CREATING_DEP:
      return {
        ...state,
        isCreatingDep: action.isCreatingDep,
      };
    case types.SET_DEP_GROUP_PLATE_TYPE:
      return {
        ...state,
        depGroupPlateType: action.plateType,
      };
    case types.SET_USERS:
      return {
        ...state,
        depGroupUsers: action.users,
      };
    case types.SET_TABLE_FILTER:
      return {
        ...state,
        tableFilter: action.filter,
      };
    case types.SET_USERS_FILTER:
      return {
        ...state,
        usersFilter: action.filter,
      };
    case types.SET_TABLE_ORDER_USERS:
      return {
        ...state,
        usersOrder: {
          col_name: action.col_name,
          order_direct: action.order_direct,
        },
      };
    case types.SHOW_WINDOW_CREATE_DEPGROUP:
      return {
        ...state,
        showWindowCreateDepGroup: action.val,
      };
    case types.SET_DEP_CREATE:
      return {
        ...state,
        depCreate: action.value,
      };
    case types.SET_DEP_CREATE_FROM_USER:
      return {
        ...state,
        depCreateFromUser: action.value,
      };
    case types.PUT_FETCHED_USERS_BY_PAGES:
      // page - необязательный параметр. Если он не пришел, то берется число из редюсера и инкрементируется.
      return {
        ...state,
        load_users_by_pages: action.users,
        page_to_load: action.page ? action.page + 1 : state.page_to_load + 1,
      };
    case types.SET_IS_USER_EDIT:
      return {
        ...state,
        isUserEdit: action.isUserEdit,
      };
      case types.SET_IS_USER_DELETE:
        return {
          ...state,
          isUserDelete: action.isUserDelete,
        };

    case types.SET_USERS_META:
      return {
        ...state,
        usersMeta: action.usersMeta
      }
      case types.SET_IS_USERS_LOADING:
        return {
          ...state,
          isLoading: action.isLoading,
        };
    case types.SET_CURRENT_PAGE:
       return {
         ...state,
         currentPage: action.currentPage,
         };

    case types.DELETE_USER_FROM_STATE:
          return {
            ...state,
            depGroupUsers: state.depGroupUsers.filter(user => user.id !== action.id)
          };
    default:
      return state;
  }
};

export default reducer;
