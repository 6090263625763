// Меню в правом верхнем углу с профилем пользователя и компании
import React, { useState, useRef, useCallback, useEffect } from "react";
import { cookieMaster } from "../../../../utils/CookieMaster";
import { fetchExistingUserData, showCompanyWindow } from "../../../actions";
import { State } from "../../../../rootReducer";
import { connect, useDispatch, useSelector } from "react-redux";
import Avatar from "../../Avatar/Avatar";
import { useHistory } from "react-router-dom";
import { urlApp } from "../../../../App";
import styled from "styled-components";
import Icon from "../../Icon";
import RadioButton from "../../RadioButton";
import { nanoid } from "nanoid";
import { setCompany, setPlateEditMode } from "../../../../pages/Company/actions";
import { openExistingUser } from "../../../../pages/NewUser/actions";
import { useContext } from "react";
import { useStateContext } from "../../../../useStateContext";
import { fetchData } from "../../../../utils/fetchData";

import './HeaderAvatar.scss';

const HeadAvatar = styled.div`
  background-position: center;
  border-radius: 50%;
  justify-self: center;
  cursor: pointer;
  background-color: rgba(196, 196, 196, 0.1);
  width: 41px;
  height: 41px;
`;

const AvatarMenuStyle = styled.div`
  display: flex;
  background: #fff;
  z-index: 1001;
  flex-direction: column;
  width: 240px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
`;
const MenuItemStyle = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #212121;
  padding: 8px;
  cursor: pointer;
  &:first-child {
    border-bottom: 1px solid #e0e0e0;
  }
  &:last-child {
    border-top: 1px solid #e0e0e0;
  }
  img {
    margin-right: 8px;
    width: 16px;
  }
  &:hover {
    color: #1baaf0;
    background-color: rgba(27, 170, 240, 0.1);
  }
`;
const WrapperItemCompany = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 8px;
  padding: 8px;
  font-size: 14px;
  line-height: 16px;
  color: #212121;

  &:first-child {
    margin-top: 4px;
  }
  margin-top: 4px;

  img {
    position: absolute;
    right: 0;
    color: #1baaf0;
    img {
      opacity: 1;
    }
  }
`;
const CompanyStyle = styled.div`
  display: flex;
  width: 100%;
`;
const CreateCompanyStyle = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  border-radius: 8px;
  color: #212121;
  padding: 8px;
  cursor: pointer;
  margin-bottom: 4px;
  img {
    margin-right: 8px;
    width: 16px;
  }
  &:hover {
    color: #1baaf0;
    background-color: rgba(27, 170, 240, 0.1);
  }
`;

const HeaderAvatar = ({ 
  currentUserInfo, 
  currentId, 
  companies
}) => {
  const {
    show1,
    setshow1, // Показывать или нет Профиль пользователя / компании
  } = useContext(useStateContext);
  
  const [checkCompany, setCheckCompany] = useState<number>();
  const history = useHistory();
  const dispatch = useDispatch();
  const company = useSelector((state: State) => state.commonInfo.company);
  const user = useSelector((state: State) => state.commonInfo!.currentUserInfo);
  let name: string = `${currentUserInfo?.surname} ${currentUserInfo?.name}`;

  let plate = useRef<HTMLDivElement>(null);
  const outsideClick = useCallback(
    (e: Event) => {
      if (!plate.current?.contains(e.target as Node)) setshow1(false);
    },
    [show1, plate]
  );

  const exitHandler = useCallback(() => {
    fetchData.post("/api/v1/logout", {}).then(() => {
      cookieMaster.deleteCookie("access_token");
      cookieMaster.deleteCookie("access_token_jwt");
      cookieMaster.deleteCookie("login");
      cookieMaster.deleteCookie("company_id");
      cookieMaster.deleteCookie("user_id");
      localStorage.removeItem("cols_order_meeting") 

      localStorage.clear();

      window.location.pathname = "/login";
    });
  }, []);

  useEffect(() => {
    document.addEventListener("click", outsideClick);
    return () => document.removeEventListener("click", outsideClick);
  }, []);

  const handlerCheck = (e) => {
    setCheckCompany(+e.target.value);
    localStorage.setItem("company_id", e.target.value);
    window.location.reload();
  };

  useEffect(() => {
    let company = localStorage.getItem("company_id") || "";
    setCheckCompany(+company);
  }, [localStorage.getItem("company_id")]);

  return (
    <HeadAvatar
      className="header-avatar filter_plate"
      ref={plate}
      onClick={() => setshow1(true)}
    >
      <Avatar
        style={{width: '41px', height: '41px', border: '3px solid #fff'}}
        url={
          currentUserInfo?.image
            ? urlApp + `/files/${currentUserInfo.image.url}/download`
            : null
        }
        name={name && name}
      />
      
      {show1 && (
        <AvatarMenuStyle>
          <MenuItemStyle
            onClick={() => {
              // @ts-ignore
              dispatch(openExistingUser(user));
              history.push(`/users/${currentId}`);
            }}
          >
            <Icon name="people" />
            Профиль
          </MenuItemStyle>

          {company &&
            Object.keys(company).map((id) => {
              return (
                <WrapperItemCompany key={nanoid()}>
                  <CompanyStyle>
                    <RadioButton
                      name="company"
                      value={+id}
                      check={checkCompany === +id}
                      onChange={handlerCheck}
                      title={company[id]?.name}
                    />
                  </CompanyStyle>
                  {user!.roles[0] < 3 && (
                    <Icon
                      name="setting"
                      onClick={() => {
                        history.push(`/CompanyDetails`);
                        let selectedCompany = companies[parseInt(id)];
                        if (selectedCompany)
                          dispatch(setCompany(selectedCompany));
                      }}
                    />
                  )}
                </WrapperItemCompany>
              );
            })}
          {user?.isCreatorCompany  && (
            <CreateCompanyStyle
              onClick={() => {
                dispatch(setCompany({ id: 0 }));
                dispatch(setPlateEditMode(true));
                dispatch(showCompanyWindow(true));
              }}
            >
              <Icon name="circle-plus" />
              Создать
            </CreateCompanyStyle>
          )}
          <MenuItemStyle onClick={exitHandler}>
            <Icon name="exit" />
            Выйти
          </MenuItemStyle>
        </AvatarMenuStyle>
      )}
    </HeadAvatar>
  );
};

const mapStateToProps = (state: State) => {
  return {
    currentUserInfo: state.commonInfo.currentUserInfo,
    currentId: state.commonInfo.current_user,
    companies: state.commonInfo.company
  };
};

const mapDispatchToProps = {
  fetchExistingUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderAvatar);
