import React from "react";
import "./style.sass";

interface Props {
  onViewChange: (newView: "day" | "week") => void;
  view: "day" | "week" ;
}

const SchedulerViewSwitcher: React.FC<Props> = ({ onViewChange, view }) => {
  return (
    <>
    <div className="view-title">Показать</div>
    <div className="scheduler-view-switcher">
      <div
        className={`scheduler-view-switcher__button ${
          view === "day" ? "active" : ""
        }`}
        onClick={() => onViewChange("day")}
      >
        <>День</>
      </div>
      <div
        className={`scheduler-view-switcher__button ${
          view === "week" ? "active" : ""
        }`}
        onClick={() => onViewChange("week")}
      >
        <>Неделя</>
      </div>
    </div>
    </>
  );
};

export default SchedulerViewSwitcher;
