import axios from "axios";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { nanoid } from "nanoid";
import { StatusContext } from "../../../context/status";
import AddFileButton from "../templates/AddFileButton";
import NameOfStep from "../templates/NameOfStep";
import RadioButtons from "../templates/RadioButtons";
import "../BpResultForm.scss";

let fileTranslate;

const ForkedStage_GPH_TD = () => {
  const {
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,
    taskCurrentGlobal,
    setchangeTask,
    company_id,
    setloadDataNow,
  } = useContext(StatusContext);

  const [data, setData] = useState(
    settingsOfFields?.forkedStage_GPH_TD?.extra
      ? settingsOfFields.forkedStage_GPH_TD.extra
      : {}
  );

  const [ForkedStageGph1, setForkedStageGph1] = useState([]);
  const [ForkedStageGph2, setForkedStageGph2] = useState([]);
  const [ForkedStageGph3, setForkedStageGph3] = useState([]);
  const [ForkedStageGph4, setForkedStageGph4] = useState([]);
  const [ForkedStageGph5, setForkedStageGph5] = useState([]);

  const url = taskId ? `${apiBp}/task/${taskId}` : null;
  useEffect(() => {
    console.log("use in form worked");
    if (taskId) {
      axios
        .patch(
          url,
          { ...settingsOfFields.forkedStage_GPH_TD, extra: data },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))
        // .then(() => setInputValue())

        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);

  function translateFile(file) {
    let reader = new FileReader();

    reader.onload = function () {
      let result = [...new Uint8Array(reader.result)]
        .map((item) => item.toString(16).padStart(2, "0"))
        .join("");
      fileTranslate = result;
      return result;
    };
    reader.readAsArrayBuffer(file);
  }

  //Добавляет файлы
  const onSendFileForkedStageGph = (e, numberFilesForkedStageGph) => {
    let file = e.target.files[0];
    translateFile(file);
    setTimeout(() => {
      if (numberFilesForkedStageGph === "filesForkedStageGph1") {
        if (file && file.name) {
          setForkedStageGph1([
            ...ForkedStageGph1,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesForkedStageGph1",
            },
          ]);
        }
      } else if (numberFilesForkedStageGph === "filesForkedStageGph2") {
        if (file && file.name) {
          setForkedStageGph2([
            ...ForkedStageGph2,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesForkedStageGph2",
            },
          ]);
        }
      } else if (numberFilesForkedStageGph === "filesForkedStageGph3") {
        if (file && file.name) {
          setForkedStageGph3([
            ...ForkedStageGph3,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesForkedStageGph3",
            },
          ]);
        }
      } else if (numberFilesForkedStageGph === "filesForkedStageGph4") {
        if (file && file.name) {
          setForkedStageGph4([
            ...ForkedStageGph4,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesForkedStageGph4",
            },
          ]);
        }
      } else if (numberFilesForkedStageGph === "filesForkedStageGph5") {
        if (file && file.name) {
          setForkedStageGph5([
            ...ForkedStageGph5,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesForkedStageGph5",
            },
          ]);
        }
      }
    }, [500]);
  };

  //Удаляет файлы
  const onDeletedFileForkedStageGph = (keyId, numberFilesForkedStageGph) => {
    if (numberFilesForkedStageGph === "filesForkedStageGph1") {
      setForkedStageGph1(ForkedStageGph1.filter((file) => file.id !== keyId));
    } else if (numberFilesForkedStageGph === "filesForkedStageGph2") {
      setForkedStageGph2(ForkedStageGph2.filter((file) => file.id !== keyId));
    } else if (numberFilesForkedStageGph === "filesForkedStageGph3") {
      setForkedStageGph3(ForkedStageGph3.filter((file) => file.id !== keyId));
    } else if (numberFilesForkedStageGph === "filesForkedStageGph4") {
      setForkedStageGph4(ForkedStageGph4.filter((file) => file.id !== keyId));
    } else if (numberFilesForkedStageGph === "filesForkedStageGph5") {
      setForkedStageGph5(ForkedStageGph5.filter((file) => file.id !== keyId));
    }
  };

  //Смотрит на какую кнопку какого рездела с файлами было нажато
  const onClickFetchFilesForkedStageGph = (e, numberFilesRecruitInitial) => {
    e.preventDefault();

    let data;

    if (numberFilesRecruitInitial === "filesForkedStageGph1") {
      data = JSON.stringify({
        files: ForkedStageGph1,
      });
      console.log("Нажал на кнопку для отправки файлов filesForkedStageGph1");
    }

    if (numberFilesRecruitInitial === "filesForkedStageGph2") {
      data = JSON.stringify({
        files: ForkedStageGph2,
      });
      console.log("Нажал на кнопку для отправки файлов filesForkedStageGph2");
    }

    if (numberFilesRecruitInitial === "filesForkedStageGph3") {
      data = JSON.stringify({
        files: ForkedStageGph3,
      });
      console.log("Нажал на кнопку для отправки файлов filesForkedStageGph3");
    }

    if (numberFilesRecruitInitial === "filesForkedStageGph4") {
      data = JSON.stringify({
        files: ForkedStageGph4,
      });
      console.log("Нажал на кнопку для отправки файлов filesForkedStageGph4");
    }

    if (numberFilesRecruitInitial === "filesForkedStageGph5") {
      data = JSON.stringify({
        files: ForkedStageGph5,
      });
      console.log("Нажал на кнопку для отправки файлов filesForkedStageGph5");
    }

    let config = {
      method: "patch",
      url: `${apiBp}/task/${taskId}`,
      headers: {
        Authorization: bearer,
        "company-id": company_id,
        "secret-token": document.cookie.replace(
          /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        ),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setchangeTask((prev) => prev + 1);
        console.log("Запрос отправлен");
      })
      .catch((e) => {
        console.log(e);
      });

    if (numberFilesRecruitInitial === "filesForkedStageGph1") {
      setForkedStageGph1([]);
    }

    if (numberFilesRecruitInitial === "filesForkedStageGph2") {
      setForkedStageGph2([]);
    }

    if (numberFilesRecruitInitial === "filesForkedStageGph3") {
      setForkedStageGph3([]);
    }

    if (numberFilesRecruitInitial === "filesForkedStageGph4") {
      setForkedStageGph4([]);
    }

    if (numberFilesRecruitInitial === "filesForkedStageGph5") {
      setForkedStageGph5([]);
    }
  };

  return (
    <form id="forkedStage_GPH_TD">
      <div>
        <NameOfStep text={"Прикрепление файла"} />
        <div className="file-uploaded__block">
          <ul>
            {taskCurrentGlobal &&
              taskCurrentGlobal["files"] &&
              taskCurrentGlobal["files"]["list"]
                .filter((item) => item?.tag == "filesForkedStageGph1")
                .map((item2) => (
                  <li>
                    <a
                      href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                      target="_blank"
                    >
                      {item2.name}
                    </a>
                  </li>
                ))}
          </ul>
        </div>
        <AddFileButton
          filesList={taskCurrentGlobal}
          data={data}
          setData={setData}
          id={"f6"}
          handlerOnSendFile={onSendFileForkedStageGph}
          handlerOnDeletedFile={onDeletedFileForkedStageGph}
          handlerOnClickFetchFiles={onClickFetchFilesForkedStageGph}
          allFiles={ForkedStageGph1}
          numberFiles={"filesForkedStageGph1"}
        />
      </div>
      <div>
        <NameOfStep
          text={"Подтверждение принятия информации по официальному окладу"}
        />
        <RadioButtons
          data={data}
          setData={setData}
          name={"f6_1"}
          positiveText={"Да"}
          negativeText={"Нет"}
        />
      </div>
      <div>
        <NameOfStep text={"Составление и прикрепление договора "} />
        <div className="file-uploaded__block">
          <ul>
            {taskCurrentGlobal &&
              taskCurrentGlobal["files"] &&
              taskCurrentGlobal["files"]["list"]
                .filter((item) => item?.tag == "filesForkedStageGph2")
                .map((item2) => (
                  <li>
                    <a
                      href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                      target="_blank"
                    >
                      {item2.name}
                    </a>
                  </li>
                ))}
          </ul>
        </div>
        <AddFileButton
          filesList={taskCurrentGlobal}
          data={data}
          setData={setData}
          id={"f6_2"}
          handlerOnSendFile={onSendFileForkedStageGph}
          handlerOnDeletedFile={onDeletedFileForkedStageGph}
          handlerOnClickFetchFiles={onClickFetchFilesForkedStageGph}
          allFiles={ForkedStageGph2}
          numberFiles={"filesForkedStageGph2"}
        />
      </div>
    </form>
  );
};

export default ForkedStage_GPH_TD;
