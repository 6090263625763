import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { State } from "../../../rootReducer";
import styled from "styled-components";
import Icon from "../../../common/components/Icon";
import { CheckBox } from "@material-ui/icons";
import { actualRequest, setPaid } from "../actions";
import palette from "../../../common/components/palette";
import { useHistory } from "react-router-dom";

const NewRow = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 16px;
  //   overflow: auto;
  &:last-child {
    margin-bottom: 0;
  }
  ${({ styles }) => styles && { ...styles }}
`;

type Props = {
  subscribe_end: string;
};

const SuccessfulPayment: React.FC<Props> = ({ subscribe_end }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let companyId: any = localStorage.getItem("company_id");

  useEffect(() => {
    dispatch(actualRequest(companyId));
    dispatch(setPaid(false));
  }, []);

  return (
    <NewRow style={{ margin: "auto", marginTop: "200px" }}>
      <div
        style={{
          boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.2)",
          width: "565px",
          height: "204px",
          paddingTop: "36px",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <Icon name="sucPayment" />
        </div>
        <div
          style={{
            textAlign: "center",
            marginTop: "10px",
            fontSize: "16px",
            fontWeight: 400,
          }}
        >
          Оплата успешно произведена.
        </div>
        {/* <div style={{ textAlign: "center", fontSize: "16px", fontWeight: 400 }}>
          Подписка оформлена до {subscribe_end} г.
        </div> */}
        <div
          style={{
            textAlign: "center",
            fontSize: "16px",
            fontWeight: 400,
            marginTop: "20px",
            cursor: "pointer",
            color: palette.blue,
          }}
          onClick={() => {
            history.push("/CompanyDetails");
          }}
        >
          Вернуться на страницу компании
        </div>
      </div>
    </NewRow>
  );
};

const mapStateToProps = (state: State) => {
  // console.log(state.commonInfo.company)
  return {
    subscribe_end: state.payment.subscribe_end,
    //   currentTariff: state.payment.currentTariff,
    // step: state.payment.step,
    // months: state.payment.months,
    // members: state.payment.members,
    //   paid_success: state.payment.paid_success,
    // paymentDetailsData: state.payment.paymentDetails,
    // commonInfoCompany: state.commonInfo.company,
  };
};

const mapDispatchToProps = {
  // changeStep
};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessfulPayment);
