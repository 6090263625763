// Контекстное меню для бизнес-процесса
import React, { useContext, useState, useEffect, useRef } from "react";
import { StatusContext } from "../../../context/status.js";
import { ClickAwayListener } from "@material-ui/core";
import "./BpItemTaskMenu.scss";
import axios from "axios";
import turn from "../../../../../../img/power_button.svg";
import trash from "../../../../../../img/TrashMenuTask.svg";
import trashFalse from "../../../../../../img/trash-false.svg";

const BpItemTaskMenu = ({ id, status }) => {
  const {
    setIdBp,
    taskId,
    setChange,
    setOpenMenuTask,
    openMenuTask,
    Mount,
    isAdmin,
    userId,
    businessProcessCurrentGlobal,
    company_id,
    bearer,
    setTaskId,
  } = useContext(StatusContext);

  const [statusDisplay, setStatusDisplay] = useState("block");
  const [change1, setChange1] = useState(false);

  const closeMenuRef = useRef(null);

  // Включить видимость меню при открытии
  useEffect(() => {
    setStatusDisplay("block");
  }, [change1]);

  useEffect(() => {
    if (Mount.current) {
      const handleClickOutside = (e) => {
        if (
          closeMenuRef.current &&
          !e.composedPath().includes(closeMenuRef.current)
        ) {
          setOpenMenuTask(false);
          Mount.current = false;
        }
      };
      document.body.addEventListener("click", handleClickOutside);

      return () =>
        document.body.removeEventListener("click", handleClickOutside);
    }
  }, [taskId]);
  // console.log(taskId);

  // Контекстное меню
  if (taskId === id && status === 0 && openMenuTask) {
    //  alert(idBp)
    return (
      <div ref={closeMenuRef} style={{ display: `${statusDisplay}` }}>
        <ClickAwayListener
          onClickAway={() => {
            setOpenMenuTask(false);
            setIdBp("");
          }}
        >
          <div className="bp-item-task-menu">
            <ul>
              {businessProcessCurrentGlobal.status !== 5 && (
                <li
                  className="turn"
                  onClick={(e) => {
                    let config = {
                      method: "post",
                      url: `${process.env.REACT_APP_BACKEND}/api/v1/workflow/bp/start-from/${taskId}`,
                      headers: {
                        Authorization: bearer,
                        "company-id": company_id,
                        "secret-token": document.cookie.replace(
                          /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                          "$1"
                        ),
                        Accept: "application/json",
                      },
                    };

                    axios(config)
                      .then(function (response) {
                        setChange((prev) => prev + 1);
                        console.log(JSON.stringify(response.data));
                      })
                      .catch((e) => {
                        console.log(e);
                      });

                    e.stopPropagation();
                    // setStatusDisplay("none");
                  }}
                >
                  <img src={turn} alt="" className="img_power_button" />
                  Запустить с этой задачи
                </li>
              )}
              {/* написать условие чтобы удалять мог админ, инициатор, другие только если статус черновик */}
              {isAdmin &&
              businessProcessCurrentGlobal.status === 0 &&
              userId === businessProcessCurrentGlobal.initiator_id &&
              !businessProcessCurrentGlobal.template_id ? (
                <li
                  className="trush"
                  onClick={(e) => {
                    let config1 = {
                      method: "delete",
                      url: `${process.env.REACT_APP_BACKEND}/api/v1/task/${taskId}`,
                      headers: {
                        Authorization: bearer,
                        "company-id": company_id,
                        "secret-token": document.cookie.replace(
                          /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                          "$1"
                        ),
                      },
                    };

                    axios(config1)
                      .then(function (response) {
                        setChange((prev) => prev + 1);
                        setTaskId();
                        console.log(JSON.stringify(response.data));
                      })
                      .catch((e) => {
                        console.log(e);
                      });

                    e.stopPropagation();
                    // setStatusDisplay("none");
                  }}
                >
                  <img src={trash} alt="" className="img_power_trush" />
                  Удалить задачу
                </li>
              ) : (
                <li
                  className="trush_false"
                  onClick={(e) => {
                    e.stopPropagation();
                    // setStatusDisplay("none");
                  }}
                >
                  <img src={trashFalse} alt="" className="img_power_trush" />
                  Удалить задачу
                </li>
              )}
            </ul>
          </div>
        </ClickAwayListener>
      </div>
    );
    // ******************
  } else return null;
  {
  }
};

export default BpItemTaskMenu;
