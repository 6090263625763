import React from 'react';

const Trash = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.7188 4.59375L3.28125 4.59376" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.53125 8.53125V13.7812" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.4688 8.53125V13.7812" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.4062 4.59375V17.0625C16.4062 17.2365 16.3371 17.4035 16.214 17.5265C16.091 17.6496 15.924 17.7188 15.75 17.7188H5.25C5.07595 17.7188 4.90903 17.6496 4.78596 17.5265C4.66289 17.4035 4.59375 17.2365 4.59375 17.0625V4.59375" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.7812 4.59375V3.28125C13.7812 2.93315 13.643 2.59931 13.3968 2.35317C13.1507 2.10703 12.8168 1.96875 12.4688 1.96875H8.53125C8.18315 1.96875 7.84931 2.10703 7.60317 2.35317C7.35703 2.59931 7.21875 2.93315 7.21875 3.28125V4.59375" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default Trash;
