import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { State } from '../../../rootReducer';
import { Role } from '../../types';
import styled from 'styled-components';

type Props = {
  name: string;
  roleId: number | undefined; // undefined потому что по задаче может быть еще не назначено исполнителя. Из ProjectTaskTr, TaskTr может быть undefined
  roles: Role[];
  className?: string;
};

const NameCellStyle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  padding-right: 4px;
  text-overflow: ellipsis;
`;

const NameCell: React.FC<Props> = ({ name, roleId, className, roles }) => {
  const role = useMemo(() => {
    return roles.find((role) => role.id === roleId);
  }, [roleId, roles]);

  return (
    <NameCellStyle className={`name_cell ${className ? className : ''}`}>
      <div className="name">{name}</div>
      <div className="sub_info">{role?.rus_name}</div>
    </NameCellStyle>
  );
};

const mapStateToProps = (state: State) => {
  return {
    roles: state.commonInfo.roles,
  };
};

export default connect(mapStateToProps)(NameCell);
