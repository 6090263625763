import React from "react";
import UserSchedule from "./UserSchedule";
import DaysOff from "./DaysOff";

const ScheduleSection = ({ setChangeDataUser }) => {
  return (
    <>
      <UserSchedule setChangeDataUser={setChangeDataUser} />
      <DaysOff setChangeDataUser={setChangeDataUser} />
    </>
  );
};

export default ScheduleSection;
