export default function extend(scheduler) {
  scheduler.ical = scheduler._parsers.ical = {
    canParse: function canParse(data) {
      if (typeof data === "string") {
        return new RegExp("^BEGIN:VCALENDAR").test(data);
      }
      return false;
    },
    parse: function parse(str) {
      var data = str.match(RegExp(this.c_start + "[^\f]*" + this.c_end, ""));
      if (!data.length) return;

      // mpl: handle bad unfolding
      data[0] = data[0].replace(/[\r\n]+ /g, "");

      //unfolding 
      data[0] = data[0].replace(/[\r\n]+(?=[a-z \t])/g, " ");
      //drop property
      data[0] = data[0].replace(/;[^:\r\n]*:/g, ":");
      var incoming = [];
      var match;
      var event_r = RegExp("(?:" + this.e_start + ")([^\f]*?)(?:" + this.e_end + ")", "g");
      while ((match = event_r.exec(data)) !== null) {
        var e = {};
        var param;
        var param_r = /[^\r\n]+[\r\n]+/g;
        while ((param = param_r.exec(match[1])) !== null) {
          this.parse_param(param.toString(), e);
        }
        if (e.uid && !e.id) e.id = e.uid; //fallback to UID, when ID is not defined
        incoming.push(e);
      }
      return incoming;
    },
    parse_param: function parse_param(str, obj) {
      var d = str.indexOf(":");
      if (d == -1) return;
      var name = str.substr(0, d).toLowerCase();
      var value = str.substr(d + 1).replace(/\\,/g, ",").replace(/[\r\n]+$/, "");
      if (name == "summary") name = "text";else if (name == "dtstart") {
        name = "start_date";
        value = this.parse_date(value, 0, 0);
      } else if (name == "dtend") {
        name = "end_date";
        value = this.parse_date(value, 0, 0);
      }
      obj[name] = value;
    },
    parse_date: function parse_date(value, dh, dm) {
      var t = value.split("T");
      var utcMark = false;
      if (t[1]) {
        dh = t[1].substr(0, 2);
        dm = t[1].substr(2, 2);
        utcMark = !!(t[1][6] == "Z");
      }
      var dy = t[0].substr(0, 4);
      var dn = parseInt(t[0].substr(4, 2), 10) - 1;
      var dd = t[0].substr(6, 2);
      if (scheduler.config.server_utc || utcMark) {
        return new Date(Date.UTC(dy, dn, dd, dh, dm));
      } else {
        return new Date(dy, dn, dd, dh, dm);
      }
    },
    c_start: "BEGIN:VCALENDAR",
    e_start: "BEGIN:VEVENT",
    e_end: "END:VEVENT",
    c_end: "END:VCALENDAR"
  };
}