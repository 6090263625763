// Модальное окно для сохранения бп как шаблона
import React, { useContext, useState, useEffect } from "react";
import { StatusContext } from "../../../context/status.js";
import { ClickAwayListener } from "@material-ui/core";
import "./SaveBp.scss";
import axios from "axios";
import { CreateNotif } from "../../../../../../utils/createNotification";

const SaveBp = ({ setStatusDisplay, statusDisplay }) => {
  const {
    idBp,
    company_id,
    bearer,
    apiBp,
    setTaskId,
    openSaveBp,
    setOpenSaveBp,
    loadTemplateBp,
    setLoadTemplateBp,
    businessProcessCurrentGlobal
  } = useContext(StatusContext);

  const [nameBp, setNameBp] = useState(businessProcessCurrentGlobal.name);

  const createSample = () => {
    // console.log(company_id);
    if (idBp && nameBp) {
      let data = JSON.stringify({
        name: `Шаблон ${nameBp}`,
        company_id: company_id,
      });

      let config = {
        method: "post",
        url: `${apiBp}/bp-template/from-bp/${idBp}`,
        headers: {
          Authorization: bearer,
          "company-id": company_id,
          "secret-token": localStorage.getItem("access_token_jwt"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((res) => {
          //  console.log(res);

        //   setBpListChange((prev) => !prev);
          setLoadTemplateBp(prev => !prev)
          CreateNotif(
            `Шаблон по типовому бизнес-процессу "${nameBp}" создан `,
            "success"
          );
        })

        .catch(function (error) {
          CreateNotif(`Ошибка создания бизнес-процесса по шаблону `, "error");
        });
    }
  };

  if (openSaveBp) {
    return (
      <ClickAwayListener
        onClickAway={() => {
          // setStatusDisplay("none");
          setOpenSaveBp(false);
        }}
      >
        <div className="save_bp">
          <div className="name_bp">Укажите название бизнес-процесса</div>
          <div className="input_for_name">
            <input
              type="text"
              value={nameBp}
              onChange={(e) => {
                setNameBp(e.target.value);
              }}
              className="input_save_bp"
              placeholder="Свое название шаблона БП"
            />
          </div>
          <div className="button_for_bp">
            <button
              className="cancel_button"
              onClick={() => {
                setOpenSaveBp(false);
              }}
            >
              Отмена
            </button>
            <button
              className="save_button"
              onClick={(e) => {
                e.stopPropagation();
                setStatusDisplay("none");
                setOpenSaveBp(false);
                createSample();
                setTaskId();
              }}
              disabled={!nameBp}
            >
              Сохранить
            </button>
          </div>
        </div>
      </ClickAwayListener>
    );
  }
};

export default SaveBp;
