import React, { FC } from 'react';
import { connect } from 'react-redux';
import { State } from '../../../rootReducer';

import './CommonInfoPreloader.scss';

const CommonInfoPreloader: FC<{
  dataWasFetched: boolean
}> = ({
  dataWasFetched
}) => {
  return (
    <div className={'common-info-preloader' +
                    (!dataWasFetched ? ' common-info-preloader--show' : '')
    }>
      <div className="common-info-preloader__content"/>
      <div className="common-info-preloader__shadow"/>
    </div>
  )
}

const mapStateToProps = ( state: State ) => {
  return {
    dataWasFetched: state.commonInfo.wasFetchedFastRequests
  }
}

export default connect(mapStateToProps)(CommonInfoPreloader)
