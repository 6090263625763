import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Button from "../../../common/components/newComponent/Buttons/Button";
import Icon from "../../../common/components/Icon";
import { State } from "../../../rootReducer";
import { changeStep } from "../actions";

type Props = {
  contextStep: number;
  currentStep: number;
  name: string;
  changeStep: (step: number) => void;
};

const BreadCrumbsText: React.FC<Props> = ({
  contextStep,
  currentStep,
  name,
  changeStep,
}) => {
  const breadCrumbsStyles = [
    {
      fontWeight: 500,
      color: "#212121",
      cursor: "pointer",
    },
    {
      fontWeight: 400,
      color: "#212121",
      cursor: "pointer",
    },
    {
      color: "#A8A8A8",
      cursor: "pointer",
    },
  ];

  return (
    <div
      style={
        // contextStep > currentStep
        //   ? breadCrumbsStyles[0]
        //   : 
          currentStep === contextStep
          ? breadCrumbsStyles[0]
          : breadCrumbsStyles[2]
      }
      onClick={() => {
        if (contextStep === currentStep) changeStep(contextStep);
      }}
    >
      {name}
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  currentTariff: state.payment.currentTariff,
  step: state.payment.step,
  months: state.payment.months,
  members: state.payment.members,
});

const mapDispatchToProps = {
  // changeStep
};

export default connect(mapStateToProps, mapDispatchToProps)(BreadCrumbsText);
