export class AddPanelRequired {
    name = null;
    format = null;
    start_at = null;
    location = null;
    chairman = null;
    secretary = null;
    members = null;
  
    constructor(props) {
      Object.entries(props).map((item) => {
        if (this.hasOwnProperty(item[0])) {
          if (item[1] !== "") {
            this[item[0]] = item[1];
          }
        }
      });
    }
    getResult() {
      return !!Object.values(this).filter((item) => item === null).length;
      
    }
  }
  