/* Фильтр */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import CloseIcon from "@material-ui/icons/Close";
import { useContext, useState, useEffect, useMemo } from "react";
import { StatusContext } from "../../../context/status";
import TextField from "@material-ui/core/TextField";
import { ClickAwayListener } from "@material-ui/core";
import XCircle from "../../Icons/XCircle.svg";

import {
  BsFilter1,
  BsFilter2,
  BsFilter4,
  BsFilter5,
  BsFilter51,
  BsFilter51_active,
  BsFilter52,
  BsFilter52_active,
  BsFilter57,
  BsFilter57_active,
  BsFilter59,
  BsFilter59_active,
  BsFilter51Vector,
  BsFilter51Vector_active,
  BsFilter57Vector_active,
  BsFilter51Vector_two,
  BsFilter51Vector_two_active,
} from "../../Icons";

import "../BusinessMain.scss";
import { nanoid } from "nanoid";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const BusinessFilter = () => {
  const {
    setBpListSamples,
    setBpListChange,
    setOpenFilter,
    setBpDrafts,
    setBpWorks,
    setBpFilter,
    setTextBpFilter,
    users,
    setOpenTasks,
  } = useContext(StatusContext);

  const [selectedBpDone, setSelectedBpDone] = useState(false);
  const [selectedBpWork, setSelectedBpWork] = useState(false);
  const [selectedBpCancel, setSelectedBpCancel] = useState(false);
  const [selectedBpDraft, setSelectedBpDraft] = useState(false);

  const [selectedExecutor, setSelectedExecutor] = useState(1);

  const [usersInitiator, setUsersInitiator] = useState({
    id: nanoid(),
    value: "",
    userId: "",
    inputValue: "",
  });
  const [usersArr, setUsersArr] = useState([
    { id: nanoid(), value: "", userId: "" },
  ]);
  const executors = useMemo(() => users, [users]);

  const initiators = useMemo(() => users, [users]);

  const executorsAll = useMemo(() => users, [users]);

  const classes = useStyles();

  const [beginBp, setBeginBp] = useState("");

  const [endBp, setEndBp] = useState("");

  // console.log("usersArr", usersArr);
  // console.log("usersInitiator", usersInitiator);
  // console.log("initiators", initiators);
  // console.log(usersArr[0].id);
  useEffect(() => {
    setBeginBp("");
    setEndBp("");
    setSelectedExecutor(1);
    setUsersInitiator({});
  }, []);

  const handleClick = () => {
    setOpenTasks(false);
    let textFilter = "";
  console.log(textFilter);

    if (beginBp.length > 0 && endBp.length == 0) {
      textFilter += `filter[deadline][0]=${beginBp}`;
    }

    if (endBp.length > 0 && beginBp.length == 0) {
      textFilter += `filter[deadline][1]=${endBp}`;
    }

    if (beginBp.length > 0 && endBp.length > 0) {
      textFilter += `filter[deadline][0]=${beginBp}&filter[deadline][1]=${endBp}`;
    }

    usersArr.map((item) => {
      if (item.userId !== "") {
        if (textFilter.length > 0 && item.userId !== 0) {
          textFilter += `&filter[executor_id][]=${item.userId}`;
        }

        if (textFilter.length == 0 && item.userId !== 0) {
          textFilter += `filter[executor_id][]=${item.userId}`;
        }
      }
    });

    if (
      textFilter.length > 0 &&
      usersInitiator !== 0 &&
      usersInitiator.userId
    ) {
      textFilter += `&filter[initiator_id][]=${usersInitiator.userId}`;
    }

    if (
      textFilter.length == 0 &&
      usersInitiator !== 0 &&
      usersInitiator.userId
    ) {
      textFilter += `filter[initiator_id][]=${usersInitiator.userId}`;
    }

    if (textFilter.length > 0 && selectedBpDone == true) {
      textFilter += `&filter[status][]=4`;
    }

    if (textFilter.length == 0 && selectedBpDone == true) {
      textFilter += `filter[status][]=4`;
    }

    if (textFilter.length > 0 && selectedBpWork == true) {
      textFilter += `&filter[status][]=2`;
    }

    if (textFilter.length == 0 && selectedBpWork == true) {
      textFilter += `filter[status][]=2`;
    }

    if (textFilter.length > 0 && selectedBpCancel == true) {
      textFilter += `&filter[status][]=5`;
    }

    if (textFilter.length == 0 && selectedBpCancel == true) {
      textFilter += `filter[status][]=5`;
    }

    if (textFilter.length > 0 && selectedBpDraft == true) {
      textFilter += `&filter[status][]=0`;
    }

    if (textFilter.length == 0 && selectedBpDraft == true) {
      textFilter += `filter[status][]=0`;
    }

    setBpDrafts(false);
    setBpWorks(false);
    setOpenFilter(false);
    setBpListSamples(false);
    setBpFilter(true);

    setTextBpFilter(textFilter);

    setSelectedBpDone(false);
    setSelectedBpWork(false);
    setSelectedBpCancel(false);
    setSelectedBpDraft(false);

    setBpListChange((prev) => !prev);
  };
  //Исполниель
  const inputValueExecutor = (value, id) => {
    let newValue = value.split(" ").slice(1).join(" ");
    let userId = value.split(" ").shift();

    setUsersArr(
      usersArr.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            value: newValue,
            userId: userId,
            inputValue: value,
          };
        }
        return item;
      })
    );
  };
  const inputValueCloseExecutor = (id) => {
    // let idCloseValue = usersArr.find((name) => name.id === id).value;
    if (usersArr.length == 1) {
      setUsersArr(
        usersArr.map((item) => {
          if (item.id == id) {
            return { id: nanoid(), value: "", userId: "" };
          }
          return item;
        })
      );
    } else {
      setUsersArr((usersArr) => usersArr.filter((item) => item.id != id));
    }
  };

  //Инициатор
  const inputValueInitiator = (value) => {
    let userId = value.split(" ").shift();

    setUsersInitiator((usersInitiator) => {
      return { ...usersInitiator, value: value, userId: userId };
    });
  };
  const inputValueCloseInitiator = () => {
    setUsersInitiator({ id: nanoid(), value: "", userId: "" });
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpenFilter(false);
      }}
    >
      <div className="business-filter">
        {/* <div className="business-filter__block-left">
        <h2 className="business-filter_h2 ">Шаблоны</h2>
        <div>
          <p>Все</p>
        </div>
      </div> */}
        <div className="business-filter__block-right">
          <h2 className="business-filter_h2 ">
            <span
              style={{ fontSize: "20px", position: "relative", right: "65px" }}
            >
              Фильтр бизнес-процессов
            </span>
            &nbsp;&nbsp;&nbsp;
            <span
              style={{ cursor: "pointer", color: "#7B7B7B", fontSize:"17px" }}
              onClick={() => {
                setBpDrafts(false);
                setBpWorks(false);
                setOpenFilter(false);
                setBpListSamples(false);
                setBpFilter(false);
                setTextBpFilter("");

                setSelectedBpDone(false);
                setSelectedBpWork(false);
                setSelectedBpCancel(false);
                setSelectedBpDraft(false);

                setBpListChange((prev) => !prev);
              }}
            >
              Закрыть
              <img src={XCircle} alt="" style={{ marginLeft: "6px" }} />
            </span>
          </h2>
          <div className="bf__block-One">
            <div className="bf__block-One__divInput">
              <div className=" bf__icons">
                <BsFilter1 />
              </div>

              <p className="bf_pText">Дедлайн</p>

              <FormControl variant="filled" className={classes.formControl}>
                <InputLabel id="demo-simple-select-filled-label"></InputLabel>
                <form className={classes.container} noValidate>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p className="bf_pText">c</p>
                    <div style={{ marginLeft: "5px" }}>
                      <TextField
                        id="date"
                        value={beginBp}
                        onChange={(e) => setBeginBp(e.target.value)}
                        type="date"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>
                </form>
              </FormControl>
            </div>
            <div className="bf__block-One__divInput">
              <p className="bf_pText ">&nbsp;</p>
              <FormControl variant="filled" className={classes.formControl}>
                <InputLabel id="demo-simple-select-filled-label"></InputLabel>
                <form className={classes.container} noValidate>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p className="bf_pText">по</p>
                    <div style={{ marginLeft: "5px" }}>
                      <TextField
                        id="date"
                        value={endBp}
                        onChange={(e) => setEndBp(e.target.value)}
                        type="date"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>
                </form>
              </FormControl>
            </div>
          </div>

          {/* исполнитель */}
          <div className="bf_block__scroll" style={{ marginTop: "30px" }}>
            <div className="bf__block-Two">
              <div className=" bf__icons ">
                <BsFilter2 />
              </div>
              <p className="bf_pText">Исполнитель</p>
            </div>
          </div>

          {/* Другие исполнители */}

          {usersArr?.map((item) => (
            <div
              className="bf_block__scroll"
              style={{ marginTop: "0 px" }}
              key={item.id}
            >
              <div className="bf__block-Two">
                <div style={{ marginTop: "0 px" }}>
                  {!(usersArr[0].id === item.id) && (
                    <div
                      className="bf__block-Two_icon_individ bf__block_close-icons"
                      style={{ marginTop: "0 px", cursor: "pointer" }}
                    >
                      <CloseIcon
                        onClick={() => {
                          inputValueCloseExecutor(item.id);
                        }}
                      />
                    </div>
                  )}
                  {!!item.inputValue && (
                    <div
                      className="bf__block-Two_icon_individ bf__block_close-icons"
                      style={{ marginTop: "0 px", cursor: "pointer" }}
                    >
                      <CloseIcon
                        onClick={() => {
                          inputValueCloseExecutor(item.id);
                        }}
                      />
                    </div>
                  )}

                  <FormControl variant="filled" className={classes.formControl}>
                    <>
                      <input
                        list={item.id}
                        style={{ marginLeft: "0px", width: 400 }}
                        className="input-form "
                        id="demo-simple-select-filled"
                        value={usersArr.value}
                        onChange={(e) => {
                          inputValueExecutor(e.target.value, item.id);
                        }}
                      />

                      <datalist id={item.id}>
                        {executorsAll.map((executor) => (
                          <option key={executor.id}>
                            {`${executor.id}  ${executor.name} ${executor.surname}`}
                          </option>
                        ))}
                      </datalist>
                    </>
                  </FormControl>
                </div>
              </div>
            </div>
          ))}

          {/*  ***************** */}

          <div
            className="bf__block-Four "
            style={{ marginTop: "0px", marginBottom: "10px" }}
            onClick={() => {
              let i = usersArr.length;
              setUsersArr([
                ...usersArr,
                { id: nanoid(), value: "", userId: "" },
              ]);
            }}
          >
            <div className="bf__block-Four_icon ">
              <BsFilter4 />
            </div>
            <p className="bf__block-Four_text ">Добавить</p>
          </div>

          {/* инициатор */}
          <div className="bf_block__scroll" style={{ marginTop: "30px" }}>
            <div className="bf__block-Two">
              <div className=" bf__icons ">
                <BsFilter2 />
              </div>
              <p className="bf_pText">Инициатор</p>

              <div style={{ marginTop: "5px" }}>
                {!!usersInitiator.value && (
                  <div
                    className="bf__block-Two_icon bf__block_close-icons"
                    style={{ marginTop: "10px", cursor: "pointer" }}
                    onClick={() => inputValueCloseInitiator()}
                  >
                    <CloseIcon />
                  </div>
                )}

                <FormControl variant="filled" className={classes.formControl}>
                  <>
                    <input
                      list="initiators"
                      onChange={(e) => {
                        inputValueInitiator(e.target.value);
                      }}
                      value={usersInitiator.value}
                      style={{ marginLeft: "0px", width: 400 }}
                      className="input-form "
                      id="demo-simple-select-filled"
                    />

                    <datalist id="initiators">
                      {initiators.map((initiators) => (
                        <option key={initiators.id}>
                          {`${initiators.id}  ${initiators.name} ${initiators.surname}`}
                        </option>
                      ))}
                    </datalist>
                  </>
                </FormControl>
              </div>
            </div>
          </div>

          {/* Статус */}
          <div
            className="bf__block-Six "
            style={{ marginTop: "30px", cursor: "default" }}
          >
            <div className=" bf__icons">
              <BsFilter5 />
              {/* проверить */}
            </div>
            <p className="bf_pText">Статус</p>
            <div className="bf__block-Six__blockIcons">
              <div
                title="Завершено"
                onClick={() => {
                  setSelectedBpDone((prev) => !prev);
                }}
                className="bf__block-Six_icons bf__block-Six_position"
              >
                {selectedBpDone ? (
                  <>
                    <BsFilter51_active />
                    <div>
                      <BsFilter51Vector_active />
                    </div>
                    <div className="bf__block-Six_icons bf__block-Six_position_svgIndivid">
                      <BsFilter51Vector_two_active />
                    </div>
                  </>
                ) : (
                  <>
                    <BsFilter51 />
                    <div>
                      <BsFilter51Vector />
                    </div>
                    <div className="bf__block-Six_icons bf__block-Six_position_svgIndivid">
                      <BsFilter51Vector_two />
                    </div>
                  </>
                )}
              </div>
              <div
                title="В работе"
                onClick={() => {
                  setSelectedBpWork((prev) => !prev);
                }}
                className="bf__block-Six_icons"
              >
                {selectedBpWork ? <BsFilter52_active /> : <BsFilter52 />}
              </div>
              <div
                title="Отменено"
                onClick={() => {
                  setSelectedBpCancel((prev) => !prev);
                }}
                className="bf__block-Six_icons bf__block-Six_position"
              >
                {selectedBpCancel ? (
                  <>
                    {" "}
                    <BsFilter57_active />
                    <div>
                      <BsFilter57Vector_active />
                    </div>
                  </>
                ) : (
                  <BsFilter57 />
                )}
              </div>
              <div
                title="Черновик"
                onClick={() => {
                  setSelectedBpDraft((prev) => !prev);
                }}
                className="bf__block-Six_icons"
              >
                {selectedBpDraft ? <BsFilter59_active /> : <BsFilter59 />}
              </div>
            </div>
          </div>

          {/* Кнопка Показать */}
          <div className="bf__block-Seven ">
            <button
              onClick={() => handleClick()}
              className="bf__block-Seven_button"
            >
              Показать
            </button>
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default BusinessFilter;
