import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import {
  setProjectSections,
  removeProjectSection,
  changeSectionName,
} from '../actions';
import { State } from '../../../rootReducer';
import { SectionInComponent } from '../../../common/types';
import styled from 'styled-components';
import palette from '../../../common/components/palette';
import Icon from '../../../common/components/Icon';

type Props = {
  name: string;
  index: number;
  id: number;
  sections: SectionInComponent[];
  setProjectSections: (sections: SectionInComponent[]) => void;
  removeProjectSection: (sectionId: number) => void;
  changeSectionName: (sectionId: number, name: string) => void;
};
const WrapperText = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #212121;
  width: 100%;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    max-width: 296px;
    margin-left: 8px;
    color: #1baaf0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  img {
    margin-left: 12px;
    /* opacity: 0; */
    cursor: pointer;
    transition: opacity 0.1s ease;

    &:last-of-type {
      margin-left: auto;
    }
  }

  &:hover {
    img {
      opacity: 1;
    }
  }
`;

const WrapperInput = styled.div`
  width: 100%;
  height: 24px;
  border-radius: 6px;
  position: relative;
  border: 1px solid ${palette.blackLighter};

  input {
    width: 100%;
    padding: 0 8px;
  }
`;

const SectionElem: React.FC<Props> = ({
  id,
  name,
  index,
  sections,
  changeSectionName,
  setProjectSections,
  removeProjectSection,
}) => {
  const [isEdit, setEdit] = useState<boolean>(false);
  const [inputValue, setValue] = useState<string>(name);

  useEffect(() => {
    setValue(name);
  }, [name]);

  const input = useRef<HTMLInputElement>(null);

  const blurHandler = useCallback(() => {
    if (inputValue === name) {
      setEdit(false);
    }
    if (id) {
      changeSectionName(id, inputValue);
    } else {
      let clone = sections.slice();
      clone.splice(index, 1, { name: inputValue, id: 0 });
      setProjectSections(clone);
    }
    setEdit(false);
  }, [
    changeSectionName,
    id,
    index,
    inputValue,
    name,
    sections,
    setProjectSections,
  ]);

  const deleteHandler = useCallback(() => {
    if (id) {
      removeProjectSection(id);
    } else {
      let clone = sections.slice();
      clone.splice(index, 1);
      setProjectSections(clone);
    }
  }, [id, removeProjectSection, sections, index, setProjectSections]);

  return (
    <li>
      {isEdit ? (
        <WrapperInput>
          <input
            ref={input}
            value={inputValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setValue(e.currentTarget.value)
            }
            onBlur={blurHandler}
          />
        </WrapperInput>
      ) : (
        <>
          <WrapperText
            title={name}
            onClick={() => {
              setEdit(true);
              setTimeout(() => input.current?.focus(), 0);
            }}
          >
            {index + 1}.<span> {name}</span>
            {/* <img
            onClick={() => {
              setEdit(true);
              setTimeout(() => input.current?.focus(), 0);
            }}
            src={require('../../../img/pen.png')}
            alt="pen"
          /> */}
          </WrapperText>
          <Icon name="cross" onClick={() => deleteHandler()} alt="delete" />
          {/* <img
            onClick={() => deleteHandler()}
            src={require('../../../img/red_union.png')}
            alt="delete"
          /> */}
        </>
      )}
    </li>
  );
};

const mapStateToProps = (state: State) => {
  return {
    sections: state.newProject.sections,
  };
};

const mapDispatchToProps = {
  setProjectSections,
  removeProjectSection,
  changeSectionName,
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionElem);
