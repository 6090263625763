import React from 'react';
import Input from '../Inputs/Input'



type Props = {
    value: string,
    className?: string,
    placeholder: string,
    changeHandler: (value: string) => void
}


const Search: React.FC<Props> = ({value, className, placeholder, changeHandler}) => {
    return (
        <div className={`search ${className ? className : ''}`}>
            <Input
                value={value}
                withIcon={false}
                inputType="text"
                title=''
                placeholder={placeholder}
                changeHandler={changeHandler}
            />
        </div>
    )
}


export default Search