import React from 'react';
import { setProjectCurators } from '../actions';
import { connect } from 'react-redux';
import { State } from '../../../rootReducer';
import { getCuratorSelecData, getProjectCurators } from '../selectors';
import MultySelectCurator from '../../../common/components/Selects/MultySelectCurator';  // Для куратора создан свой компонент MultySelectCurator

type Props = {
  curatorSelectData: any;
  curators_ids: any;
  setProjectCurators: (curators: number[]) => void;
  disabled?: boolean;
};

const CuratorsSelect: React.FC<Props> = ({
  curatorSelectData,
  curators_ids,
  setProjectCurators,
  disabled
}) => {
  return (
     // Для куратора создан свой компонент MultySelectCurator
    <MultySelectCurator
      disabled={disabled}
      itemsList={curatorSelectData.list}
      selectedItems={curatorSelectData.selected}
      isValid={curatorSelectData.selected.length > 0}
      addHandler={(value: number) => {
        setProjectCurators([...curators_ids, value as number]);
      }}
      removeHandler={(value: number) => {
        setProjectCurators(curators_ids.filter((id) => id !== value));
      }}
    />
  );
};

const mapStateToProps = (state: State) => {
  return {
    curators_ids: getProjectCurators(state),
    curatorSelectData: getCuratorSelecData(state),
  };
};

const mapDispatchToProps = {
  setProjectCurators,
};

export default connect(mapStateToProps, mapDispatchToProps)(CuratorsSelect);
