import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { State } from '../../../rootReducer';
import styled from 'styled-components';
import { nanoid } from 'nanoid';
import {
  duplicateTask,
  make_as_subtask,
  make_subtask,
  settingDependencies,
} from '../actions';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { checkDeleteTask } from "../../Tasks/actions";

type Props = {
  idSelectedTask: string|number,
  isBpTask: boolean;
  isDeleteActionAllowed: boolean;
  setShowContextDropDown: (boolean) => void;
  duplicateTask: () => void;
  make_subtask: () => void;
  make_as_subtask: () => void;
  checkDeleteTask: (id: string|number) => void;
};

const WindowItem = styled.div`
  height: 32px;
  padding: 8px;
  size: 14px;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  
  &:hover {
    background-color: #f3fafe;
  }
  
  &.window-item {
    margin-top: 2px;
    position: relative;
    padding-top: 10px;
    
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 89%;
      height: 1px;
      border-top: 1px dashed #000;
    }
  }
`;

const Window = styled.div`
  position: absolute;
  width: 184px;
  right: 0px;
  top: 45px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  padding: 4px;
  z-index: 99;

  .window-item {
    &:first-child {
      &:before {
        border: none;
      }
    }
  }
`;

const ContextDropdownMenu: React.FC<Props> = ({
  idSelectedTask,
  isBpTask,
  isDeleteActionAllowed,
  setShowContextDropDown,
  duplicateTask,
  make_subtask,
  make_as_subtask,
  checkDeleteTask
}) => {
  const dispatch = useDispatch();

  const [listItems, setListItems] = useState<string[]>(
    [
      'Настроить зависимость',
      'Дублировать задачу',
      'Создать подзадачу',
      'Сделать подзадачей'
    ]
  );

  const handleClickAway = () => {
    setShowContextDropDown(false);
  }

  const clickDeleteHandler = (id: string|number) => {
    checkDeleteTask(id);
    setShowContextDropDown(false);
  };
  
  useEffect(() => {
    if(isDeleteActionAllowed) {
      setListItems((state) => ([
        ...state,
        'Удалить задачу'
      ]));
    }

    if(isBpTask && isDeleteActionAllowed) {
      setListItems(['Удалить задачу']);
    }

    if(isBpTask && !isDeleteActionAllowed) {
      setListItems([]);
    }
  }, []);

  return (
    <>
      {listItems.length &&
        <ClickAwayListener onClickAway={handleClickAway}>
          <Window className={'ThreeDotsDropdownMenu'}>
            {listItems.map((item, i) => {
              return (
                <WindowItem
                  className={item === 'Удалить задачу' ? 'window-item' : ''}
                  key={nanoid()}
                  onClick={() => {
                    if(item === 'Удалить задачу') {
                      clickDeleteHandler(idSelectedTask);
                      return;
                    }
                    
                    switch (item) {
                      case listItems[0]:
                        dispatch(settingDependencies(true));
                        break;
                      case listItems[1]:
                        duplicateTask();
                        break;
                      case listItems[2]:
                        make_subtask();
                        break;
                      case listItems[3]:
                        make_as_subtask();
                        break;
                    }
                  }}
                >
                  {item}
                </WindowItem>
              );
            })}
          </Window>
        </ClickAwayListener>
      }
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
  };
};

const mapDispatchToProps = {
  duplicateTask,
  make_subtask,
  make_as_subtask,
  checkDeleteTask
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContextDropdownMenu);
