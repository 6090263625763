import React from "react";

const BsFilter51_active = ({ props }) => {
  return (
    <svg
      {...props}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="#F2F2F2"
      xmlns="http://www.w3.org/2000/svg"
      stroke="green"
    >
      <rect
        x="0.5"
        y="0.5"
        width="35"
        height="35"
        rx="3.5"
        fill="green"
        fill-opacity="0.3"
        stroke="green"
      />
    </svg>
  );
};

export default BsFilter51_active;
