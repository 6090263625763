import React from "react";
import procent from "../../../../../../img/percentage.svg";
import { CreateNotif } from "../../../../../../utils/createNotification";

const TaskCreateLoad = ({
  taskLoadValue,
  setTaskLoadValue,
  workLoadValue,
  setWorkLoadValue,
  typeOfValue,
  setTypeOfValue,
  blockChanges,
}) => {
  // console.log(blockChanges);
  return (
    <div>
      <div>
        <label className="p__drop-content">
          <img src={procent} style={{ width: "19px" }} />
          Загрузка
        </label>
        <div style={{ display: "flex" }}>
          <input
            type="number"
            className={!blockChanges ? "input-form" : "input-form blockChange"}
            id="businessTask__executor"
            value={typeOfValue === "task_load" ? taskLoadValue : workLoadValue}
            onChange={(e) => {
              if (+e.target.value >= 0) {
                if (typeOfValue === "task_load") {
                  setTaskLoadValue(e.target.value);
                } else {
                  setWorkLoadValue(e.target.value);
                }

                if (+e.target.value === 0) {
                  CreateNotif(
                    "Значение загрузки должно быть больше 0",
                    "error"
                  );
                  setTimeout(() => {
                    if (+e.target.value === 0) {
                      if (typeOfValue === "task_load") {
                        setTaskLoadValue(0.1);
                      } else {
                        setWorkLoadValue(0.1);
                      }
                    }
                  }, 1000);
                }
              }
            }}
          ></input>
          <div className="form-task__dependencies__btns">
            <div>
              <button
                type="button"
                onClick={() => {
                  setTypeOfValue(() => "task_load");
                }}
                className={
                  typeOfValue === "task_load"
                    ? "form-task__dependencies__btn-active"
                    : ""
                }
              >
                Часы
              </button>
            </div>
            <div>
              <button
                type="button"
                onClick={() => {
                  setTypeOfValue((prev) => "work_load");
                }}
                className={
                  typeOfValue === "work_load"
                    ? "form-task__dependencies__btn-active"
                    : ""
                }
              >
                Проценты
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskCreateLoad;
