import React, {useState, useCallback} from 'react';
import withTitle from '../../../utils/hoc/withTitle';
import {connect} from 'react-redux';
import Input from '../../../common/components/Inputs/Input';
import CustomDatePicker from '../../../common/components/DatePickers/DatePicker';
import {DayOff, User} from '../../../common/types';
import {setUserDaysOff} from '../actions';
import {State} from '../../../rootReducer';
import {store} from '../../../App';
import DateMaster from '../../../utils/DateMaster';
import NameWithInput from '../../../common/components/newComponent/Inputs/NameWithInput';
import Button from '../../../common/components/newComponent/Buttons/Button';

const moment = require('moment');
require('moment-weekday-calc');

type Props = {
    schedule: number[];
    workBegin: string;
    workEnd: string;
    daysOff: DayOff[];
    userId: number;
    currentUserInfo: User | null;
    setUserDaysOff: (daysOff: DayOff[]) => void;
    setChangeDataUser: any;
    rolesUser: number[];
};

//расписание можно менять для новых пользователей и если ты админ или директор

const DaysOff: React.FC<Props> = ({
                                      daysOff,
                                      userId,
                                      currentUserInfo,
                                      setUserDaysOff,
                                      setChangeDataUser,
                                      schedule,
                                      rolesUser,
                                      workBegin,
                                      workEnd,
                                  }) => {
    const [date, setDate] = useState(new Date().toISOString());
    const [comment, setComment] = useState('');

    const addHandler = useCallback(
        (date, comment) => {
            if (date && comment) {
                date = moment(date).format('DD.MM.YYYY');
                setChangeDataUser((prev) => ({
                    data: {...prev.data},
                    schedule: {
                        ...prev.schedule,
                        daysOff: [...daysOff, {date, comment}],
                        weekDays: [
                            ...schedule.map((el) => ({
                                day: el,
                                time: [{begin: workBegin, end: workEnd}],
                            })),
                        ],
                    },
                }));
                setUserDaysOff([{date, comment}, ...daysOff]);
                setComment('');
            }
        },
        [daysOff, setUserDaysOff]
    );

    const deleteHanlder = useCallback(
        (index) => {
            let clone = daysOff.slice();
            clone.splice(index, 1);
            setChangeDataUser((prev) => ({
                data: {...prev.data},
                schedule: {
                    ...prev.schedule,
                    daysOff: [...clone],
                    weekDays: [
                        ...schedule.map((el) => ({
                            day: el,
                            time: [{begin: workBegin, end: workEnd}],
                        })),
                    ],
                },
            }));
            setUserDaysOff(clone);
        },
        [daysOff, setUserDaysOff]
    );

    return (
        <NameWithInput title="Выходные дни" iconName="clock">
            <div
                className={`days_off${
                    userId === 0 ||
                    currentUserInfo?.roles.includes(1) ||
                    userId === currentUserInfo?.id ||
                    currentUserInfo!.roles.sort()[0] < rolesUser.sort()[0]
                        ? ''
                        : ' disabled'
                }`}
            >
                <div className="days_off_input">
                    <CustomDatePicker
                        date={new Date(date)}
                        acceptHandler={(date) => setDate(date)}
                    />
                    <Input
                        inputType="text"
                        placeholder="Комментарий"
                        title=""
                        value={comment}
                        changeHandler={(val) => setComment(val)}
                    />
                    <Button
                        onClick={() => addHandler(date, comment)}
                        title="+"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '20px',
                            marginLeft: '8px',
                        }}
                    />
                </div>
                <div className="days_off_list">
                    {daysOff.map((day, index) => (
                        <div key={index} className="dayoff_elem">
                            <div className="dayoff_date">{day.date}</div>
                            <div className="dayoff_comment">{day.comment}</div>
                            <div onClick={() => deleteHanlder(index)} className="cross" style={{marginRight: '1rem'}}>
                                {' '}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </NameWithInput>
    );
};

const mapStateToProps = (state: State) => {
    return {
        schedule: state.newUser.schedule,
        workBegin: state.newUser.workBegin,
        workEnd: state.newUser.workEnd,
        daysOff: state.newUser.daysOff,
        rolesUser: state.newUser.roles,
        userId: state.newUser.id,
        currentUserInfo: state.commonInfo.currentUserInfo,
    };
};

const mapDispatchToProps = {
    setUserDaysOff: setUserDaysOff,
};

export default connect(mapStateToProps, mapDispatchToProps)(DaysOff);
