import SchedulerFactory from "./scheduler_factory";
import gplExtensions from "./ext/extensions_gpl";
var factory = new SchedulerFactory(gplExtensions);
var scheduler = factory.getSchedulerInstance();
var Scheduler = {
  plugin: scheduler.bind(factory.plugin, factory)
};
window.scheduler = scheduler;
window.Scheduler = Scheduler;
export default scheduler;
export { scheduler, Scheduler };