import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { StatusContext } from "../../../context/status";
import CheckboxElement from "../templates/CheckboxElement";
import NameOfStep from "../templates/NameOfStep";
import "../BpResultForm.scss";

const CompletionOfBPDismissal = () => {
  const {
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,
    company_id,
    setloadDataNow,
  } = useContext(StatusContext);

  const [data, setData] = useState(
    settingsOfFields?.completionOfBP?.extra
      ? settingsOfFields.completionOfBP.extra
      : {}
  );
  const url = taskId ? `${apiBp}/task/${taskId}` : null;

  useEffect(() => {
    console.log("use in form worked");
    if (taskId) {
      axios
        .patch(
          url,
          {
            ...settingsOfFields.completionOfBP,
            extra: data,
          },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))
        // .then(() => setInputValue())

        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);

  return (
    <form id="finalRegistrationByJurist">
      <div>
        <NameOfStep text={"Подтверждение закрытия"} />
        <CheckboxElement
          name={"h8"}
          text={"Подтверждаю отсутствие претензий со стороны уволенного"}
          data={data}
          setData={setData}
        />
      </div>
    </form>
  );
};

export default CompletionOfBPDismissal;
