export default function (scheduler) {
  (function () {
    scheduler.$keyboardNavigation.getFocusableNodes = scheduler._getFocusableNodes;
    scheduler.$keyboardNavigation.trapFocus = function trapFocus(root, e) {
      if (e.keyCode != 9) return false;
      var focusable = scheduler.$keyboardNavigation.getFocusableNodes(root);
      var currentFocus = document.activeElement;
      var currentIndex = -1;
      for (var i = 0; i < focusable.length; i++) {
        if (focusable[i] == currentFocus) {
          currentIndex = i;
          break;
        }
      }
      var nextIndex, nextItem;
      if (e.shiftKey) {
        // back tab
        // go to the last element if we focused on the first
        nextIndex = currentIndex <= 0 ? focusable.length - 1 : currentIndex - 1;
        nextItem = focusable[nextIndex];
        if (nextItem) {
          nextItem.focus();
          e.preventDefault();
          return true;
        }
      } else {
        // forward tab
        // forward tab from last element should go back to the first element
        nextIndex = currentIndex >= focusable.length - 1 ? 0 : currentIndex + 1;
        nextItem = focusable[nextIndex];
        if (nextItem) {
          nextItem.focus();
          e.preventDefault();
          return true;
        }
      }
      return false;
    };
  })();
}