import React from 'react';
import Button from '../../../../common/components/newComponent/Buttons/Button';
import { User } from "../../../../common/types";


interface Props {
    setIsShowAddMeeting: (mode: boolean) => void
    currentUser:  null | User;
}

const MeetingAddButton:React.FC<Props> = ({setIsShowAddMeeting , currentUser}) => {

    return (
      <>
     {(currentUser?.roles?.length == 1 && currentUser?.roles?.[0] == 7) ?
        (<Button
            disabled
            title="Добавить"
            icon="addPlus"
            design="blue-btn"
            style={{
              padding: ".5rem",
              width: "150px",
              marginLeft: "8px",
              height: "50px",
              alignItems: "center",
              fontSize: "18px",
              fontWeight: "400",
            }}
          /> ) :
         (
          <Button
          title="Добавить"
          icon="addPlus"
          design="blue-btn"
          style={{
            padding: ".5rem",
            width: "150px",
            marginLeft: "8px",
            height: "50px",
            background: "#01B0E9",
            alignItems: "center",
            fontSize: "18px",
            fontWeight: "400",
          }}
          onClick={() => {
            setIsShowAddMeeting(true)
          }}
        /> 
         ) }  

         </>   

    );
};

export default MeetingAddButton;