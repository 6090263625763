import React from 'react';
import Priority from '../Priority/Priority';
import styled from 'styled-components';
import { columnsOfTables } from '../../shared_data';

type Props = {
  priorityId: number;
  style?: object;
};
const WrapperPriorityCell = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 96px;
`;
const PriorityCell: React.FC<Props> = ({ priorityId, style }) => {
  return (
    <WrapperPriorityCell>
      <Priority id={priorityId} noname style={style} />
    </WrapperPriorityCell>
  );
};

export default PriorityCell;
