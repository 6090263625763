import React from "react";

const useDebounce = (val, delay) => {
  const [debounceVar, setDebounceVar] = React.useState(val);
  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceVar(val);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [val]);
  return debounceVar;
};

export default useDebounce;
