import React from "react";

const BsFilter51 = ({ props }) => {
  return (
    <svg
      {...props}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="#F2F2F2"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="35"
        height="35"
        rx="3.5"
        fill="#F2F2F2"
        fill-opacity="0.1"
        stroke="#8fffc7"
      />
    </svg>
  );
};

export default BsFilter51;
