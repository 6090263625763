import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { StatusContext } from "../../../context/status";
import NameOfStep from "../templates/NameOfStep";
import RadioButtons from "../templates/RadioButtons";
import TextAreaOrInputElement from "../templates/TextAreaOrInputElement";
import "../BpResultForm.scss";
import { nanoid } from "nanoid";
import AddFileButton from "../templates/AddFileButton";

let fileTranslate;
const CheckOfHeadOfDepartment = () => {
  const {
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,

    taskCurrentGlobal,
    setchangeTask,
    company_id,
    setloadDataNow,
  } = useContext(StatusContext);

  const [data, setData] = useState(
    settingsOfFields?.checkOfHeadOfDepartment?.extra
      ? settingsOfFields.checkOfHeadOfDepartment.extra
      : {}
  );

  const [allFilesHeadOfDep1, setAllFilesHeadOfDep1] = useState([]);

  function translateFile(file) {
    let reader = new FileReader();

    reader.onload = function () {
      let result = [...new Uint8Array(reader.result)]
        .map((item) => item.toString(16).padStart(2, "0"))
        .join("");
      fileTranslate = result;
      return result;
    };
    reader.readAsArrayBuffer(file);
  }

  //Добавляет файлы
  const onSendFileHeadOfDep = (e, numberFilesHeadOfDep) => {
    let file = e.target.files[0];
    translateFile(file);
    setTimeout(() => {
      if (numberFilesHeadOfDep === "filesHeadOfDep1") {
        if (file && file.name) {
          setAllFilesHeadOfDep1([
            ...allFilesHeadOfDep1,
            {
              data: fileTranslate,
              id: nanoid(),
              name: file.name.match(/(.*)\.[^.]+$/)[1],
              ext: (file.name.match(/\.[0-9a-z]+$/i)[0] || "").substring(1),
              tag: "filesHeadOfDep1",
            },
          ]);

          console.log("allFilesHeadOfDep1", allFilesHeadOfDep1);
        }
      }
    }, [500]);
  };
  //Удаляет файл
  const onDeletedFileHeadOfDep = (keyId, numberFilesHeadOfDep) => {
    if (numberFilesHeadOfDep === "filesHeadOfDep1") {
      setAllFilesHeadOfDep1(
        allFilesHeadOfDep1.filter((file) => file.id !== keyId)
      );
    }
  };

  //Смотрит на какую кнопку какого раздела с файлами было нажато
  const onClickFetchFilesHeadOfDep = (e, numberFilesHeadOfDep) => {
    e.preventDefault();
    console.log(numberFilesHeadOfDep);

    let data;
    if (numberFilesHeadOfDep === "filesHeadOfDep1") {
      data = JSON.stringify({
        files: allFilesHeadOfDep1,
      });
      console.log("Нажал на кнопку для отправки файлов filesHeadOfDep1");
    }

    let config = {
      method: "patch",
      url: `${apiBp}/task/${taskId}`,
      headers: {
        Authorization: bearer,
        "company-id": company_id,
        "secret-token": document.cookie.replace(
          /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
          "$1"
        ),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setchangeTask((prev) => prev + 1);
        console.log("Запрос отправлен");
      })
      .catch((e) => {
        console.log(e);
      });

    if (numberFilesHeadOfDep === "filesHeadOfDep1") {
      setAllFilesHeadOfDep1([]);
    }
  };

  const url = taskId ? `${apiBp}/task/${taskId}` : null;
  // console.log(taskId);

  useEffect(() => {
    console.log("use in form worked");
    if (taskId) {
      axios
        .patch(
          url,
          { ...settingsOfFields.checkOfHeadOfDepartment, extra: data },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))
        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);

  // console.log("     ", );

  return (
    <form id="checkOfHeadOfDepartment">
      <div>
        <NameOfStep text={"Актуальность договора"} />
        <RadioButtons
          data={data}
          setData={setData}
          name={"b2"}
          positiveText={"Да"}
          negativeText={"Нет"}
        />
      </div>
      <div>
        <NameOfStep
          text={
            "Условия договора (предмет, стоимость, сроки) проверены и соответствуют интересам подразделения"
          }
        />
        <RadioButtons
          data={data}
          setData={setData}
          name={"b2_1"}
          positiveText={"Да"}
          negativeText={"Нет"}
        />
      </div>
      <div>
        <NameOfStep
          text={
            "Проведен анализ альтернативных контрагентов и коммерческих предложений"
          }
        />
        <RadioButtons
          data={data}
          setData={setData}
          name={"b2_2"}
          positiveText={"Да"}
          negativeText={"Нет"}
        />
      </div>
      {data.b2_2 && (
        <div>
          <TextAreaOrInputElement
            name={"b2_2_1"}
            isInput={true}
            type={"text"}
            data={data}
            setData={setData}
          />
          <AddFileButton
            filesList={taskCurrentGlobal}
            data={data}
            setData={setData}
            id={"c3"}
            handlerOnSendFile={onSendFileHeadOfDep}
            handlerOnDeletedFile={onDeletedFileHeadOfDep}
            handlerOnClickFetchFiles={onClickFetchFilesHeadOfDep}
            allFiles={allFilesHeadOfDep1}
            numberFiles={"filesHeadOfDep1"}
          />
          {taskCurrentGlobal && taskCurrentGlobal["files"] && (
            <div className="file-uploaded__block">
              <ul>
                {taskCurrentGlobal["files"]["list"]
                  .filter((item) => item.tag == "filesHeadOfDep1")
                  .map((item2) => (
                    <li>
                      <a
                        href={`${process.env.REACT_APP_BACKEND}${item2.url}`}
                        target="_blank"
                      >
                        {item2.name}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>
      )}
      <div>
        <NameOfStep
          text={
            "Контрагент соответствует целям подразделения. взаимоотношения с КА напрямую связаны с основной деятельностью и спецификой подразделения?"
          }
        />
        <RadioButtons
          data={data}
          setData={setData}
          name={"b2_3"}
          positiveText={"Да"}
          negativeText={"Нет"}
        />
      </div>
      {data.b2_3 && (
        <div>
          <TextAreaOrInputElement
            name={"b2_3_1"}
            isInput={true}
            type={"text"}
            data={data}
            setData={setData}
          />
        </div>
      )}
    </form>
  );
};

export default CheckOfHeadOfDepartment;
