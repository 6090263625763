import moment from "moment";

//  "15-12-2022 16:48:00"  ->  "2022-12-15T13:48:00Z"
// Преобразуем дату, введенную пользователем в задаче, в дату в виде iso для отправки на сервер
// При этом дата преобразуется к 0-му часовому поясу (по Гринвичу)
function formatToStringUTC(strDate) {
  return moment(strDate, "DD-MM-YYYY HH:mm:ss").utc().format();
}

// "2022-12-15T13:48:00Z"  -> "15-12-2022 16:48:00"
//  Преобразуем дату, полученную с сервера в виде iso, записанную в нулевом часовом поясе в дату,
//  используемую в программе в текущем временном поясе на компьютере
const formatStringDate = (strDate) => {
  return moment(strDate).format("DD-MM-YYYY HH:mm:ss");
};

// Отклонение от Часовой зоны по Гринвичу в минутах
const timeZone = () => {
  return -new Date().getTimezoneOffset();
};

export { formatToStringUTC, formatStringDate, timeZone };
