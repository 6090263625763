import React from "react";

const BsFilter59 = ({ props }) => {
  return (
<svg
{...props}
width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="35" height="35" rx="3.5" fill="#F2F2F2" stroke="#c2c2c2"/>
<path d="M18 20.25H15.75V18L22.5 11.25L24.75 13.5L18 20.25Z" stroke="#c2c2c2" stroke-width="2" 
stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.8125 12.9375L23.0625 15.1875" stroke="#c2c2c2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M24.1875 17.4375V23.625C24.1875 23.7742 24.1282 23.9173 24.0227 24.0227C23.9173 24.1282 23.7742 24.1875 23.625 
24.1875H12.375C12.2258 24.1875 12.0827 24.1282 11.9773 24.0227C11.8718 23.9173 11.8125 23.7742 11.8125 23.625V12.375C11.8125
 12.2258 11.8718 12.0827 11.9773 11.9773C12.0827 11.8718 12.2258 11.8125 12.375 11.8125H18.5625" stroke="#c2c2c2" stroke-width="2" 
 stroke-linecap="round" stroke-linejoin="round"/>
</svg>


  );
};

export default BsFilter59;
