import { Action } from "redux";
import { Task } from "../../common/types";

export enum types {
  CHANGE_TASK_LIST = "@@PAYMENT/CHANGE_TASK_LIST",
  FETCH_TASK_DEPENDENCE = "@@PAYMENT/FETCH_TASK_DEPENDENCE",
  CHANGE_VISUALISATION_LIST = "@@PAYMENT/CHANGE_VISUALISATION_LIST", //Доработать в сслучае добавление подгрузки при скроле
  CHANGE_STEP = "@@PAYMENT/CHANGE_STEP",
  PAID_SUCCESS = "@@PAYMENT/PAID_SUCCESS",
  CHANGE_PRICE_PARTS = "@@PAYMENT/CHANGE_PRICE_PARTS",
  PAYMENT_DETAILS = "@@PAYMENT/PAYMENT_DETAILS",
  SET_FREE = "@@PAYMENT/SET_FREE",
  CREATE_BILL = "@@PAYMENT/CREATE_BILL",
  BILL_PDF_CREATE = "@@PAYMENT/BILL_PDF_CREATE",
  AMOUNT = "@@PAYMENT/AMOUNT",
  ACTUAL_REQUEST = "@@PAYMENT/ACTUAL_REQUEST",
  FETCH_MODULES_PRICE = "@@PAYMENT/FETCH_MODULES_PRICE",
  SET_MODULES_PRICE = "@@PAYMENT/SET_MODULES_PRICE",
  SET_TOTAL_PRICE = "@@PAYMENT/SET_TOTAL_PRICE",
  SET_SUBSCRIBE_STATUS = "@@PAYMENT/SET_SUBSCRIBE_STATUS",
  SET_SELECTED_MODULES = "@@PAYMENT/SET_SELECTED_MODULES",
  SET_PAID = "@@PAYMENT/SET_PAID",
  SET_BILL_URL = "@@PAYMENT/SET_BILL_URL",
  SET_PAYMENT_REQUEST_ID = "@@PAYMENT/SET_PAYMENT_REQUEST_ID",
  SET_SUBSCRIBE_START = "@@PAYMENT/SET_SUBSCRIBE_START",
  SET_SUBSCRIBE_END = "@@PAYMENT/SET_SUBSCRIBE_END",
  SET_USERS_PAID = "@@PAYMENT/SET_USERS_PAID",
  SET_LOADING = "@@PAYMENT/SET_LOADING",
  SET_MONTHS_COUNT = "@@PAYMENT/SET_MONTHS_COUNT",
  SET_BILL_INFO = "@@PAYMENT/SET_BILL_INFO",
  FETCH_ONLINE_BILL_STATUS = "@@PAYMENT/FETCH_ONLINE_BILL_STATUS",
  FETCH_OFFLINE_BILL_STATUS = "@@PAYMENT/FETCH_OFFLINE_BILL_STATUS",
  CHECK_ACTIVE_PAYMETHOD = "@@PAYMENT/CHECK_ACTIVE_PAYMETHOD",
}

export interface fetchOnlineBillStatusAction
  extends Action<types.FETCH_ONLINE_BILL_STATUS> {
  company_id: number;
}
export interface fetchOfflineBillStatusAction
  extends Action<types.FETCH_OFFLINE_BILL_STATUS> {
  company_id: number;
}
export interface actualRequestAction extends Action<types.ACTUAL_REQUEST> {
  company_id: number; // текущий ид компании
}

export interface setPaidAction extends Action<types.SET_PAID> {
  isPaid: boolean;
}
export interface checkActivePaymethodAction
  extends Action<types.CHECK_ACTIVE_PAYMETHOD> {
  is_active_paymethod: boolean;
}
export interface setBillInfoAction extends Action<types.SET_BILL_INFO> {
  bill_info: any;
}
export interface setBillUrlAction extends Action<types.SET_BILL_URL> {
  url: string;
}
export interface setPaymentRequestIDAction
  extends Action<types.SET_PAYMENT_REQUEST_ID> {
  payment_request_id: number;
}

export interface setSubscribeStartAction
  extends Action<types.SET_SUBSCRIBE_START> {
  subscribe_start: string;
}
export interface setSubscribeEndAction extends Action<types.SET_SUBSCRIBE_END> {
  subscribe_end: string;
}
export interface setUsersPaidAction extends Action<types.SET_USERS_PAID> {
  users_paid: number;
}
export interface setLoadingAction extends Action<types.SET_LOADING> {
  loading: boolean;
}

export interface setMonthsCountAction extends Action<types.SET_MONTHS_COUNT> {
  months_count: number;
}

export interface billPDFCreateAction extends Action<types.BILL_PDF_CREATE> {
  payment_request_id: number; // ид подписки. скачивание пдф
}
export interface createBillAction extends Action<types.CREATE_BILL> {
  company_id: number | string; // текущий ид компании
  months_count: number; // количество месяцев в калькуляторе
  users_count: number; //кол-во юзеров в кальк
  modules: string[]; // галочки в кальк
  payment_type: string; // тип оплаты
}
export interface billPDFCreateAction extends Action<types.BILL_PDF_CREATE> {
  payment_request_id: number; // ид подписки. скачивание пдф
}

export interface amountAction extends Action<types.AMOUNT> {
  company_id: number | string; // текущий ид компании
  months_count: number; // количество месяцев в калькуляторе
  users_count: number; //кол-во юзеров в кальк
  modules: string[]; // галочки в кальк
}
export interface actualRequestAction extends Action<types.ACTUAL_REQUEST> {
  company_id: number; // текущий ид компании
}
export interface totalPriceAction extends Action<types.SET_TOTAL_PRICE> {
  total_price: string;
}
export interface setSubscribeStatusAction
  extends Action<types.SET_SUBSCRIBE_STATUS> {
  subscribe_status: boolean;
}
export interface setSelectedModulesAction
  extends Action<types.SET_SELECTED_MODULES> {
  module: string;
}

export interface setModulesPriceAction extends Action<types.SET_MODULES_PRICE> {
  module_prices: object; // текущий ид компании
}
export interface fetchModulesPriceAction
  extends Action<types.FETCH_MODULES_PRICE> {}
export interface changeStepAction extends Action<types.CHANGE_STEP> {
  stepNumber: number;
}

export interface changeTaskListAction extends Action<types.CHANGE_TASK_LIST> {
  tasks: Task[];
}

export interface fetchTaskDependenceAction
  extends Action<types.FETCH_TASK_DEPENDENCE> {
  id: number;
}

export interface paidSuccessAction extends Action<types.PAID_SUCCESS> {
  value: boolean;
}

export interface paymentDetailsAction extends Action<types.PAYMENT_DETAILS> {
  value: object;
}

export interface pricePartsAction extends Action<types.CHANGE_PRICE_PARTS> {
  members?: number;
  months?: number;
}

export interface setFreeAction extends Action<types.SET_FREE> {
  companyId: number;
}

export type PaymentsAction =
  | createBillAction
  | billPDFCreateAction
  | amountAction
  | actualRequestAction
  | setModulesPriceAction
  | changeStepAction
  | pricePartsAction
  | fetchTaskDependenceAction
  | paidSuccessAction
  | paymentDetailsAction
  | changeTaskListAction
  | setPaidAction
  | setFreeAction
  | totalPriceAction
  | setSubscribeStatusAction
  | setSelectedModulesAction
  | setBillUrlAction
  | setPaymentRequestIDAction
  | setSubscribeStartAction
  | setSubscribeEndAction
  | setLoadingAction
  | setBillInfoAction
  | setUsersPaidAction
  | checkActivePaymethodAction
  | setMonthsCountAction;
