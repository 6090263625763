import React, { useCallback, ReactNode } from 'react';
import './styles.sass';
import { urlApp } from '../../../App';
import Avatar from '../Avatar/Avatar';
import { User } from '../../types';

type Props = {
  text: string;
  checked: boolean;
  value: number;
  user: User;
  component?: ReactNode;
  // addHandler: (value: number) => void,
  // removeHandler: (value: number) => void,
  handler: (user: User, value: string) => void;
  positions: any;
  action_icon: string;
};

const NewCheckboxItem: React.FC<Props> = ({
  text,
  handler,
  action_icon,
  positions,
  user,
  checked,
  value,
  component,
}) => {
  const clickHandler = useCallback(() => {
    // if (!checked) addHandler(value)
    // else removeHandler(value)
    handler(user, action_icon);
  }, [checked, value]);

  return (
    <div onClick={clickHandler} className="new_checkbox_item">
      <div className={'leftPart'}>
        {/*{component}*/}
        {/*<div className={'avatar'}/>*/}
        <Avatar
          style={{ width: '32px', height: '32px', marginRight: '8px' }}
          name={text}
          url={user.image ? urlApp + `/files/${user.image.url}/download` : null}
        />
        <div className="checkbox_text">
          <div>{text}</div>
          <div>
            {
              positions.find((position) => position.id === user.positions[0])
                .name
            }
          </div>
        </div>
      </div>

      {action_icon && (
        <div className={'rightPart'}>
          <div
            className={`action_icon ${
              action_icon === 'checked' ? 'checked' : ''
            } ${action_icon === 'plus' ? 'plus' : ''}  ${
              action_icon === 'cross' ? 'cross' : ''
            }   `}
          />
        </div>
      )}
    </div>
  );
};

export default NewCheckboxItem;
