import React, { useState } from "react";
// import Input from '../../../common/components/Inputs/Input';
import SectionElem from "./SectionElem";
import { connect } from "react-redux";
import { setProjectSections, addSection } from "../actions";
import { State } from "../../../rootReducer";
import { getSections } from "../selectors";
import styled from "styled-components";
import { SectionInComponent } from "../../../common/types";

import Button from "../../../common/components/newComponent/Buttons/Button";
import CustomInput from "../../../common/components/newComponent/Inputs/CustomInput";

type Props = {
  projectId: number;
  sections: SectionInComponent[];
  setProjectSections: (sections: SectionInComponent[]) => void;
  addSection: (projectId: number, name: string) => void;
  disabled?: boolean;
};

const WrapperBlockInput = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WrapperBlockSections = styled.div`
  position: relative;
  max-width: 408px;
  width: 100%;

  li {
    display: flex;
    justify-content: space-between;
    min-height: 24px;
    line-height: 24px;
    margin: 0.5rem 0;
  }
  
  .project_sections__disabled {
    backdrop-filter: grayscale(1);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
`;

const ProjectSections: React.FC<Props> = ({
  sections,
  projectId,
  setProjectSections,
  addSection,
  disabled
}) => {
  const [sectionName, setSectionName] = useState("");

  const addHandler = () => {
    if (!sectionName) return;
    if (projectId) {
      addSection(projectId, sectionName);
    } else {
      setProjectSections([...sections, { name: sectionName, id: 0 }]);
    }
    setSectionName("");
  };

  return (
    <WrapperBlockSections className="project_sections">
      {disabled && (
        <div className='project_sections__disabled'></div>
      )}
      
      {sections.length > 0 && (
        <ol>
          {sections.map((section, ind) => (
            <SectionElem
              name={section.name}
              id={section.id ? section.id : 0}
              key={`${performance.now()}_${ind}`}
              index={ind}
            />
          ))}
        </ol>
      )}
      <WrapperBlockInput>
        {/* <Input
          inputType="text"
          placeholder=""
          title=""
          value={sectionName}
          changeHandler={setSectionName}
        /> */}
        <CustomInput
          // placeholder="Обязательное поле"
          value={sectionName}
          inputChangeHandler={setSectionName}
        />
        <Button
          onClick={addHandler}
          title="Добавить"
          design="outline"
          style={{ marginLeft: ".5rem", alignItems: "center" }}
        />
      </WrapperBlockInput>
    </WrapperBlockSections>
  );
};

const mapStateToProps = (state: State) => {
  return {
    sections: getSections(state),
    projectId: state.newProject.id,
  };
};

const mapDispatchToProps = {
  setProjectSections,
  addSection,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSections);
