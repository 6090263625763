import {Action} from "redux"
import {Task, Project, Meeting} from "../../common/types"

export enum types {
    FETCH_MEETINGS = '@@MEETINGS/FETCH_MEETINGS',
    WRITE_MEETINGS = '@@MEETINGS/WRITE_MEETINGS',
    SET_NEW_PARAMETERS_TO_REDUCER = '@@MEETINGS/SET_NEW_PARAMETERS_TO_REDUCER',
    SET_DEFAULT_TO_REDUCER = '@@MEETINGS/SET_DEFAULT_TO_REDUCER',
    MEETING_PLATE_HIDING_SIGNAL = '@@MEETINGS/MEETING_PLATE_HIDING_SIGNAL',
    CREATE_NEW_MEETING = '@@MEETINGS/CREATE_NEW_MEETING',
    EDIT_MEETING = '@@MEETINGS/EDIT_MEETING',
    CREATE_CHECKLIST_ITEMS = '@@MEETINGS/CREATE_CHECKLIST_ITEMS',
    CREATE_RESOLUTION_ITEM = '@@MEETINGS/CREATE_RESOLUTION_ITEM',
    PATCH_RESOLUTION_ITEM = '@@MEETINGS/PATCH_RESOLUTION_ITEM'
}


export interface fetchMeetingsAction extends Action<types.FETCH_MEETINGS> {
    tableFilter?: string
}

export interface writeMeetingsAction extends Action<types.WRITE_MEETINGS> {
    meetings: Meeting[]
}

export interface setNewParametersToReducerAction extends Action<types.SET_NEW_PARAMETERS_TO_REDUCER> {
    data: Object
}

export interface setMeetingDefaultActionsAction extends Action<types.SET_DEFAULT_TO_REDUCER> {

}

export interface meetingHidePlateAction extends Action<types.MEETING_PLATE_HIDING_SIGNAL> {
    value: boolean
}

export interface createNewMeetingAction extends Action<types.CREATE_NEW_MEETING> {
}

export interface editMeetingAction extends Action<types.EDIT_MEETING> {
}

export interface createChecklistItemsAction extends Action<types.CREATE_CHECKLIST_ITEMS> {

}

export interface createResolutionItemAction extends Action<types.CREATE_RESOLUTION_ITEM> {
    context: string
    delete_index?: number
}

export interface patchResolutionItemAction extends Action<types.PATCH_RESOLUTION_ITEM> {
    context: string
    id: number,
    body: object
}


export type MeetingsAction =
    fetchMeetingsAction |
    writeMeetingsAction |
    setMeetingDefaultActionsAction |
    meetingHidePlateAction |
    createNewMeetingAction |
    editMeetingAction |
    createChecklistItemsAction |
    createResolutionItemAction |
    patchResolutionItemAction |
    setNewParametersToReducerAction
