export default function (scheduler) {
  scheduler.$keyboardNavigation.marker = {
    clear: function clear() {
      var divs = scheduler.$container.querySelectorAll(".dhx_focus_slot");
      for (var i = 0; i < divs.length; i++) {
        divs[i].parentNode.removeChild(divs[i]);
      }
    },
    createElement: function createElement() {
      var element = document.createElement("div");
      element.setAttribute("tabindex", -1);
      element.className = "dhx_focus_slot";
      return element;
    },
    renderMultiple: function renderMultiple(start, end, method) {
      var divs = [];
      var currentStart = new Date(start);
      var currentEnd = new Date(Math.min(end.valueOf(), scheduler.date.add(scheduler.date.day_start(new Date(start)), 1, "day").valueOf()));
      while (currentStart.valueOf() < end.valueOf()) {
        divs = divs.concat(method.call(this, currentStart, new Date(Math.min(currentEnd.valueOf(), end.valueOf()))));
        currentStart = scheduler.date.day_start(scheduler.date.add(currentStart, 1, "day"));
        currentEnd = scheduler.date.day_start(scheduler.date.add(currentStart, 1, "day"));
        currentEnd = new Date(Math.min(currentEnd.valueOf(), end.valueOf()));
      }
      return divs;
    },
    render: function render(start, end, section) {
      this.clear();
      var divs = [];
      var modes = scheduler.$keyboardNavigation.TimeSlot.prototype._modes;
      var view = scheduler.$keyboardNavigation.TimeSlot.prototype._getMode();
      switch (view) {
        case modes.units:
          divs = this.renderVerticalMarker(start, end, section);
          break;
        case modes.timeline:
          divs = this.renderTimelineMarker(start, end, section);
          break;
        case modes.year:
          divs = divs.concat(this.renderMultiple(start, end, this.renderYearMarker));
          break;
        case modes.month:
          divs = this.renderMonthMarker(start, end);
          break;
        case modes.weekAgenda:
          divs = divs.concat(this.renderMultiple(start, end, this.renderWeekAgendaMarker));
          break;
        case modes.list:
          divs = this.renderAgendaMarker(start, end);
          break;
        case modes.dayColumns:
          divs = divs.concat(this.renderMultiple(start, end, this.renderVerticalMarker));
          break;
      }
      this.addWaiAriaLabel(divs, start, end, section);
      this.addDataAttributes(divs, start, end, section);
      for (var i = divs.length - 1; i >= 0; i--) {
        if (divs[i].offsetWidth) {
          return divs[i];
        }
      }
      return null;
    },
    addDataAttributes: function addDataAttributes(divs, start, end, section) {
      var dateToStr = scheduler.date.date_to_str(scheduler.config.api_date);
      var from = dateToStr(start),
        to = dateToStr(end);
      for (var i = 0; i < divs.length; i++) {
        divs[i].setAttribute("data-start-date", from);
        divs[i].setAttribute("data-end-date", to);
        if (section) {
          divs[i].setAttribute("data-section", section);
        }
      }
    },
    addWaiAriaLabel: function addWaiAriaLabel(divs, start, end, section) {
      var label = "";
      var state = scheduler.getState();
      var mode = state.mode;
      var dateTimeLabel = false;
      label += scheduler.templates.day_date(start);
      if (scheduler.date.day_start(new Date(start)).valueOf() != start.valueOf()) {
        label += " " + scheduler.templates.hour_scale(start);
        dateTimeLabel = true;
      }
      if (scheduler.date.day_start(new Date(start)).valueOf() != scheduler.date.day_start(new Date(end)).valueOf()) {
        label += " - " + scheduler.templates.day_date(end);
        if (dateTimeLabel || scheduler.date.day_start(new Date(end)).valueOf() != end.valueOf()) {
          label += " " + scheduler.templates.hour_scale(end);
        }
      }
      if (section) {
        if (scheduler.matrix && scheduler.matrix[mode]) {
          label += ", " + scheduler.templates[mode + "_scale_label"](section.key, section.label, section);
        } else if (scheduler._props && scheduler._props[mode]) {
          label += ", " + scheduler.templates[mode + "_scale_text"](section.key, section.label, section);
        }
      }
      for (var i = 0; i < divs.length; i++) {
        scheduler._waiAria.setAttributes(divs[i], {
          "aria-label": label,
          "aria-live": "polite"
        });
      }
    },
    renderWeekAgendaMarker: function renderWeekAgendaMarker(start_date, end_date) {
      var divs = scheduler.$container.querySelectorAll(".dhx_wa_day_cont .dhx_wa_scale_bar");
      var currDate = scheduler.date.week_start(new Date(scheduler.getState().min_date));
      var index = -1;
      var markerDate = scheduler.date.day_start(new Date(start_date));
      for (var i = 0; i < divs.length; i++) {
        index++;
        if (scheduler.date.day_start(new Date(currDate)).valueOf() == markerDate.valueOf()) {
          break;
        } else {
          currDate = scheduler.date.add(currDate, 1, "day");
        }
      }
      if (index != -1) return this._wrapDiv(divs[index]);
      return [];
    },
    _wrapDiv: function _wrapDiv(cell) {
      var marker = this.createElement();
      marker.style.top = cell.offsetTop + "px";
      marker.style.left = cell.offsetLeft + "px";
      marker.style.width = cell.offsetWidth + "px";
      marker.style.height = cell.offsetHeight + "px";
      cell.appendChild(marker);
      return [marker];
    },
    renderYearMarker: function renderYearMarker(start_date, end_date) {
      var cell = scheduler._get_year_cell(start_date);
      cell.style.position = "relative";
      var marker = this.createElement();
      marker.style.top = "0px";
      marker.style.left = "0px";
      marker.style.width = "100%";
      marker.style.height = "100%";
      cell.appendChild(marker);
      return [marker];
    },
    renderAgendaMarker: function renderAgendaMarker(start_date, end_date) {
      var block = this.createElement();
      block.style.height = "1px";
      block.style.width = "100%";
      block.style.opacity = 1;
      block.style.top = "0px";
      block.style.left = "0px";
      scheduler.$container.querySelector(".dhx_cal_data").appendChild(block);
      return [block];
    },
    renderTimelineMarker: function renderTimelineMarker(start_date, end_date, section) {
      var view_opts = scheduler._lame_copy({}, scheduler.matrix[scheduler._mode]);
      var areas = view_opts._scales;
      //timespans must always use actual position, not rounded
      view_opts.round_position = false;
      var blocks = [];
      var min_date = start_date ? new Date(start_date) : scheduler._min_date;
      var max_date = end_date ? new Date(end_date) : scheduler._max_date;
      if (min_date.valueOf() < scheduler._min_date.valueOf()) min_date = new Date(scheduler._min_date);
      if (max_date.valueOf() > scheduler._max_date.valueOf()) max_date = new Date(scheduler._max_date);
      if (!view_opts._trace_x) return blocks;
      for (var i = 0; i < view_opts._trace_x.length; i++) {
        if (scheduler._is_column_visible(view_opts._trace_x[i])) break;
      }
      if (i == view_opts._trace_x.length) return blocks;
      var area = areas[section];
      if (!(min_date < end_date && max_date > start_date)) return blocks;
      var block = this.createElement();
      var start_pos = scheduler._timeline_getX({
        start_date: start_date
      }, false, view_opts) - 1;
      var end_pos = scheduler._timeline_getX({
        start_date: end_date
      }, false, view_opts) - 1;
      var height = view_opts._section_height[section] - 1 || view_opts.dy - 1;
      var top = 0;
      if (scheduler._isRender('cell')) {
        top = area.offsetTop;
        start_pos += view_opts.dx;
        end_pos += view_opts.dx;
        area = scheduler.$container.querySelector(".dhx_cal_data");
      } else {}
      var width = Math.max(1, end_pos - start_pos - 1);
      block.style.cssText = "height: " + height + "px; left: " + start_pos + "px; width: " + width + "px; top: " + top + "px;";
      if (area) {
        area.appendChild(block);
        blocks.push(block);
      }
      return blocks;
    },
    renderMonthCell: function renderMonthCell(date) {
      var cells = scheduler.$container.querySelectorAll(".dhx_month_head");
      var divs = [];
      for (var i = 0; i < cells.length; i++) {
        divs.push(cells[i].parentNode);
      }
      var firstDate = scheduler.date.week_start(new Date(scheduler.getState().min_date));
      var index = -1;
      var weekNumber = 0;
      var dayIndex = -1;
      var currDate = firstDate;
      var markerDate = scheduler.date.day_start(new Date(date));
      for (var i = 0; i < divs.length; i++) {
        index++;
        if (dayIndex == 6) {
          weekNumber++;
          dayIndex = 0;
        } else {
          dayIndex++;
        }
        if (scheduler.date.day_start(new Date(currDate)).valueOf() == markerDate.valueOf()) {
          break;
        } else {
          currDate = scheduler.date.add(currDate, 1, "day");
        }
      }
      if (index == -1) {
        return [];
      }
      var left = scheduler._colsS[dayIndex];
      var top = scheduler._colsS.heights[weekNumber];
      var div = this.createElement();
      div.style.top = top + "px";
      div.style.left = left + "px";
      div.style.width = scheduler._cols[dayIndex] + "px";
      div.style.height = (scheduler._colsS.heights[weekNumber + 1] - top || scheduler._colsS.height) + "px";
      var container = scheduler.$container.querySelector(".dhx_cal_data");
      var datatable = container.querySelector("table");
      if (datatable.nextSibling) {
        container.insertBefore(div, datatable.nextSibling);
      } else {
        container.appendChild(div);
      }
      return div;
    },
    renderMonthMarker: function renderMonthMarker(start_date, end_date) {
      var res = [];
      var currentDate = start_date;
      while (currentDate.valueOf() < end_date.valueOf()) {
        res.push(this.renderMonthCell(currentDate));
        currentDate = scheduler.date.add(currentDate, 1, "day");
      }
      return res;
    },
    renderVerticalMarker: function renderVerticalMarker(start_date, end_date, section) {
      var index = scheduler.locate_holder_day(start_date);
      var divs = [];
      var area = null;
      var c = scheduler.config;
      if (scheduler._ignores[index]) return divs;
      if (scheduler._props && scheduler._props[scheduler._mode] && section) {
        var view = scheduler._props[scheduler._mode];
        index = view.order[section];
        var inner_index = view.order[section];
        if (!(view.days > 1)) {
          index = inner_index;
          if (view.size && index > view.position + view.size) {
            index = 0;
          }
        } else {
          //var units_l = view.size || view.options.length;

          index = scheduler.locate_holder_day(start_date) + inner_index;
          //index = index*units_l + inner_index;
        }
      }

      area = scheduler.locate_holder(index);
      if (!area || area.querySelector(".dhx_scale_hour")) {
        // hour scale instead of date column
        return document.createElement("div");
      }
      var start = Math.max(start_date.getHours() * 60 + start_date.getMinutes(), c.first_hour * 60);
      var end = Math.min(end_date.getHours() * 60 + end_date.getMinutes(), c.last_hour * 60);
      if (!end && scheduler.date.day_start(new Date(end_date)).valueOf() > scheduler.date.day_start(new Date(start_date)).valueOf()) {
        end = c.last_hour * 60;
      }
      if (end <= start) {
        return [];
      }
      var block = this.createElement();

      // +1 for working with section which really takes up whole height (as % would be == 0)
      var all_hours_height = scheduler.config.hour_size_px * c.last_hour + 1;
      var hour_ms = 60 * 60 * 1000;
      block.style.top = Math.round((start * 60 * 1000 - scheduler.config.first_hour * hour_ms) * scheduler.config.hour_size_px / hour_ms) % all_hours_height + "px";
      block.style.lineHeight = block.style.height = Math.max(Math.round((end - start) * 60 * 1000 * scheduler.config.hour_size_px / hour_ms) % all_hours_height, 1) + "px";
      block.style.width = "100%";
      area.appendChild(block);
      divs.push(block);
      return divs[0];
    }
  };
}