import React from 'react';
import { connect } from 'react-redux';
import { State } from '../../../rootReducer';
import { setAddUserToGroupDep } from '../actions';
import styled from 'styled-components';

type Props = {
  name: string;
  counter: number;
  setAddUserToGroupDep: (val: boolean) => void;
};

const SelectedDepStyle = styled.div`
  margin-bottom: 24px;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  span {
    color: rgba(0, 0, 0, 0.87);
    font-size: 32px;
    margin-bottom: 8px;
  }
`;

const SelectedDep: React.FC<Props> = ({
  name,
}) => {

  return (
    <SelectedDepStyle>
      <span>{name}</span>
    </SelectedDepStyle>
  );
};

const mapStateToProps = (state: State) => ({});

const mapDispatchToProps = {
  setAddUserToGroupDep,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectedDep);
