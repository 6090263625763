import axios from "axios";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { StatusContext } from "../../../context/status";
import NameOfStep from "../templates/NameOfStep";
import RadioButtons from "../templates/RadioButtons";
import "../BpResultForm.scss";
import SelectTemplate from "../templates/SelectTemplate";

const ForkedStage_SMZ_IP = () => {
  const {
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,
    company_id,
    setloadDataNow,
  } = useContext(StatusContext);

  const [data, setData] = useState(
    settingsOfFields?.forkedStage_SMZ_IP?.extra
      ? settingsOfFields.forkedStage_SMZ_IP.extra
      : {}
  );
  const url = taskId ? `${apiBp}/task/${taskId}` : null;
  useEffect(() => {
    console.log("use in form worked");
    if (taskId) {
      axios
        .patch(
          url,
          { ...settingsOfFields.forkedStage_SMZ_IP, extra: data },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))
        // .then(() => setInputValue())

        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);

  return (
    <form id="forkedStage_SMZ_TD">
      <div>
        <NameOfStep text={"Подтверждение должности в штатном расписании"} />
        <SelectTemplate
          listArr={["Подтверждаю", "Не подтверждаю"]}
          name={"e5"}
          data={data}
          setData={setData}
        />
      </div>
      <div>
        <NameOfStep
          text={"Соответствие указанного оклада штатному расписанию "}
        />
        <RadioButtons
          data={data}
          setData={setData}
          name={"e5_1"}
          positiveText={"Да"}
          negativeText={"Нет"}
        />
      </div>
      <div>
        <NameOfStep text={"Подтверждение формата взаимоотношений "} />
        <RadioButtons
          data={data}
          setData={setData}
          name={"e5_2"}
          positiveText={"Да"}
          negativeText={"Нет"}
        />
      </div>
      <div>
        <NameOfStep
          text={"Проверка соотношения СМЗ/ИП и трудоустроенных сотрудников"}
        />
        <SelectTemplate
          listArr={["СМЗ", "ИП"]}
          name={"e5_3"}
          data={data}
          setData={setData}
        />
      </div>
      <div>
        <NameOfStep text={"Подтверждение формата взаимоотношений "} />
        <RadioButtons
          data={data}
          setData={setData}
          name={"e5_4"}
          positiveText={"Да"}
          negativeText={"Нет"}
        />
      </div>
      <div>
        <NameOfStep text={"Подтверждение оклада"} />
        <RadioButtons
          data={data}
          setData={setData}
          name={"e5_5"}
          positiveText={"Да"}
          negativeText={"Нет"}
        />
      </div>
      <div>
        <NameOfStep
          text={
            "Подтверждение наличия лимита ДС на организации на данного сотрудника"
          }
        />
        <RadioButtons
          data={data}
          setData={setData}
          name={"e5_6"}
          positiveText={"Да"}
          negativeText={"Нет"}
        />
      </div>
    </form>
  );
};

export default ForkedStage_SMZ_IP;
