import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { StatusContext } from "../../../context/status";
import "../BpResultForm.scss";
import NameOfStep from "../templates/NameOfStep";
import SelectTemplate from "../templates/SelectTemplate";
import TextAreaOrInputElement from "../templates/TextAreaOrInputElement";

const СheckOfChiefAccountant = () => {
  const {
    users,
    settingsOfFields,
    taskId,
    bearer,
    apiBp,
    thisTabs,
    closeWindowTask,
    company_id,
    setloadDataNow,
  } = useContext(StatusContext);

  const [data, setData] = useState(
    settingsOfFields?.checkOfChiefAccountant?.extra
      ? settingsOfFields.checkOfChiefAccountant.extra
      : {}
  );

  const url = taskId ? `${apiBp}/task/${taskId}` : null;
  const typesOfDocument = ["Электронный", "Бумажный"];

  // const [nextLink, setNextLink] = useState(
  //   `${process.env.REACT_APP_PUBLIC_API}/users`
  // );
  // const [usersInitiator, setUsersInitiator] = useState([]);
  // const [inputValue, setInputValue] = useState("");
  /*
  useEffect(() => {
    if (!!nextLink) {
      
      axios
        .get(nextLink, {
          headers: {  Authorization: bearer, "company-id": company_id,
            Authorization: document.cookie.replace(
              /(?:(?:^|.*;\s*)access_token\s*\=\s*([^;]*).*$)|^.*$/,
              "$1"
            ),
          },
        })
        .then((res) => {
          
          setNextLink(res.data.links.next);
          setUsers([...res.data.data]);
        });
    }
  }, [nextLink]);

  useEffect(() => {
    users.map((el) => {
      setUsersInitiator([
        ...usersInitiator,
        {
          name: el.name + " " + el.surname,
          value: el.id,
          id: el.id,
        },
      ]);
    });
  }, [users]);
*/

  useEffect(() => {
    console.log("use in form worked");
    if (taskId) {
      axios
        .patch(
          url,
          { ...settingsOfFields.checkOfChiefAccountant, extra: data },
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
              "secret-token": localStorage.getItem("access_token_jwt"),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("answer    ", res.data.data.extra);
        })
        .then(() => setloadDataNow((loadDataNow) => !loadDataNow))
        .catch((e) => {
          console.log(e);
        });
    }
  }, [data, setData, thisTabs, closeWindowTask, taskId]);

  // console.log("     ", );

  return (
    <form id="checkOfChiefAccountant">
      <div>
        <NameOfStep
          text={
            "Существенность/сопоставимость сделки с основными показателями организации/аналогичными сделками"
          }
        />
        <TextAreaOrInputElement
          name={"d4"}
          isInput={false}
          type={"text"}
          data={data}
          setData={setData}
        />
      </div>
      <div>
        <NameOfStep
          text={
            "Существенность/сопоставимость сделки с основными показателями контрагента"
          }
        />
        <TextAreaOrInputElement
          name={"d4_1"}
          isInput={false}
          type={"text"}
          data={data}
          setData={setData}
        />
      </div>
      <div>
        <NameOfStep text={" Системы взаиморасчетов"} />
        <TextAreaOrInputElement
          name={"d4_2"}
          isInput={false}
          type={"text"}
          data={data}
          setData={setData}
        />
      </div>
      <div>
        <NameOfStep
          text={"Переход права собственности на запасы, ОС, НА и другой"}
        />
        <TextAreaOrInputElement
          name={"d4_3"}
          isInput={false}
          type={"text"}
          data={data}
          setData={setData}
        />
      </div>

      <div>
        <NameOfStep text={"Выбор вида договора"} />
        <TextAreaOrInputElement
          name={"d4_4"}
          isInput={false}
          type={"text"}
          data={data}
          setData={setData}
        />
      </div>
      <div>
        <NameOfStep text={"Тип документооборота"} />
        <div>
          <SelectTemplate
            listArr={typesOfDocument}
            name={"d4_5"}
            data={data}
            setData={setData}
          />
        </div>
      </div>
      <div>
        <NameOfStep text={"Ответственный менеджер"} />
        <SelectTemplate
          listArr={users}
          name={"d4_6"}
          data={data}
          setData={setData}
        />
      </div>
      <div>
        <NameOfStep
          text={
            "При внешнеторговом контракте оценка рисков по 115 ФЗ, анализ возможных проблем при валютном контроле"
          }
        />
        <TextAreaOrInputElement
          name={"d4_7"}
          isInput={false}
          type={"text"}
          data={data}
          setData={setData}
        />
      </div>
      <div>
        <NameOfStep text={"Риски контрагента"} />
        <TextAreaOrInputElement
          name={"d4_8"}
          isInput={true}
          type={"text"}
          data={data}
          setData={setData}
        />
      </div>
      <div>
        <NameOfStep
          text={"Соответствие предмета договора деятельности нашей организации"}
        />
        <TextAreaOrInputElement
          name={"d4_9"}
          isInput={false}
          type={"text"}
          data={data}
          setData={setData}
        />
      </div>
      <div>
        <NameOfStep
          text={
            "Соответствие предмета договора примерам первичных документов + отчетов по крупным расходам"
          }
        />
        <TextAreaOrInputElement
          name={"d4_10"}
          isInput={false}
          type={"text"}
          data={data}
          setData={setData}
        />
      </div>
      <div>
        <NameOfStep text={"Прочие условия договора"} />
        <TextAreaOrInputElement
          name={"d4_11"}
          isInput={false}
          type={"text"}
          data={data}
          setData={setData}
        />
      </div>
      <div>
        <NameOfStep
          text={"Риски принятия данную услугу/товар/ТМЦ в расход для целей НУ"}
        />
        <TextAreaOrInputElement
          name={"d4_12"}
          isInput={false}
          type={"text"}
          data={data}
          setData={setData}
        />
      </div>
      <div>
        <NameOfStep text={"Система налогообложения контрагент"} />
        <TextAreaOrInputElement
          name={"d4_13"}
          isInput={false}
          type={"text"}
          data={data}
          setData={setData}
        />
      </div>
      <div>
        <NameOfStep
          text={
            "Комментарии по особенностям Налогового и Бух учета для Фин Дира и операционного бухгалтера на участке"
          }
        />
        <TextAreaOrInputElement
          name={"d4_14"}
          isInput={false}
          type={"text"}
          data={data}
          setData={setData}
        />
      </div>
    </form>
  );
};

export default СheckOfChiefAccountant;
