export default function extend(scheduler) {
  function div(className) {
    var element = document.createElement("div");
    var classes = (className || "").split(" ");
    classes.forEach(function (cssClass) {
      element.classList.add(cssClass);
    });
    return element;
  }
  var itemTypes = {
    rows_container: function rows_container() {
      return div("dhx_cal_navbar_rows_container");
    },
    row: function row() {
      return div("dhx_cal_navbar_row");
    },
    view: function view(config) {
      var element = div("dhx_cal_tab");
      element.setAttribute("name", config.view + "_tab");
      element.setAttribute("data-tab", config.view);
      // element.setAttribute("data-viewname", config.view); // FIXME: need to remove? not used anywhere
      if (scheduler.config.fix_tab_position) {
        if (config.$firstTab) {
          element.classList.add("dhx_cal_tab_first");
        } else if (config.$lastTab) {
          element.classList.add("dhx_cal_tab_last");
        } else if (config.view !== "week") {
          element.classList.add("dhx_cal_tab_standalone");
        }
      }
      return element;
    },
    date: function date() {
      return div("dhx_cal_date");
    },
    button: function button(config) {
      return div("dhx_cal_nav_button dhx_cal_nav_button_custom dhx_cal_tab");
    },
    builtInButton: function builtInButton(config) {
      return div("dhx_cal_" + config.view + "_button dhx_cal_nav_button");
    },
    spacer: function spacer() {
      return div("dhx_cal_line_spacer");
    },
    minicalendarButton: function minicalendarButton(config) {
      var minicalendarDiv = div('dhx_minical_icon');
      if (!config.click && !minicalendarDiv.$_eventAttached) {
        scheduler.event(minicalendarDiv, "click", function () {
          if (scheduler.isCalendarVisible()) {
            scheduler.destroyCalendar();
          } else {
            scheduler.renderCalendar({
              position: this,
              date: scheduler.getState().date,
              navigation: true,
              handler: function handler(date, calendar) {
                scheduler.setCurrentView(date);
                scheduler.destroyCalendar();
              }
            });
          }
        });
      }
      return minicalendarDiv;
    },
    html_element: function html_element(config) {
      return div("dhx_cal_nav_content");
    }
  };
  function findRenderer(config) {
    var renderer;
    if (config.view) {
      switch (config.view) {
        case "today":
        case "next":
        case "prev":
          renderer = itemTypes.builtInButton;
          break;
        case "date":
          renderer = itemTypes.date;
          break;
        case "spacer":
          renderer = itemTypes.spacer;
          break;
        case "button":
          renderer = itemTypes.button;
          break;
        case "minicalendar":
          renderer = itemTypes.minicalendarButton;
          break;
        default:
          renderer = itemTypes.view;
          break;
      }
    } else if (config.rows) {
      renderer = itemTypes.rows_container;
    } else if (config.cols) {
      renderer = itemTypes.row;
    }
    return renderer;
  }
  function renderElement(config) {
    var renderer = findRenderer(config);
    if (!renderer) {
      return;
    }
    var element = renderer(config);
    if (config.css) {
      element.classList.add(config.css);
    }
    if (config.width) {
      var value = config.width;
      if (value === value * 1) {
        value += "px";
      }
      element.style.width = value;
    }
    if (config.height) {
      var value = config.height;
      if (value === value * 1) {
        value += "px";
      }
      element.style.height = value;
    }
    if (config.click) {
      element.addEventListener("click", config.click);
    }
    if (config.html) {
      element.innerHTML = config.html;
    }
    if (config.align) {
      var value = "";
      if (config.align == "right") {
        value = "flex-end";
      } else if (config.align == "left") {
        value = "flex-start";
      }
      element.style.justifyContent = value;
    }
    return element;
  }
  function prepareConfig(config) {
    if (typeof config === "string") {
      config = {
        view: config
      };
    }
    if (!config.view && !config.rows && !config.cols) {
      config.view = "button";
    }
    return config;
  }
  function renderLayout(config) {
    var fragment = document.createDocumentFragment();
    var items;
    if (Array.isArray(config)) {
      items = config;
    } else {
      items = [config];
    }
    for (var i = 0; i < items.length; i++) {
      var view = prepareConfig(items[i]);
      if (view.view === "day" && items[i + 1]) {
        var next = prepareConfig(items[i + 1]);
        if (next.view === "week" || next.view === "month") {
          view.$firstTab = true;
        }
      }
      if (view.view === "month" && items[i - 1]) {
        var next = prepareConfig(items[i - 1]);
        if (next.view === "week" || next.view === "day") {
          view.$lastTab = true;
        }
      }
      var element = renderElement(view);
      fragment.appendChild(element);
      if (view.cols || view.rows) {
        element.appendChild(renderLayout(view.cols || view.rows));
      }
    }
    return fragment;
  }
  scheduler._init_nav_bar = function (items) {
    var navBar = this.$container.querySelector(".dhx_cal_navline");
    if (!navBar) {
      navBar = document.createElement("div");
      navBar.className = "dhx_cal_navline dhx_cal_navline_flex";
      scheduler._update_nav_bar(items, navBar);
      return navBar;
    }
    return navBar;
  };
  var previousNavbar = null;
  var previousHeight = null;
  scheduler._update_nav_bar = function (config, container) {
    if (!config) {
      return;
    }
    var heightChanged = false;
    var configChanged = false;
    var newHeight = config.height || scheduler.xy.nav_height;
    if (previousHeight === null || previousHeight !== newHeight) {
      heightChanged = true;
    }
    if (!previousNavbar || JSON.stringify(config) !== previousNavbar) {
      configChanged = true;
    }
    if (heightChanged) {
      scheduler.xy.nav_height = newHeight;
    }
    if (configChanged) {
      container.innerHTML = "";
      container.appendChild(renderLayout(config));
    }
    if (heightChanged || configChanged) {
      scheduler.unset_actions();
      scheduler._els = [];
      scheduler.get_elements();
      scheduler.set_actions();
    }
    if (newHeight === 0) {
      container.style.display = "none";
    } else {
      container.style.display = "";
    }
    previousHeight = newHeight;
  };
}