import React, { useState } from 'react';
import styled from "styled-components";

const WrapperTasksTableHead = styled.div`
  /* max-width: 1280px; */
  overflow: hidden;
  width: 100%;
  padding: 0px 5px 0px 40px;
  margin-right: ${({ isScroll }) => isScroll && "8px"};
  display: flex;
  height: 69px;
  justify-content: space-between;
`;

const BlockStyled = styled.div`
  display: flex;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #a8a8a8;
  align-items: center;

  svg {
    width: 10px;
    height: 10px;
  }
`;

const HeaderItemName = styled.div`
  cursor: pointer;
  padding-left: 4px;
  & > span {
    position: relative;
  }
`;

const columnsOfTables = [
  {
    columnId: "id",
    showingText: "№",
    style: { minWidth: "110px", paddingLeft: "38px" },
  },
  {
    columnId: "nameTask",
    showingText: "Задача",
    style: { minWidth: "65%", maxWidth: "800px", marginLeft: "11px" },
  },
  {
    columnId: "author_id",
    showingText: "Автор",
    style: { width: "82px", paddingLeft: "10px" },
  },
  {
    columnId: "executor_id",
    showingText: "Исполнитель",
    style: {
      width: "75px",
      marginRight: "15px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  {
    columnId: "project",
    showingText: "Проект/Раздел",
    style: { minWidth: "192px"},
  },
  {
    columnId: "end",
    showingText: "Дедлайн",
    style: { minWidth: "100px" },
  },
  {
    columnId: "task_load",
    showingText: "Загрузка",
    style: { minWidth: "92px" },
  },
  {
    columnId: "priority_id",
    showingText: "Приоритет",
    style: { minWidth: "77px" },
  },
];

const MeetingTableMiniHead = () => {
  const [Clicked, setClicked] = useState<any>({ col: "end", count: 1 });
    return (
        <WrapperTasksTableHead>
        <BlockStyled>
          {Object.keys(columnsOfTables).map((key) => {
            return (
              (columnsOfTables[key].columnId === "id" ||
                columnsOfTables[key].columnId === "nameTask") && (
                <HeaderItemName
                  key={key}
                  style={columnsOfTables[key].style}
                  onClick={() => {
                    setClicked({
                      col: columnsOfTables[key].columnId,
                      count:
                        Clicked.col === columnsOfTables[key].columnId
                          ? Clicked.count === 2
                            ? 1
                            : Clicked.count + 1
                          : 1,
                    });
                  }}
                >
                  <span>
                    {columnsOfTables[key].showingText}
                  </span>
                </HeaderItemName>
              )
            );
          })}
        </BlockStyled>
  
        <BlockStyled style={{ marginRight: "50px" }}>
          {Object.keys(columnsOfTables).map((key) => {
            return columnsOfTables[key].columnId === "author_id" ||
              columnsOfTables[key].columnId === "executor_id" ||
              columnsOfTables[key].columnId === "project" ||
              columnsOfTables[key].columnId === "end" ||
              columnsOfTables[key].columnId === "task_load" ||
              columnsOfTables[key].columnId === "priority_id" ? (
              <HeaderItemName
                key={columnsOfTables[key].columnId}
                style={columnsOfTables[key].style}
                onClick={() => {
                  setClicked({
                    col: columnsOfTables[key].columnId,
                    count:
                      Clicked.col === columnsOfTables[key].columnId
                        ? Clicked.count === 2
                          ? 1
                          : Clicked.count + 1
                        : 1,
                  });
                }}
              >
                <span>
                  {columnsOfTables[key].showingText}
                  {/* <SortIcon
                    active={Clicked.col === columnsOfTables[key].columnId}
                    activeSort={
                      Clicked.col === columnsOfTables[key].columnId &&
                      Clicked.count === 1
                        ? "top"
                        : "bottom"
                    }
                  /> */}
                </span>
                {/* {Clicked.col === columnsOfTables[key].columnId &&
                    Clicked.count === 1 && <DetailsRoundedIcon />}
                  {Clicked.col === columnsOfTables[key].columnId &&
                    Clicked.count === 2 && <ChangeHistoryRoundedIcon />} */}
              </HeaderItemName>
            ) : null;
          })}
        </BlockStyled>
      </WrapperTasksTableHead>
    );
};

export default MeetingTableMiniHead;