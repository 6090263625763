// Кнопка смены пароля пользователем
import React from 'react';
import withTitle from '../../../utils/hoc/withTitle';
import { connect } from 'react-redux';
import { State } from '../../../rootReducer';
import { setChangePasswordMode } from '../actions';
import NameWithInput from '../../../common/components/newComponent/Inputs/NameWithInput';

import { Link, useHistory } from 'react-router-dom';

type Props = {
  password: string;
  setChangePasswordMode: (mode: boolean) => void;
};

const UserPassword: React.FC<Props> = ({ setChangePasswordMode }) => {
  return (
    <NameWithInput
      title="Пароль"
      iconName="key"
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '40px',
      }}
    >
      {/*
      <div
        style={{ color: '#1BAAF0', cursor: 'pointer' }}
        onClick={() => setChangePasswordMode(true)}
      >
        Сменить пароль
      </div>
      */}
      {/*
      <div
        style={{ color: '#1BAAF0', cursor: 'pointer' }}
      >
         <Link to='/password-recovery-from-user'> Сменить пароль</Link> 
      </div>

      */} 

      <div
        style={{ color: '#1BAAF0', cursor: 'pointer' }}
      >
         <Link to='/password-recovery-from-user2'> Сменить пароль</Link> 
      </div>



    </NameWithInput>

  );
};

const mapStateToProps = (state: State) => {
  return {
    password: state.newUser.password,
  };
};

const mapDispatchToProps = {
  setChangePasswordMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPassword);
