import React from "react";
import { connect } from "react-redux";
import { Role } from "../../types";
import { State } from "../../../rootReducer";
import { getPosSelectData } from "../../../pages/NewUser/selectors";
import styled from "styled-components";

type Props = {
  roleId: number;
  roles: Role[];
  positions: Object;
  userPositionId: number;
};
const PositionBlickStyle = styled.div`
  max-width: 200px;
  min-width: 200px;
`;

const PositionCell: React.FC<Props> = ({
  roleId,
  roles,
  positions,
  userPositionId,
}) => {
  // это дикие костыли, но это самое простое быстрое решение проблемы. Будет время на рефактор - это будет переделываться.
  // getPosSelectData не отдает должность текущего пользователя (построение списка пользователей). 
  // Эта функция берет из redux только значение для личного кабинета текущего авторизованного пользователя.
  let userPosition = null;
  for (let each of positions["list"])
    if (each.value === userPositionId) userPosition = each.text;

  return <PositionBlickStyle>{userPosition}</PositionBlickStyle>;
};

const mapStateToProps = (state: State) => {
  return {
    positions: getPosSelectData(state),
    roles: state.commonInfo.roles,
  };
};

export default connect(mapStateToProps)(PositionCell);
