import axios from "axios";
import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useContext,
  useCallback,
} from "react";
import "./ChatMenu.scss";
import { StatusContext } from "../../context/status";
import DateMaster from "../../../../../utils/DateMaster";
import ReplyIcon from "@material-ui/icons/Reply";
import CloseIcon from "@material-ui/icons/Close";
import { urlApp } from "../../../../../App";
import { throttle } from "../../../../../utils/throttle";
import useDebounce from "../../../../../utils/hoc/useDebounce";
import { TextareaAutosize } from "@material-ui/core";
const Chat = ({
  bearer,
  company_id,
  etTaskId,
  userId,
  taskCurrent,
  statusOfTask,
  businessProcess,
  nameTask,
}) => {
  const refInputCheck = useRef(null);

  const [message, setMessage] = useState("");
  const [allFiles, setAllFiles] = useState([]);
  const [fetchComments, setFetchComments] = useState([]);

  const [timerFetchData, setTimerFetchData] = useState(0);
  const [checkOtvet, setCheckOtvet] = useState(false);
  const [checkOtvetId, setCheckOtvetId] = useState("");
  const [checkOtvetText, setCheckOtvetText] = useState({
    name: "",
    surname: "",
    text: "",
  });

  const changeInputValue = (InputValue) => {
    setMessage(InputValue);
  };

  const clickFormButton = () => {
    if (taskCurrent.status === 2) sendMessage();
  };
  // console.log(allFiles);
  // console.log("fetchComments", fetchComments);

  const [isFetchingNewComm, setIsFetchingNewComm] = useState(1);
  const [isFetchingOldComm, setIsFetchingOldComm] = useState(1);
  const scrollRef = useRef(null);

  const scrollHandler = useCallback(
    (e) => {
      const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
      let scrollBottom = scrollHeight - scrollTop - clientHeight;
      if (
        scrollHeight - 5 <= scrollTop + clientHeight &&
        fetchComments.length === 20 * isFetchingNewComm
      ) {
        // throttle - чтобы вызывать функцию не чаще, чем через заданный минимальный интервал.
        let f1000 = throttle(
          () => setIsFetchingNewComm(isFetchingNewComm + 1),
          1500
        );
        f1000();
      }
      if (scrollTop === 0) {
        let f1000 = throttle(
          () => setIsFetchingOldComm(isFetchingOldComm + 1),
          500
        );
        f1000();
      }
    },

    [isFetchingNewComm, fetchComments, isFetchingOldComm]
  );

  const fetchOldComment = useDebounce(isFetchingOldComm, 200);

  useEffect(() => {
    setFetchComments([]);
  }, [etTaskId]);
  //запрос на подгрузку новых коммов

  useEffect(() => {
    if (etTaskId) {
      axios
        .get(
          `${
            process.env.REACT_APP_PUBLIC_API
          }/tasks/${etTaskId}/comments/newfrom/${
            fetchComments.length && fetchComments[fetchComments.length - 1]?.id
          }`,
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
            },
          }
        )
        .then((r) => {
          // console.log("1111111111", r.data.data);
          setFetchComments([...fetchComments, ...r.data.data]);
        })
        // .then(() => console.log("комментраий получен по пагинации "))
        .catch((e) => {
          console.log(e);
        });
    }
  }, [isFetchingNewComm, timerFetchData]);

  //запрос на подгрузку старых коммов
  useEffect(() => {
    if (etTaskId) {
      axios
        .get(
          `${
            process.env.REACT_APP_PUBLIC_API
          }/tasks/${etTaskId}/comments/oldfrom/${
            fetchComments.length && fetchComments[0]?.id
          }`,
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
            },
          }
        )
        .then((r) => {
          setFetchComments([...r.data.data.reverse(), ...fetchComments]);
        })
        // .then(() => console.log("комментраий получен по пагинации  "))
        .catch((e) => {
          console.log(e);
        });
    }
  }, [fetchOldComment]);

  //таймер для запросов
  useEffect(() => {
    setInterval(() => {
      setTimerFetchData((timerFetchData) => timerFetchData + 1);
    }, 3000);
  }, []);

  //получение комментарий
  //получение комментарий
  useEffect(() => {
    if (etTaskId) {
      axios
        .get(
          `${process.env.REACT_APP_PUBLIC_API}/tasks/${etTaskId}/comments?order=id&page=1&orderType=desc`,
          {
            headers: {
              Authorization: bearer,
              "company-id": company_id,
            },
          }
        )
        .then((r) => {
          // console.log("333333333", r.data.data);
          setFetchComments(r.data.data.reverse());
        })
        // .then(() => console.log("комментраий получен  "))
        .then(() => {
          scrollRef.current.scrollTo(0, scrollRef.current.scrollHeight);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [etTaskId]);

  // удаление сообщения
  // удаление сообщения
  const deletedMessage = (taskId) => {
    let deletedCommentConfig = {
      method: "DELETE",
      url: `${process.env.REACT_APP_PUBLIC_API}/tasks/${etTaskId}/comments/${taskId}`,
      headers: {
        Accept: "application/json",
        Authorization: bearer,
        "company-id": company_id,
      },
    };

    axios(deletedCommentConfig)
      .then(() => {
        setFetchComments(fetchComments.filter((item) => item.id !== taskId));
      })
      .then(() => console.log("комментраий удален  " + taskId))
      .catch((e) => {
        console.log(e);
      });

    setCheckOtvet(false);
    setCheckOtvetId("");
  };

  //ответ на сообщение
  //ответ на сообщение
  const replyMessage = (taskId, userId, text) => {
    let infoUser = {
      method: "get",
      url: `${process.env.REACT_APP_PUBLIC_API}/users/${userId}`,
      headers: {
        Accept: "application/json",
        Authorization: bearer,
        "company-id": company_id,
      },
    };

    axios(infoUser)
      .then((res) => {
        setCheckOtvetText({
          ...checkOtvetText,
          name: res.data.data.name,
          surname: res.data.data.surname,
          text: text,
        });
      })
      .then(() => console.log("пользолватель получен"))
      .catch((e) => {
        console.log(e);
      });

    setCheckOtvet(true);
    setCheckOtvetId(taskId);
    refInputCheck.current.focus();
  };

  //отмена 'ответить на сообщение'
  //отмена 'ответить на сообщение'
  const closeOtvetInInput = () => {
    setCheckOtvetId("");
    setCheckOtvet(false);
  };

  // отправка файла и принятия его id для для дальнейщей отправки
  // отправка файла и принятия его id для для дальнейщей отправки
  const sendFile = (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    let sendFileAndGetIdConfig = {
      method: "POST",
      url: `${process.env.REACT_APP_PUBLIC_API}/files`,

      data: formData,
      headers: {
        Accept: "application/json",
        Authorization: bearer,
        "company-id": company_id,
      },
    };

    axios(sendFileAndGetIdConfig)
      .then((resp) => {
        setAllFiles([...allFiles, resp.data.data]);
      })
      .then(() => console.log("отправка файли и принятие его id "))
      .catch((e) => {
        console.log(e);
      });
  };

  // удаляет файлы
  // удаляет файлы
  const onDeletedFile = (fileId) => {
    let deletedFileConfig = {
      method: "DELETE",
      url: `${process.env.REACT_APP_PUBLIC_API}/files/${fileId}`,

      data: { success: true },
      headers: {
        Accept: "application/json",
        Authorization: bearer,
        "company-id": company_id,
      },
    };

    axios(deletedFileConfig)
      .then(() => {
        setAllFiles(allFiles.filter((com) => com.id !== fileId));
      })
      .then(() => console.log("удаление файлов "))
      .catch((e) => {
        console.log(e);
      });
  };

  //кнопка отправить комментарий и овтета на сообщение
  //кнопка отправить комментарий и овтета на сообщение
  const sendMessage = () => {
    //если есть файл то отправь с файлом
    if (allFiles.length) {
      if (!checkOtvet) {
        //просто отправить сообщение
        let sendCommentAndOtvetConfig = {
          method: "POST",
          url: `${process.env.REACT_APP_PUBLIC_API}/tasks/${etTaskId}/comments`,
          data: {
            text: message,
            author_id: userId,
            task_id: etTaskId,
            parent_id: 0,
            files: allFiles.map((i) => i.id),
          },
          headers: {
            Accept: "application/json",
            Authorization: bearer,
            "company-id": company_id,
          },
        };

        axios(sendCommentAndOtvetConfig)
          .then(() => {
            console.log("отправить комментарий  ");
          })
          .catch((e) => {
            console.log(e);
          });
        setIsFetchingNewComm(isFetchingNewComm + 1);
        setAllFiles([]);
        setMessage("");
        setCheckOtvetId("");
        setCheckOtvet(false);
      } else {
        //ответ на сообщение
        let sendOtvetConfig = {
          method: "POST",
          url: `${process.env.REACT_APP_PUBLIC_API}/tasks/${etTaskId}/comments`,
          data: {
            text: message,
            author_id: userId,
            task_id: etTaskId,
            parent_id: checkOtvetId,
            files: allFiles.map((i) => i.id),
          },
          headers: {
            Accept: "application/json",
            Authorization: bearer,
            "company-id": company_id,
          },
        };

        axios(sendOtvetConfig)
          .then((res) => console.log(res.data))
          .then(() => console.log("отправка ответа на комментарий   "))
          .catch((e) => {
            console.log(e);
          });
        setIsFetchingNewComm(isFetchingNewComm + 1);
        setAllFiles([]);
        setMessage("");
        setCheckOtvetId("");
        setCheckOtvet(false);
      }
    } //если нет файла то отправь без него
    else {
      if (!checkOtvet) {
        //просто отправить сообщение
        let sendCommentAndOtvetConfig = {
          method: "POST",
          url: `${process.env.REACT_APP_PUBLIC_API}/tasks/${etTaskId}/comments`,
          data: {
            text: message,
            author_id: userId,
            task_id: etTaskId,
            parent_id: 0,
          },
          headers: {
            Accept: "application/json",
            Authorization: bearer,
            "company-id": company_id,
          },
        };

        axios(sendCommentAndOtvetConfig)
          .then(() => console.log("отправить комментарий  "))
          .catch((e) => {
            console.log(e);
          });
        setIsFetchingNewComm(isFetchingNewComm + 1);
        setAllFiles([]);
        setMessage("");
        setCheckOtvetId("");
        setCheckOtvet(false);
      } else {
        //ответ на сообщение

        let sendOtvetConfig = {
          method: "POST",
          url: `${process.env.REACT_APP_PUBLIC_API}/tasks/${etTaskId}/comments`,
          data: {
            text: message,
            author_id: userId,
            task_id: etTaskId,
            parent_id: checkOtvetId,
          },
          headers: {
            Accept: "application/json",
            Authorization: bearer,
            "company-id": company_id,
          },
        };

        axios(sendOtvetConfig)
          // .then((res) => console.log(res.data))
          // .then(() => console.log("отправка ответа на комментарий   "))
          .catch((e) => {
            console.log(e);
          });
        setIsFetchingNewComm(isFetchingNewComm + 1);
        setAllFiles([]);
        setMessage("");
        setCheckOtvetId("");
        setCheckOtvet(false);
      }
    }
  };

  // при нажатии на интер отправлять даные
  const keyPressHandler = (event) => {
    if (event.key === "Enter") {
      if (message.length || (allFiles.length && taskCurrent.status === 2)) {
        sendMessage();
      }
    }
  };

  // console.log(fetchComments);

  return (
    <div id="chat">
      {businessProcess.template_id ? (
        <span style={{ marginBottom: "5px" }}>Чат Этапов: {nameTask}</span>
      ) : (
        <span style={{ marginBottom: "5px" }}>Чат задачи: {nameTask}</span>
      )}

      <div className="chat-content" onScroll={scrollHandler} ref={scrollRef}>
        <div className="chat-content-scroll">
          {fetchComments.map((comment) =>
            comment.author_id === userId ? (
              <div
                key={comment.id}
                className=" chat-message chat-message_text chat-message-my"
              >
                {statusOfTask !== 4 && (
                  <h4>
                    {` ${comment.author.name} ${comment.author.surname}`}
                    <span
                      onClick={() =>
                        replyMessage(
                          comment.id,
                          comment.author_id,
                          comment.text
                        )
                      }
                      className="chat-message__answer"
                    >
                      Ответить
                    </span>
                    <span
                      onClick={() => deletedMessage(comment.id)}
                      className="chat-message__deleted"
                    >
                      удалить
                    </span>
                  </h4>
                )}

                <span className="chat-message__spanData">
                  {(() => {
                    const dateMaster = new DateMaster(comment.created_at);
                    return dateMaster.commentDate;
                  })()}
                </span>
                {!!comment.parent_id && (
                  <div className="chat-message__div-Otev">
                    {fetchComments.map((com) => {
                      if (comment.parent_id === com.id) {
                        return (
                          <p
                            style={{ whiteSpace: "pre-wrap" }}
                            key={comment.id}
                          >
                            {com.text}
                          </p>
                        );
                      }
                    })}
                  </div>
                )}

                <p style={{ whiteSpace: "pre-wrap" }}>{comment.text}</p>
                {comment.files?.map((file) => (
                  <div
                    // Вот такой способ скачивания файла
                    onClick={() => {
                      window.open(urlApp + `/files/${file.url}/download`);
                    }}
                    key={file.id}
                    className="chat-message__file-download  chat-message__file-download_in-message"
                  >
                    <img
                      style={{ height: "22px" }}
                      src={`${process.env.REACT_APP_PUBLIC_URL}/assets/FilePlus.png`}
                      alt="#"
                    />
                    {/* Вот такой способ скачивания файла */}
                    <a
                      href={`${process.env.REACT_APP_PUBLIC_API}/files/${file.url}/download`}
                      target="_blank"
                    >
                      {file.name}
                    </a>
                  </div>
                ))}
              </div>
            ) : (
              <div key={comment.id} className="chat-message strangers_message">
                <div className="chat-message_img"></div>
                <div className="chat-message_text">
                  {statusOfTask !== 4 && (
                    <h4>
                      {` ${comment.author.name} ${comment.author.surname}`}
                      <span
                        onClick={() =>
                          replyMessage(
                            comment.id,
                            comment.author_id,
                            comment.text
                          )
                        }
                        className="chat-message__answer"
                      >
                        Ответить
                      </span>
                      <span
                        onClick={() => deletedMessage(comment.id)}
                        className="chat-message__deleted"
                      >
                        удалить
                      </span>
                    </h4>
                  )}

                  <span className="chat-message__spanData">
                    {(() => {
                      const dateMaster = new DateMaster(comment.created_at);
                      return dateMaster.commentDate;
                    })()}
                  </span>
                  {!!comment.parent_id && (
                    <div className="chat-message__div-Otev">
                      {fetchComments.map((com) => {
                        if (comment.parent_id === com.id) {
                          return (
                            <p
                              style={{ whiteSpace: "pre-wrap" }}
                              key={comment.id}
                            >
                              {com.text}
                            </p>
                          );
                        }
                      })}
                    </div>
                  )}
                  <p style={{ whiteSpace: "pre-wrap" }}>{comment.text}</p>
                  {comment.files?.map((file) => (
                    <div
                      // Вот такой способ скачивания файла
                      onClick={() => {
                        window.open(urlApp + `/files/${file.url}/download`);
                      }}
                      key={file.id}
                      className="chat-message__file-download  chat-message__file-download_in-message"
                    >
                      <img
                        style={{ height: "22px" }}
                        src={`${process.env.REACT_APP_PUBLIC_URL}/assets/FilePlus.png`}
                        alt="#"
                      />
                      {/* Вот такой способ скачивания файла */}
                      <a
                        href={`${process.env.REACT_APP_PUBLIC_API}/files/${file.url}/download`}
                        target="_blank"
                      >
                        {file.name}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            )
          )}

          {/* <div className="chat-content_data">
            <p>Сегодня</p>
          </div> */}
        </div>
      </div>

      {statusOfTask === 4 ? (
        <></>
      ) : (
        <div className="chat-bottom">
          {!!allFiles.length && (
            <div className="chat-bottom__files-wrapper">
              {allFiles.map((file) => (
                <div key={file.id} className="chat-message__file-download">
                  <span
                    onClick={() => onDeletedFile(file.id)}
                    className="chat-message__file-download_deletedBtn"
                  >
                    ×
                  </span>
                  <img
                    style={{ height: "22px" }}
                    src={`${process.env.REACT_APP_PUBLIC_URL}/assets/FilePlus.png`}
                    alt="#"
                  />

                  <p>{file?.name}</p>

                  {/* написать url  */}
                </div>
              ))}
            </div>
          )}

          <input id={1232131232131221} type="file" onChange={sendFile} />

          <button
            className={`chat-btn ${
              allFiles.length > 0 ? "chat-btn__file-action" : ""
            } `}
            style={{
              marginRight: "8px",
            }}
          >
            <label htmlFor={1232131232131221} className="chat-bottom_label">
              <img
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/input/Vector.svg`}
              />
            </label>
          </button>

          {/* <button className="chat-btn">
          <img src={`${process.env.REACT_APP_PUBLIC_URL}/assets/@.svg`} />
        </button> */}
          {checkOtvet && (
            <div className=" chat-bottom__input_otvet">
              <div className=" chat-bottom__input_otvet_icon">
                <ReplyIcon />
              </div>
              <div className=" chat-bottom__input_otvet_text">
                <h5>{`${checkOtvetText.name}  ${checkOtvetText.surname}`}</h5>
                <p>{checkOtvetText.text}</p>
              </div>
              <div
                onClick={closeOtvetInInput}
                className=" chat-bottom__input_otvet_iconClose"
              >
                <CloseIcon />
              </div>
            </div>
          )}
          <TextareaAutosize
            aria-label=""
            ref={refInputCheck}
            id={"rightPlate_name_textarea"}
            className="input-form"
            style={{
              width: "70%",
              marginLeft: "17px",
              marginRight: "25px",
              lineHeight: "20px",
            }}
            placeholder={
              taskCurrent.status === 2
                ? "Введите текст"
                : "Задача не в работе. Чат недоступен"
            }
            value={message}
            onChange={(e) => changeInputValue(e.currentTarget.value)}
          />

          {/* <input
          onKeyPress={keyPressHandler}
          ref={refInputCheck}
          className="input-form"
          style={{
            width: 70 + "%",
            marginLeft: 17 + "px",
            marginRight: 25 + "px",
          }}
          type="text"
          placeholder={
            taskCurrent.status === 2
              ? "Введите текст"
              : "Задача не в работе. Чат недоступен"
          }
          value={message}
          onChange={(e) => changeInputValue(e.target.value)}
        /> */}
          {!message.length && !allFiles.length ? (
            <button
              style={{ background: "#ececec", cursor: "default" }}
              className="chat-btn"
              id="send-message"
            >
              <img
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/send-message.svg`}
              />
            </button>
          ) : (
            <button
              className="chat-btn"
              id="send-message"
              onClick={clickFormButton}
            >
              <img
                src={`${process.env.REACT_APP_PUBLIC_URL}/assets/send-message.svg`}
              />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Chat;
