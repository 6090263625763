import React from "react";
import styled from "styled-components";

type Props = {
  children?: any;
  title?: string;
  style?: object;
};
const LoginBlock = styled.div`
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgb(32 48 90 / 5%);
  border-radius: 20px;
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const Title = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  color: #02405e;
  margin-bottom: 24px;
  text-align: center;
`;

const CustomBlock: React.FC<Props> = ({ children, title, style }) => {
  return (
    <LoginBlock>
      {title && <Title style={style}>{title}</Title>}
      {children}
    </LoginBlock>
  );
};

export default CustomBlock;
