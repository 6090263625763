import React from 'react';

const AccordionIcon4 = () => {
    return (
        <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.78463 6.10887L1.68476 1.06726C1.61653 1.02479 1.53843 1.00161 1.45849 1.00008C1.37855 0.998556 1.29965 1.01875 1.22992 1.05858C1.16018 1.09842 1.10212 1.15645 1.06171 1.22672C1.0213 1.29699 1 1.37695 1 1.45839V11.5416C1 11.623 1.0213 11.703 1.06171 11.7733C1.10212 11.8435 1.16018 11.9016 1.22992 11.9414C1.29965 11.9813 1.37855 12.0014 1.45849 11.9999C1.53843 11.9984 1.61653 11.9752 1.68476 11.9327L9.78463 6.89113C9.85041 6.85018 9.90477 6.79271 9.94248 6.72422C9.98019 6.65573 10 6.57852 10 6.5C10 6.42148 9.98019 6.34427 9.94248 6.27578C9.90477 6.20729 9.85041 6.14982 9.78463 6.10887Z" stroke="#436EA0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        
    );
};

export default AccordionIcon4;