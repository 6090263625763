import {types, setCompanyAction} from './action_types';
import {File as FileType} from '../../common/types';

import {Reducer} from 'redux';

const initialState = {
    id: 0,
    name: '',
    description: '',
    address: '',
    inn: '',
    kpp: '',
    okved: '',
    bik: '',
    ogrn: '',
    logo: null as File | FileType | null,
    bank_name: '',
    checking_account: '',
    correspondent_account: '',
    paid: 0 as number,
    pricing_plan_id: 0 as number,

    plateEditMode: false
};

export type CompanyInfoPlateState = typeof initialState;

const reducer: Reducer<CompanyInfoPlateState> = (
    state = initialState,
    action
) => {
    switch (action.type) {

        case types.SET_PLATE_EDIT_MODE:
            // let newObj = initialState
            // if (action.data.id) newObj['id'] = action.data.id

            return {
                ...state,
                plateEditMode: action.value
            }

        case types.SET_COMPANY:
            return {
                ...state,
                ...action.data
            };

        case types.SET_INITIAL:
            return {
                ...initialState
            };

        default:
            return state;
    }
};

export default reducer;
