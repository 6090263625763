import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { cookieMaster } from "../../../../../utils/CookieMaster";
import styled from "styled-components";
import MenuItem from "@material-ui/core/MenuItem";
import Agreed from "../../Icons/ImageWithColor/ImgMemberStatus/Agreed";
import Refusal from "../../Icons/ImageWithColor/ImgMemberStatus/Refusal";
import Time from "../../Icons/ImageWithColor/ImgMemberStatus/Time";
import Button from "../../../../../common/components/newComponent/Buttons/Button";
import { Menu } from "@material-ui/core";
import TimeWindow from "./TimeWindow";

let REACT_APP_PUBLIC_MEETING = `${process.env.REACT_APP_PUBLIC_MEETING}`;

interface Props {
  meetingId: number;
  userId: number;
  anchorEl1: null | HTMLElement;
  setAnchorEl1: (mode: null | HTMLElement) => void;
  statusDataMember: boolean;
  setStatusDataMember: (mode: boolean) => void;
  handleClick2: (mode: any) => void;
  openTime: boolean;
  setOpenTime: (mode: boolean) => void;
  openRefuse: boolean;
  setOpenRefuse: (mode: boolean) => void;
  statusMember: () => void;
}

const SpanNameStatus = styled.div`
  margin-left: 10px;
`;

const MemberStatus: React.FC<Props> = (props: Props) => {
  const {
    meetingId,
    userId,
    anchorEl1,
    setAnchorEl1,
    handleClick2,
    statusDataMember,
    setStatusDataMember,
    openTime,
    setOpenTime,
    openRefuse,
    setOpenRefuse,
  } = props;

  const closeHandler = useCallback((e) => {
    e.stopPropagation();
    setAnchorEl1(null);
  }, []);

  const agreedClick = useCallback(async () => {
    try {
      const data = await axios.post(
        `${REACT_APP_PUBLIC_MEETING}/api/v1/meetings/${meetingId}/members/${userId}/confirm`,
        {},
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `${cookieMaster.getCookie("access_token")}`,
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
    setStatusDataMember(!statusDataMember);
  }, [meetingId]);

  return (
    <>
      <Menu
        anchorEl={anchorEl1}
        keepMounted
        open={Boolean(anchorEl1)}
        onClose={closeHandler}
        style={{ margin: "55px 0 0 10px" }}
      >
        <MenuItem
          onClick={(e) => {
            agreedClick();
            closeHandler(e);
          }}
        >
          <Agreed /> <SpanNameStatus>Потвердить участие</SpanNameStatus>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            // timeClick();
            setOpenTime(!openTime);
            closeHandler(e);
          }}
        >
          <Time /> <SpanNameStatus>Предложить другое время</SpanNameStatus>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            // refusalClick();
            setOpenRefuse(!openRefuse);
            closeHandler(e);
          }}
        >
          <Refusal /> <SpanNameStatus>Отказаться от участия</SpanNameStatus>
        </MenuItem>
      </Menu>
    </>
  );
};

export default MemberStatus;
