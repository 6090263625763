// Выбор отдела для добавления пользователя в компанию

import React, { useState, useEffect } from "react"; //***************************** */
import withTitle from "../../../utils/hoc/withTitle";
import { connect, useSelector, useDispatch } from "react-redux";
import SelectWithSecondaryBtn from "../../../common/components/Selects/SelectWithUsers";
import { setUserDepartment } from "../actions";
import { State } from "../../../rootReducer";
import { getDepSelectData } from "../selectors";
import { SelectData, User } from "../../../common/types";
import NameWithInput from "../../../common/components/newComponent/Inputs/NameWithInput";
import {
  selectDepGroup,
  setDepFilter,
  setDepGroupType,
  showWindowCreateDepGroup,
  setDepCreate,
  setDepCreateFromUser
} from "../../Users/actions";

type Props = {
  depCreate: boolean;
  setDepCreate: (value: boolean) => void;
  setDepCreateFromUser: (value: boolean) => void;  
  depSelectData: SelectData;
  userId: number;
  currentUserInfo: User | null;
  setUserDepartment: (position: number[]) => void;
  setChangeDataUser: any;
  rolesUser: number[];
  title?: string;
  isAddUserInCompany?: boolean;
};

const DepartmentSelect: React.FC<Props> = ({
  depCreate,
  setDepCreate,
  setDepCreateFromUser,
  depSelectData,
  userId,
  currentUserInfo,
  setUserDepartment,
  setChangeDataUser,
  rolesUser,
  title,
  isAddUserInCompany,
}) => {
  const dispatch = useDispatch();

  let listUsers = [...depSelectData.list];
  listUsers.unshift({ text: " + Создать новый отдел", value: 0 });

  useEffect(() => {
    dispatch(setDepCreate(false));
  }, []);

  useEffect(() => {
    if (depCreate === true) {
      dispatch(setDepCreate(false));

      setUserDepartment([
        depSelectData.list[depSelectData.list.length - 1].value as number,
      ]);

      (userId !== 0 || isAddUserInCompany) &&
        setChangeDataUser((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            departments: [
              depSelectData.list[depSelectData.list.length - 1].value,
            ],
          },
          schedule: { ...prev.schedule },
        }));
    }
  }, [depCreate]);

  return (
    <NameWithInput title={title} iconName="bag">
      <SelectWithSecondaryBtn
        disabled={
          !(
            userId === 0 ||
            (userId === currentUserInfo?.id &&
              currentUserInfo!.roles.sort()[0] < rolesUser.sort()[0]) ||
            currentUserInfo?.roles.includes(1) ||
            currentUserInfo!.roles.sort()[0] < rolesUser.sort()[0]
          )
        }
        list={listUsers}
        selected={depSelectData.selected}
        selectHandler={(value) => {
          if (value === 0) {
            dispatch(setDepCreateFromUser(true));
            dispatch(setDepGroupType("deps"));
            dispatch(showWindowCreateDepGroup(true));
          } else {
            dispatch(setDepCreate(false));

            setUserDepartment([value as number]);

            (userId !== 0 || isAddUserInCompany) &&
              setChangeDataUser((prev) => ({
                ...prev,
                data: { ...prev.data, departments: [value] },
                schedule: { ...prev.schedule },
              }));
          }
        }}
      />
    </NameWithInput>
  );
};

const mapStateToProps = (state: State) => {
  return {
    depCreate: state.usersPage.depCreate,
    depSelectData: getDepSelectData(state),
    userId: state.newUser.id,
    rolesUser: state.newUser.roles,
    currentUserInfo: state.commonInfo.currentUserInfo,
  };
};

const mapDispatchToProps = {
  setUserDepartment: setUserDepartment,
  showWindowCreateDepGroup,
  setDepCreate,
  setDepCreateFromUser
};

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentSelect);
