import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import {
  AccordionIcon2,
  AccordionIcon7,
} from "../../../../../../img/projectIcons";
import Button from "@material-ui/core/Button";
import CommonCirclePreloader from "../../../../../../common/components/CommonInfoPreloader/CommonCirclePreloader";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MeetingTaskSection from "./Tasks/MeetingTaskSection";
import MeetingTableMiniHead from "./Tasks/MeetingTableMiniHead";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {
  selectTask,
  setTaskPriority,
  setShownTaskInfoPlate,
  setIsMeetingTask,
  setIdQuestion
} from "../../../../../TaskInfoPlate/actions";
import { useContext } from "react";
import { useStateContext } from "../../../../../../useStateContext";
import axios from "axios";
import { cookieMaster } from "../../../../../../utils/CookieMaster";
import { Task } from "../../../../../../common/types";
import CreateQuestion from "../Questions/CreateQuestion";
import { CreateNotif } from "../../../../../../utils/createNotification";
import { connect } from "react-redux";
import { State } from "../../../../../../rootReducer";
import { getSelectedTask } from "../../../../../TaskInfoPlate/selectors";
import {formatStringDate} from "../../../../../../common/format";


let REACT_APP_PUBLIC_MEETING = `${process.env.REACT_APP_PUBLIC_MEETING}`;

interface Props {
  item: any;
  setOpenAccord(v: boolean): void;
  getQuestions: (id: any) => void;
  meeting: any;
  isShownTaskInfoPlate: boolean;
  selectedTask: Task | null;
  isSendingCreateNewTasks: boolean;
  priorities: any;
}

interface idTasks {
  question_id: number;
  task_id: number;
}

const DivRight = styled.div`
  display: flex;
  align-items: center;
`;
const DivLeft = styled.div`
  display: flex;
  align-items: center;
`;

const TextSection = styled.h2`
  display: inline;
  width: 400px;
  color: #292a34;
  line-height: 24px;
  text-decoration: none;
  font-size: 16px;
`;

const useStyles = makeStyles((theme) => ({
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
    "&.Mui-expanded": {
      margin: "0",
      border: "1px solid rgba(41, 42, 52, 0.5)",
    },
  },
  MuiAccordionSym: {
    "&>.MuiAccordionSummary-content": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    ".Mui-expanded": {
      minWidth: "48px",
      border: "1px solid #36EA0",
    },
  },
  MuiAccordionrootNone: {
    "&>.MuiAccordionSummary-root:hover": {
      cursor: "default",
    },
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
}));

const MeetingSectionsTask: React.FC<Props> = ({
  setOpenAccord: setOpenAccordQuestion,
  item,
  meeting,
  getQuestions,
  isShownTaskInfoPlate,
  selectedTask,
  isSendingCreateNewTasks,
  priorities
}) => {
  const [openAccord, setOpenAccord] = useState<boolean>(false);
  const [openPatchQuestion, setOpenPatchQuestion] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();

  // @ts-ignore
const {  setStartTaskTime  } = useContext(useStateContext);

  const userId = Number(localStorage.getItem("user_id"));

  const [idTasks, setIdTasks] = useState<idTasks[]>();
  const [tasks, setTasks] = useState<Task[]>();
  const [nameQuestions, setNameQuestions] = useState<string>(item.name);

  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: any) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const DivIcons2 = styled.p(({ rotateLocal }) => ({
    transform: rotateLocal && "rotate(90deg)",
    width: "10px",
    height: "15px",
  }));

  const numbers = idTasks?.map((item) => item?.task_id).join(",");

  const clickHandler = (e: React.MouseEvent) => {
    // console.log("item",item)
    // setOpenAccord(false); // Сворачивание аккордиона с задачей при создании задачи
    // setOpenAccordQuestion(false); // Сворачивание аккордиона с повесткой при создании задачи
    e.stopPropagation(); 
    let priority = priorities.find((el) => el.slug === "medium") || priorities[1];
   // setIsMeetingTask(true);
   // setIdQuestion(item.id); // id повестки
    dispatch(setIsMeetingTask(true));
    dispatch(setIdQuestion(item.id));
    setStartTaskTime(new Date());
    dispatch(setTaskPriority(priority.id));
    dispatch(setShownTaskInfoPlate(true));
    handleClose(e);
  };

  const getTasks = useCallback(
    async (numbers) => {
      try {
        if (numbers) {
          const list = await axios.get(
            `${process.env.REACT_APP_PUBLIC_URL}/api/v1/tasks?ids=${numbers}`,
            {
              headers: {
                "company-id": `${localStorage.getItem("company_id")}`,
                "Content-type": "application/json",
                Authorization: `${cookieMaster.getCookie("access_token")}`,
              },
            }
          );
          if (list?.data?.data) {
            list.data.data.forEach((item, index, array) => {
              let begin = item.begin;
              let end = item.end;
              item.begin = formatStringDate(begin);
              item.end = formatStringDate(end);
            });
            setTasks(list.data.data);
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    [item, numbers, isShownTaskInfoPlate]
  );

  const getTasksId = useCallback(async () => {
    try {
      const list = await axios.get(
        `${process.env.REACT_APP_PUBLIC_MEETING}/api/v1/questions/${item.id}/tasks`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `${cookieMaster.getCookie("access_token")}`,
          },
        }
      );
      setIdTasks(list.data.data);
      getTasks(numbers);
    } catch (err) {
      console.log(err);
    }
  }, [item, numbers, isShownTaskInfoPlate]);

  useEffect(() => {
    getTasksId();
    getTasks(numbers);
  }, [isShownTaskInfoPlate, openAccord, numbers, isSendingCreateNewTasks]);

  const patchQuest = useCallback(async () => {
    try {
      const list = await axios.patch(
        `${process.env.REACT_APP_PUBLIC_MEETING}/api/v1/questions/${item.id}`,
        {
          name: nameQuestions,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `${cookieMaster.getCookie("access_token")}`,
          },
        }
      );
      setOpenPatchQuestion(!openPatchQuestion);
    } catch (err) {
      console.log(err);
    }
    getQuestions(item.meeting_id);
  }, [item, openPatchQuestion, nameQuestions]);

  const deleteQuest = useCallback(async (id) => {
    axios
      .delete(`${REACT_APP_PUBLIC_MEETING}/api/v1/questions/${id}`, {
        headers: {
          Authorization: `${cookieMaster.getCookie("access_token")}`,
        },
      })
      .then((res) => {
        CreateNotif(`Повестка удалена!`, "success");
        getQuestions(item.meeting_id);
      })
      .catch((e) => {
        CreateNotif(`Ошибка удаления повестки!`, "warning");
      });
    getQuestions(item.meeting_id);
  }, []);

  return (
    <>
      {openPatchQuestion ? (
        <CreateQuestion
          openAddQuestion={openPatchQuestion}
          setOpenAddQuestion={setOpenPatchQuestion}
          // idForQuestion={idForQuestion as number}
          addHandler={patchQuest}
          valueQuestions={nameQuestions}
          setValueQuestions={setNameQuestions}
        />
      ) : (
        ""
      )}
      <Accordion
        expanded={openAccord}
        onChange={(e, expanded) => {
          setOpenAccord(expanded);
          getTasks(numbers);
        }}
        classes={{
          root: classes.MuiAccordionroot,
        }}
        style={{
          width: "98%",
          boxShadow: "none",
          marginBottom: "10px",
          // border: "1px solid #436EA0",
          borderRadius: "4px 4px 0px 0px",
        }}
      >
        <AccordionSummary
          //   disableTouchRipple={!tasksState.length}
          classes={{
            root: classes.MuiAccordionSym,
          }}
          style={{
            height: "46px",
            overflow: "hidden",
            border: "1px solid #dbdbdb",
            borderRadius: "4px",
            //   marginBottom: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            padding: "0px 12px",
            minHeight: "46px",
          }}
        >
          <DivLeft>
            <div style={{ width: "70px", paddingLeft: "15px" }}>
              <DivIcons2 rotateLocal={openAccord ? openAccord : undefined}>
                <AccordionIcon2 />
              </DivIcons2>
            </div>
            <TextSection>{item.name}</TextSection>
          </DivLeft>
          <DivRight>
          {meeting.initiator_id === userId ||
            meeting.chairman.user_id === userId ||
            meeting.secretary.user_id === userId ? (  
            <Button onClick={handleClick}>
              <AccordionIcon7 />
            </Button>):null}
            
            {meeting.initiator_id === userId ||
            meeting.chairman.user_id === userId ||
            meeting.secretary.user_id === userId ? (
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={(e) => clickHandler(e)}>
                  Создать задачу
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    handleClose(e);
                    // editClickHandler();
                    setOpenPatchQuestion(!openPatchQuestion);
                  }}
                >
                  Редактировать
                </MenuItem>

                <MenuItem
                  onClick={(e) => {
                    handleClose(e);
                    // editClickHandler();
                    deleteQuest(item.id);
                  }}
                >
                  Удалить
                </MenuItem>
              </Menu>
            ) : null}
          </DivRight>
        </AccordionSummary>
        <AccordionDetails
          style={{
            backgroundColor: "#f4f4f4",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {tasks && <MeetingTableMiniHead />}
          {tasks ? (
            tasks?.map((item: any) => {
              return <MeetingTaskSection key={item.id} item={item} />;
            })
          ) : (
            <div style={{ textAlign: "center" }}>Задачи отсутствуют</div>
          )}
          {/* tasks && (
          <DivButton>
            <Button>Все задачи</Button>
            <ArrowForwardIosIcon
              style={{
                color: "#01b0e9",
                width: "17px",
                paddingTop: "2px",
              }}
            />
          </DivButton>
            ) */}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    priorities: state.commonInfo.priorities,
    selectedTask: getSelectedTask(state),
    isShownTaskInfoPlate: state.taskInfoPlate.isShownTaskInfoPlate,
    isSendingCreateNewTasks: state.taskInfoPlate.isSendingCreateNewTasks,
  };
};

const mapDispatchToProps = {
  selectTask,
  setTaskPriority,
  setShownTaskInfoPlate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MeetingSectionsTask);
