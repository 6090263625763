export default {
  date: {
    month_full: ["Január", "Február", "Marec", "Apríl", "Máj", "Jún", "Júl", "August", "September", "Október", "November", "December"],
    month_short: ["Jan", "Feb", "Mar", "Apr", "Máj", "Jún", "Júl", "Aug", "Sept", "Okt", "Nov", "Dec"],
    day_full: ["Nedeľa", "Pondelok", "Utorok", "Streda", "Štvrtok", "Piatok", "Sobota"],
    day_short: ["Ne", "Po", "Ut", "St", "Št", "Pi", "So"]
  },
  labels: {
    dhx_cal_today_button: "Dnes",
    day_tab: "Deň",
    week_tab: "Týždeň",
    month_tab: "Mesiac",
    new_event: "Nová udalosť",
    icon_save: "Uložiť",
    icon_cancel: "Späť",
    icon_details: "Detail",
    icon_edit: "Edituj",
    icon_delete: "Zmazať",
    confirm_closing: "Vaše zmeny nebudú uložené. Skutočne?",
    //Vaše změny budou ztraceny, opravdu ?
    confirm_deleting: "Udalosť bude natrvalo vymazaná. Skutočne?",
    section_description: "Poznámky",
    section_time: "Doba platnosti",
    /*recurring events*/
    confirm_recurring: "Prajete si upraviť celú radu opakovaných udalostí?",
    section_recurring: "Opakovanie udalosti",
    button_recurring: "Vypnuté",
    button_recurring_open: "Zapnuté",
    button_edit_series: "Upraviť opakovania",
    button_edit_occurrence: "Upraviť inštancie",
    /*agenda view extension*/
    agenda_tab: "Program",
    date: "Dátum",
    description: "Poznámka",
    /*year view extension*/
    year_tab: "Rok",
    full_day: "Celý deň",
    // Full day

    /*week agenda view extension*/
    week_agenda_tab: "Program",
    /*grid view extension*/
    grid_tab: "Mriežka",
    /* touch tooltip*/
    drag_to_create: "Drag to create",
    drag_to_move: "Drag to move",
    /* dhtmlx message default buttons */
    message_ok: "OK",
    message_cancel: "Cancel",
    /* wai aria labels for non-text controls */
    next: "Next",
    prev: "Previous",
    year: "Year",
    month: "Month",
    day: "Day",
    hour: "Hour",
    minute: "Minute",
    /* recurring event components */
    repeat_radio_day: "Denne",
    repeat_radio_week: "Týždenne",
    repeat_radio_month: "Mesaène",
    repeat_radio_year: "Roène",
    repeat_radio_day_type: "Každý",
    repeat_text_day_count: "deò",
    repeat_radio_day_type2: "Každý prac. deò",
    repeat_week: "Opakova každý",
    repeat_text_week_count: "týždeò v dòoch:",
    repeat_radio_month_type: "Opakova",
    repeat_radio_month_start: "On",
    repeat_text_month_day: "deò každý",
    repeat_text_month_count: "mesiac",
    repeat_text_month_count2_before: "každý",
    repeat_text_month_count2_after: "mesiac",
    repeat_year_label: "On",
    select_year_day2: "poèas",
    repeat_text_year_day: "deò",
    select_year_month: "mesiac",
    repeat_radio_end: "Bez dátumu ukonèenia",
    repeat_text_occurences_count: "udalostiach",
    repeat_radio_end3: "Ukonèi",
    repeat_radio_end2: "Po",
    month_for_recurring: ["Január", "Február", "Marec", "Apríl", "Máj", "Jún", "Júl", "August", "September", "Október", "November", "December"],
    day_for_recurring: ["Nede¾a", "Pondelok", "Utorok", "Streda", "Štvrtok", "Piatok", "Sobota"]
  }
};