import React, {useCallback, useState, useRef, useEffect} from 'react';
import AddButton from '../../../common/components/Buttons/AddButton';
// import DisplayTypeSelect from '../../../common/components/Selects/DisplayTypeSelect'
import UserProjectSelect from '../../../common/components/Selects/UserProjectSelect/UserProjectSelect';
import {Project, SelectData, Section, Task, User} from '../../../common/types';
import {connect, useDispatch, useSelector} from 'react-redux';
import {getProjects} from '../../Projects/selectors';
import {State} from '../../../rootReducer';

import DotsButton from '../../../common/components/Buttons/DotsButton';
import {openExistingProject} from '../../NewProject/actions';
import {useHistory, useParams} from 'react-router-dom';
import {fetchData} from '../../../utils/fetchData';
import TableSearch from '../../../common/components/TableSearch/TableSearch';

import FilterButton from '../../../common/components/Buttons/filterButton';
import ViewWeekRoundedIcon from '@material-ui/icons/ViewWeekRounded';
import ProjectsFilters from '../../Projects/components/ProjectFilters';
import ColumnsWindow from '../../../common/ColumnsCustomWindow';
import Button from '../../../common/components/newComponent/Buttons/Button';
import Toggler from '../../../common/components/Togglers/Toggler';
import {
    DefaultFilterTemplates,
    FilterTemplates,
    setFilters,
    ShowFiltersWindow,
} from '../../../common/actions';
import FiltersWindow from '../../../common/FiltersWindow';
import Icon from '../../../common/components/Icon';
import {cookieMaster} from '../../../utils/CookieMaster';
import RuleItem from './RuleItem';
import {createDeleteResolutionItem, setNewParametersToReducer} from "../actions";
import styled from "styled-components";
import {nanoid} from "nanoid";
import MultySelect from "../../../common/components/Selects/MultySelect";
import NameWithInput from "../../../common/components/newComponent/Inputs/NameWithInput";
import CustomInput from "../../../common/components/newComponent/Inputs/CustomInput";
import {TextareaAutosize} from "@material-ui/core";
import TaskDateTime from "../../../common/components/DatePickers/DateTimePiker";
import {begin} from "../../TaskInfoPlate/reducer";
import {initialState} from "../reducer";

type Props = {
    projects: Project[];
    sections: Section[];
    selectedProject: Project | null;
    openExistingProject: (project: Project) => void;
    currentUserRoles: number[] | undefined;
    tableFilter: string;
    projectId: number | null;
    appliedFiltersExist: boolean;
    showColumnsWindow: boolean;
    showFiltersWindow: boolean;
    tableOrTreeToggler: string;
    ShowFiltersWindow: () => void;
    current_user: number;
    filters: any;
    FilterTemplates: (data: {}) => void;
    DefaultFilterTemplates: (data) => void;
    setFilters: (
        data_from?: string,
        date_to?: string,
        executors?: number[],
        authors?: number[],
        statuses?: number[],
        actual?: boolean
    ) => void;
    // businessProcessesModalShow: (show: boolean) => void;
    setNewParametersToReducer: (obj: object) => void,
    reducerRules: any[],
    checkList: any[],
    commonUsers: User[],
    newRule: {
        agenda_item_id: number | null,
        name: string,
        begin: string,
        end: string,
        description: string,
        executors: number[]
    },
    createDeleteResolutionItem: (context: string, delete_index?: number) => void    // если послать без delete_index, то создаст. Если с ним - удалит
};


const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 36px;
  margin-right: 8px;
`;


const Rules: React.FC<Props> = ({
                                             // showFiltersWindow,
                                             // showColumnsWindow,
                                             // projectId,
                                             // projects,
                                             // selectedProject,
                                             // openExistingProject,
                                             currentUserRoles,
                                             // tableOrTreeToggler,
                                             // ShowFiltersWindow,
                                             // filters,
                                             setNewParametersToReducer,
                                             reducerRules,
                                             checkList,
                                             commonUsers,
                                             newRule,
                                             createDeleteResolutionItem
                                         }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [timer, setTimer] =
        useState<ReturnType<typeof setTimeout> | null>(null);
    // создал изолированную копию объекта, чтобы не перезаписать пропс
    const newRolesArr = currentUserRoles?.map((item) => {
        return item;
    });
    newRolesArr?.sort();

    const [showMenu, setShow] = useState<boolean>(false);
    const [ShowAddNewRule, setShowAddNewRule] = useState<boolean>(false);
    const {id} = useParams();
    const menu = useRef<HTMLDivElement>(null);
    const tasksOrder = useSelector(
        (state: State) => state.projectsPage.tasksOrder
    );
    const outsideClick = useCallback((e: Event) => {
        if (!menu.current?.contains(e.target as HTMLElement)) setShow(false);
    }, []);

    // useEffect(() => {
    //   document.addEventListener('click', outsideClick);
    //   return () => document.removeEventListener('click', outsideClick);
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    let company_id = localStorage.getItem('company_id');

    return <div style={{}}>

        <div style={{marginTop: '8px'}}>
            {/*существующие*/}
            {!!reducerRules.length && reducerRules.map(rule => (<RuleItem ruleItem={rule} key={nanoid()}/>))}
        </div>

        {!ShowAddNewRule && <div style={{marginTop: '8px', justifyContent: 'start'}}>
            <Button title={'Добавить правило'} design={'outline'} onClick={() => setShowAddNewRule(true)}/>
        </div>}

        {ShowAddNewRule && <div className={'addNewMeetingItem'}>

            <div style={{fontSize: '16px'}}>
                Новое правило
            </div>

            <NameWithInput title="Название" iconName="people">
                <TextareaAutosize value={newRule.name}
                                  onChange={arg => setNewParametersToReducer({
                                      newRule: {
                                          ...newRule,
                                          name: arg.target.value
                                      }
                                  })}/>
            </NameWithInput>

            <NameWithInput title="Описание" iconName="people">
                <TextareaAutosize value={newRule.description} onChange={arg => setNewParametersToReducer({
                    newRule: {
                        ...newRule,
                        description: arg.target.value
                    }
                })}/>
            </NameWithInput>

            <NameWithInput title="Пункт повестки" iconName="people">
                <MultySelect itemsList={checkList.map(item => {
                    return {
                        text: item.name,
                        value: item.id
                    }
                })}
                             radio={true}
                             selectedItems={
                                 newRule.agenda_item_id ? [checkList.find(item => item.id === newRule.agenda_item_id)].map(item => ({
                                             text: item.name,
                                             value: item.id
                                         }
                                     ))
                                     : []
                             }
                             addHandler={(value) => {
                                 setNewParametersToReducer({
                                     newRule: {
                                         ...newRule,
                                         agenda_item_id: value
                                     }
                                 })
                             }}
                             removeHandler={() => {
                                 setNewParametersToReducer({
                                     newRule: {
                                         ...newRule,
                                         agenda_item_id: null
                                     }
                                 })
                             }}
                />
            </NameWithInput>

            <NameWithInput title="На кого распространяется" iconName="people">
                <MultySelect itemsList={commonUsers.map(user => {
                    return {
                        text: user.name + ' ' + user.surname,
                        value: user.id
                    }
                })}
                             selectedItems={
                                 commonUsers.filter(user => newRule.executors.includes(user.id)).map(user => {
                                     return {
                                         text: user.name + ' ' + user.surname,
                                         value: user.id
                                     }
                                 })
                             }
                             addHandler={(value) => {
                                 setNewParametersToReducer({
                                     newRule: {
                                         ...newRule,
                                         executors: [...newRule.executors, value]
                                     }
                                 })
                             }}
                             removeHandler={(arg) => {
                                 setNewParametersToReducer({
                                     newRule: {
                                         ...newRule,
                                         executors: [...newRule.executors.filter(item => item !== arg)]
                                     }
                                 })
                             }}
                />
            </NameWithInput>

            <NameWithInput title="Начало" iconName="calendar-dark">
                <div style={{width: '232px'}}>
                    <TaskDateTime
                        acceptHandler={(val) => setNewParametersToReducer({
                            newRule: {
                                ...newRule,
                                begin: val
                            }
                        })}
                        date={
                            newRule.begin ? newRule.begin : ''
                        }
                    />
                </div>
            </NameWithInput>

            <NameWithInput title="Конец" iconName="calendar-dark">
                <div style={{width: '232px'}}>
                    <TaskDateTime
                        acceptHandler={(val) => setNewParametersToReducer({
                            newRule: {
                                ...newRule,
                                end: val
                            }
                        })}
                        date={
                            newRule.end ? newRule.end : ''
                        }
                    />
                </div>
            </NameWithInput>


            <FlexDiv style={{justifyContent: 'flex-end', margin: '0px'}}>
                <Button title={"Отмена"} design={'secondary'} style={{marginRight: '8px'}} onClick={() => {
                    setNewParametersToReducer({
                        newRule: {
                            ...initialState.newRule
                        }
                    })
                    setShowAddNewRule(false)
                }}/>
                <Button title={"Сохранить"}
                        disabled={newRule.name === '' || newRule.agenda_item_id === null || newRule.begin === ''}
                        onClick={() => {
                            createDeleteResolutionItem('rule')
                            setShowAddNewRule(false)
                        }}/>
            </FlexDiv>

        </div>}

    </div>
};

const mapStateToProps = (state: State) => {
    return {
        appliedFiltersExist: Boolean(
            Object.keys(state.projectsPage.filters.appliedFilters).length
        ),
        reducerRules: state.meetingsPage.meetingRules,
        projects: getProjects(state),
        // selectData: getProjectSelectData(state),
        selectedProject: state.projectsPage.selectedProject,
        sections: state.commonInfo.sections,
        // showFiltersWindow: state.projectsPage.filtersWindowShow,
        showFiltersWindow: state.commonInfo.filters.showFiltersWindow,
        currentUserRoles: state.commonInfo.currentUserInfo?.roles,
        tableFilter: state.projectsPage.tableFilter,
        projectId: state.projectsPage.selectedProject?.id || null,
        showColumnsWindow: state.projectsPage.showColumnsWindow,
        tableOrTreeToggler: state.projectsPage.tableOrTreeToggler,
        current_user: state.commonInfo.current_user,
        filters: state.commonInfo.filters,
        checkList: state.meetingsPage.checkList,
        commonUsers: state.commonInfo.users,
        newRule: state.meetingsPage.newRule,
    };
};

const mapDispatchToProps = {
    // fetchTasks,
    openExistingProject,
    ShowFiltersWindow,
    DefaultFilterTemplates,
    FilterTemplates,
    setFilters,
    setNewParametersToReducer,
    createDeleteResolutionItem
};

export default connect(mapStateToProps, mapDispatchToProps)(Rules);
