import { Button } from "@material-ui/core";
import React from "react";
import Icon from "../../../../common/components/Icon";
import styled from "styled-components";


const ButtonBox = styled.div`
  display: flex;
  Button {
    border: 1px solid #dbdbdb;
    height: 50px;
    margin: -5px 8px 0 10px;
    padding: 0px;
    min-width: 150px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #292a34;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    width: auto;
  }
`;



interface Props {
    setIsShowFilterMeeting: (mode: boolean) => void
}


const MeetingFilterButton:React.FC<Props> = ({setIsShowFilterMeeting}) => {
  return (
    <ButtonBox>
      <Button
        //   design={default_filter_active ? "outline" : "blue_lightest"}
        onClick={() => setIsShowFilterMeeting(true)}
      >
        <Icon
          name={"VectorFilter"}
          style={{
            marginRight: "5px",
            paddingBottom: "4px",
            marginLeft: "10px",
          }}
        />
        <span> Фильтр</span>
      </Button>
    </ButtonBox>
  );
};

export default MeetingFilterButton;
