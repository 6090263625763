import React from 'react';
import './styles.sass';
import Icon from '../Icon';
import styled from 'styled-components';
import palette from '../palette';

type Props = {
  url: string | null;
  name?: string;
  style?: object;
  isTooltip?: boolean;
};

const AvatarStyle = styled.div`
  overflow: hidden;
  border-radius: 50%;
`;

const CustomAvatarStyle = styled.div`
  color: ${palette.white};
  position: relative;
  display: flex;
  justify-content: center;
  background: #1baaf0;
  width: 100%;
  height: 100%;
  span {
    position: absolute;
    top: 50%;
    font-size: 14px;
    line-height: 0px;
  }
`;
const Avatar: React.FC<Props> = ({ url, name = '', style = {}, isTooltip = true }) => {
  let minName: string[] = [];
  name.split(' ').map((el) => {
    minName.push(typeof el[0] === 'string' ? el[0].toUpperCase() : el[0]);
  });
  return isTooltip ? (
      <AvatarStyle className='avatar' style={style}>
        {url !== null ? (
          <Icon src={url} alt="avatar" width="100%" height="100%" />
        ) : (
          <CustomAvatarStyle>
            <span>{minName.join('')}</span>
          </CustomAvatarStyle>
        )}
      </AvatarStyle>
  ) : (
    <AvatarStyle style={style}>
      {url !== null ? (
        <Icon src={url} alt="avatar" width="100%" height="100%" />
      ) : (
        <CustomAvatarStyle>
          <span>{minName.join('')}</span>
        </CustomAvatarStyle>
      )}
    </AvatarStyle>
  );
};

export default Avatar;
