import { nanoid } from 'nanoid';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateTask } from '../../../../common/actions';
import { State } from '../../../../rootReducer';
import { fetchTaskDependence } from '../../../Tasks/actions';
import {
  sendReqToChangeTemplRepTask,
  setChildTasks,
  setCyclicTaskToChange,
} from '../../actions';
import Dependence from '../Dependence';

const DependenceChild = ({ childTasks }) => {
  const dispatch = useDispatch();

  const { selectedTask, flagForActionCyclycTask, child_tasks } = useSelector(
    (state: State) => state.taskInfoPlate
  );

  return (
    <div>
      {childTasks.map((task) => {
        return (
          <Dependence
            task={task}
            icon="child-dep"
            key={nanoid()}
            dependence="child"
            onDelete={(taskId) => {
              if (selectedTask?.child_tasks?.length) {
                let found_item = selectedTask?.child_tasks?.findIndex(
                  (item) => item === taskId
                );
                if (found_item > -1) {
                  let copied_array = selectedTask?.child_tasks;
                  copied_array.splice(found_item, 1);
                  if ([0, null].indexOf(selectedTask.cyclic_task_id) === -1) {
                    // сравнение одного с несколькими
                    // эта задача вообще циклическая или сама по себе ?
                    // значит циклическая
                    if (flagForActionCyclycTask === '') {
                      dispatch(
                        setCyclicTaskToChange({
                          child_tasks: copied_array,
                        })
                      ); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                    } else if (flagForActionCyclycTask === 'task') {
                      dispatch(
                        updateTask(selectedTask.id, {
                          remove_child_tasks: [taskId],
                        })
                      );
                    } else {
                      dispatch(
                        updateTask(selectedTask.id, {
                          remove_child_tasks: [taskId],
                        })
                      );
                      dispatch(
                        sendReqToChangeTemplRepTask({
                          child_tasks: copied_array,
                        })
                      );
                    }
                  } else
                    dispatch(
                      updateTask(selectedTask.id, {
                        remove_child_tasks: [taskId],
                      })
                    );
                }
                setTimeout(() => {
                  dispatch(fetchTaskDependence(selectedTask!.id));
                }, 250);
              } else if (child_tasks.length > 0) {
                dispatch(
                  setChildTasks(child_tasks.filter((id) => id !== taskId))
                );
              }
            }}
          />
        );
      })}
    </div>
  );
};

export default DependenceChild;
