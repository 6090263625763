import React, { useEffect, useMemo, useState } from "react";
import UsersTableHead from "./UsersTableHead";
import UsersTableContent from "./UsersTableContent";
import { State } from "../../../rootReducer";
import { connect, useDispatch, useSelector } from "react-redux"; //****************************************** */
import { getGroupCounter } from "../selectors";
import { Department, Workgroup, User } from "../../../common/types";
import SelectedDep from "./SelectedDep";
import styled from "styled-components";
import { useRef } from "react";

type Props = {
  isFetching: boolean;
  plateType: string | null;
  selectedDepGroupId: number | null;
  groupsCounter: number;
  groups: Workgroup[];
  deps: Department[];
  depGroupUsers: User[];
  isLoading: boolean;
};

const WrapperListUser = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 0 0 40px;
  position: relative;
  height: 100%;
  width: 100%;
`;

const WrapperUsersBlock = styled.div`
  max-height: calc(100vh - 210px);
  overflow-y: auto;
`;

const LoaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; 
  transition: all 0.25s ease-in-out;
  backdrop-filter: blur(1px);
  will-change: opacity, visibility;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loader = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  border: 4px solid #f3f3f3;
  border-top: 4px solid #1BAAF0;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  animation: spin 1s linear infinite;
  top: 35%;
  left: 45%
`;

const UsersTable: React.FC<Props> = ({
  isFetching,
  groupsCounter,
  selectedDepGroupId,
  plateType,
  deps,
  groups,
  depGroupUsers,
  isLoading,
}) => {
  //******************************************************************* */
  const [isScroll, setScroll] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const depGroup = useMemo(() => {
    if (plateType === "deps") {
      return deps.find((dep) => dep.id === selectedDepGroupId);
    } else return groups.find((group) => group.id === selectedDepGroupId);
  }, [deps, groups, plateType, selectedDepGroupId, ref]);
  useEffect(() => {
    let widthScrol = ref?.current?.offsetWidth || 0;
    let widthTable = ref?.current?.scrollWidth || 0;
    if (widthScrol > widthTable) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  }, [ref, isFetching]);

  return (
    <WrapperListUser>
      {isLoading && (
        <LoaderOverlay>
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        </LoaderOverlay>
      )}
      <>
        {selectedDepGroupId && (
          <SelectedDep name={depGroup!.name} counter={groupsCounter} />
        )}
        <UsersTableHead isScroll={isScroll} />
        <WrapperUsersBlock ref={ref}>
          {depGroupUsers.length > 0 ? (
            <UsersTableContent />
          ) : (
            <div className="main_title" style={{ padding: 24 }}>
              Сотрудники не найдены
            </div>
          )}
        </WrapperUsersBlock>
      </>
    </WrapperListUser>
  );
};

const mapStateToProps = (state: State) => {
  return {
    isFetching: state.usersPage.isFetchUsers,
    selectedDepGroupId: state.usersPage.selectedDepGroupId,
    plateType: state.usersPage.depGroupPlateType,
    groupsCounter: getGroupCounter(state),
    groups: state.commonInfo.workgroups,
    deps: state.commonInfo.departments,
    depGroupUsers: state.usersPage.depGroupUsers,
    isLoading: state.usersPage.isLoading,
  };
};

export default connect(mapStateToProps)(UsersTable);
