import React, { useContext } from "react";
import { StatusContext } from "../../../context/status";
import "./SortBtn.scss";
import axios from "axios";

const SortBtn = ({ name }) => {
  const { filterMethod, setFilterMethod, setBpList, company_id, bearer } =
    useContext(StatusContext);

  return (
    <div
      className="sort-btn"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {name === "id1" && (
        <>
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/assets/ShapeBlack.svg`}
            alt="asc"
            className={`sort-btn__top-btn 
          ${filterMethod === "asc" ? "" : "sort-btn__top-btn__active"}`}
            onClick={() => {
              setFilterMethod("asc");

              axios
                .get(`${process.env.REACT_APP_BACKEND}/api/v1/bp?sort=id`, {
                  headers: {
                    "secret-token": document.cookie.replace(
                      /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                      "$1"
                    ),
                    Accept: "application/json",
                    "company-id": company_id,
                    Authorization: bearer,
                  },
                })
                .then((res) => {
                  setBpList(res.data.data);
                })
                .catch((e) => {
                  console.log(e);
                });
            }}
          />
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/assets/ShapeBlack.svg`}
            alt="desc"
            className={`sort-btn__bottom-btn 
        ${filterMethod === "desc" ? "" : "sort-btn__top-btn__active"}`}
            onClick={() => {
              setFilterMethod("desc");

              axios
                .get(`${process.env.REACT_APP_BACKEND}/api/v1/bp?sort=-id`, {
                  headers: {
                    "secret-token": document.cookie.replace(
                      /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                      "$1"
                    ),
                    Accept: "application/json",
                    "company-id": company_id,
                    Authorization: bearer,
                  },
                })
                .then((res) => {
                  setBpList(res.data.data);
                })
                .catch((e) => {
                  console.log(e);
                });
            }}
          />
        </>
      )}
      {name === "Наименование" && (
        <>
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/assets/ShapeBlack.svg`}
            alt="asc"
            className={`sort-btn__top-btn 
          ${filterMethod === "asc" ? "" : "sort-btn__top-btn__active"}`}
            onClick={() => {
              setFilterMethod("asc");

              axios
                .get(`${process.env.REACT_APP_BACKEND}/api/v1/bp?sort=name`, {
                  headers: {
                    "secret-token": document.cookie.replace(
                      /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                      "$1"
                    ),
                    Accept: "application/json",
                    "company-id": company_id,
                    Authorization: bearer,
                  },
                })
                .then((res) => {
                  setBpList(res.data.data);
                })
                .catch((e) => {
                  console.log(e);
                });
            }}
          />
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/assets/ShapeBlack.svg`}
            alt="desc"
            className={`sort-btn__bottom-btn 
        ${filterMethod === "desc" ? "" : "sort-btn__top-btn__active"}`}
            onClick={() => {
              setFilterMethod("desc");

              axios
                .get(`${process.env.REACT_APP_BACKEND}/api/v1/bp?sort=-name`, {
                  headers: {
                    "secret-token": document.cookie.replace(
                      /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                      "$1"
                    ),
                    Accept: "application/json",
                    "company-id": company_id,
                    Authorization: bearer,
                  },
                })
                .then((res) => {
                  setBpList(res.data.data);
                })
                .catch((e) => {
                  console.log(e);
                });
            }}
          />
        </>
      )}
      {name === "Статус" && (
        <>
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/assets/ShapeBlack.svg`}
            alt="asc"
            className={`sort-btn__top-btn 
          ${filterMethod === "asc" ? "" : "sort-btn__top-btn__active"}`}
            onClick={() => {
              setFilterMethod("asc");

              axios
                .get(`${process.env.REACT_APP_BACKEND}/api/v1/bp?sort=status`, {
                  headers: {
                    "secret-token": document.cookie.replace(
                      /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                      "$1"
                    ),
                    Accept: "application/json",
                    "company-id": company_id,
                    Authorization: bearer,
                  },
                })
                .then((res) => {
                  setBpList(res.data.data);
                })
                .catch((e) => {
                  console.log(e);
                });
            }}
          />
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/assets/ShapeBlack.svg`}
            alt="desc"
            className={`sort-btn__bottom-btn 
        ${filterMethod === "desc" ? "" : "sort-btn__top-btn__active"}`}
            onClick={() => {
              setFilterMethod("desc");

              axios
                .get(
                  `${process.env.REACT_APP_BACKEND}/api/v1/bp?sort=-status`,
                  {
                    headers: {
                      "secret-token": document.cookie.replace(
                        /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                        "$1"
                      ),
                      Accept: "application/json",
                      "company-id": company_id,
                      Authorization: bearer,
                    },
                  }
                )
                .then((res) => {
                  setBpList(res.data.data);
                })
                .catch((e) => {
                  console.log(e);
                });
            }}
          />
        </>
      )}
      {name === "Дедлайн" && (
        <>
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/assets/ShapeBlack.svg`}
            alt="asc"
            className={`sort-btn__top-btn 
          ${filterMethod === "asc" ? "" : "sort-btn__top-btn__active"}`}
            onClick={() => {
              setFilterMethod("asc");

              axios
                .get(
                  `${process.env.REACT_APP_BACKEND}/api/v1/bp?sort=deadline`,
                  {
                    headers: {
                      "secret-token": document.cookie.replace(
                        /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                        "$1"
                      ),
                      Accept: "application/json",
                      "company-id": company_id,
                      Authorization: bearer,
                    },
                  }
                )
                .then((res) => {
                  setBpList(res.data.data);
                })
                .catch((e) => {
                  console.log(e);
                });
            }}
          />
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/assets/ShapeBlack.svg`}
            alt="desc"
            className={`sort-btn__bottom-btn 
        ${filterMethod === "desc" ? "" : "sort-btn__top-btn__active"}`}
            onClick={() => {
              setFilterMethod("desc");

              axios
                .get(
                  `${process.env.REACT_APP_BACKEND}/api/v1/bp?sort=-deadline`,
                  {
                    headers: {
                      "secret-token": document.cookie.replace(
                        /(?:(?:^|.*;\s*)access_token_jwt\s*\=\s*([^;]*).*$)|^.*$/,
                        "$1"
                      ),
                      Accept: "application/json",
                      "company-id": company_id,
                      Authorization: bearer,
                    },
                  }
                )
                .then((res) => {
                  setBpList(res.data.data);
                })
                .catch((e) => {
                  console.log(e);
                });
            }}
          />
        </>
      )}
    </div>
  );
};

export default SortBtn;
