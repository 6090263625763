import {
    createChecklistItemsAction,
    createNewMeetingAction, createResolutionItemAction, editMeetingAction,
    fetchMeetingsAction, meetingHidePlateAction, patchResolutionItemAction, setMeetingDefaultActionsAction,
    setNewParametersToReducerAction,
    types,
    writeMeetingsAction,
} from './action_types'
import {Meeting, Task} from '../../common/types'
import {Project} from '../../common/types'


export const fetchMeetings = (tableFilter?: string): fetchMeetingsAction => ({
    type: types.FETCH_MEETINGS,
    tableFilter,
})


export const writeMeetings = (meetings: Meeting[]): writeMeetingsAction => ({
    type: types.WRITE_MEETINGS,
    meetings,
})


export const setNewParametersToReducer = (data: object): setNewParametersToReducerAction => ({
    type: types.SET_NEW_PARAMETERS_TO_REDUCER,
    data,
})

export const setMeetingDefaultValues = (): setMeetingDefaultActionsAction => ({
    type: types.SET_DEFAULT_TO_REDUCER
})


export const meetingPlateHidingSignal = (value: boolean): meetingHidePlateAction => ({
    type: types.MEETING_PLATE_HIDING_SIGNAL,
    value,
});


export const createNewMeeting = (): createNewMeetingAction => ({
    type: types.CREATE_NEW_MEETING
});


export const editMeeting = (): editMeetingAction => ({
    type: types.EDIT_MEETING
});


export const createChecklistItems = (): createChecklistItemsAction => ({
    type: types.CREATE_CHECKLIST_ITEMS
});


export const createDeleteResolutionItem = (context: string, delete_index?: number): createResolutionItemAction => ({
    type: types.CREATE_RESOLUTION_ITEM,
    context,
    delete_index
});


export const patchResolutionItem = (context: string, id: number, body: object): patchResolutionItemAction => ({
    type: types.PATCH_RESOLUTION_ITEM,
    context,
    id,
    body
});
