import { Button, FormControl, Input } from "@material-ui/core";
import React, { useEffect, useMemo, useRef, useState, forwardRef  } from "react";
import Icon from "../../../../common/components/Icon";
import CalendarIcon from "../../../../common/components/Icon/imgs/calendar_blank.svg";
import NameIcon from "../../../../common/components/Icon/imgs/name.svg";
import UsersIcon from "../../../../common/components/Icon/imgs/filterUser.svg";
import addIcon from "../../../../common/components/Icon/imgs/add.svg";
import CancelIcon from "../../../../common/components/Icon/imgs/cancel.svg";
import StatusIcon from "../../../../common/components/Icon/imgs/status.svg";
import QuestionIcon from "../../../../common/components/Icon/imgs/questions.svg";
// import {Approwed, Canceled, Finished,In_progress,On_approval} from "../Icons"
import { connect } from "react-redux";

import styled from "styled-components";
import { setDate } from "date-fns";
import { Filter } from "../../Filter";
import axios from "axios";
import { cookieMaster } from "../../../../utils/CookieMaster";
import { User } from "../../../../common/types";
import { nanoid } from "nanoid";
import { format as Fr } from "date-fns";
import Approved from "../Icons/approved";
import On_approval from "../Icons/on_approval";
import In_progress from "../Icons/in_progress";
import Finished from "../Icons/finished";
import Canceled from "../Icons/canceled";
import On_approvalactive from "../Icons/ImageWithColor/On_approval";
import ApprovedActive from "../Icons/ImageWithColor/Approved";
import In_progressActive from "../Icons/ImageWithColor/In_progress";
import FinishedActive from "../Icons/ImageWithColor/Finished";
import CanceledActive from "../Icons/ImageWithColor/Canceled";
import Select from "react-select";
import { styles } from "@material-ui/pickers/views/Calendar/Calendar";
import stylesModule from "./filter.module.css";
import { setSearchMeetings } from "../../../../common/actions";
import { State } from "../../../../rootReducer";

const MainWindow = styled.div`
  position: fixed;
  height: calc(100vh - 150px);
  max-width: 560px;
  max-height: 750px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  top: ${({ isShowFilterMeeting }) =>
    isShowFilterMeeting ? "152px" : "-1000%"};
  left: 335px;
  transition: right 0.8s ease;
  z-index: 5;
  border-radius: 12px;
  box-shadow: 3px 6px 16px #00000033;
  padding: 30px 40px 40px 40px;
  background-color: white;
`;

const MainTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;
const MainTitleButton = styled.div`
  width: 94px;
  color: black;
  cursor: pointer;
  font-size: 18px;
`;

const Item = styled.div`
  margin-top: 25px;
`;

const ItemHeader = styled.div`
  display: flex;
  gap: 20px;
`;
const Title = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #292a34;
`;
const InputBox = styled.div`
  margin: 18px 38px 18px 18px;
  display: flex;
  gap: 20px;
  Input,
  StyledSelect {
    width: 100%;
    padding: 20px 8px 8px 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: rgba(41, 42, 52, 0.87);
    background: rgba(0, 0, 0, 0.03);
    border-radius: 4px 4px 0px 0px;
  }
`;
const FilterRow = styled.div`
  display: flex;
`;
const AddUserButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 18px 38px 18px 18px;
  gap: 12px;
  color: rgb(1, 176, 233);
`;

const StatusContainer = styled.div`
  display: flex;
  margin: 18px 5px 18px 45px;
  gap: 10px;
`;
const StatusBox = styled.div`
  display: flex;
`;

const ButtonBox = styled.div`
  width: 100%;
  margin-top: 25px;
  display: flex;
  gap: 32px;
  Button {
    color: white;
    width: 163px;
    height: 50px;
    background: #01b0e9;
    border: 1px solid #01b0e9;
    borderradius: 4px;
    transition: all 0.3s easy 0s;
  }
  Button:hover {
    color: gray;
  }
`;
const StyledSelect = styled(Select)`
  /* Обертка React Select */
  .react-select-container {
    position: relative;
    width: 100% !important;
  }

  /* Основной блок Select */
  .react-select__control {
    display: flex;
    align-items: center;
    height: 56px;
    border: none;
    border-radius: 4px;
    box-shadow: none;
    background-color: #f2f2f2;
    transition: all 0.2s;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    cursor: pointer;
  }

  /* Активный Select */
  .react-select__control:hover {
    box-shadow: none;
    background-color: #e8e8e8;
    cursor: pointer;
  }

  /* Скрытие подчеркивания при фокусе на Select */
  .react-select__control--is-focused {
    box-shadow: none;
  }

  /* Скрытие стрелки Select */
  .react-select__indicator-separator {
    display: none;
  }

  /* Иконка стрелки */
  .react-select__indicator {
    color: #bdbdbd;
    margin-right: 12px;
    transition: transform 0.2s;
  }

  /* Иконка стрелки при наведении */
  .react-select__indicator:hover {
    color: #757575;
    transform: rotate(180deg);
  }

  /* Разделитель между опциями */
  .react-select__menu-list {
    border-top: 1px solid #e0e0e0;
  }

  /* Опции Select */
  .react-select__option {
    font-size: 16px;
    font-family: "Roboto", sans-serif;
  }

  /* Активная опция */
  .react-select__option--is-focused {
    background-color: #e8e8e8;
  }

  /* Выбранная опция */
  .react-select__option--is-selected {
    background-color: #d6d6d6;
    color: #212121;
  }
`;

interface Props {
  isShowFilterMeeting: boolean;
  setIsShowFilterMeeting: (mode: boolean) => void;
  changeFilter: () => void;
  setFilter: (mode: any) => void;
  filter: Filter;
  users: any;
  setSearchMeetings: (search: any) => void
}

const MeetingChangeFilter = forwardRef<HTMLDivElement, Props>(({
  changeFilter,
  setIsShowFilterMeeting,
  isShowFilterMeeting,
  setFilter,
  filter,
  users,
  setSearchMeetings
},ref) => {
  let token = cookieMaster.getCookie("access_token");

  const [clean, setClean] = useState(false);
  const [name, setName] = useState("");
  const [start_at, setStartAt] = useState("");
  const [format, setFormat] = useState<number>();
  const [location, setLocation] = useState("");
  const [chairman, setChairman] = useState<any>([]);
  const [secretary, setSecretary] = useState<any>([]);
  const [members, setMembers] = useState<any>([]);
  const [correctUsers, setCorrectUsers] = useState<any>();
  const [status, setStatus] = useState({
    on_approval: { active: false, value: 0 },
    approved: { active: false, value: 1 },
    in_progress: { active: false, value: 2 },
    finished: { active: false, value: 3 },
    canceled: { active: false, value: 4 },
  });

  const handleFilter = () => {
    setSearchMeetings(null)

    setFilter(
      new Filter({
        name,
        start_at,
        // format,
        location,
        chairman: chairman
          ?.map((chman: any) => chman?.value)
          .filter((item) => item),
        secretary: secretary
          ?.map((scrt: any) => scrt?.value)
          .filter((item) => item),
        members: members
          ?.map((memb: any) => memb?.value)
          .filter((item) => item),
        status: Object.values(status)
          .filter((item) => item.active)
          .map((item) => item.value),
      })
    );
    setIsShowFilterMeeting(false);
  };

  const handleChangeParticipants = (
    event,
    index: number,
    participants,
    setParticipants
  ) => {
    const newParticipant = [...participants];
    newParticipant[index] = { ...newParticipant[index], ...event };

    setParticipants(newParticipant);
  };

  const handleDeleteParticipants = (
    participant,
    i,
    participants,
    setParticipants
  ) => {
    setParticipants((prev) => [...prev.filter((item, ind) => i !== ind)]);
  };

  const handleChangeStatus = (e) => {
    setStatus((prev) => ({
      ...prev,
      [e.dataset.name]: {
        ...prev[e.dataset.name],
        active: !prev[e.dataset.name].active,
      },
    }));
  };

  useEffect(() => {
    setName(filter.name ?? "");
    setStartAt(filter.start_at ?? "");
    setLocation(filter.location ?? "");

    filter?.status?.map((stat) => {
      const statusUser = Object.entries(status)?.find(
        (item) => item[1].value == stat
      );
      const statusTitle = statusUser !== undefined ? statusUser[0] : "";
      setStatus((prev) => ({
        ...prev,
        [statusTitle]: { ...prev[statusTitle], active: true },
      }));
    });

    const corrUsers = users?.map((user) => {
      return {
        value: user.id,
        label: user.name,
      };
    });
    setCorrectUsers(corrUsers);

    // chman//////////////////////////////////////////////////////////////////////
    const chman: any = [];
    for (let i = 0; i < filter.chairman.length; i++) {
      const chmanUser = corrUsers?.find(
        (item) => item.value == filter.chairman[i]
      );
      if (chmanUser) {
        chman.push({ ...chmanUser, id: nanoid() });
      }
    }
    setChairman(chman);
    // members//////////////////////////////////////////////////////////////////////

    const memb: any = [];
    for (let i = 0; i < filter.members.length; i++) {
      const memberUser = corrUsers?.find(
        (item) => item.value == filter.members[i]
      );
      if (memberUser) {
        memb.push({ ...memberUser, id: nanoid() });
      }
    }
    setMembers(memb);

    // secretary//////////////////////////////////////////////////////////////////////
    const scrt: any = [];
    for (let i = 0; i < filter.secretary.length; i++) {
      const secretarUser = corrUsers?.find(
        (item) => item.value == filter.secretary[i]
      );
      if (secretarUser) {
        scrt.push({ ...secretarUser, id: nanoid() });
      }
    }
    setSecretary(scrt);
  }, [clean]);

  const handleClean = () => {
    localStorage.removeItem("meeting_filter_params");
    setFilter(
      new Filter(
        JSON.parse(localStorage.getItem("meeting_filter_params") as string) ??
          {}
      )
    );
    setStatus({
      on_approval: { active: false, value: 0 },
      approved: { active: false, value: 1 },
      in_progress: { active: false, value: 2 },
      finished: { active: false, value: 3 },
      canceled: { active: false, value: 4 },
    });
    setClean(!clean);
  };

  
  return (
    <>
      <MainWindow isShowFilterMeeting={isShowFilterMeeting} ref ={ref}>
        {/* {clean && (
          <> */}
        <MainTitle>
          <MainTitleButton onClick={() => handleClean()}>
            Очистить
          </MainTitleButton>
          <MainTitleButton onClick={() => setIsShowFilterMeeting(false)}>
            Закрыть
          </MainTitleButton>
        </MainTitle>
        <Item>
          <FilterRow>
            <div>
              <ItemHeader>
                <img src={CalendarIcon} alt="" />
                <Title>Дата и время*</Title>
              </ItemHeader>
              <InputBox>
                <Input
                  type="date"
                  fullWidth
                  value={start_at}
                  onChange={(e) => setStartAt(e.target.value)}
                ></Input>
              </InputBox>
            </div>
            <div>
              <ItemHeader>
                <img src={NameIcon} alt="" />
                <Title>Название*</Title>
              </ItemHeader>
              <InputBox>
                <Input
                  placeholder="Название"
                  type="text"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></Input>
              </InputBox>
            </div>
          </FilterRow>
        </Item>
        <Item>
          <ItemHeader>
            <img src={UsersIcon} alt="" />
            <Title>Председатель*</Title>
          </ItemHeader>
          {chairman?.map((chman: any, i) => {
            return (
              <InputBox key={chman?.id}>
                <img
                  src={CancelIcon}
                  alt=""
                  onClick={() => {
                    handleDeleteParticipants(chman, i, chairman, setChairman);
                  }}
                />
                <div style={{ width: "100%", borderBottom: "1px solid gray" }}>
                  <StyledSelect
                    isClearable={true}
                    placeholder="Председатель"
                    defaultValue={chman}
                    options={correctUsers}
                    className="react-select"
                    classNamePrefix="react-select"
                    onChange={(e) => {
                      handleChangeParticipants(e, i, chairman, setChairman);
                    }}
                  />
                </div>
              </InputBox>
            );
          })}
          <AddUserButton
            onClick={() => setChairman((prev) => [...prev, { id: nanoid() }])}
          >
            <img src={addIcon} alt="" />
            <span>Добавить председателя</span>
          </AddUserButton>
        </Item>

        <Item>
          <ItemHeader>
            <img src={UsersIcon} alt="" />
            <Title>Секретарь*</Title>
          </ItemHeader>
          {secretary?.map((scrt: any, i) => {
            return (
              <InputBox key={scrt?.id}>
                <img
                  src={CancelIcon}
                  alt=""
                  onClick={() => {
                    handleDeleteParticipants(scrt, i, secretary, setSecretary);
                  }}
                />
                <div style={{ width: "100%", borderBottom: "1px solid gray" }}>
                  <StyledSelect
                    isClearable={true}
                    defaultValue={scrt}
                    options={correctUsers}
                    placeholder="Секретарь"
                    className="react-select"
                    classNamePrefix="react-select"
                    onChange={(e) => {
                      handleChangeParticipants(e, i, secretary, setSecretary);
                    }}
                  />
                </div>
              </InputBox>
            );
          })}
          <AddUserButton
            onClick={() => setSecretary((prev) => [...prev, { id: nanoid() }])}
          >
            <img src={addIcon} alt="" />
            <span>Добавить секретаря</span>
          </AddUserButton>
        </Item>
        <Item>
          <ItemHeader>
            <img src={UsersIcon} alt="" />
            <Title>Участники*</Title>
          </ItemHeader>
          {members?.map((member: any, i) => {
            return (
              <InputBox key={member?.id}>
                <img
                  src={CancelIcon}
                  alt=""
                  onClick={() => {
                    handleDeleteParticipants(member, i, members, setMembers);
                  }}
                />
                <div style={{ width: "100%", borderBottom: "1px solid gray" }}>
                  <StyledSelect
                    isClearable={true}
                    defaultValue={member}
                    options={correctUsers}
                    placeholder="Участник"
                    className="react-select"
                    classNamePrefix="react-select"
                    onChange={(e) => {
                      handleChangeParticipants(e, i, members, setMembers);
                    }}
                  />
                </div>
              </InputBox>
            );
          })}
          <AddUserButton
            onClick={() => setMembers((prev) => [...prev, { id: nanoid() }])}
          >
            <img src={addIcon} alt="" />
            <span>Добавить участника</span>
          </AddUserButton>
        </Item>
        <Item>
          <ItemHeader>
            <img src={StatusIcon} alt="" />
            <Title>Статус*</Title>
          </ItemHeader>
          <StatusContainer>
            <StatusBox
              title="на согласовании"
              data-name={"on_approval"}
              onClick={(e) => handleChangeStatus(e.currentTarget)}
            >
              {status.on_approval.active ? (
                <On_approvalactive props={""}  />
              ) : (
                <On_approval />
              )}
            </StatusBox>
            <StatusBox
              title="согласовано"
              data-name={"approved"}
              onClick={(e) => handleChangeStatus(e.currentTarget)}
            >
              {status.approved.active ? (
                <ApprovedActive props={""} />
              ) : (
                <Approved />
              )}
            </StatusBox>
            <StatusBox
              title="в процессе"
              data-name={"in_progress"}
              onClick={(e) => handleChangeStatus(e.currentTarget)}
            >
              {status.in_progress.active ? (
                <In_progressActive />
              ) : (
                <In_progress />
              )}
            </StatusBox>
            <StatusBox
              title="состоялось"
              data-name={"finished"}
              onClick={(e) => handleChangeStatus(e.currentTarget)}
            >
              {status.finished.active ? (
                <FinishedActive />
              ) : (
                <Finished />
              )}
            </StatusBox>
            <StatusBox
              title="отменено"
              data-name={"canceled"}
              onClick={(e) => handleChangeStatus(e.currentTarget)}
            >
              {status.canceled.active ? (
                <CanceledActive />
              ) : (
                <Canceled />
              )}
            </StatusBox>
          </StatusContainer>
        </Item>
        <ButtonBox>
          <Button
            onClick={() => {
              handleFilter();
            }}
          >
            Показать
          </Button>
        </ButtonBox>
        {/* </>
        )} */}
      </MainWindow>
    </>
  );
});

const mapStateToProps = (state: State) => {
  return {
    
    searchMeetings: state.commonInfo.searchMeetings
    
  };
};


const mapDispatchToProps = {
  setSearchMeetings,
  
};
export default connect(mapStateToProps,mapDispatchToProps)(MeetingChangeFilter) ;
